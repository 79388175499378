import { NavLink } from "react-router-dom";
import MoaRank from "../moa_rank";
import Tooltip from "../tooltip";

import styles from "./data_grid.module.css";

export interface INameCellProps {
  displayName?: string;
  descriptiveName?: string;
  url: string;
  moaRank?: number | null;
  moaTooltip?: string;
}

export default function NameCell({
  displayName,
  descriptiveName,
  url,
  moaRank = null,
  moaTooltip = "",
}: INameCellProps) {
  const hasRank = Number.isFinite(moaRank);

  //  some components might have a more detail descriptive name
  const name = descriptiveName || displayName;

  return (
    <div className={styles.nameCell} data-testid="data-grid_name-cell">
      {hasRank && moaRank && (
        <Tooltip tooltip={moaTooltip}>
          <MoaRank theme="primary" rank={moaRank} />
        </Tooltip>
      )}
      <NavLink to={`${url}`}>
        <span>{name}</span>
      </NavLink>
    </div>
  );
}
