import { AccountIcon } from "../icons";

import classes from "./account.module.css";

export default function Avatar() {
  return (
    <a href="/profile" className={classes.avatar}>
      <AccountIcon />
    </a>
  );
}
