import { useRef, ReactNode, SyntheticEvent } from "react";
import ReactDOM from "react-dom";

import classes from "./overlay.module.css";

export interface IOverlayProps {
  children?: ReactNode;
  classNames?: string[];
  onClick: () => void;
}

export default function Overlay({
  children = null,
  classNames = [],
  onClick,
}: IOverlayProps) {
  const containerRef = useRef(null);

  const handleOnClick = (evt: SyntheticEvent) => {
    //  compare evt target with the parent ref
    //  to see if click is on an overlay or one of the child (e.g. popup)
    const isOverlayClick = evt.target === containerRef.current;
    if (isOverlayClick && onClick) {
      onClick();
    }
  };

  const fullClassNames = [classes.overlay].concat(classNames);

  //  render popup as a child of body
  //  to avoid having it as a child of scrollable element
  //  and needing to calculate its position relative to the parent
  return ReactDOM.createPortal(
    <div
      ref={containerRef}
      className={fullClassNames.join(" ")}
      onClick={handleOnClick}
    >
      {children}
    </div>,
    document.body
  );
}
