import { interpolateNumber } from "d3-interpolate";

export function groupBy(arr: Record<string, string | number>[], key?: string) {
  if (!arr || !key) {
    return {};
  }

  return arr.reduce(
    (acc: Record<string, unknown[]>, x: Record<string, string | number>) => {
      (acc[x[key]] = acc[x[key]] || []).push(x);
      return acc;
    },
    {}
  );
}

export function flatten<T>(nestedArr: T[][]) {
  if (!nestedArr) {
    return nestedArr;
  }

  return nestedArr.reduce((acc: T[], arr: T[]) => {
    if (!arr) {
      return acc;
    }

    if (!Array.isArray(arr)) {
      acc.push(arr);
      return acc;
    }

    return acc.concat(arr);
  }, []);
}

export function interpolateBetweenNumbers(
  start: number,
  end: number,
  targetLen: number,
  interpolateFn = interpolateNumber
) {
  const fillNum = targetLen - 1;
  const interpolator = interpolateFn(start, end);
  const interpolated = new Array(fillNum).fill(null).map((_, i) => {
    return interpolator(i / fillNum);
  });

  //  remove the first item from the interpolated number since
  //  that's just repetition
  return [start, ...interpolated.slice(1), end];
}

export function interpolateArray(arr: unknown, targetLength: number) {
  if (!arr || !Array.isArray(arr)) {
    return arr;
  }

  //  calculate how many numbers between array items we need to generate
  //  keeping the number of generated numbers consistent between array items
  const fillDiff = targetLength - arr.length;
  const numIntervalsToFill = arr.length - 1;
  const fillPerItem = Math.floor(fillDiff / numIntervalsToFill);

  return arr.reduce((acc, currItem, index) => {
    if (index >= arr.length - 1) {
      return acc;
    }

    const isFirstItem = index === 0;
    const isLastItem = index === arr.length - 2;

    //  if it's a last item of an array (and the array has more than one item)
    //  just fill out the rest of missing values (sometimes it might not be possible)
    const fillForIteration =
      !isLastItem || isFirstItem
        ? fillPerItem + 2
        : targetLength - acc.length + 1;

    const nextItem = arr[index + 1];
    const interpolated = interpolateBetweenNumbers(
      currItem,
      nextItem,
      fillForIteration
    );

    const toAdd = !isFirstItem ? interpolated.slice(1) : interpolated;

    //  immutable concat
    return [...acc, ...toAdd];
  }, []);
}

export function extendArray(arr: unknown, numItems = 4, incrementFn = null) {
  if (!arr || !Array.isArray(arr) || !arr.length) {
    return [];
  }

  const extendValue = arr[0];

  let extendedValues = new Array(numItems).fill(extendValue);
  if (incrementFn) {
    extendedValues = extendedValues.map(incrementFn);
  }

  return extendedValues.concat(arr);
}
