import { ReactNode, MouseEvent, createElement } from "react";

import QueryLink from "../query_link";

import styles from "./button.module.css";

export interface IButtonProps {
  children?: ReactNode;
  className?: string;
  activeClassName?: string;
  type?: string;
  size?: string;
  role?: string;
  to?: string;
  download?: boolean;
  isDisabled?: boolean;
  onClick?: (evt: MouseEvent<HTMLElement>) => void;
  [key: string]: unknown;
}

export default function Button({
  children,
  className = "",
  activeClassName = "",
  type = "button",
  size = "large",
  role = "clear",
  to = "",
  download = false,
  isDisabled = false,
  onClick = () => null,
  ...restProps
}: IButtonProps) {
  //  add any potential extra class names
  const allClassNames = [
    styles.button,
    styles[size],
    styles[role],
    isDisabled && styles.disabled,
    className,
  ];

  //  do not use link for external or non-http links
  const isInternalLink =
    to && !to.startsWith("mailto") && !to.startsWith("http");
  const shouldUseAnchor = download || !isInternalLink;

  const elementName = to ? (shouldUseAnchor ? "a" : QueryLink) : "button";

  //  @TODO - not sure how to type properly
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const props: any = {
    className: allClassNames.join(" "),
    onClick,
    type,
    ...restProps,
  };

  if (to) {
    if (!shouldUseAnchor) {
      props.to = to;
    } else {
      props.href = to;
    }

    if (download) {
      props.download = true;
    }

    if (activeClassName) {
      props.activeClassName = activeClassName;
    }
  }

  //  might need to disable default navigation on QueryLink
  if (elementName === QueryLink) {
    props.isDisabled = isDisabled;
  }

  return createElement(elementName, props, children);
}
