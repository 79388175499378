import ReactDataGrid from "react-data-grid";

import { IDataGridColumn } from "@syntensor/common/components/data_grid_columns";

import styles from "./data_grid.module.css";

export function convertRemToPixels(rem: number) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

//  @TODO - won't work after resize
export const ROW_HEIGHT = convertRemToPixels(2.222);
export const DOUBLE_ROW_HEIGHT = ROW_HEIGHT * 2;
export const HEADER_ROW_HEIGHT = ROW_HEIGHT * 1.75;

export interface IDataGridProps<R> {
  rows: R[];
  columns: IDataGridColumn[];
  rowHeight?: number;
  headerRowHeight?: number;
}

export default function DataGrid<R>({
  rows,
  columns,
  rowHeight = ROW_HEIGHT,
  headerRowHeight = HEADER_ROW_HEIGHT,
}: IDataGridProps<R>) {
  return (
    <div className={styles.dataGrid}>
      <ReactDataGrid
        rows={rows}
        columns={columns}
        headerRowHeight={headerRowHeight}
        rowHeight={rowHeight}
      />
    </div>
  );
}
