import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  CookieStorage,
} from "amazon-cognito-identity-js";

export interface ICongitoUserSessionDataPaload {
  auth_time: number;
  client_id: string;
  "cognito:groups": string[];
  event_id: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  origin_jti: string;
  scope: string;
  sub: string;
  token_use: string;
  username: string;
}

export interface ICognitoUserToken {
  jwtToken: string;
  payload: ICongitoUserSessionDataPaload;
}

/* @TODO aws-cognito-identity-js type has Capitalised props for some reason */
export interface ICognitoUserSessionData {
  idToken: ICognitoUserToken;
  accessToken: ICognitoUserToken;
  refreshToken?: {
    token: string;
  };
}

export const NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED";

const userPoolId = process.env.REACT_APP_AWS_COGNITO_POOL_ID;
const clientId = process.env.REACT_APP_AWS_COGNITO_CLIENT_ID;
const domain = process.env.REACT_APP_COOKIE_DOMAIN || "";
const isLocalhost = process.env.REACT_APP_COOKIE_DOMAIN === "localhost";
const storage = new CookieStorage({
  domain,
  secure: isLocalhost ? false : true,
  sameSite: isLocalhost ? "lax" : "none",
});

const poolData = {
  UserPoolId: `${userPoolId}`,
  ClientId: `${clientId}`,
  Storage: storage,
};

const userPool = new CognitoUserPool(poolData);

let currentUser = userPool.getCurrentUser();

export function getCurrentUser() {
  return currentUser;
}

/**
 * Make login in always case insensitive
 */
export function sanitiseUsername(username: string) {
  return username && typeof username === "string"
    ? username.toLocaleLowerCase()
    : username;
}

function getCognitoUser(username: string) {
  const sanitisedUsername = sanitiseUsername(username);
  const userData = {
    Username: sanitisedUsername,
    Pool: userPool,
    Storage: storage,
  };
  const cognitoUser = new CognitoUser(userData);
  return cognitoUser;
}

export async function getSession() {
  if (!currentUser) {
    currentUser = userPool.getCurrentUser();
  }

  return new Promise<ICognitoUserSessionData>((resolve, reject) => {
    if (currentUser) {
      currentUser.getSession(
        (err: Error | null, session: ICognitoUserSessionData | null) => {
          if (err) {
            reject(err);
          } else if (session) {
            resolve(session);
          }
        }
      );
    } else {
      //  missing user so throw to display login screen
      throw new Error("Missing user");
    }
  }).catch((err) => {
    throw err;
  });
}

export async function signUpUserWithEmail(
  username: string,
  email: string,
  password: string
) {
  return new Promise((resolve, reject) => {
    const sanitisedUsername = sanitiseUsername(username);
    const attributeList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: email,
      }),
    ];

    userPool.signUp(
      sanitisedUsername,
      password,
      attributeList,
      [],
      function (err, res) {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      }
    );
  }).catch((err) => {
    throw err;
  });
}

export async function verifyCode(username: string, code: string) {
  return new Promise((resolve, reject) => {
    const sanitisedUsername = sanitiseUsername(username);
    const cognitoUser = getCognitoUser(sanitisedUsername);

    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export interface ICognitoNewPasswordRequireResponse {
  [key: string]: CognitoUserAttribute | string;
  action: string;
}

export async function signInWithEmail(username: string, password: string) {
  return new Promise<CognitoUserSession | ICognitoNewPasswordRequireResponse>(
    (resolve, reject) => {
      const sanitisedUsername = sanitiseUsername(username);
      const authenticationData = {
        Username: sanitisedUsername,
        Password: password,
      };
      const authenticationDetails = new AuthenticationDetails(
        authenticationData
      );

      currentUser = getCognitoUser(sanitisedUsername);

      currentUser.authenticateUser(authenticationDetails, {
        onSuccess: function (res) {
          resolve(res);
        },
        onFailure: function (err) {
          console.error("err", err);
          reject(err);
        },
        newPasswordRequired: function (res) {
          res.action = NEW_PASSWORD_REQUIRED;
          resolve(res as ICognitoNewPasswordRequireResponse);
        },
        customChallenge: () => {
          // @TODO - need to find out when a custom challenge could be issued
          console.error("Custom COGNITO challenge not implemented");
        },
      });
    }
  ).catch((err) => {
    throw err;
  });
}

export function signOut() {
  if (currentUser) {
    currentUser.signOut();
  }
}

export async function getAttributes() {
  return new Promise<CognitoUserAttribute[] | undefined>((resolve, reject) => {
    if (currentUser) {
      currentUser.getUserAttributes((err, attributes) => {
        if (err) {
          reject(err);
        } else {
          resolve(attributes);
        }
      });
    }
  }).catch((err) => {
    throw err;
  });
}

export async function setAttributes(attributes: Record<string, string>) {
  return new Promise<string>((resolve, reject) => {
    const attributeList = Object.keys(attributes).map((key) => {
      const attrValue = attributes[key];
      return new CognitoUserAttribute({ Name: key, Value: attrValue });
    });

    if (currentUser) {
      currentUser.updateAttributes(attributeList, (err, res) => {
        if (err) {
          reject(err);
        } else if (res) {
          resolve(res);
        } else {
          reject("Uknown error updating attributes");
        }
      });
    }
  }).catch((err) => {
    throw err;
  });
}

export async function sendCode(username: string) {
  return new Promise((resolve, reject) => {
    const sanitisedUsername = sanitiseUsername(username);
    const cognitoUser = getCognitoUser(sanitisedUsername);

    if (!cognitoUser) {
      reject(`could not find ${sanitisedUsername}`);
      return;
    }

    cognitoUser.forgotPassword({
      onSuccess: function (res) {
        resolve(res);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

export async function forgotPassword(
  username: string,
  code: string,
  password: string
) {
  return new Promise((resolve, reject) => {
    const sanitisedUsername = sanitiseUsername(username);
    const cognitoUser = getCognitoUser(sanitisedUsername);

    if (!cognitoUser) {
      reject(`could not find ${sanitisedUsername}`);
      return;
    }

    cognitoUser.confirmPassword(code, password, {
      onSuccess: function () {
        resolve("password updated");
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
}

export async function changePassword(oldPassword: string, newPassword: string) {
  return new Promise((resolve, reject) => {
    if (currentUser) {
      currentUser.changePassword(oldPassword, newPassword, function (err, res) {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
    }
  });
}

export async function completeNewPasswordChallenge(
  newPassword: string,
  userAttributes: any
) {
  const validAttr = { ...userAttributes };

  delete validAttr.email_verified;

  //  do not include email in the request payload,
  //  otherwise will trigger "Cannot modify an already provided email" error from Cognito
  //  @see https://stackoverflow.com/questions/71667989/aws-cognito-respond-to-new-password-required-challenge-returns-cannot-modify-an
  delete validAttr.email;

  return new Promise(function (resolve, reject) {
    if (currentUser) {
      currentUser.completeNewPasswordChallenge(newPassword, validAttr, {
        onSuccess: function (res) {
          resolve(res);
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    }
  });
}
