import { ReactNode } from "react";
import cn from "classnames";

import BackBtn from "@syntensor/common/components/back_btn";
import DynamicsContent from "../../../_content/metrics/graph/dynamics.mdx";
import { mdxComponents } from "../markdown_components";

import styles from "../metrics.module.css";

export default function MetricsGraphDynamics() {
  const components = {
    ...mdxComponents,
    h2: ({ children, ...props }: { children?: ReactNode }) => {
      return (
        <h2 {...props} className={styles.titleWithBtn}>
          <BackBtn />
          {children}
        </h2>
      );
    },
    Row: ({ withborder, ...props }: { withborder?: string }) => {
      const rowStyles = cn(styles.row, {
        [styles.rowWithBorder]: withborder !== "",
      });
      return <div {...props} className={rowStyles} />;
    },
  };

  return (
    <div className={styles.content}>
      <DynamicsContent components={components} />
    </div>
  );
}
