import { NodeDataDefinition } from "cytoscape";

import styles from "./tooltip.module.css";

export interface ITooltipProps {
  data: NodeDataDefinition;
}

export default function Tooltip({ data }: ITooltipProps) {
  const name = data.label;
  return <div className={styles.tooltip}>{name}</div>;
}
