import { SyntheticEvent, useState } from "react";
import { useCallback } from "react";

import Button from "@syntensor/common/components/button";
import { signOut } from "@syntensor/common/libs/auth/cognito";
import StatusMsgs from "./status_msgs";
import getCopy from "@syntensor/common/data/copy";

import styles from "./profile.module.css";

export default function Signout() {
  const [errorMsg, setErrorMsg] = useState("");

  const handleLogout = useCallback(
    async (evt: SyntheticEvent) => {
      evt.preventDefault();

      try {
        await signOut();
        window.location.reload();
      } catch (err: unknown) {
        console.log(err);
        if (err && err instanceof Error && err.message) {
          setErrorMsg(err.message);
        }
      }
    },
    [setErrorMsg]
  );

  return (
    <div className={styles.signout}>
      <div className={styles.content}>
        <div className={styles.row}>{getCopy("profile_signout_title")}</div>
        <div className={styles.buttonRow}>
          <Button role="secondary" onClick={handleLogout}>
            {getCopy("profile_signout_cta")}
          </Button>
        </div>

        <StatusMsgs errorMsg={errorMsg} />
      </div>
    </div>
  );
}
