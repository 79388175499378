import { Core } from "cytoscape";
import Button from "@syntensor/common/components/button";

import styles from "./explorer.module.css";

export interface ILayoutDownloadProps {
  cyt: Core | null;
  pathwayId: string;
}

export default function LayoutDownload({
  cyt,
  pathwayId,
}: ILayoutDownloadProps) {
  const handleDownloadClick = () => {
    if (cyt) {
      const nodes = cyt.nodes().map((node) => {
        const dataToSave = { ...node.data() };
        delete dataToSave["syntensor"];
        return {
          data: dataToSave, // Get data like id, etc.
          position: node.position(), // Get x and y positions
        };
      });
      const edges = cyt.edges().map((edge) => {
        return {
          data: edge.data(), // Get data like id, etc.
        };
      });
      const json = { nodes, edges };
      const normalizePathway = pathwayId.replace("PW_", "");
      const filename = `${normalizePathway}.syntensor.json`;
      const jsonStr = JSON.stringify(json);
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/json;charset=utf-8," + encodeURIComponent(jsonStr)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }

    console.log("handle download click");
  };

  return (
    <div
      className={styles.control}
      data-testid="download-json-btn"
      style={{ bottom: "var(--space-sm)", right: "var(--space-sm)" }}
    >
      <Button onClick={handleDownloadClick}>Download json?</Button>
    </div>
  );
}
