import { useContext, useCallback } from "react";

// import { login } from "@syntensor/common/data/fetch_data";
import { AuthContext } from "../auth/auth_context";
import { NEW_PASSWORD_REQUIRED } from "@syntensor/common/libs/auth/cognito";
import { NavLink } from "react-router-dom";
import Input from "@syntensor/common/components/input";
import SubmitBtn from "@syntensor/common/components/submit_btn";
import useFormInput from "@syntensor/common/hooks/use_form_input";
import useFormSubmit from "@syntensor/common/hooks/use_form_submit";
import StatusMsgs from "./status_msgs";
import getCopy from "@syntensor/common/data/copy";
import Logo from "@syntensor/common/components/logo";
import ProteinRender from "@syntensor/common/components/protein_render";

import classes from "./login.module.css";

export interface ILoginProps {
  onLoginSuccessful?: () => void;
  onTempLoginSuccessful?: () => void;
}

export default function Login({
  onLoginSuccessful = () => undefined,
  onTempLoginSuccessful = () => undefined,
}: ILoginProps) {
  const { signInWithEmail } = useContext(AuthContext);
  const { inputs, handleInputChange } = useFormInput();

  const onSubmit = useCallback(async () => {
    if (signInWithEmail === undefined) {
      console.error("Missing auth context signInWithEmail function");
      return;
    }

    const resp = await signInWithEmail(inputs.email, inputs.password);

    if (resp && "action" in resp && resp.action === NEW_PASSWORD_REQUIRED) {
      if (
        onTempLoginSuccessful &&
        typeof onTempLoginSuccessful === "function"
      ) {
        onTempLoginSuccessful();
      }
    } else {
      if (onLoginSuccessful && typeof onLoginSuccessful === "function") {
        onLoginSuccessful();
      }
    }
  }, [inputs, onLoginSuccessful, onTempLoginSuccessful, signInWithEmail]);

  const { isLoading, errorMsg, handleSubmit } = useFormSubmit({
    onSubmit,
  });

  return (
    <div className={classes.login}>
      <header className={classes.header}>
        <div className={classes.headerContainer}>
          <div>
            <h1 className={classes.headerTitle}>
              {getCopy("profile_login_company")}
            </h1>
          </div>
          <div>
            <a
              className={classes.headerAccessLink}
              href="https://syntensor.com/beta-access"
              target="_blank"
              rel="noreferrer"
            >
              {getCopy("profile_login_contact-us")}
            </a>
          </div>
        </div>
      </header>
      <div className={classes.content}>
        <div className={classes.contentContainer}>
          <div>
            <div className={classes.logo}>
              <Logo />
            </div>
            <h2
              className={classes.title}
              dangerouslySetInnerHTML={{
                __html: getCopy("profile_login_title"),
              }}
            />
            <form className={classes.form} onSubmit={handleSubmit}>
              <div className={classes.row}>
                <Input
                  required
                  name="email"
                  type="email"
                  placeholder={getCopy("profile_login_email")}
                  autoComplete="email"
                  value={inputs.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className={classes.row}>
                <Input
                  required
                  placeholder={getCopy("profile_login_password")}
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  value={inputs.password}
                  onChange={handleInputChange}
                />
              </div>
              <StatusMsgs errorMsg={errorMsg} />
              <div className={classes.row}>
                <NavLink className={classes.link} to="/profile/forgot-password">
                  {getCopy("profile_login_forgot-password")}
                </NavLink>
              </div>
              <div className={classes.buttonRow}>
                <SubmitBtn role="secondary" isLoading={isLoading} type="submit">
                  {getCopy("profile_login_cta")}
                </SubmitBtn>
              </div>
            </form>
          </div>
          <div>
            <div className={classes.protein}>
              <ProteinRender />
            </div>
          </div>
        </div>
      </div>
      <footer className={classes.footer}>
        <div className={classes.footerContainer}>
          <span
            className={classes.gray}
            dangerouslySetInnerHTML={{
              __html: getCopy("profile_login_copyright"),
            }}
          />
        </div>
      </footer>
    </div>
  );
}
