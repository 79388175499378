import { useContext } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";

import { IStudyMatchParams } from "../types";
import { StudyDataContext } from "..";
import { ProjectDataContext } from "../../projects/project";

import styles from "./title.module.css";

export default function StudieTitle() {
  const { params } = useRouteMatch<IStudyMatchParams>();
  const { projectId } = params;
  const projectLink = `/projects/${projectId}`;

  const projectData = useContext(ProjectDataContext);
  const studyData = useContext(StudyDataContext);

  return (
    <h2 className={styles.title} data-testid="projects_study_title">
      {projectData && (
        <NavLink className={styles.projectLink} to={projectLink}>
          {projectData.name}/
        </NavLink>
      )}
      {studyData && <span>{studyData.displayName}</span>}
    </h2>
  );
}
