import Button from "@syntensor/common/components/button";
import { SearchIcon } from "@syntensor/common/components/icons";
import Input from "@syntensor/common/components/input";
import Logo from "@syntensor/common/components/logo";
import getCopy from "@syntensor/common/data/copy";

import styles from "./placeholder.module.css";

export default function Placeholder() {
  return (
    <div className={styles.placeholder}>
      <div className={styles.content}>
        <h1 className={styles.title}>{getCopy("placeholder_header_title")}</h1>
        <div className={styles.body}>
          <div className={styles.logo}>
            <Logo />
          </div>
          <h2 className={styles.subtitle} data-testid="placeholder_title">
            {getCopy("placeholder_form_title")}
          </h2>
          <p className={styles.description}>
            {getCopy("placeholder_form_description")}
          </p>
          <form className={styles.form}>
            <div className={styles.diseaseInput}>
              <div className={styles.searchIcon}>
                <SearchIcon />
              </div>
              <Input
                placeholder={getCopy("placeholder_form_input-placeholder")}
              />
            </div>
            <div>
              <Input
                placeholder={getCopy("placeholder_form_drug-placeholder")}
              />
            </div>
            <div>
              <Button
                role="primary"
                isDisabled={true}
                data-testid="placeholder_generate-btn"
              >
                {getCopy("placeholder_form_cta")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
