export function setCookie(
  cname: string,
  cvalue: string,
  exhours: number,
  path = "/"
) {
  const d = new Date();
  d.setTime(d.getTime() + exhours * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=${path}`;
}

export function getCookie(cname: string) {
  const name = cname + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function hasCookie(cookieName: string) {
  return getCookie(cookieName) !== "";
}
