import getCopy from "@syntensor/common/data/copy";
import { PATHWAYS_COLORS_SCALE, PATHWAYS_MAX } from "../columns";

import styles from "../../components/legends/legends.module.css";

export default function PathwayLegend() {
  const background = `linear-gradient(
    90deg,
    ${PATHWAYS_COLORS_SCALE(0)},
    ${PATHWAYS_COLORS_SCALE(PATHWAYS_MAX)}
  )`;

  return (
    <div className={styles.proteinLegend}>
      <div className={styles.subtitle}>
        {getCopy("studies_pathway-legend_title")}
      </div>
      <div className={styles.scale}>
        <div className={styles.scaleGraphics}>
          <div className={styles.scaleGraphic}>
            <div className={styles.scaleGraphicBar} style={{ background }} />
            <div className={styles.scaleGraphicLabels}>
              <span className={styles.scaleGraphicLabels}>
                {getCopy("studies_pathway-legend_min")}
              </span>
              <span className={styles.scaleGraphicLabelsDark}>
                {getCopy("studies_pathway-legend_max")}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.scaleLabels}>
          <span>{getCopy("studies_pathway-legend_low-rank")}</span>
          <span>{getCopy("studies_pathway-legend_high-rank")}</span>
        </div>
      </div>
      <div className={styles.title}>
        {getCopy("studies_pathway-legend_legend")}
      </div>
    </div>
  );
}
