import { useRouteMatch } from "react-router-dom";
import { useContext } from "react";
import { useMutation } from "react-query";

import {
  EStudyType,
  IStudyContextData,
  // TStudyToxicityEndpoint,
  // TStudyPotencyEndpoint,
} from "@syntensor/common/types";
import getCopy from "@syntensor/common/data/copy";
import EditableTextField from "@syntensor/common/components/editable_text_field";
import { fetchStudyUpdate } from "@syntensor/common/data/fetch_data";
import { IProject } from "@syntensor/common/types";
import Button from "@syntensor/common/components/button";
import Select from "@syntensor/common/components/select";

import { StudyDataContext } from "..";
import StudiesTitle from "../title";
import { IStudyMatchParams } from "../types";
import { ProjectDataContext } from "../../projects/project";
import StudyComponentsGrid from "../components/components_grid";
import StudyPathwaysGrid from "../pathways/pathways_grid";
import { STUDY_URL_PREFIX } from "../routes";
import OverviewTopMoaProteinTable from "./top_proteins_table";
import CompoundsTable from "../endpoints/compounds_table";
import DiliTable from "./dili_table";

import overviewStyles from "./overview.module.css";
import styles from "../studies.module.css";

export const STUDY_OVERVIEW_URL_PREFIX = `/projects/:projectId/studies/:studyId/overview`;

export default function Overview() {
  const { params } = useRouteMatch<IStudyMatchParams>();
  const { studyId } = params;

  const studyMatch = useRouteMatch<IStudyMatchParams>(STUDY_URL_PREFIX);
  const url = studyMatch?.url;

  const projectData = useContext<IProject | null>(ProjectDataContext);
  const canEditDescription =
    projectData?.roleScope === "admin" ||
    projectData?.roleScope === "super-admin";

  const studyData = useContext<IStudyContextData | null>(StudyDataContext);
  const {
    targets = [],
    compounds = [],
    cellLines = [],
    topMoaProteins = [],
    endpoints = {},
    findings = "",
    description = "",
  } = studyData || {};

  const studyType = studyData?.type || EStudyType.MOA_DECONVOLUTION;

  const { mutate } = useMutation({
    mutationFn: (newFindings: string) => {
      return fetchStudyUpdate(studyId, { findings: newFindings });
    },
    onSuccess: () => {
      if (studyData && studyData.refreshStudyData) {
        studyData.refreshStudyData();
      }
    },
  });

  const updateAction = async (findings: string) => {
    return await mutate(findings);
  };

  const targetComponents = targets.filter((target) => {
    return target.componentType !== "pathway";
  });
  const targetPathways = targets.filter((target) => {
    return target.componentType === "pathway";
  });
  const targetComponentIds = targetComponents.map(
    (target) => target.syntensorId
  );
  const targetPathwaytIds = targetPathways.map((target) => target.syntensorId);

  const cellLine = cellLines && cellLines.length > 0 ? cellLines[0].id : "";

  //  for Toxicity/Potency study, we're passing dynamic keys to allow for more flexibility
  let channels: string[] = [];
  if (studyType === EStudyType.POTENCY) {
    channels = endpoints["potency"]?.channels || [];
  } else if (studyType === EStudyType.TOXICITY) {
    channels = Object.keys(endpoints);
  }
  // const channels = studyTypeendpoints
  //   ? endpoints.map((e: TStudyPotencyEndpoint | TStudyToxicityEndpoint) => {
  //       if (typeof e === "string") {
  //         return e;
  //       }

  //       return e.id;
  //     })
  //   : [];

  return (
    <>
      <div className={styles.titles}>
        <StudiesTitle />
      </div>
      <div className={overviewStyles.sectionHeader}>
        <div className={overviewStyles.description}>
          <h3 className={overviewStyles.title}>
            {getCopy("studies_overview_description-title")}
          </h3>
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        <div className={overviewStyles.findings}>
          <h3 className={overviewStyles.title}>
            {getCopy("studies_overview_findings-title")}
          </h3>
          <EditableTextField
            isEditEnabled={canEditDescription}
            value={findings}
            placeholder={getCopy("studies_overview_findings-placeholder")}
            updateAction={updateAction}
          />
        </div>
      </div>
      <div className={overviewStyles.content}>
        <div className={overviewStyles.controls}>
          <div className={styles.noGrowControl}>
            <Select
              label={getCopy("studies_components_cellline-dropdown")}
              options={cellLines}
              size="small"
            />
          </div>
        </div>
        {studyType === EStudyType.MOA_DECONVOLUTION && (
          <div className={overviewStyles.gridSection}>
            <h3 className={overviewStyles.gridTitle}>
              {getCopy("studies_overview_moa-title")}
            </h3>
            <p className={overviewStyles.gridDescription}>
              {getCopy("studies_overview_moa-description")}
            </p>
            <OverviewTopMoaProteinTable
              cellLine={cellLine}
              compounds={compounds}
              proteins={topMoaProteins}
            />
            <div className={overviewStyles.gridCta}>
              <Button role="subtle" to={`${url}/moa`}>
                {getCopy("studies_overview_moa-cta")}
              </Button>
            </div>
          </div>
        )}
        {studyType === EStudyType.POTENCY && (
          <div className={overviewStyles.gridSection}>
            <h3 className={overviewStyles.gridTitle}>
              {getCopy("studies_overview_potency-title")}
            </h3>
            <p className={overviewStyles.gridDescription}>
              {getCopy("studies_overview_potency-description")}
            </p>
            <CompoundsTable
              studyType={studyType}
              compounds={compounds}
              cellLine={cellLine}
              keys={channels}
            />
            <div className={overviewStyles.gridCta}>
              <Button role="subtle" to={`${url}/endpoints`}>
                {getCopy("studies_overview_potency-cta")}
              </Button>
            </div>
          </div>
        )}
        {studyType === EStudyType.TOXICITY && (
          <>
            <div className={overviewStyles.gridSection}>
              <h3 className={overviewStyles.gridTitle}>
                {getCopy("studies_overview_dili-title")}
              </h3>
              <p className={overviewStyles.gridDescription}>
                {getCopy("studies_overview_dili-description")}
              </p>
              <DiliTable compounds={compounds} cellLine={cellLine} />
            </div>
            <div className={overviewStyles.gridSection}>
              <h3 className={overviewStyles.gridTitle}>
                {getCopy("studies_overview_toxicity-title")}
              </h3>
              <p className={overviewStyles.gridDescription}>
                {getCopy("studies_overview_toxicity-title")}
              </p>
              <CompoundsTable
                studyType={studyType}
                cellLine={cellLine}
                compounds={compounds}
                keys={channels}
              />
              <div className={overviewStyles.gridCta}>
                <Button role="subtle" to={`${url}/endpoints`}>
                  {getCopy("studies_overview_toxicity-cta")}
                </Button>
              </div>
            </div>
          </>
        )}
        <div className={overviewStyles.gridSection}>
          <h3 className={overviewStyles.gridTitle}>
            {getCopy("studies_overview_components-title")}
          </h3>
          <p className={overviewStyles.gridDescription}>
            {getCopy("studies_overview_components-description")}
          </p>
          <div className={overviewStyles.gridContent}>
            <StudyComponentsGrid
              ids={targetComponentIds}
              hasFilters={false}
              hasLoadMore={false}
              keepPreviousData={true}
            />
          </div>
          <div className={overviewStyles.gridCta}>
            <Button role="subtle" to={`${url}/components`}>
              {getCopy("studies_overview_components-cta")}
            </Button>
          </div>
        </div>
        <div className={overviewStyles.gridSection}>
          <h3 className={overviewStyles.gridTitle}>
            {getCopy("studies_overview_pathways-title")}
          </h3>
          <p className={overviewStyles.gridDescription}>
            {getCopy("studies_overview_pathways-description")}
          </p>
          <div className={overviewStyles.gridContent}>
            <StudyPathwaysGrid
              ids={targetPathwaytIds}
              hasFilters={false}
              hasLoadMore={false}
              keepPreviousData={true}
            />
          </div>
          <div className={overviewStyles.gridCta}>
            <Button role="subtle" to={`${url}/pathways`}>
              {getCopy("studies_overview_pathways-cta")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
