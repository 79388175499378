import { MouseEvent, useEffect, useState } from "react";

import { NavLink, useLocation, useRouteMatch } from "react-router-dom";

import Logo from "@syntensor/common/components/logo";
import {
  EIconSize,
  EqualizerIcon,
  MenuIcon,
  MenuOpenIcon,
  ProjectsIcon,
  UsersIcon,
} from "@syntensor/common/components/icons";
import Account from "@syntensor/common/components/account";

import { IProjectMatchParams } from "../projects/types";
import { IsUserInAdminGroup } from "../auth/auth_context";
import ProjectSubmenu from "./project_submenu";

import styles from "./sidebar.module.css";

export default function Sidebar() {
  const isUserInAdminGroup = IsUserInAdminGroup();

  const projectsRouteMatch = useRouteMatch({
    path: "/projects",
    exact: true,
  });
  const projectRouteMatch = useRouteMatch<IProjectMatchParams>({
    path: "/projects/:projectId",
  });
  const metricRouteMatch = useRouteMatch({
    path: "/metrics/",
    exact: false,
  });
  const location = useLocation();
  // const locationRef = useRef(location.pathname);
  // console.log("location", location);

  const [isProjectSubmenuOpen, setIsProjectSubmenuOpen] = useState(false);

  const projectId = projectRouteMatch?.params?.projectId;
  const hasProjectSubmenu = projectId;

  const handleProjectIconClick = (evt: MouseEvent<HTMLAnchorElement>) => {
    if (hasProjectSubmenu) {
      evt.preventDefault();
      setIsProjectSubmenuOpen(!isProjectSubmenuOpen);
    }
  };

  useEffect(() => {
    setIsProjectSubmenuOpen(false);
  }, [location]);

  const isProjectLinkActive = !!projectRouteMatch;
  const isProjectsLinkActive = !!projectsRouteMatch;
  const isMetricsLinkActive = !!metricRouteMatch;

  const projectIcon = isProjectSubmenuOpen ? <MenuOpenIcon /> : <MenuIcon />;

  return (
    <>
      <nav className={styles.sidebar}>
        <ul className={styles.list}>
          <li>
            <NavLink className={styles.logoLink} to="/">
              <Logo />
            </NavLink>
          </li>
          {hasProjectSubmenu && (
            <li>
              <NavLink
                exact
                className={styles.link}
                activeClassName={styles.linkActive}
                isActive={() => isProjectLinkActive}
                to="/projects"
                onClick={handleProjectIconClick}
              >
                {projectIcon}
              </NavLink>
              {isProjectSubmenuOpen && (
                <div className={styles.submenu}>
                  <ProjectSubmenu projectId={projectId} />
                </div>
              )}
            </li>
          )}
          <li>
            <NavLink
              exact
              className={styles.link}
              activeClassName={styles.linkActive}
              isActive={() => isProjectsLinkActive}
              to="/projects"
            >
              <ProjectsIcon />
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              className={styles.link}
              activeClassName={styles.linkActive}
              isActive={() => isMetricsLinkActive}
              to="/metrics"
            >
              <EqualizerIcon />
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className={styles.userIconsWrapper}>
        <div>
          <Account />
        </div>
        {isUserInAdminGroup && (
          <div>
            <NavLink className={styles.users} to="/users">
              <UsersIcon size={EIconSize.LARGE} />
            </NavLink>
          </div>
        )}
      </div>
    </>
  );
}
