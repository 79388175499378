import Tooltip from "../tooltip";

import { ReactComponent as InfoGraphic } from "./icon.svg";

export interface IInfoBtnProps {
  children: string;
}

export default function InfoBtn({ children = "tooltip" }: IInfoBtnProps) {
  return (
    <Tooltip tooltip={children}>
      <InfoGraphic />
    </Tooltip>
  );
}
