import qs from "qs";

export const ARRAY_DELIMETER = ",";
export const OBJECT_DELIMETER = ":";

export const QS_OPTIONS = {};

export function getCurrentUrl() {
  return window && window.location ? window.location.href : "";
}

export function parseUrlSearchQuery(searchString: string) {
  //  get rid of the question mark if any
  const search =
    searchString.indexOf("?") === 0 ? searchString.slice(1) : searchString;
  return qs.parse(search, QS_OPTIONS);
}

export function getCurrentParams() {
  return parseUrlSearchQuery(window?.location?.search);
}

export function getCurrentParam(paramName: string) {
  const params = getCurrentParams();
  return params[paramName] || null;
}

export function getBackUrl() {
  return getCurrentParam("b");
}

export function encodeQueryParamsToSearchString(
  params: Record<string, unknown>
) {
  //  remove any empty params
  Object.keys(params).forEach((key) => {
    if (params[key] == null) {
      delete params[key];
    }
  });

  const paramsString = qs.stringify(params, QS_OPTIONS);
  return `?${paramsString}`;
}

export function parseLinkHeader(header: string) {
  if (header.length === 0) {
    throw new Error("Input must not be of zero length");
  }

  // Split parts by comma
  const parts = header.split(",");

  const links: Record<string, string> = {};

  // Parse each part into a named link
  for (let i = 0; i < parts.length; i++) {
    const section = parts[i].split(";");
    if (section.length !== 2) {
      throw new Error("section could not be split on ';'");
    }

    const url = section[0].replace(/<(.*)>/, "$1").trim();
    const name = section[1].replace(/rel="(.*)"/, "$1").trim();
    links[name] = url;
  }

  return links;
}
