import { useContext, useEffect, useRef, useState, ChangeEvent } from "react";
import { useQuery } from "react-query";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Core, NodeDefinition, NodeSingular } from "cytoscape";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";

import {
  IStudyContextData,
  IStudyContextMeta,
  ISelectableCompound,
  IStudyComponent,
} from "@syntensor/common/types";
import { fetchStudyComponents } from "@syntensor/common/data/fetch_data";
import { navigateTo } from "@syntensor/common/browser_history";
import { IStudyPathwayDetail } from "@syntensor/common/types";
import Select from "@syntensor/common/components/select";
import useDimensions from "@syntensor/common/hooks/use_dimensions";
import Button from "@syntensor/common/components/button";
import { TreeIcon } from "@syntensor/common/components/icons";

import {
  IStudyPathwayDetailComponentMatchParams,
  IStudyMatchParams,
  IStudyPathwayDetailMatchParams,
} from "../../types";
import { getSbgnStylesheet } from "./stylesheets";
import { ICytoscapeCallback, initCytoscape } from "./cytoscape";
import Tooltip from "./tooltip";
import CytoscapeOverlay from "./overlay/overlay";
import { StudyDataContext, StudyMetaContext } from "../..";
import { reactQueryOptions } from "../../../config";
import { STUDY_URL_PREFIX } from "../../routes";
import StudyPathwayDetailComponentDetail from "./component_detail";
import LayoutDownload from "./layout_download";
import PathwayDetailExplorerLegend from "./legend";
import { getCompoundColName } from "../../components/columns";
import PathwayHierarchyTree from "../hierarchy";
import { processGraphData } from "./process_graph_data";
import {
  selectIsPathwayHierarchyOpen,
  setIsPathwayHierarchyOpen,
} from "../../../store/ui";

import styles from "./explorer.module.css";

export const ENABLE_LAYOUT_DOWNNLOAD = false;

export interface IPathwayDetailExplorerProps {
  data: IStudyPathwayDetail;
  baseUrl?: string;
}

export default function PathwayDetailExplorer({
  data,
  baseUrl = `${STUDY_URL_PREFIX}/pathways`,
}: IPathwayDetailExplorerProps) {
  const { params } = useRouteMatch<IStudyPathwayDetailMatchParams>();
  const { pathwayId, studyId = "" } = params;
  const studyMatch = useRouteMatch<IStudyMatchParams>(baseUrl);
  const studyBaseUrl = studyMatch?.url || "";
  const pathwayComponentDetailUrl = `${baseUrl}/${pathwayId}/explorer/:componentId/:reactionId?`;
  const studyPathwayComponentDetailMatch =
    useRouteMatch<IStudyPathwayDetailComponentMatchParams>(
      pathwayComponentDetailUrl
    ) || { params: { componentId: "", reactionId: "" } };
  const { componentId, reactionId } =
    studyPathwayComponentDetailMatch?.params || {};
  const studyData = useContext<IStudyContextData | null>(StudyDataContext);
  const studyMeta = useContext<IStudyContextMeta | null>(StudyMetaContext);
  const { cellLines = [], compounds = [] } = studyData || {};
  const [tooltip, setTooltip] = useState<NodeDefinition | null>(null);
  const [cyt, setCyt] = useState<Core | null>(null);
  const [selectedCompounds, setSelectedCompounds] = useState<string[] | null>(
    null
  );
  const isPathwayHierarchyOpen = useSelector(selectIsPathwayHierarchyOpen);
  const dispatch = useDispatch();
  const [containerRef, dimension] = useDimensions();
  const hasDimension = dimension && dimension.width > 0 && dimension.height > 0;

  const selectedNode = useRef<NodeSingular | null>(null);

  const format = "sbgn";
  const layoutId = "preset";

  const sbgn = data ? data.sbgn : "";

  const toggleHierarchyDisplayed = () => {
    dispatch(setIsPathwayHierarchyOpen(!isPathwayHierarchyOpen));
  };

  const handleNodeMouseOver: ICytoscapeCallback = (node, position) => {
    if (node) {
      const data = node.data();

      //  we don't want tooltips for compartments
      //  or for the nodes which have syntensor data
      //  since they are already labeled
      const hasSyntensorData =
        node.data("syntensor") && node.data("syntensor").simulations;
      const isClassWithTooltip =
        data.class !== "compartment" && data.class !== "submap";
      if (isClassWithTooltip && !hasSyntensorData) {
        setTooltip({ data: node.data(), position });
      }
    }
  };

  const handleNodeMouseOut: ICytoscapeCallback = () => {
    setTooltip(null);
  };

  const handleNodeClick: ICytoscapeCallback = (node) => {
    if (node) {
      const { reactionId, syntensorId, reactomeId, syntensor } = node.data();
      const className = node.data("class");

      let urlNavigateTo = "";
      if (className === "submap") {
        //  different navigational pattern for submaps
        const syntensorPathwayId = `PW_${reactomeId}`;
        urlNavigateTo = `${studyBaseUrl}/${syntensorPathwayId}/explorer`;
      } else {
        //  check we're not clicking on a node with no syntensor data,
        //  in which case there's no point in displaying anything
        if (syntensor) {
          urlNavigateTo = `${studyBaseUrl}/${pathwayId}/explorer/${syntensorId}`;
          if (reactionId) {
            urlNavigateTo += `/${reactionId}`;
          }
        }
      }

      if (urlNavigateTo) {
        navigateTo(urlNavigateTo);
      }
    }
  };

  const handleNodeDblClick = () => {
    console.log("handle node dbl click");
  };

  const handleCompoundChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    setSelectedCompounds([evt.currentTarget.value]);
  };

  const callbacks = {
    onNodeMouseOver: handleNodeMouseOver,
    onNodeMouseOut: handleNodeMouseOut,
    onNodeClick: handleNodeClick,
    onNodeDblClick: handleNodeDblClick,
  };

  const cellLineIds = cellLines.map((cellLine) => cellLine.id);
  const cellLinesQueryKey = cellLineIds.join(",");
  const queryKey = [
    "studies",
    "components",
    studyId,
    cellLinesQueryKey,
    "protein",
    pathwayId,
  ];

  const { data: proteinData } = useQuery<{
    items: IStudyComponent[];
    after: string;
  }>(
    queryKey,
    async () => {
      if (compounds.length && cellLines.length) {
        const params = {
          filters: {
            pathwayId,
            componentType: ["protein", "complex"],
          },
          size: 10000, // get all proteins without pagination
        };
        return await fetchStudyComponents(studyId, params);
      }
    },
    { ...reactQueryOptions, retry: false }
  );

  useEffect(() => {
    if (!sbgn) {
      console.log("Missing SBGN data");
      return;
    }

    //  wait for global study data to load to prevent flicker of unstyled graph
    if (
      compounds.length === 0 ||
      cellLines.length === 0 ||
      !selectedCompounds
    ) {
      return;
    }

    //  wait for the perturbation data to load
    if (!proteinData) {
      return;
    }

    if (!hasDimension) {
      return;
    }

    const graph = sbgn;

    //  sometimes we might end up with a big graph and we want to bail
    if (graph.nodes.length > 5000) {
      console.error("Too many nodes, creating graph would hang up, bailing");
      return;
    }

    const processedGraph = processGraphData(graph, proteinData.items);

    const cyt = initCytoscape({
      graph: processedGraph,
      style: getSbgnStylesheet(selectedCompounds, cellLines),
      layoutId,
      callbacks: {
        onNodeMouseOver: handleNodeMouseOver,
        onNodeMouseOut: handleNodeMouseOut,
        onNodeClick: handleNodeClick,
      },
      dynamicLayout: ENABLE_LAYOUT_DOWNNLOAD,
    });
    setCyt(cyt);
  }, [
    pathwayId,
    format,
    layoutId,
    compounds,
    cellLines,
    selectedCompounds,
    proteinData,
    hasDimension,
  ]);

  //  select the positive/negative controls and first tested compound
  useEffect(() => {
    if (compounds.length > 0 && selectedCompounds === null) {
      // find first compound which is not control
      const firstNoControlCompound = compounds.find(
        (compound) => !compound.control
      );
      if (firstNoControlCompound) {
        setSelectedCompounds([firstNoControlCompound.compoundId]);
      }
    }
  }, [compounds]);

  const selectableCompounds: ISelectableCompound[] = compounds.map(
    (compound) => {
      const isSelected =
        selectedCompounds?.includes(compound.compoundId) || false;
      return {
        ...compound,
        isSelected,
      };
    }
  );

  useEffect(() => {
    if (cyt) {
      //  we might have closed
      if (selectedNode.current) {
        selectedNode.current.removeClass("selected");
        selectedNode.current = null;
      }

      if (componentId) {
        //  only protein subnodes have reactionId
        const selector = reactionId
          ? `[syntensorId="${componentId}"][reactionId="${reactionId}"]`
          : `[syntensorId="${componentId}"]`;
        const nodes = cyt.nodes(selector);

        //  do not force zoom in if canvas already zoomed more
        const zoom = Math.max(1.5, cyt.zoom());
        if (nodes && nodes.length > 0) {
          const node = nodes[0];
          node.addClass("selected");

          selectedNode.current = node;

          //  delay animating to make sure the sidepanel is rendered
          //  and the cytospace canvas is centered correctly
          setTimeout(() => {
            cyt.animate({
              center: {
                eles: node,
              },
              zoom,
              duration: 250,
            });
          }, 150);
        }
      }
    }
  }, [componentId, reactionId, cyt]);

  const compoundOptions = selectableCompounds.map((comp) => {
    return {
      id: comp.id,
      name: getCompoundColName(comp),
    };
  });

  const pathwayHierarchy =
    studyMeta && studyMeta.pathways ? studyMeta.pathways.hierarchy : [];

  return (
    <div ref={containerRef} className={styles.explorer}>
      {hasDimension && (
        <div
          className={styles.explorerInner}
          style={{ width: dimension.width, height: dimension.height }}
        >
          {isPathwayHierarchyOpen && (
            <div className={styles.hierarchy}>
              <PathwayHierarchyTree
                activePathwayId={pathwayId}
                hierarchy={pathwayHierarchy}
              />
            </div>
          )}
          <div className={styles.canvasWrapper}>
            <div
              id="cy"
              data-testid="explorer-canvas"
              className={styles.canvas}
            />
            {cyt && (
              <CytoscapeOverlay
                cyt={cyt}
                callbacks={callbacks}
                cellLines={cellLines}
                compounds={selectableCompounds}
                displayOnHover={true}
              />
            )}
            {tooltip && tooltip.position && (
              <div
                className={styles.tooltip}
                style={{
                  left: `${tooltip.position.x}px`,
                  top: `${tooltip.position.y}px`,
                }}
              >
                <Tooltip data={tooltip.data} />
              </div>
            )}
            <div className={styles.topLeftControl}>
              <div>
                <Button
                  className={cn(styles.hierarchyBtn, {
                    [styles.hiearchyBtnActive]: isPathwayHierarchyOpen,
                  })}
                  role="subtle"
                  size="small"
                  onClick={toggleHierarchyDisplayed}
                >
                  <TreeIcon />
                </Button>
              </div>
              <Select
                options={compoundOptions}
                onChange={handleCompoundChange}
                size="small"
              />
            </div>
            <div className={styles.topRightControl}>
              {sbgn && (
                <PathwayDetailExplorerLegend
                  nodes={sbgn.nodes}
                  edges={sbgn.edges}
                />
              )}
            </div>
            {ENABLE_LAYOUT_DOWNNLOAD && (
              <LayoutDownload cyt={cyt} pathwayId={pathwayId} />
            )}
          </div>
          <Switch>
            <Route
              path={pathwayComponentDetailUrl}
              render={() => (
                <StudyPathwayDetailComponentDetail
                  baseUrl={`${baseUrl}/:pathwayId/explorer/:componentId`}
                />
              )}
            />
          </Switch>
        </div>
      )}
    </div>
  );
}
