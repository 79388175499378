import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { useRouteMatch, useLocation } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";

import {
  fetchStudyComments,
  deleteStudyComment,
  postNewStudyComment,
  resolveStudyComment,
  editStudyComment,
} from "@syntensor/common/data/fetch_data";
import Comment from "@syntensor/common/components/comment";
import AddCommmentForm from "@syntensor/common/components/comment/add_comment_form";
import { IStudyComment } from "@syntensor/common/types";
import Loader from "@syntensor/common/components/loader";
import SearchInput from "@syntensor/common/components/search_form/search_input";
import { EIconSize, XIcon } from "@syntensor/common/components/icons";
import { highlightMatchTerm } from "@syntensor/common/utils/search_utils";
import Select from "@syntensor/common/components/select";
import getCopy from "@syntensor/common/data/copy";
import { setCommentIdQueryParam } from "@syntensor/common/browser_history";

import { IStudyMatchParams } from "../studies/types";
import { selectComments } from "../store/comments";
import { getCurrentUserSub } from "../auth/auth_context";
import { isUserProjectAdmin } from "../projects/project";
import { getNoCommentMessage, groupCommentsToReplies } from "./utils";

import styles from "./comments.module.css";

const useUrlQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function Comments() {
  const query = useUrlQuery();
  const commentId = query.get("commentId");

  const userSub = getCurrentUserSub();
  const isUserAdmin = isUserProjectAdmin();

  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const { params } = useRouteMatch<IStudyMatchParams>();
  const { studyId } = params;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submissionKey, setSubmissionKey] = useState(0);

  const dirtyCache = useRef(false);

  const { areCommentsOpen } = useSelector(selectComments);

  const [viewId, setViewId] = useState<string>("current");
  const [shouldDisplayAll, setShouldDisplayAll] = useState<boolean>(false);

  const [updatedCommentId, setUpdatedCommentId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [apiError, setApiError] = useState("");

  //  keep track of comments with displayed replies in the parent component
  //  this would typically be handled by the comment component rendering
  //  it's however because of search it's better to render replies in parent
  const [expandedComments, setExpandedComments] = useState<string[]>([]);

  const commentsQueryKey = [
    "comments",
    studyId,
    "page",
    viewId,
    shouldDisplayAll,
    searchTerm,
  ];

  //  make sure we don't unnecessarily refetch all comments queries
  //  which are the same for all pages
  if (viewId === "current") {
    commentsQueryKey.push(pathname);
  }

  const { data: comments = [], isLoading } = useQuery<IStudyComment[]>(
    commentsQueryKey,
    async () => {
      const params: Record<string, string | boolean> = {
        all: shouldDisplayAll,
      };

      if (viewId === "current") {
        params.path = pathname;
      }
      if (searchTerm) {
        params.s = searchTerm;
      }

      //  somehow we need to reset expanded comments
      // setExpandedComments([]);

      //  if we have modified comments in any way, make sure to get
      //  the next request fresh from the server
      const fetchOpts: RequestInit = {};
      if (dirtyCache.current) {
        fetchOpts.headers = { "cache-control": "no-cache" };
        dirtyCache.current = false;
      }

      const comments = await fetchStudyComments(studyId, params, fetchOpts);
      return comments;
    },
    {
      //  comments should be refetched every two minutes
      refetchInterval: 2000 * 60,

      //  if we're filtering with search, we don't want
      //  the previous comments to disappear
      keepPreviousData: !!searchTerm,
    }
  );

  const invalidateAllCommentQueries = () => {
    dirtyCache.current = true;
    queryClient.invalidateQueries({ queryKey: ["comments"] });
    setUpdatedCommentId("");
  };

  const handleApiError = (err: unknown) => {
    if (err instanceof Error) {
      setApiError(err.message);
    }
  };

  const handleApiSucces = () => {
    setApiError("");
  };

  const addCommentMutation = useMutation({
    mutationFn: async ({
      reply,
      parentCommentId,
    }: {
      reply: string;
      parentCommentId?: string;
    }) => {
      setIsSubmitting(true);

      const newCommentPayload: Record<string, string> = {
        text: reply,
        path: pathname,
      };
      if (parentCommentId) {
        newCommentPayload.parentCommentId = parentCommentId;
      }

      return await postNewStudyComment(studyId, newCommentPayload);
    },
    onError: handleApiError,
    onSuccess: () => {
      setIsSubmitting(false);
      setSubmissionKey((prev) => prev + 1);
      handleApiSucces();
    },
    onSettled: invalidateAllCommentQueries,
  });

  const deleteCommentMutation = useMutation({
    mutationFn: async (commentId: string) => {
      return await deleteStudyComment(studyId, commentId);
    },
    onError: handleApiError,
    onSuccess: handleApiSucces,
    onSettled: invalidateAllCommentQueries,
  });

  const editCommentMutation = useMutation({
    mutationFn: async ({
      commentId,
      reply,
    }: {
      commentId: string;
      reply: string;
    }) => {
      return await editStudyComment(studyId, commentId, reply);
    },
    onMutate: async ({
      commentId,
      reply,
    }: {
      commentId: string;
      reply: string;
    }) => {
      // cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: commentsQueryKey });

      // snapshot the previous value
      const prevComments = queryClient.getQueryData(
        commentsQueryKey
      ) as IStudyComment[];

      // Optimistically update to the new value
      const newComments = prevComments.map((comment) => {
        if (comment.id === commentId) {
          return { ...comment, text: reply };
        }

        return comment;
      });

      queryClient.setQueryData(commentsQueryKey, newComments);

      return { prevComments };
    },
    onError: (err, _, context) => {
      // roll back to the previous value
      queryClient.setQueryData(commentsQueryKey, context?.prevComments);
      handleApiError(err);
    },
    onSuccess: handleApiSucces,
    onSettled: invalidateAllCommentQueries,
  });

  const resolveCommentMutation = useMutation({
    mutationFn: async ({
      commentId,
      isResolved,
    }: {
      commentId: string;
      isResolved: boolean;
    }) => {
      return await resolveStudyComment(studyId, commentId, isResolved);
    },
    onError: handleApiError,
    onSuccess: handleApiSucces,
    onSettled: invalidateAllCommentQueries,
  });

  const handleTabClick = (evt: MouseEvent<HTMLButtonElement>) => {
    const { currentTarget } = evt;
    const viewId = currentTarget.dataset.id;
    if (viewId) {
      setViewId(viewId);
    }
  };

  const handleResolve = (evt: ChangeEvent<HTMLSelectElement>) => {
    setShouldDisplayAll(evt.target.value === "all");
  };

  const handleClickComment = (commentId: string, parentCommentId: string) => {
    if (parentCommentId) {
      if (!expandedComments.includes(parentCommentId)) {
        setExpandedComments((prev) => [...prev, parentCommentId]);
      }

      //  we might be coming from search, so we need to reset search term
      setSearchTerm("");

      setTimeout(() => {
        setCommentIdQueryParam(commentId);
      });
    }
  };

  const handleAddComment = (comment: string, parentCommentId?: string) => {
    if (comment) {
      addCommentMutation.mutate({ reply: comment, parentCommentId });
    }
  };

  const handleEditComment = (commentId: string, reply: string) => {
    setUpdatedCommentId(commentId);
    editCommentMutation.mutate({ commentId, reply });
  };

  const handleResolveComment = (commentId: string, isResolved: boolean) => {
    setUpdatedCommentId(commentId);
    resolveCommentMutation.mutate({ commentId, isResolved });
  };

  const handleDeleteComment = (commentId: string) => {
    const confirmed = confirm(getCopy("studies_comments_delete-confirmation"));
    if (confirmed) {
      setUpdatedCommentId(commentId);
      deleteCommentMutation.mutate(commentId);
    }
  };

  const handleClearSearch = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    setSearchTerm("");
  };

  const handleToggleRepliesDisplay = (
    commentId: string,
    shouldDisplayReplies: boolean
  ) => {
    if (shouldDisplayReplies && !expandedComments.includes(commentId)) {
      setExpandedComments((prev) => [...prev, commentId]);
    } else {
      setExpandedComments((prev) => prev.filter((id) => id !== commentId));
    }
  };

  useEffect(() => {
    if (commentId && areCommentsOpen && comments.length) {
      const commentEl = document.getElementById(`comment-${commentId}`);
      if (commentEl) {
        commentEl.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }
  }, [commentId, areCommentsOpen, comments]);

  //  if we are searching, highlight the match portions of comment
  const formattedComments = comments.map((comment) => {
    const text = searchTerm
      ? highlightMatchTerm(comment.text, searchTerm)
      : comment.text;
    const isUpdating = updatedCommentId === comment.id;
    return { ...comment, text, isUpdating };
  });

  const groupedComments = groupCommentsToReplies(formattedComments);

  if (!areCommentsOpen) {
    return null;
  }

  return (
    <div className={styles.comments}>
      <div className={styles.fixedHeader}>
        <div className={styles.header}>
          <h3 className={styles.title}>{getCopy("studies_comments_title")}</h3>
        </div>
        <ul className={styles.btns}>
          <li>
            <button
              data-id="current"
              className={`${styles.btn} ${
                viewId === "current" && styles.btnActive
              }`}
              onClick={handleTabClick}
            >
              {getCopy("studies_comments_current-page")}
            </button>
          </li>
          <li>
            <button
              data-id="all"
              className={`${styles.btn}  ${
                viewId === "all" && styles.btnActive
              }`}
              onClick={handleTabClick}
            >
              {getCopy("studies_comments_all-pages")}
            </button>
          </li>
        </ul>
        <form className={styles.search}>
          <div className={styles.searchInput}>
            <SearchInput
              isLoading={isLoading}
              name="search-comments"
              placeholder="Search comments..."
              onChange={(input: string) => setSearchTerm(input)}
              minChars={3}
              size="large"
              autoComplete="off"
              debounceDelay={200}
              defaultValue={searchTerm}
            />
            {searchTerm && (
              <div className={styles.searchClear}>
                <button
                  className={styles.searchClearBtn}
                  onClick={handleClearSearch}
                >
                  <XIcon size={EIconSize.SMALL} />
                </button>
              </div>
            )}
          </div>
          <Select
            size="small"
            onChange={handleResolve}
            options={[
              {
                id: "resolved",
                name: getCopy("studies_comments_unresolved-comments"),
              },
              { id: "all", name: getCopy("studies_comments_all-comments") },
            ]}
          />
        </form>
      </div>
      <div className={styles.content}>
        {apiError && <p className={styles.error}>{apiError}</p>}
        {!isLoading && groupedComments.length === 0 && (
          <div className={styles.emptyComments}>
            {getNoCommentMessage({ searchTerm, viewId, shouldDisplayAll })}
          </div>
        )}
        {isLoading && <Loader />}
        {groupedComments && (
          <ul className={styles.list}>
            {groupedComments.map(({ replies, ...comment }) => {
              // replies are coming from API descending chronologically
              const sortedReplies = replies
                ? replies.sort((replyA, replyB) => {
                    return (
                      new Date(replyA.createdAt).getTime() -
                      new Date(replyB.createdAt).getTime()
                    );
                  })
                : [];

              const areCommentsRepliesDisplayed = expandedComments.includes(
                comment.id
              );
              const canManage =
                isUserAdmin || comment.createdBy.sub === userSub;
              const needsPageLink = viewId === "all";

              return (
                <li key={comment.id} id={`comment-${comment.id}`}>
                  <Comment
                    {...comment}
                    isClickable={!!comment.parentCommentId && !!searchTerm}
                    needsPageLink={needsPageLink}
                    canManage={canManage}
                    areRepliesDisplayed={areCommentsRepliesDisplayed}
                    replies={replies}
                    onClick={handleClickComment}
                    onEdit={handleEditComment}
                    onResolve={handleResolveComment}
                    onDelete={handleDeleteComment}
                    onToggleRepliesDisplay={handleToggleRepliesDisplay}
                  />
                  <div className={styles.replies}>
                    {areCommentsRepliesDisplayed && (
                      <>
                        {sortedReplies.length > 0 && (
                          <ul className={styles.repliesList}>
                            {sortedReplies.map((reply) => {
                              const canManageReply =
                                isUserAdmin ||
                                comment.createdBy.sub === userSub;
                              return (
                                <li key={reply.id}>
                                  <Comment
                                    {...reply}
                                    isClickable={
                                      !!reply.parentCommentId && !!searchTerm
                                    }
                                    canManage={canManageReply}
                                    onEdit={handleEditComment}
                                    onResolve={handleResolveComment}
                                    onDelete={handleDeleteComment}
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        )}
                        {/*
                          we increment submission key after successful submission to re-mount
                          AddCommentForm and reset its state
                        */}
                        <div className={styles.replyForm} key={submissionKey}>
                          <AddCommmentForm
                            isLoading={isSubmitting}
                            theme="small"
                            parentCommentId={comment.id}
                            placeholder={getCopy(
                              "studies_comments_reply-placeholder"
                            )}
                            onSubmit={handleAddComment}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        )}
        <div className={styles.addComments} key={submissionKey}>
          <AddCommmentForm
            placeholder={getCopy("studies_comments_add-comment-placeholder")}
            isLoading={isSubmitting}
            onSubmit={handleAddComment}
          />
        </div>
      </div>
    </div>
  );
}
