import { Route, Switch } from "react-router-dom";
import AdminListUsers from "./list_users";
import AdminNewUserForm from "./new_user_form";

export default function AdminUsers() {
  return (
    <Switch>
      <Route path={`/users/new`} component={AdminNewUserForm} />
      <Route path={`/users`} component={AdminListUsers} />
    </Switch>
  );
}
