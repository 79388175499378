import { createAction, AnyAction } from "@reduxjs/toolkit";
import { IStoreState } from "./types";

export const toggleAreCommentsOpen = createAction<boolean>(
  "TOGGLE_ARE_COMMENTS_OPEN"
);
export const setHasStudyComments = createAction<boolean>(
  "SET_HAS_STUDY_COMMENTS"
);
export const setHasPageComments = createAction<boolean>(
  "SET_HAS_PAGE_COMMENTS"
);

export const selectComments = (state: IStoreState) => state.comments;

export const selectAreCommentsOpen = (state: IStoreState) => {
  const comments = selectComments(state);
  return comments.areCommentsOpen;
};

export const selectHasPageComments = (state: IStoreState) => {
  const comments = selectComments(state);
  return comments.hasPageComments;
};

export const initialState = {
  areCommentsOpen: false,
  hasStudyComments: false,
  hasPageComments: false,
};

export default function comments(state = initialState, action: AnyAction) {
  switch (action.type) {
    case toggleAreCommentsOpen.type:
      return {
        ...state,
        areCommentsOpen: action.payload,
      };
    case setHasPageComments.type:
      return {
        ...state,
        hasPageComments: action.payload,
      };
    case setHasStudyComments.type:
      return {
        ...state,
        hasStudyComments: action.payload,
      };
    default:
      return state;
  }
}
