import { ChangeEvent, useEffect, useState } from "react";

import Popup from "@syntensor/common/components/popup";
import Overlay from "@syntensor/common/components/popup/overlay";
import Button from "@syntensor/common/components/button";
import Toggle from "@syntensor/common/components/toggle";
import {
  getAnalyticalCookieConsent,
  getOptOutStatus,
  giveAnalyticalCookieConsent,
  optOutTracking,
  revokeAnalyticalCookieConsent,
  setTrackingPreference,
} from "@syntensor/common/analytics/analytics";
import { ReactComponent as MatomoLogo } from "./matomo_logo.svg";
import { XIcon } from "../icons";

import styles from "./tracking_popup.module.css";

export interface ITrackingPopupProps {
  title: string;
  cookies: Record<string, string>[];
  cookiePolicy: string;
  confirmLabel: string;
  acceptAll: string;
  onClose: () => void;
}

export default function TrackingPopup({
  title,
  cookies = [],
  cookiePolicy,
  confirmLabel,
  acceptAll,
  onClose = () => null,
}: ITrackingPopupProps) {
  const [hasCookieConsent, setHasCookieConsent] = useState(false);
  const [isUserOptOut, setIsUserOptOut] = useState(false);

  const onConfirmChoiceClick = () => {
    if (hasCookieConsent) {
      giveAnalyticalCookieConsent();
    } else {
      revokeAnalyticalCookieConsent();
    }
    if (isUserOptOut) {
      optOutTracking();
    }

    //  make sure we don't display banner again to the user
    setTrackingPreference();

    if (onClose && typeof onClose === "function") {
      onClose();
    }
  };

  const onAcceptAllClick = () => {
    giveAnalyticalCookieConsent();

    //  make sure we don't display banner again to the user
    setTrackingPreference();

    if (onClose && typeof onClose === "function") {
      onClose();
    }
  };

  const onAnalyticalCookiesChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    setHasCookieConsent(evt.target.checked);
    setIsUserOptOut(false);
  };

  const onTrackingOptOutChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    setIsUserOptOut(!evt.target.checked);
    setHasCookieConsent(false);
  };

  const updateTrackingState = async () => {
    const hasCookieConsent = await getAnalyticalCookieConsent();
    const isUserOptOut = await getOptOutStatus();

    setHasCookieConsent(hasCookieConsent);
    setIsUserOptOut(isUserOptOut);
  };

  useEffect(() => {
    updateTrackingState();
  }, []);

  return (
    <Overlay onClick={onClose}>
      <Popup classNames={[styles.trackingPopup]} onClose={onClose}>
        <div className={styles.header}>
          <h2
            className={styles.headerTitle}
            dangerouslySetInnerHTML={{ __html: title }}
            data-testid="management-content_title"
          />

          <div className={styles.closeBtn}>
            <Button onClick={onClose} data-testid="consent-popup_close-btn">
              <XIcon />
            </Button>
          </div>
        </div>
        {cookies && cookies.length > 0 && (
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <h3
                className={styles.sectionTitle}
                dangerouslySetInnerHTML={{ __html: cookies[0].title }}
              />
              <div
                className={styles.offLabel}
                dangerouslySetInnerHTML={{ __html: cookies[0].note }}
              />
            </div>
            <p dangerouslySetInnerHTML={{ __html: cookies[0].description }} />
          </div>
        )}
        {cookies && cookies.length > 1 && (
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <h3
                className={styles.sectionTitle}
                dangerouslySetInnerHTML={{ __html: cookies[1].title }}
              />
              <Toggle
                isChecked={hasCookieConsent}
                onChange={onAnalyticalCookiesChanged}
              />
            </div>
            <p dangerouslySetInnerHTML={{ __html: cookies[1].description }} />
          </div>
        )}
        {cookies && cookies.length > 2 && (
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <h3
                className={styles.sectionTitle}
                dangerouslySetInnerHTML={{ __html: cookies[2].title }}
              />
              <Toggle
                isChecked={!isUserOptOut}
                onChange={onTrackingOptOutChanged}
              />
            </div>
            <p dangerouslySetInnerHTML={{ __html: cookies[2].description }} />
          </div>
        )}
        {cookies && cookies.length > 3 && (
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <h3
                className={styles.sectionTitle}
                dangerouslySetInnerHTML={{ __html: cookies[3].title }}
              />
              <div
                className={styles.onLabel}
                dangerouslySetInnerHTML={{ __html: cookies[3].note }}
              />
            </div>
            <p dangerouslySetInnerHTML={{ __html: cookies[3].description }} />
          </div>
        )}
        <div className={styles.section}>
          <div className={styles.logo}>
            <MatomoLogo />
          </div>
        </div>
        <div className={styles.footer}>
          <div dangerouslySetInnerHTML={{ __html: cookiePolicy }} />
          <div className={styles.btns}>
            <Button
              className={styles.acceptBtn}
              onClick={onConfirmChoiceClick}
              role="secondary"
              data-testid="management-content_accept-confirm"
            >
              {confirmLabel}
            </Button>
            <Button
              className={styles.manageBtn}
              onClick={onAcceptAllClick}
              role="subtle"
              data-testid="management-content_accept-cta"
            >
              {acceptAll}
            </Button>
          </div>
        </div>
      </Popup>
    </Overlay>
  );
}
