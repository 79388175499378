import { useContext } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useQuery } from "react-query";

import {
  IStudyContextData,
  TStudyComponentDetail,
} from "@syntensor/common/types";
import getCopy from "@syntensor/common/data/copy";
import ViewToggle from "@syntensor/common/components/view_toggle";
import Loader from "@syntensor/common/components/loader";
import Error from "@syntensor/common/components/error";
import { fetchStudyComponentDetail } from "@syntensor/common/data/fetch_data";

import { reactQueryOptions } from "../../config";

import Header from "./header";
import StudiesTitle from "../title";
import { StudyDataContext } from "..";
import { STUDY_URL_PREFIX } from "../routes";
import { IStudyComponentDetailMatchParams } from "../types";
import StudyComponentsGrid from "../components/components_grid";
import StudyPathwaysGrid from "../pathways/pathways_grid";
import { getAssociatedTabsForComponentType } from "./get_associated_tabs";

import styles from "./component_detail.module.css";
import studiesStyles from "../studies.module.css";

export interface IStudyComponentDetailProps {
  isOverview?: boolean;
  baseUrl?: string;
}

export default function ComponentDetail({
  isOverview = false,
  baseUrl = `${STUDY_URL_PREFIX}/components`,
}: IStudyComponentDetailProps) {
  const studyMatch = useRouteMatch<IStudyComponentDetailMatchParams>(
    `${baseUrl}/:componentId`
  );
  const { params, url = "" } = studyMatch || {};
  const { studyId, componentId } = params || {};
  const studyData = useContext<IStudyContextData | null>(StudyDataContext);
  const {
    cellLines = [],
    compounds = [],
    componentTypes: availableComponentTypes = [],
  } = studyData || {};

  const cellLineIds = cellLines.map((cellLine) => cellLine.id);
  const cellLinesQueryKey = cellLineIds.join(",");
  const queryKey = [
    "studies",
    "components",
    studyId,
    componentId,
    cellLinesQueryKey,
  ];

  const {
    data: component,
    isLoading,
    error,
  } = useQuery<TStudyComponentDetail>(
    queryKey,
    async () => {
      if (componentId && studyId && compounds.length && cellLines.length) {
        return await fetchStudyComponentDetail(studyId, componentId, {
          cellLines: cellLineIds,
        });
      }

      return Promise.resolve(null);
    },
    { ...reactQueryOptions, retry: false }
  );

  if (error) {
    return <Error error={error} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (!component) {
    return null;
  }

  const { displayName, xrefs, componentType } = component;
  const description = xrefs?.geneDescription;
  const synonym = xrefs?.geneSynonym;

  const links = [
    {
      url: `https://pubmed.ncbi.nlm.nih.gov/?term=${displayName}`,
      label: getCopy("studies_component-detail_pubmed"),
    },
  ];

  if (xrefs) {
    if (componentType === "protein" && xrefs.linktoUniprot) {
      links.push({
        url: xrefs.linktoUniprot,
        label: getCopy("studies_component-detail_uniprot"),
      });
    } else if (xrefs.linkToEnsembl) {
      links.push({
        url: xrefs.linkToEnsembl,
        label: getCopy("studies_component-detail_ensembl"),
      });
    }
  }

  const availableSubPages = getAssociatedTabsForComponentType(
    componentType,
    url,
    availableComponentTypes
  );

  //  we should get the first enabled subpage
  const defaultSubPage = availableSubPages.find((page) => {
    return !page.isDisabled;
  });
  const defaultChildComponentType = defaultSubPage?.id;

  //  maybe no components
  if (!defaultChildComponentType) {
    return null;
  }

  return (
    <>
      {!isOverview && (
        <div className={studiesStyles.titles}>
          <StudiesTitle />
          <div className={studiesStyles.controlRow}>
            <div />
          </div>
        </div>
      )}
      <div className={studiesStyles.sectionHeader}>
        <Header
          {...component}
          isOverview={isOverview}
          cellLines={cellLineIds}
          description={description}
          synonym={synonym}
          links={links}
          compounds={compounds}
        />
      </div>
      <h3 className={styles.componentsTitles}>
        {getCopy("studies_component-detail_associated-title")}
      </h3>
      <div
        className={studiesStyles.sectionHeader}
        style={{ marginBottom: "calc(-1 * var(--space-xxl))" }}
      >
        <div className={studiesStyles.controls}>
          <div className={studiesStyles.controlRow}>
            <div className={studiesStyles.mainControl}>
              <ViewToggle theme="mainNav" btns={availableSubPages} />
            </div>
          </div>
        </div>
      </div>
      <div className={studiesStyles.sectionContent}>
        <Switch>
          {availableComponentTypes.includes("gene") && (
            <Route
              path={`${baseUrl}/:componentId/genes`}
              render={() => (
                <StudyComponentsGrid
                  hasFilters={false}
                  target={componentId}
                  componentType="gene"
                />
              )}
            />
          )}
          {availableComponentTypes.includes("transcript") && (
            <Route
              path={`${baseUrl}/:componentId/transcripts`}
              render={() => (
                <StudyComponentsGrid
                  hasFilters={false}
                  target={componentId}
                  componentType="transcript"
                />
              )}
            />
          )}
          {availableComponentTypes.includes("protein") && (
            <Route
              path={`${baseUrl}/:componentId/proteins`}
              render={() => (
                <StudyComponentsGrid
                  hasFilters={false}
                  target={componentId}
                  componentType="protein"
                />
              )}
            />
          )}
          {availableComponentTypes.includes("protein") && (
            <Route
              path={`${baseUrl}/:componentId/encoded-proteins`}
              render={() => (
                <StudyComponentsGrid
                  hasFilters={false}
                  target={componentId}
                  componentType="encodedProtein"
                />
              )}
            />
          )}
          {availableComponentTypes.includes("protein") && (
            <Route
              path={`${baseUrl}/:componentId/interacting-proteins`}
              render={() => (
                <StudyComponentsGrid
                  hasFilters={false}
                  target={componentId}
                  componentType="interactingProtein"
                />
              )}
            />
          )}
          {availableComponentTypes.includes("complex") && (
            <Route
              path={`${baseUrl}/:componentId/complexes`}
              render={() => (
                <StudyComponentsGrid
                  hasFilters={false}
                  target={componentId}
                  componentType="complex"
                />
              )}
            />
          )}
          <Route
            path={`${baseUrl}/:componentId/pathways`}
            render={() => (
              <StudyPathwaysGrid hasFilters={false} componentId={componentId} />
            )}
          />
          <Redirect
            from={`${baseUrl}/:componentId`}
            to={`${baseUrl}/:componentId/${defaultChildComponentType}s`}
          />
        </Switch>
      </div>
    </>
  );
}
