import { useMemo, ElementType } from "react";

export default function useMemoMdxComponent(
  Component: ElementType | null,
  props: Record<string, unknown>
) {
  //  wrap components to receive props from mdx file
  //  and returns function returning the component
  //  which is how mdx modules export component
  return useMemo(() => {
    //  eslint-disable-next-line react/display-name
    return (mdxProps: Record<string, unknown>) => {
      if (!Component) {
        return null;
      }

      return <Component {...mdxProps} {...props} />;
    };
  }, [Component, ...Object.values(props)]);
}
