import { useCallback, useRef, useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import cn from "classnames";

import SearchForm from "@syntensor/common/components/search_form";
import SearchFormFilters from "@syntensor/common/components/search_form/search_form_filters";
import { navigateWithQueryParams } from "@syntensor/common/browser_history";
import getCopy from "@syntensor/common/data/copy";
import Overlay from "@syntensor/common/components/popup/overlay";

import fetchAndFormatSearch from "./match_search";
import { IStudyMatchParams } from "../types";
import {
  STUDY_URL_PREFIX,
  getComponentDetailUrl,
  getPathwayDetailUrl,
  getStudyUrl,
} from "../routes";

import styles from "./search.module.css";
import { IStudyComponent, IStudyPathway } from "@syntensor/common/types";

export const VIEW_ALL_ITEM = {
  type: "showAll",
  name: "Show all results",
};

export const SEARCH_LIMIT = 8;

export interface ISearchProps {
  onSearchCancel?: () => void;
}

export default function Search({ onSearchCancel }: ISearchProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const blurTimeout = useRef<number>(null);

  const match = useRouteMatch<IStudyMatchParams>({
    path: STUDY_URL_PREFIX,
  });
  const { params } = match || {};
  const { projectId = "", studyId = "" } = params || {};

  const [componentTypeFilter, setComponentTypeFilter] = useState("all");
  const [isSearchFocus, setIsSearchFocus] = useState(false);

  const cachedSearchRef = useRef("");
  const cachedComponentTypeFiltersRef = useRef(componentTypeFilter);
  const isSubmittedRef = useRef(false);

  const [suggestions, setSuggestions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleItemSelected = (item: IStudyComponent | IStudyPathway) => {
    const isPathway = !("componentType" in item);
    const studyUrl = getStudyUrl(projectId, studyId);

    const url = !isPathway
      ? getComponentDetailUrl(studyUrl, item.syntensorId)
      : getPathwayDetailUrl(studyUrl, item.syntensorId);
    navigateWithQueryParams(url);
    handleClose();
  };

  const fetchSearch = useCallback(
    async (search: string) => {
      const componentType =
        componentTypeFilter !== "all" ? componentTypeFilter : null;
      const filters = { componentType };

      try {
        setIsLoading(true);
        setHasError(false);

        const { suggestions } = await fetchAndFormatSearch({
          studyId,
          search,
          filters,
        });

        //  check we're getting results for active search,
        //  and not e.g. delayed one with outdated search term
        //  or that we haven't already navigated to results page
        if (search !== cachedSearchRef.current || isSubmittedRef.current) {
          //  outdated results for one of the previous
          //  search terms, bail
          setIsLoading(false);
          return;
        }

        setSuggestions(suggestions);
        setIsLoading(false);
      } catch (err) {
        console.error(`Error fetching search results: ${err}`);
        setIsLoading(false);
        setHasError(true);
        alert("Error fetching search results");
      }
    },
    [studyId, componentTypeFilter, setSuggestions]
  );

  const handleSearch = useCallback(
    (search: string) => {
      fetchSearch(search);
    },
    [fetchSearch]
  );

  //  refetch results if some filter params change (e.g. component type)
  useEffect(() => {
    const hasSuggestions = suggestions;
    const haveFiltersChanged =
      cachedComponentTypeFiltersRef.current !== componentTypeFilter;

    if (cachedSearchRef.current && haveFiltersChanged && hasSuggestions) {
      fetchSearch(cachedSearchRef.current);
    }
  }, [componentTypeFilter, suggestions, fetchSearch]);

  //  focus input on mount
  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  useEffect(() => {
    cachedComponentTypeFiltersRef.current = componentTypeFilter;
  }, [componentTypeFilter]);

  const handleClose = () => {
    //  clear suggestion list
    setSuggestions(null);
    setIsSearchFocus(false);

    //  make sure we can focus input again
    if (inputRef && inputRef.current) {
      inputRef.current.blur();
    }

    if (onSearchCancel && typeof onSearchCancel === "function") {
      onSearchCancel();
    }
  };

  const handleFocus = () => {
    if (blurTimeout.current) {
      clearTimeout(blurTimeout.current);
    }
  };

  const handleInputFocus = () => {
    setIsSearchFocus(true);
  };

  const handleBlur = () => {
    if (blurTimeout.current) {
      clearTimeout(blurTimeout.current);
    }
  };

  const handleCancel = () => {
    //  clearing cached search term will make the component
    //  ignore the fetch results once they come in
    cachedSearchRef.current = "";

    //  also make sure we "cancel" any active search requests
    setIsLoading(false);

    handleClose();
  };

  const handleFilterSelect = (type: string) => {
    setComponentTypeFilter(type);
  };

  const handleChange = (input: string) => {
    //  make sure we enable submit button again
    //  for different search
    isSubmittedRef.current = false;

    //  cache searched string so that we can
    //  refetch results if some other params change (e.g. componenty type)
    cachedSearchRef.current = input;
  };

  const placeholder = isSearchFocus
    ? getCopy("studies_search_placeholder-active")
    : getCopy("studies_search_placeholder");

  const shouldDisplayFooter = isSearchFocus;
  const afterChildren = shouldDisplayFooter && (
    <div className={styles.footer}>
      <div>
        <SearchFormFilters
          activeComponentType={componentTypeFilter}
          onSelect={handleFilterSelect}
        />
      </div>
    </div>
  );

  const hasSearchTerm = !!cachedSearchRef.current;

  //  this version of the submit button
  const hasSubmitBtn = false;
  const hasCancelBtn = isSearchFocus || hasSearchTerm;

  //  for pages with footer displayed on focus, overlay
  //  the rest of the page content
  const shouldDisplayOverlay = isSearchFocus;

  const searchClasses = cn(styles.search, {
    [styles.searchWithTerm]: hasSearchTerm,
    [styles.searchWithError]: hasError,
  });

  return (
    <div className={searchClasses} data-testid="header_search">
      <SearchForm<IStudyComponent | IStudyPathway>
        isLoading={isLoading}
        placeholder={placeholder}
        suggestions={suggestions}
        defaultInputValue={cachedSearchRef.current}
        hasSubmitBtn={hasSubmitBtn}
        hasCancelBtn={hasCancelBtn}
        onChange={handleChange}
        onSearch={handleSearch}
        onCancel={handleCancel}
        onClose={handleClose}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onInputFocus={handleInputFocus}
        onItemSelected={handleItemSelected}
        autoComplete="off"
        name="study-search"
        inputRef={inputRef}
        afterChildren={afterChildren}
      />
      {shouldDisplayOverlay && (
        <Overlay classNames={[styles.overlay]} onClick={handleClose} />
      )}
    </div>
  );
}
