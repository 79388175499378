import cn from "classnames";

import {
  GENE_COLORS_SCALE,
  VALUE_FORMATTER,
  isGeneDarkColor,
} from "../../../components/columns";

import styles from "./compounds.module.css";

interface ICompoundBoxProps {
  id: string;
  label: string;
  value: number;
  isSelected: boolean;
  isDeselected: boolean;
  onMouseOver?: (compoundId: string) => void;
  onMouseOut?: (compoundId: string) => void;
  onClick?: (compoundId: string) => void;
}

export default function Compound({
  id,
  label,
  value,
  isDeselected,
  isSelected,
  onMouseOver,
  onMouseOut,
  onClick,
}: ICompoundBoxProps) {
  const isDarkColor = isGeneDarkColor(value);
  const backgroundColor = GENE_COLORS_SCALE(value);
  const formattedValue = VALUE_FORMATTER(value);

  const handleMouseOver = () => {
    if (onMouseOver) {
      onMouseOver(id);
    }
  };

  const handleMouseOut = () => {
    if (onMouseOut) {
      onMouseOut(id);
    }
  };

  const handleOnClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  const valueClassName = cn(styles.value, {
    [styles.darkValue]: isDarkColor,
  });

  const boxClassName = cn(styles.box, {
    [styles.selectedBox]: isSelected,
    [styles.deselectedBox]: isDeselected,
  });

  return (
    <div className={styles.compound}>
      <span
        className={boxClassName}
        style={{
          backgroundColor,
        }}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleOnClick}
      >
        <span className={valueClassName}>{formattedValue}</span>
      </span>
      {label && <span className={styles.label}>{label}</span>}
    </div>
  );
}
