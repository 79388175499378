import { ChangeEvent } from "react";

import classes from "./toggle.module.css";

export function renderToggleGraphic() {
  return (
    <span className={classes.graphic}>
      <span className={classes.graphicDot} />
    </span>
  );
}

export interface IToggleProps {
  label?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
  theme?: string;
}

export default function Toggle({
  label = "",
  isChecked = false,
  isDisabled = false,
  onChange = () => null,
  theme = "large",
}: IToggleProps) {
  const classNames = [classes.toggle, classes[theme]];

  return (
    <label className={classNames.join(" ")}>
      <input
        className={classes.input}
        checked={isChecked}
        type="checkbox"
        onChange={onChange}
        disabled={isDisabled}
      />
      {label && <span className={classes.label}>{label}</span>}
      {renderToggleGraphic()}
    </label>
  );
}
