import { useRouteMatch } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { fetchStudyPathways } from "@syntensor/common/data/fetch_data";
import {
  ESortDir,
  IStudyPathway,
  IStudyContextData,
} from "@syntensor/common/types";
import Error from "@syntensor/common/components/error";
import Loader from "@syntensor/common/components/loader";
import DataGrid from "@syntensor/common/components/data_grid";
import LoadMoreBtn from "@syntensor/common/components/load_more_btn";
import DataGridColumns from "@syntensor/common/components/data_grid_columns";
import { flatten } from "@syntensor/common/utils/array_utils";
import getCopy from "@syntensor/common/data/copy";

import GridHeader from "../components/components_grid_header";
import { EFilterTypes } from "../filters";
import { IStudyMatchParams } from "../types";
import { StudyDataContext } from "..";
import { reactQueryOptions } from "../../config";
import { getPathwaysColumns } from "./columns";
import { selectPathwayFilters } from "../../store/filters";
import { STUDY_URL_PREFIX } from "../routes";
import PathwayLegend from "./legends/pathway_legend";
import {
  selectPathwayColumns,
  updatePathwayColumns,
} from "../../store/columns";

import styles from "../studies.module.css";

export interface IStudyPathwaysGridProps {
  componentId?: string;
  ids?: string[];
  hasFilters?: boolean;
  hasLoadMore?: boolean;
  firstColWidth?: number;
  keepPreviousData?: boolean;
}

export default function StudyPathwaysGrid({
  ids,
  componentId = "",
  hasFilters = true,
  hasLoadMore = true,
  keepPreviousData = false,
  firstColWidth,
}: IStudyPathwaysGridProps) {
  const studyMatch = useRouteMatch<IStudyMatchParams>(STUDY_URL_PREFIX);
  const { projectId = "", studyId = "" } = studyMatch?.params || {};

  const [sortDir, setSortDir] = useState<ESortDir>(ESortDir.ASC);
  const [sortBy, setSortBy] = useState("");
  const [hasColumnsPopup, setHasColumnsPopup] = useState(false);
  const [isLegendDisplayed, setIsLegendDisplayed] = useState(false);
  const dataGridColumns = useSelector(selectPathwayColumns);
  const filters = useSelector(selectPathwayFilters);

  const dispatch = useDispatch();

  const studyData = useContext<IStudyContextData | null>(StudyDataContext);
  const { cellLines = [], compounds = [] } = studyData || {};

  const cellLineIds = cellLines.map((cellLine) => cellLine.id);
  const cellLinesQueryKey = cellLineIds.join(",");
  const idsQueryKey = ids ? ids.join(",") : [];
  const queryKey = [
    "studies",
    "pathways",
    studyId,
    cellLinesQueryKey,
    idsQueryKey,
    JSON.stringify(filters),
    sortBy,
    sortDir,
    componentId,
  ];

  const {
    data,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPreviousData,
  } = useInfiniteQuery<{ items: IStudyPathway[]; after: string }>(
    queryKey,
    async ({ pageParam = null }) => {
      if (sortBy) {
        const params: Record<
          string,
          string | Record<string, string | number | null | string[] | number[]>
        > = {
          filters: { ...filters },
          sortBy,
          sortDir,
          after: pageParam,
        };

        if (typeof params.filters === "object") {
          if (componentId) {
            params.filters.componentId = componentId;
          }

          if (ids && ids.length > 0) {
            params.filters.syntensorId = ids;
          }
        }

        return await fetchStudyPathways(studyId, params);
      }

      return [];
    },
    {
      ...reactQueryOptions,
      retry: false,
      keepPreviousData,
      getNextPageParam: (lastPage = { items: [], after: "" }) => {
        const { after } = lastPage;
        return after;
      },
    }
  );

  const handleSortClick = (sortBy: string, sortDir: ESortDir) => {
    if (sortBy) {
      setSortBy(sortBy);
    }
    if (sortDir) {
      setSortDir(sortDir);
    }
  };

  const handleHasColumnToggle = () => {
    setHasColumnsPopup(!hasColumnsPopup);
  };

  const handleToggleLegend = () => {
    setIsLegendDisplayed(!isLegendDisplayed);
  };

  const handleEditColumns = (
    toggleColumns: string | string[],
    isEnabled: boolean | boolean[]
  ) => {
    dispatch(updatePathwayColumns({ toggleColumns, isEnabled }));
  };

  //  set initial sort to the positive compound or just first one
  //  and get initial columns
  useEffect(() => {
    if (!sortBy && cellLines.length && compounds.length) {
      //  set initial sort
      const sortCompound =
        compounds.find((compound) => {
          return compound.control === "+";
        }) || compounds[0];
      setSortBy(
        `simulations.dtRank.${cellLineIds[0]}:${sortCompound.compoundId}`
      );
    }
  }, [cellLines, compounds]);

  const columns = getPathwaysColumns({
    cellLines: cellLineIds,
    compounds: compounds,
    projectId,
    studyId,
    sortBy,
    sortDir,
    onSortClick: handleSortClick,
    firstColWidth,
  });

  const mergedColumns = columns.map((col) => {
    const isEnabled =
      col.key in dataGridColumns ? dataGridColumns[col.key] : col.isEnabled;
    return {
      ...col,
      isEnabled,
    };
  });

  if (error) {
    return <Error error={error} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  const displayedColumns = mergedColumns.filter((col) => {
    return col.isEnabled || typeof col.isEnabled === "undefined";
  });

  const pathways =
    data && data.pages ? flatten(data.pages.map((p) => p.items)) : [];
  const hasPathways = pathways.length > 0;

  return (
    <>
      <>
        <GridHeader
          hasFilters={hasFilters}
          filterType={EFilterTypes.PATHWAYS_FILTER_TYPE}
          columns={mergedColumns}
          isLegendDisplayed={isLegendDisplayed}
          onEditColumns={handleHasColumnToggle}
          onToggleLegend={handleToggleLegend}
        />
        {isLegendDisplayed && (
          <div className={styles.legend}>
            <PathwayLegend />
          </div>
        )}
        {hasPathways && <DataGrid columns={displayedColumns} rows={pathways} />}
      </>
      {hasPathways && (
        <div className={styles.loadMoreBtn}>
          {hasLoadMore && (
            <LoadMoreBtn
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          )}
        </div>
      )}
      {!hasPathways && (
        <p className={styles.emptyResult}>
          {getCopy("studies_pathways_no-pathways")}
        </p>
      )}
      {hasColumnsPopup && (
        <DataGridColumns
          columns={mergedColumns}
          onToggle={handleEditColumns}
          onClose={handleHasColumnToggle}
          onConfirm={handleHasColumnToggle}
          onCancel={handleHasColumnToggle}
        />
      )}
      {/* Do we wanna display loader over stale content - typically to prevent
        layout from jumping in between queries */}
      {keepPreviousData && isPreviousData && <Loader />}
    </>
  );
}
