import { useRef } from "react";

import Button from "@syntensor/common/components/button";
import {
  EIconSize,
  FilterIcon,
  XIcon,
} from "@syntensor/common/components/icons";
import { EDataGridColumnType } from "@syntensor/common/components/data_grid_columns";
import useClickOutside from "@syntensor/common/hooks/use_click_outside";
import useKeyDown from "@syntensor/common/hooks/use_key_down";

import { TFilterValue, IFilter } from "./";
import MultiselectFilter from "./multiselect_filter";
import NumericFilter from "./numeric_filter";

import styles from "./filters.module.css";

export interface IFilterProps extends IFilter {
  isOpen?: boolean;
  onFilterOpenToggle?: (id: string) => void;
  onFilterChange?: (id: string, value: TFilterValue) => void;
  onFilterRemove?: (id: string) => void;
  onFilterCancel?: () => void;
}

export default function Filter({
  id,
  name = "",
  value = [],
  options = [],
  type = EDataGridColumnType.NUMERIC,
  isOpen = true,
  onFilterOpenToggle = () => undefined,
  onFilterChange = () => undefined,
  onFilterRemove = () => undefined,
  onFilterCancel = () => undefined,
}: IFilterProps) {
  //  depending on the filter type, and number of filter
  //  values enabled display different value indicator
  const hasIndicator =
    type === EDataGridColumnType.MULTISELECT && value
      ? value.length > 0
      : !!value;

  //  merge values and options
  const handleFilterValueChange = (value: TFilterValue) => {
    //  values will depend on the filter type
    //  for multiselect, it should be an array of values
    //  for number/range select, it should be an array with one number?
    onFilterChange(id, value);
  };

  const handleFilterRemove = () => {
    onFilterRemove(id);
  };

  const handleFilterOpenToggle = () => {
    onFilterOpenToggle(id);
  };

  const handleClickOutside = () => {
    onFilterCancel();
  };

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, isOpen, handleClickOutside);

  useKeyDown(["Escape"], () => {
    if (isOpen) {
      onFilterCancel();
    }
  });

  let renderFilter = null;

  if (type === EDataGridColumnType.MULTISELECT) {
    //  mark which filters are enabled based on passed value
    const enabledOptions = options.map((opt) => {
      const isEnabled = (value && value.includes(opt.id)) || false;
      return { ...opt, isEnabled };
    });

    renderFilter = (
      <MultiselectFilter
        options={enabledOptions}
        onFilterValueChange={handleFilterValueChange}
        onFilterCancel={onFilterCancel}
      />
    );
  } else if (type === EDataGridColumnType.NUMERIC) {
    renderFilter = (
      <NumericFilter
        value={value}
        onFilterValueChange={handleFilterValueChange}
        onFilterCancel={onFilterCancel}
      />
    );
  }

  let indicator = null;
  if (hasIndicator && value) {
    if (type === EDataGridColumnType.MULTISELECT) {
      indicator = `(${value.length})`;
    } else if (type === EDataGridColumnType.NUMERIC) {
      indicator = value;
    }
  }

  return (
    <div className={styles.filter} ref={wrapperRef}>
      <div className={styles.filterHeader}>
        <button
          className={styles.filterHeaderBtn}
          onClick={handleFilterOpenToggle}
        >
          <span className={styles.filterIcon}>
            <FilterIcon size={EIconSize.SMALL} />
          </span>
          <span className={styles.filterName}>{name}</span>
          {hasIndicator && (
            <span className={styles.filterValue}>{indicator}</span>
          )}
        </button>
        <Button
          className={styles.filterCloseBtn}
          onClick={handleFilterRemove}
          size={EIconSize.SMALL}
        >
          <XIcon />
        </Button>
      </div>
      {isOpen && <div className={styles.filterDropdown}>{renderFilter}</div>}
    </div>
  );
}
