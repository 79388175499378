import { useContext } from "react";
import { useQuery } from "react-query";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";

import Loader from "@syntensor/common/components/loader";
import Error from "@syntensor/common/components/error";
import { fetchStudyComponentDetail } from "@syntensor/common/data/fetch_data";
import Button from "@syntensor/common/components/button";
import { XIcon } from "@syntensor/common/components/icons";
import ViewToggle from "@syntensor/common/components/view_toggle";
import { TStudyComponentDetail } from "@syntensor/common/types";

import { reactQueryOptions } from "../../../../config";
import { StudyDataContext } from "../../../";
import { IStudyPathwayDetailComponentMatchParams } from "../../../types";
import ComponentHeader from "./header";
import { STUDY_URL_PREFIX } from "../../../routes";
import StudiesPathwayDetailComponentPerturbations from "./perturbations";
import StudyPathwaysGrid from "../../../pathways/pathways_grid";

import styles from "./component_detail.module.css";

export interface IStudyPathwayDetailComponentDetailProps {
  baseUrl?: string;
}

export default function StudyPathwayDetailComponentDetail({
  baseUrl = `${STUDY_URL_PREFIX}/pathways`,
}: IStudyPathwayDetailComponentDetailProps) {
  const location = useLocation();
  const { params } = useRouteMatch<IStudyPathwayDetailComponentMatchParams>(
    baseUrl
  ) || { params: { studyId: "", componentId: "", pathwayId: "" } };
  const { studyId, componentId } = params;

  const pageRouteMatch =
    useRouteMatch<IStudyPathwayDetailComponentMatchParams>(baseUrl);
  const pageUrl = pageRouteMatch?.url || "";

  const studyData = useContext(StudyDataContext);
  const { compounds = [], cellLines = [] } = studyData || {};

  const cellLineIds = cellLines.map((cellLine) => cellLine.id);
  const cellLinesQueryKey = cellLineIds.join(",");
  const queryKey = [
    "studies",
    "components",
    studyId,
    componentId,
    cellLinesQueryKey,
  ];

  const {
    data: componentDetail,
    isLoading,
    error,
  } = useQuery<TStudyComponentDetail>(
    queryKey,
    async () => {
      if (compounds.length && cellLines.length) {
        return await fetchStudyComponentDetail(studyId, componentId, {
          cellLines: cellLineIds,
        });
      }
    },
    { ...reactQueryOptions, retry: false }
  );

  if (error) {
    return (
      <div className={styles.pathwayDetailComponentDetail}>
        <Error error={error} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={styles.pathwayDetailComponentDetail}>
        <Loader />
      </div>
    );
  }

  if (!componentDetail) {
    return null;
  }

  componentDetail.descriptiveName;

  const backUrl = pageUrl.split("/").slice(0, -1).join("/");

  return (
    <div className={styles.pathwayDetailComponentDetail}>
      <div className={styles.closeBtn}>
        <Button role="clear" size="small" to={backUrl}>
          <XIcon />
        </Button>
      </div>
      <ComponentHeader {...componentDetail} />
      <ViewToggle
        activeView={location.pathname}
        btns={[
          {
            id: "differential-expression",
            to: pageUrl,
            exact: true,
            label: "Differential expression",
          },
          {
            id: "associated-pathways",
            to: `${pageUrl}/pathways`,
            label: "Associated pathways",
          },
        ]}
      />
      <Switch>
        <Route
          path={`${baseUrl}/pathways`}
          render={() => (
            <div>
              <StudyPathwaysGrid
                firstColWidth={320}
                hasFilters={false}
                componentId={componentId}
              />
            </div>
          )}
        />
        <Route
          exact={true}
          path={baseUrl}
          render={() => (
            <StudiesPathwayDetailComponentPerturbations
              simulations={componentDetail.simulations}
              compounds={compounds}
              cellLines={cellLines}
            />
          )}
        />
      </Switch>
    </div>
  );
}
