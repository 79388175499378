import { useRouteMatch } from "react-router-dom";

import { IStudyMatchParams } from "../studies/types";
import { STUDY_URL_PREFIX } from "../studies/routes";

export function useStudyUrl() {
  const match = useRouteMatch<IStudyMatchParams>({
    path: STUDY_URL_PREFIX,
  });
  return match?.url;
}
