import { useRouteMatch } from "react-router-dom";

import BackBtn from "@syntensor/common/components/back_btn";
import Loader from "@syntensor/common/components/loader";
import ErrorComp from "@syntensor/common/components/error";
import Button from "@syntensor/common/components/button";
import Input from "@syntensor/common/components/input";
import getCopy from "@syntensor/common/data/copy";
import Select, { ISelectOption } from "@syntensor/common/components/select";
import { EStudyType } from "@syntensor/common/types";

import { IStudyMatchParams } from "../types";
import CELL_LINES_AVAILABLE from "./cell_lines_available";
import useCreateStudyForm from "./use_create_study_form";
import CreateNewStudyEndpointsForm, {
  getAllAvailableEndpointsForStudyType,
} from "./endpoints";
import CreateNewStudyComponentTypeForm, {
  COMPONENT_TYPES,
} from "./component_types_form";
import CreateNewStudyCompoundsForm from "./compounds";

import styles from "./create.module.css";

export default function CreateNewStudyForm() {
  const { params } = useRouteMatch<IStudyMatchParams>();
  const { projectId, studyId } = params;
  const isEditing = !!studyId;

  const backUrl = `/projects/${projectId}`;

  const initialState = {
    cellLine: CELL_LINES_AVAILABLE[1],
    type: EStudyType.MOA_DECONVOLUTION,
    componentTypes: COMPONENT_TYPES.map((type) => type.id),
  };

  const {
    inputs,
    validateSmiles,
    handleInputChange,
    handleCompoundChange,
    isTemplateLoading,
    templateLoadError,
    onSubmit,
    isFormLoading,
    errorMsg,
  } = useCreateStudyForm({ initialState });

  const handleStudyTypeChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value: type } = evt.currentTarget;
    //  reset endpoints when changing study type to defaults
    const endpoints = getAllAvailableEndpointsForStudyType(type as EStudyType);
    handleInputChange([
      { name, value: type },
      { name: "endpoints", value: endpoints },
    ]);
  };

  if (isTemplateLoading) {
    return <Loader />;
  }
  if (templateLoadError) {
    return <ErrorComp error={templateLoadError} />;
  }

  const studyTypes = [
    { id: EStudyType.MOA_DECONVOLUTION, name: "MOA Deconvolution" },
    { id: EStudyType.POTENCY, name: "Dose Response: Potency" },
    {
      id: EStudyType.TOXICITY,
      name: "Dose Response: Toxicity",
    },
  ];

  const cellLines = CELL_LINES_AVAILABLE.reduce<ISelectOption[]>(
    (acc, cellLine) => {
      acc.push({ id: cellLine, name: cellLine, disabled: false });
      return acc;
    },
    [{ id: "", name: "Select cell line", disabled: true }]
  );

  return (
    <div className={styles.newUserForm}>
      <header className={styles.header}>
        <div className={styles.backBtn}>
          <BackBtn to={backUrl} />
        </div>
        <h1 className={styles.title}>
          {getCopy("studies_create_title")} |&nbsp;
        </h1>
        <h2 className={styles.subtitle}>
          {isEditing
            ? getCopy("studies_edit_edit-title")
            : getCopy("studies_create_new-title")}
        </h2>
      </header>
      <div className={styles.content}>
        <form onSubmit={onSubmit}>
          <div>
            <div className={styles.formSection}>
              <h3 className={styles.formTitle}>
                {getCopy("studies_create_name")}
              </h3>
              <Input
                placeholder={getCopy("studies_create_name-placeholder")}
                name="displayName"
                value={inputs.displayName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className={styles.formSection}>
              <h3 className={styles.formTitle}>
                {getCopy("studies_create_description")}
              </h3>
              <Input
                placeholder={getCopy("studies_create_description-placeholder")}
                name="description"
                elName="textarea"
                rows={5}
                value={inputs.description}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className={styles.formSection}>
              <h3 className={styles.formTitle}>
                {getCopy("studies_create_study-type")}
              </h3>
              <Select
                name="type"
                value={inputs.type}
                options={studyTypes}
                onChange={handleStudyTypeChange}
                isDisabled={isEditing}
              />
            </div>
            {inputs.type !== EStudyType.TOXICITY && (
              <div className={styles.formSection}>
                <h3 className={styles.formTitle}>
                  {getCopy("studies_create_cell-lines")}
                </h3>
                <Select
                  name="cellLine"
                  value={inputs.cellLine}
                  options={cellLines}
                  onChange={handleInputChange}
                  isDisabled={isEditing}
                />
              </div>
            )}
            <CreateNewStudyCompoundsForm
              validateSmiles={validateSmiles}
              compounds={inputs.compounds}
              onChange={handleCompoundChange}
            />
            <div className={styles.formSection}>
              <h3 className={styles.formTitle}>
                {getCopy("studies_create_target-components")}
              </h3>
              <p className={styles.formDescription}>
                {getCopy("studies_create_target-components-description")}
              </p>
              <Input
                placeholder={getCopy(
                  "studies_create_target-components-placeholder"
                )}
                name="targetExternalComponents"
                elName="textarea"
                rows={5}
                value={inputs.targetExternalComponents}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className={styles.formSection}>
              <h3 className={styles.formTitle}>
                {getCopy("studies_create_target-pathways")}
              </h3>
              <p className={styles.formDescription}>
                {getCopy("studies_create_target-pathways-description")}
              </p>
              <Input
                placeholder={getCopy(
                  "studies_create_target-pathways-placeholder"
                )}
                name="targetExternalPathways"
                elName="textarea"
                rows={5}
                value={inputs.targetExternalPathways}
                onChange={handleInputChange}
                required
              />
            </div>
            <CreateNewStudyComponentTypeForm
              value={inputs.componentTypes}
              onChange={handleInputChange}
            />
          </div>
          {(inputs.type === EStudyType.TOXICITY ||
            inputs.type === EStudyType.POTENCY) && (
            <CreateNewStudyEndpointsForm
              type={inputs.type}
              endpoints={inputs.endpoints || []}
              onChange={handleInputChange}
              isDisabled={isEditing}
            />
          )}
          <div className={styles.btn}>
            <Button isDisabled={isFormLoading} role="primary" type="submit">
              {isFormLoading && <Loader size="32px" />}
              {isEditing
                ? getCopy("studies_edit_edit-study")
                : getCopy("studies_create_create-study")}
            </Button>
          </div>
          {errorMsg && <div className={styles.error}>{errorMsg}</div>}
        </form>
      </div>
    </div>
  );
}
