import { escapeRegex } from "@syntensor/common/utils/search_utils";
import { fetchSearch } from "@syntensor/common/data/fetch_data";

export const SEARCH_LIMIT = 8;

export const DEFAULT_SEARCH_OPTIONS = {
  includeScore: true,
  threshold: 0.1,
  keys: ["name"],
  ignoreLocation: true,
};

//  @TODO - replication with API
export function capitalize(s: string) {
  if (typeof s !== "string") {
    return "";
  }

  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function highlightMatchTerm(name: string, searchString: string) {
  //  make sure weird characters in search term
  //  don't break regs (e.g. `[` or `]`)
  const searchRegex = escapeRegex(searchString);
  const re = new RegExp(searchRegex, "gi");
  return name ? name.replace(re, `<span>${searchString}</span>`) : "";
}

export default async function fetchAndFormatSearch({
  studyId,
  search,
  filters,
  limit = SEARCH_LIMIT,
  offset = 0,
}: {
  studyId: string;
  search: string;
  filters: Record<string, string | number | null>;
  limit?: number;
  offset?: number;
}) {
  //  match against components and tissues in the database
  const matches = await fetchSearch(studyId, {
    s: search,
    limit,
    offset,
    f: filters,
  });

  const formattedSuggestions = matches.map((s: Record<string, string>) => {
    //  detect type to use correct render
    const type = s.componentType ? s.componentType : "pathway";

    //  different color for matched syntax
    const highlightedName = highlightMatchTerm(
      s.descriptiveName || s.displayName,
      search
    );
    const highlightedSynonyms = highlightMatchTerm(s.synonyms, search);

    return {
      ...s,
      name: highlightedName,
      synonyms: highlightedSynonyms,
      type,
    };
  });

  return {
    suggestions: formattedSuggestions,
  };
}
