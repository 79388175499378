import { useRouteMatch } from "react-router-dom";

import MainNavLink from "@syntensor/common/components/main_nav_link";
import getCopy from "@syntensor/common/data/copy";

import styles from "./header.module.css";

export default function Header() {
  const { url } = useRouteMatch();

  return (
    <div>
      <header className={styles.header}>
        <nav className={styles.nav}>
          <ul className={styles.list}>
            <li>
              <MainNavLink
                to={`${url}/latest-activity`}
                activeClassName={styles.activeLink}
                isDisabled={true}
              >
                {getCopy("projects_menu_latest-activity")}
              </MainNavLink>
            </li>
            <li>
              <MainNavLink
                to={`${url}/compounds`}
                activeClassName={styles.activeLink}
                isDisabled={true}
              >
                {getCopy("projects_menu_compounds")}
              </MainNavLink>
            </li>
            <li>
              <MainNavLink
                to={`${url}/studies`}
                activeClassName={styles.activeLink}
              >
                {getCopy("projects_menu_studies")}
              </MainNavLink>
            </li>
            <li>
              <MainNavLink
                to={`${url}/tissues`}
                activeClassName={styles.activeLink}
                isDisabled={true}
              >
                {getCopy("projects_menu_tissues")}
              </MainNavLink>
            </li>
            <li>
              <MainNavLink
                to={`${url}/efficacy`}
                activeClassName={styles.activeLink}
                isDisabled={true}
              >
                {getCopy("projects_menu_efficacy")}
              </MainNavLink>
            </li>
            <li>
              <MainNavLink
                to={`${url}/admetox`}
                activeClassName={styles.activeLink}
                isDisabled={true}
              >
                {getCopy("projects_menu_admetox")}
              </MainNavLink>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
}
