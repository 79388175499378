import { ChangeEvent, useCallback, useState } from "react";

import Input from "@syntensor/common/components/input";
import Button from "@syntensor/common/components/button";

import { IDataGridColumnsProps, IDataGridColumn } from "./";
import { EditIcon } from "../icons";
import DataGridColumnsItem from "./data_grid_columns_item";

import styles from "./data_grid_columns.module.css";
import getCopy from "../../data/copy";

export type TDataGridColumnsPopupProps = Omit<IDataGridColumnsProps, "onClose">;

export default function DataGridColumnsPopup({
  columns,
  onToggle,
  onConfirm,
  onCancel,
}: TDataGridColumnsPopupProps) {
  //  store initial column state, so that we can discard
  //  changes onCancel
  const [initColumns] = useState<IDataGridColumn[]>(() => {
    return columns.map((col) => {
      return { ...col };
    });
  });
  const [filter, setFilter] = useState("");

  //  implement search
  const filteredColumns = columns.filter((column) =>
    column.name.toLowerCase().includes(filter.toLowerCase())
  );

  //  @TODO - for now not having select/deselect all
  // const handleSelectAll = () => {
  //   const columnNames = columns.map((column) => column.key);
  //   onToggle(columnNames, true);
  // };
  // const handleDeselectAll = () => {
  //   const columnNames = columns.map((column) => column.key);
  //   onToggle(columnNames, false);
  // };

  const handleToggle = (columnName: string, isChecked: boolean) => {
    onToggle(columnName, isChecked);
  };

  const handleConfirm = useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  const handleCancel = useCallback(() => {
    const columnKeys = initColumns.map((column) => column.key);
    const isEnabled = initColumns.map((column) => {
      return typeof column.isEnabled !== "undefined" ? column.isEnabled : true;
    });
    onToggle(columnKeys, isEnabled);
    onCancel();
  }, [initColumns, onToggle, onCancel]);

  return (
    <section className={styles.dataGridColumns}>
      <header className={styles.header}>
        <h2 className={styles.title}>
          {getCopy("components_data-grid-columns_title")}
          <EditIcon />
        </h2>
        <Input
          type="text"
          placeholder={getCopy(
            "components_data-grid-columns_input-placeholder"
          )}
          value={filter}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFilter(e.target.value)
          }
          size="small"
        />
        {/* <div className={styles.btns}>
          <Button onClick={handleSelectAll}>Select all</Button>
          <Button onClick={handleDeselectAll}>Deselect all</Button>
        </div> */}
      </header>
      <div className={styles.list}>
        {filteredColumns.map(({ key, name, isEnabled = true }) => {
          return (
            <DataGridColumnsItem
              id={key}
              key={key}
              name={name}
              isEnabled={isEnabled}
              onToggle={handleToggle}
            />
          );
        })}
      </div>
      <footer className={styles.footer}>
        <Button role="subtle" onClick={handleCancel}>
          {getCopy("components_data-grid-columns_cancel")}
        </Button>
        <Button role="primary" onClick={handleConfirm}>
          {getCopy("components_data-grid-columns_confirm")}
        </Button>
      </footer>
    </section>
  );
}
