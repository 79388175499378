import MoaRank from "@syntensor/common/components/moa_rank";
import Tooltip from "@syntensor/common/components/tooltip";

import { getMoaTooltip } from "../../../../utils/tooltip_utils";
import { capitalize } from "../../../search/match_search";
import getCopy from "@syntensor/common/data/copy";

import studiesStyles from "../../../studies.module.css";
import componentDetailStyles from "../../../component_detail/component_detail.module.css";

export interface IStudiesPathwayDetailComponentHeaderProps {
  componentType: string;
  descriptiveName?: string;
  displayName: string;
  description?: string;
  synonym?: string;
  moaRank?: number | null;
}

export default function StudiesPathwayDetailComponentHeader({
  componentType = "",
  descriptiveName = "",
  displayName = "",
  description = "",
  synonym = "",
  moaRank = null,
}: IStudiesPathwayDetailComponentHeaderProps) {
  const hasMoaRank = Number.isFinite(moaRank);
  const moaTooltip = hasMoaRank ? getMoaTooltip(componentType) : "";

  const name = descriptiveName || displayName;

  return (
    <div className={studiesStyles.controlRow}>
      <div className={studiesStyles.mainControl}>
        <div className={componentDetailStyles.headerTitles}>
          <div className={componentDetailStyles.headerTitleWrapper}>
            {hasMoaRank && moaRank && (
              <div className={componentDetailStyles.tooltip}>
                <Tooltip tooltip={moaTooltip}>
                  <MoaRank rank={moaRank} size="large" />
                </Tooltip>
              </div>
            )}
            <h3
              className={componentDetailStyles.headerTitle}
              data-testid="app_component-detail_title"
            >
              {name}
            </h3>
          </div>
          <div className={componentDetailStyles.description}>
            <span>{capitalize(componentType)}</span>
            {description && <span>{description}</span>}
            {synonym && (
              <span>
                {getCopy("studies_component-detail_synonyms")}
                <span className={componentDetailStyles.synonyms}>
                  {synonym}
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
