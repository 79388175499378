import { useContext } from "react";

import Select from "@syntensor/common/components/select";
import { IStudyContextData } from "@syntensor/common/types";
import getCopy from "@syntensor/common/data/copy";

import StudiesTitle from "../title";
import { StudyDataContext } from "..";
import StudyPathwaysGrid from "./pathways_grid";

import styles from "../studies.module.css";

export const STUDY_PATHWAYS_URL_PREFIX = `/projects/:projectId/(cell-lines|tissues|:property)/:studyId/pathways`;

export default function Pathways() {
  const studyData = useContext<IStudyContextData | null>(StudyDataContext);
  const { cellLines = [] } = studyData || {};

  return (
    <>
      <div className={styles.titles}>
        <StudiesTitle />
      </div>
      <div className={styles.sectionHeader}>
        <div className={styles.controls}>
          <div className={styles.controlRow}>
            <div className={styles.mainControl}>
              <h3 className={styles.subtitle}>
                {getCopy("studies_pathways_title")}
              </h3>
            </div>
            <div className={styles.noGrowControl}>
              <Select
                label={getCopy("studies_components_cellline-dropdown")}
                size="small"
                options={cellLines}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sectionContent}>
        <StudyPathwaysGrid />
      </div>
    </>
  );
}
