import {
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  SyntheticEvent,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { History } from "history";

import {
  getBackUrl,
  goBack,
  navigateWithQueryParams,
  scrollToHash,
} from "@syntensor/common/browser_history";
import getCopy from "@syntensor/common/data/copy";
import Button from "@syntensor/common/components/button";
import Logo from "@syntensor/common/components/logo";
import { getDefaultBackUrl } from "@syntensor/common/components/back_btn";
import {
  encodeQueryParamsToSearchString,
  parseUrlSearchQuery,
} from "@syntensor/common/utils/url_utils";

import { AuthContext, AuthStatus } from "../auth/auth_context";
import PrivacyFooter from "./privacy_footer";

import styles from "./privacy.module.css";

export function ReferenceFooter({
  hasDataRequest = false,
  backUrl,
  onBackClick,
}: {
  hasDataRequest?: boolean;
  backUrl: string;
  onBackClick: (evt: SyntheticEvent) => void;
}) {
  return (
    <div className={styles.termsFooter}>
      {hasDataRequest && (
        <div>
          <Button
            to={getCopy("profile_privacy-policy_data-request-msg")}
            role="secondary"
          >
            {getCopy("profile_privacy-policy_data-request")}
          </Button>
        </div>
      )}
      <div>
        <Button to={backUrl} role="subtle" onClick={onBackClick}>
          {getCopy("profile_terms_back")}
        </Button>
      </div>
    </div>
  );
}

export function ConsentFooter({
  backUrl,
  onBackClick,
}: {
  backUrl: string;
  onBackClick: (evt: SyntheticEvent) => void;
}) {
  return (
    <div className={styles.termsFooter}>
      <div>
        <Button to={backUrl} role="subtle" onClick={onBackClick}>
          {getCopy("profile_terms_done")}
        </Button>
      </div>
    </div>
  );
}

export function navigateToConsentPopup(history: History<unknown>) {
  //  make sure to respect any parameters which might still exist
  const query = parseUrlSearchQuery(history.location.search);
  query.privacy = "true";
  history.replace({ search: encodeQueryParamsToSearchString(query) });
}

export interface IPrivacyContentProps {
  markdown: any;
  hasDataRequest?: boolean;
}

export default function PrivacyContent({
  markdown,
  hasDataRequest = false,
}: IPrivacyContentProps) {
  const elRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { pathname } = useLocation();
  const { authStatus } = useContext(AuthContext);

  const backUrl = getBackUrl() || getDefaultBackUrl(pathname);
  const isSignupFlow = authStatus === AuthStatus.TempSignedIn;

  const handleBackClick = useCallback((evt: SyntheticEvent) => {
    goBack();

    //  prevent default navigation on the querylink
    evt.preventDefault();
    return false;
  }, []);

  //  navigate to chapters with js not to lose potential temp logged-in user
  const handleNavigationClick = useCallback(
    (evt: Event) => {
      if (!evt.currentTarget) {
        return null;
      }
      const link = evt.currentTarget as HTMLAnchorElement;
      const href = link.getAttribute("href");
      if (!href) {
        return null;
      }

      const isExternalLink = href.indexOf("http") === 0;
      if (!isExternalLink) {
        //  we navigate programmatically for internal links
        //  not to lose any potential state
        evt.preventDefault();

        //  allow normal links without hash to work as expected
        if (href.indexOf("#") > -1) {
          //  special case for manage consent popup
          const hash = href.slice(href.indexOf("#") + 1);
          if (hash === "popup") {
            navigateToConsentPopup(history);
          } else {
            scrollToHash(hash);
          }
        } else {
          //  navigate using react-router, otherwise might lead
          navigateWithQueryParams(href);
        }
      } else {
        //  don't need to do anything for the external link
      }
    },
    [history]
  );

  useLayoutEffect(() => {
    if (elRef.current) {
      const links: NodeListOf<HTMLAnchorElement> =
        elRef.current.querySelectorAll("a[href^='/']");
      links.forEach((link) => {
        //  make sure we're not on Done button or some other link
        //  which already has a programmatic navigation onclick handler
        if (!link.onclick) {
          link.onclick = handleNavigationClick;
        }
      });
    }

    return;
  }, [handleNavigationClick]);

  const { content, data } = markdown;
  const { title, contentTitle, contentTable, updateLabel } = data;

  return (
    <div className={styles.privacyPolicy}>
      <div className={styles.container}>
        <h1 className={styles.headerTitle}>
          {getCopy("profile_login_company")}
        </h1>
        <div className={styles.logo}>
          <Logo />
        </div>
        <h2>{title}</h2>
        <p className={styles.lastUpdated}>{updateLabel}</p>
        <div className={styles.cols} ref={elRef}>
          <div className={styles.contentTable}>
            <h3 className={styles.contentTableTitle}>{contentTitle}</h3>
            <ul className={styles.contentTable}>
              {contentTable &&
                contentTable.map((table: string, i: number) => {
                  return (
                    <li
                      key={i}
                      className={styles.contentTableItem}
                      dangerouslySetInnerHTML={{ __html: table }}
                    />
                  );
                })}
            </ul>
          </div>
          <div>
            <div className={styles.content}>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            <div className={styles.footer}>
              {isSignupFlow ? (
                <ConsentFooter
                  backUrl={backUrl as string}
                  onBackClick={handleBackClick}
                />
              ) : (
                <ReferenceFooter
                  hasDataRequest={hasDataRequest}
                  backUrl="/profile"
                  onBackClick={handleBackClick}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.privacyFooter}>
          <PrivacyFooter hasAccount={!isSignupFlow} />
        </div>
      </div>
    </div>
  );
}
