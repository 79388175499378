import { ReactNode, SyntheticEvent } from "react";
import { NavLink } from "react-router-dom";

import { navigateWithQueryParams } from "@syntensor/common/browser_history";

export interface IQueryLinkProps {
  children: ReactNode;
  to: string;
  isDisabled?: boolean;
  onClick?: (evt: SyntheticEvent) => void;
}

export default function QueryLink({
  children,
  to,
  isDisabled = false,
  onClick,
  ...props
}: IQueryLinkProps) {
  const handleClick = (evt: SyntheticEvent) => {
    evt.preventDefault();

    if (onClick && typeof onClick === "function") {
      onClick(evt);
    }
    //  default navigation
    if (!isDisabled) {
      navigateWithQueryParams(to);
    }
  };

  return (
    <NavLink {...props} to={to} onClick={handleClick}>
      <>{children}</>
    </NavLink>
  );
}
