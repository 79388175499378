import Button from "@syntensor/common/components/button";
import {
  EditIcon,
  VisibilityIcon,
  VisibilityOffIcon,
} from "@syntensor/common/components/icons";
import getCopy from "@syntensor/common/data/copy";
import { IDataGridColumn } from "@syntensor/common/components/data_grid_columns";

import Filters, { EFilterTypes } from "../filters";

import styles from "../studies.module.css";

export interface IStudyComponentsGridProps {
  columns?: IDataGridColumn[];
  hasFilters?: boolean;
  isLegendDisplayed?: boolean;
  filterType: EFilterTypes;
  onEditColumns: () => void;
  onToggleLegend: () => void;
}

export default function GridHeader({
  columns = [],
  hasFilters = true,
  filterType = EFilterTypes.COMPONENTS_FILTER_TYPE,
  isLegendDisplayed = false,
  onEditColumns = () => undefined,
  onToggleLegend = () => undefined,
}: IStudyComponentsGridProps) {
  const legendTitle = isLegendDisplayed
    ? getCopy("studies_grid-header_hide-legend")
    : getCopy("studies_grid-header_show-legend");

  const legendIcon = isLegendDisplayed ? (
    <VisibilityOffIcon />
  ) : (
    <VisibilityIcon />
  );

  return (
    <div className={styles.dataGridHeader}>
      {hasFilters && <Filters type={filterType} columns={columns} />}
      {
        /* need an empty div to keep layout without filters */
        !hasFilters && <div />
      }
      <div className={styles.dataGridIcons}>
        <Button
          title={legendTitle}
          size="small"
          type="text"
          className={styles.dataGridIconBtn}
          onClick={onToggleLegend}
        >
          {legendIcon}
          {legendTitle}
        </Button>
        <Button
          title={getCopy("studies_grid-header_edit-columns")}
          size="small"
          type="text"
          className={styles.dataGridIconBtn}
          onClick={onEditColumns}
        >
          <EditIcon />
          {getCopy("studies_grid-header_edit-columns")}
        </Button>
      </div>
    </div>
  );
}
