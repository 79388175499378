import classes from "./profile.module.css";

export interface IStatusMsgsProps {
  errorMsg?: string;
  successMsg?: string;
}

export default function StatusMsgs({
  errorMsg = "",
  successMsg = "",
}: IStatusMsgsProps) {
  return (
    <>
      {errorMsg && (
        <div data-testid="status-msg_error" className={classes.row}>
          <p
            className={classes.errorMsg}
            dangerouslySetInnerHTML={{ __html: errorMsg }}
          />
        </div>
      )}
      {successMsg && (
        <div data-testid="status-msg_success" className={classes.row}>
          <p
            className={classes.successMsg}
            dangerouslySetInnerHTML={{ __html: successMsg }}
          />
        </div>
      )}
    </>
  );
}
