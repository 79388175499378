import getCopy from "@syntensor/common/data/copy";
import { PROTEIN_COLORS_SCALE, PROTEIN_MAX } from "../columns";

import styles from "./legends.module.css";

export default function MoaLegend() {
  const background = `linear-gradient(
    90deg,
    ${PROTEIN_COLORS_SCALE(0)},
    ${PROTEIN_COLORS_SCALE(PROTEIN_MAX)}
  )`;

  return (
    <div className={styles.proteinLegend}>
      <div className={styles.subtitle}>
        {getCopy("studies_moa-legend_title")}
      </div>
      <div className={styles.scale}>
        <div className={styles.scaleGraphics}>
          <div className={styles.scaleGraphic}>
            <div className={styles.scaleGraphicBar} style={{ background }} />
            <div className={styles.scaleGraphicLabels}>
              <span className={styles.scaleGraphicLabelsDark}>
                {getCopy("studies_moa-legend_min")}
              </span>
              <span className={styles.scaleGraphicLabel}>
                {getCopy("studies_moa-legend_max")}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.scaleLabels}>
          <span>{getCopy("studies_moa-legend_highest-probability")}</span>
          <span>{getCopy("studies_moa-legend_lowest-probablity")}</span>
        </div>
      </div>
      <div className={styles.title}>{getCopy("studies_moa-legend_legend")}</div>
    </div>
  );
}
