import { ElementsDefinition } from "cytoscape";

export enum ESortDir {
  ASC = "asc",
  DESC = "desc",
}

export interface ICognitoUser {
  createdDate: string;
  "custom:company": string;
  "custom:role": string;
  email: string;
  email_verified: string;
  enabled: boolean;
  family_name: string;
  given_name: string;
  lastModifiedDate: string;
  status: string;
  sub: string;
}

export interface IDose {
  dose: number;
  time: number;
}

export interface ICellLine {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface ICreateCellLinePayload {
  name: string;
}

export interface ICellLineExpressionMetadata {
  cellLineId: string;
  syntensorId: string;
  baselineExpression: number;
  baselineUnit: string;
  perturbationFrequency: number;
  perturbationFrequencyUnit: number;
  meanPerturbation: number;
  meanPerturbationUnit: string;
}

export interface ICompoundDefinition {
  name: string;
  smiles: string;
  control?: string;
}

//  @TODO - possible duplication with IStudyCompound
export interface ICompound extends ICompoundDefinition {
  id: string;
  createdAt: string;
  updatedAt: string;
}

export interface ISelectableCompound extends IStudyCompound {
  isSelected: boolean;
}

export interface ICreateCompoundPayload {
  name: string;
  dosage: string;
  control?: string;
}

export interface IProject {
  id: string;
  name: string;
  logo: string;
  description: string;
  groupName: string;
  roleScope: "super-admin" | "admin" | "user";
  createdAt: string;
  updatedAt: string;
  studies: IStudy[];
}

export interface IStudyDataVersions {
  pathways: string;
  ers: string;
  studyTemplate?: string;
}

export enum EStudyType {
  MOA_DECONVOLUTION = "moa-deconvolution",
  POTENCY = "potency",
  TOXICITY = "toxicity",
}

export type IStudyEndpoint = {
  channels: string[];
  associatedComponents: string[];
  associatedPathways: string[];
};

export type TStudyEnpoints = Record<string, IStudyEndpoint>;

export enum EStudyStatus {
  REQUESTED = "REQUESTED",
  CANCELLED = "CANCELLED",
  IN_PROGRESS = "IN_PROGRESS",
  INGESTING = "INGESTING",
  COMPLETED = "COMPLETED",
  ERRORED = "ERRORED",
}

export interface IStudyInference {
  id: string;
  type: string;
  status: EStudyStatus;
}

export interface IStudy {
  id: string;
  projectId: string;
  status: EStudyStatus;
  inferences?: IStudyInference[];
  displayName: string;
  description: string;
  cellLines: string[];
  compounds: string[];
  type: EStudyType;
  targetComponents: string[];
  targetPathways: string[];
  componentTypes: string[];
  createdAt: string;
  updatedAt: string;
  createdBy: {
    sub: string;
    displayName: string;
  };
  dataVersions: IStudyDataVersions;
  findings?: string;
  endpoints?: TStudyEnpoints;
}

export interface IStudyResolved
  extends Omit<IStudy, "compounds" | "cellLines"> {
  compounds: IStudyCompound[];
  cellLines: ICellLine[];
  targets: TStudyTarget[];
  componentTypes: string[];
  topMoaProteins?: IStudyComponent[];
  targetExternalComponents?: string[];
  targetExternalPathways?: string[];
}

export interface IStudyContextData extends IStudyResolved {
  refreshStudyData: () => void;
}

export interface IPathway {
  id: string;
  name: string;
  syntensorId?: string;
  children: IPathway[];
}

export interface IStudyContextMeta {
  pathways: {
    hierarchy: IPathway[];
  };
}

export type TToxicityClasses = "active" | "inactive";

export interface IStudyRawToxicityPotencyData {
  simulationUuid: string;
  compoundId: string;
  [key: string]: number | string | boolean | TToxicityClasses;
}

export type TStudyToxicityPotency = Omit<
  IStudyRawToxicityPotencyData,
  "compoundId | simulatinoUuid"
>;

export interface IStudyEndpointPayload {
  associatedComponentIds: string[];
  associatedPathwayIds: string[];
  endpoints: string[];
}

export interface IStudyCompound
  extends Omit<ICompound, "createdAt" | "updatedAt"> {
  projectId: string;
  studyId: string;
  compoundId: string;
  cellLineId: string;
  dose: number;
  // smiles: string;
  tags?: string[];

  potency?: TStudyToxicityPotency;
  toxicity?: TStudyToxicityPotency;

  // createdAt: string;
  // updatedAt: string;
}

export interface IStudyComment {
  id: string;
  projectId: string;
  studyId: string;
  path: string;
  text: string;
  isResolved: boolean;
  createdAt: string;
  updatedAt: string;
  createdBy: {
    sub: string;
    displayName: string;
  };
  parentCommentId?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICreateStudyCommentPayload
  extends Omit<
    IStudyComment,
    " id | studyId | projectId | isResolved | createdAt | updatedAt | createdBy"
  > {}

export interface ISimulation {
  id: string;
  compoundId: string;
  cellLineId: string;
  createdAt: string;
  updatedAt: string;
  name?: string;
  description?: string;
  config?: Record<string, string | number>;
}

export interface ICreateSimulationPayload {
  id: string;
  compoundId: string;
  cellLineId: string;
  name?: string;
  description?: string;
  config?: Record<string, string | number>;
}

export interface ISimulationPathway {
  id: string;
  syntensorId: string;
  simulationId: string;
  cellLineId: string;
  compoundId: string;
  displayName: string;
  category: string;
  hdRank: number;
  dtRank: number;
  moaRank: number;
  totalUpregulatedGenes: number;
  totalDownregulatedGenes: number;
  components: string[];
  componentType?: string;
}

export interface ISimulationPathwayMoa {
  moaRank: number;
}

export interface IStudyPathwayEsDocSimulations {
  dtRank: { key: string; value: number }[];
  totalUpregulatedGenes: { key: string; value: number }[];
  totalDownregulatedGenes: { key: string; value: number }[];
}

export interface IStudyPathwaySimulations {
  dtRank: Record<string, number>;
  totalUpregulatedGenes: Record<string, number>;
  totalDownregulatedGenes: Record<string, number>;
}

export type TStudyTarget = IStudyComponent | IStudyPathway;

export interface IStudyPathwayEsDoc
  extends Omit<ISimulationPathway, "dtRank" | "components"> {
  studyId: string;
  simulations: IStudyPathwayEsDocSimulations;
}

export interface IStudyPathway extends Omit<IStudyPathwayEsDoc, "simulations"> {
  simulations: IStudyPathwaySimulations;
  components: IStudyComponent[];
}

export interface IStudyPathwayDetail extends IStudyPathway {
  parents: IPathway[];
  children: IPathway[];
  sbgn: ElementsDefinition;
}

export interface IFormattedSimulationPathway extends ISimulationPathway {
  moa: ISimulationPathwayMoa;
}

export interface ISimulationComponent {
  id: string;
  syntensorId: string;
  displayName: string;
  descriptiveName?: string;
  componentType: string;
  compartment?: string;
  simulationId: string;
  cellLineId: string;
  compoundId: string;
  perturbation?: number;
  actualPerturbation?: number;
  pathways: string[];
}

export interface ISimulationComponentMoa {
  moaRank: number;
  binding: number;
  moaPathwayId: string;
  moaPathwayDisplayName: string;
  moaPathwayRank: number;
}

export interface IFormattedSimulationComponent
  extends Omit<ISimulationComponent, "id"> {
  _search: string;
  studyId: string;
  moa: ISimulationComponentMoa | Record<string, never>;
}

export interface ICreateStudyPayload {
  projectId: string;
  type?: EStudyType;
  displayName: string;
  description: string;
  cellLines: string[];
  compounds: ICompoundDefinition[];
  // doses: IDose[];
  targetExternalComponents: string[];
  targetExternalPathways: string[];
  componentTypes: string[];
  dataVersions?: IStudyDataVersions;
  endpoints?: TStudyEnpoints;
}

export interface ICreateInferencePayload
  extends Omit<
    ICreateStudyPayload,
    | "targetExternalComponents"
    | "targetExternalPathways"
    | "componentTypes"
    | "dataVersions"
  > {
  doses: { dose: number; time: number }[];
}

export interface IStudyComponentEsDocSimulations {
  perturbation: { key: string; value: number }[];
  actualPerturbation: { key: string; value: number }[];
  moaRank?: { key: string; value: number }[];
  binding?: { key: string; value: number }[];
  moaPathwayId?: { key: string; value: string }[];
  moaPathwayDisplayName?: { key: string; value: string }[];
  moaPathwayRank?: { key: string; value: string }[];
}

export interface IStudyComponentSimulations {
  perturbation: Record<string, number>;
  actualPerturbation: Record<string, number>;
  moaRank?: Record<string, number>;
  binding?: Record<string, number>;
  moaPathwayId?: Record<string, string>;
  moaPathwayDisplayName?: Record<string, string>;
  moaPathwayRank?: Record<string, string>;
}

export interface IStudyComponentEsDoc
  extends Omit<
    ISimulationComponent,
    "perturbation" | "actualPerturbation" | "simulationId"
  > {
  studyId: string;
  simulations: IStudyComponentEsDocSimulations;
}

export interface IStudyComponent
  extends Omit<IStudyComponentEsDoc, "simulations"> {
  simulations: IStudyComponentSimulations;
  cellLinesExpressionMetadata?: Record<string, ICellLineExpressionMetadata>;
}

export type TStudyComponentFilter = string | number | (string | number)[];
export type TStudyComponentFilters = Record<string, TStudyComponentFilter>;

export interface IStudyComponentsPayload {
  filters?: TStudyComponentFilters;
  target?: string;
  sortDir?: ESortDir;
  sortBy?: string;
  size?: number;
  after?: number | string;
}

export type TStudyPathwayFilter = TStudyComponentFilter;
export type TStudyPathwayFilters = TStudyComponentFilters;

export interface IStudyPathwaysPayload
  extends Omit<IStudyComponentsPayload, "filters | target"> {
  filters?: TStudyPathwayFilters;
}

export interface IErsXrefsBase {
  associatedGenes?: string[];
  associatedTranscripts?: string[];
  associatedProteins?: string[];
  interactingProteins?: string[];
  encodedProteins?: string[];
  associatedComplexes?: string[];
  associatedPathways?: string[];
}

export interface IErsProteinXrefs extends IErsXrefsBase {
  uniprotId?: string;
  linktoUniprot?: string;
  proteinName?: string;
  synonyms?: string;
  compartments?: string;
  geneOntology?: string[];
}

export interface IErsGeneXrefs extends IErsXrefsBase {
  ensemblId?: string;
  linkToEnsembl?: string;
  hgncSymbol?: string;
  geneName?: string;
  geneSynonym?: string;
  geneDescription?: string;
  ucscGenomeBrowser?: string;
  linkToNcbi?: string;
}

export interface IErsComplexXrefs extends IErsXrefsBase {
  Reactome?: string;
}

export type TErsXrefs = IErsProteinXrefs & IErsGeneXrefs & IErsComplexXrefs;
export interface IErsComponentData {
  xrefs?: TErsXrefs;
  syntensorId?: string;
  compartment?: string;
  componentType?: string;
  displayName?: string;
  // [key: string]: string | number | TErsXrefs | undefined;
}

export type TStudyComponentDetail = IStudyComponent & IErsComponentData;
