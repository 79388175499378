import { ITreeNode } from ".";

export function findPathToNode(treeData: ITreeNode[], nodeId: string) {
  if (treeData && treeData.length > 0) {
    const rootNode = { id: "root", name: "Root", children: treeData };
    const queue: { node: ITreeNode; path: string[] }[] = [
      { node: rootNode, path: [] },
    ];

    while (queue.length > 0) {
      const currItem = queue.pop();
      if (currItem) {
        const { node: currNode, path: currPath } = currItem;
        if (currNode) {
          if (nodeId !== currNode.id && currNode.children) {
            currNode.children.forEach((child) => {
              queue.push({
                node: child,
                path: currPath.concat([currNode.id]),
              });
            });
          } else if (nodeId === currNode.id) {
            //  remove root node
            return currPath.slice(1);
          }
        }
      }
    }
  }

  return null;
}
