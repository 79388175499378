import { useEffect, useRef, useState } from "react";

import TreeItem from "./tree_view_item";
import { findPathToNode } from "./utils";

import styles from "./tree_view.module.css";

export interface ITreeNode {
  id: string;
  name: string;
  isTopLevel?: boolean;
  children?: ITreeNode[];
}

export interface ITreeViewProps {
  data: ITreeNode[];
  activeItemId?: string;
  initialExpandedNodes?: Set<string>;
  onNodeClick?: (node: ITreeNode) => void;
}

export default function TreeView({
  data,
  onNodeClick,
  activeItemId = "",
  initialExpandedNodes = new Set(),
}: ITreeViewProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [expandedNodes, setExpandedNodes] =
    useState<Set<string>>(initialExpandedNodes);

  // Toggle collapse/expand state of a node
  const toggleCollapse = (node: ITreeNode) => {
    const nodeId = node.id;
    setExpandedNodes((prevExpandedNodes) => {
      const newCollapsedNodes = new Set(prevExpandedNodes);
      if (newCollapsedNodes.has(nodeId)) {
        newCollapsedNodes.delete(nodeId);
      } else {
        newCollapsedNodes.add(nodeId);
      }
      return newCollapsedNodes;
    });
  };

  const scrollToItem = (nodeId: string) => {
    if (nodeId && containerRef.current) {
      const item = containerRef.current.querySelector(`[data-id='${nodeId}']`);
      if (item) {
        item.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  };

  //  can activeItemId replace initialExpandedNodes
  useEffect(() => {
    //  check if it's in expanded nodes
    if (!expandedNodes.has(activeItemId)) {
      const nodePath = findPathToNode(data, activeItemId);
      if (nodePath) {
        setExpandedNodes((prevNodes) => {
          const newCollapsedNodes = new Set(prevNodes);
          nodePath.forEach((nodeId) => {
            if (!newCollapsedNodes.has(nodeId)) {
              newCollapsedNodes.add(nodeId);
            }
          });
          return newCollapsedNodes;
        });

        //  wait for a tick to expand the node
        setTimeout(() => {
          scrollToItem(activeItemId);
        });
      }
    } else {
      //  node already expanded
      scrollToItem(activeItemId);
    }
  }, [activeItemId, containerRef.current]);

  return (
    <div ref={containerRef} className={styles.treeView}>
      {data &&
        data.map((node) => {
          return (
            <TreeItem
              key={node.id}
              node={node}
              activeItemId={activeItemId}
              toggleCollapse={toggleCollapse}
              onNodeClick={onNodeClick}
              expandedNodes={expandedNodes}
            />
          );
        })}
    </div>
  );
}
