import { useRouteMatch } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import DataGrid from "@syntensor/common/components/data_grid";
import {
  ESortDir,
  IStudyComponent,
  IStudyContextData,
} from "@syntensor/common/types";
import Error from "@syntensor/common/components/error";
import Loader from "@syntensor/common/components/loader";
import {
  fetchStudyComponents,
  fetchStudyMoaComponents,
} from "@syntensor/common/data/fetch_data";
import LoadMoreBtn from "@syntensor/common/components/load_more_btn";
import DataGridColumns from "@syntensor/common/components/data_grid_columns";
import { flatten } from "@syntensor/common/utils/array_utils";
import getCopy from "@syntensor/common/data/copy";
import ApiFetchError from "@syntensor/common/data/api_fetch_error";

import GridHeader from "./components_grid_header";
import { STUDY_URL_PREFIX } from "../routes";
import { StudyDataContext } from "..";
import { IStudyMatchParams } from "../types";
import { reactQueryOptions } from "../../config";
import { getComponentMoasColumns, getComponentsColumns } from "./columns";
import { selectComponentFilters } from "../../store/filters";
import { EFilterTypes } from "../filters";
import GeneTranscriptLegend from "./legends/gene_transcript_legend";
import MoaLegend from "./legends/moa_legend";
import {
  selectComponentColumns,
  updateComponentColumns,
} from "../../store/columns";

import styles from "../studies.module.css";

export interface IStudyComponentsGridProps {
  ids?: string[];
  target?: string;
  componentType?: string;
  pathwayId?: string;
  hasFilters?: boolean;
  hasLoadMore?: boolean;
  baseUrl?: string;
  keepPreviousData?: boolean;
}

export default function StudyComponentsGrid({
  ids = [],
  baseUrl = "",
  target = "",
  componentType = "",
  pathwayId = "",
  hasFilters = true,
  hasLoadMore = true,
  keepPreviousData = false,
}: IStudyComponentsGridProps) {
  const studyData = useContext<IStudyContextData | null>(StudyDataContext);
  const { cellLines = [], compounds = [] } = studyData || {};

  const [sortDir, setSortDir] = useState<ESortDir>(ESortDir.DESC);
  const [sortBy, setSortBy] = useState("");
  const [hasColumnsPopup, setHasColumnsPopup] = useState(false);
  const [isLegendDisplayed, setIsLegendDisplayed] = useState(false);
  const dataGridColumns = useSelector(selectComponentColumns);
  const filters = useSelector(selectComponentFilters);

  const dispatch = useDispatch();

  const studyMatch = pathwayId
    ? useRouteMatch<IStudyMatchParams>(`${baseUrl}/:pathwayId`)
    : useRouteMatch<IStudyMatchParams>(STUDY_URL_PREFIX);
  const { projectId = "", studyId = "" } = studyMatch?.params || {};

  const cellLineIds = cellLines.map((cellLine) => cellLine.id);
  const cellLinesQueryKey = cellLineIds.join(",");
  const queryKey = [
    "studies",
    "components",
    studyId,
    cellLinesQueryKey,
    componentType,
    JSON.stringify(filters),
    sortBy,
    sortDir,
    pathwayId,
    target,
  ];

  if (pathwayId) {
    queryKey.push(pathwayId);
  }

  if (ids) {
    queryKey.push(ids.join(","));
  }

  const {
    data,
    isLoading,
    isPreviousData,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<
    { items: IStudyComponent[]; after: string },
    ApiFetchError
  >(
    queryKey,
    async ({ pageParam = null }) => {
      if (sortBy) {
        if (componentType === "moa") {
          const moaComponents = await fetchStudyMoaComponents(studyId, {
            sortBy,
            sortDir,
            after: pageParam,
          });

          //  for now we're displaying only genes in the MoA table to get better pathway lists
          //  @see https://github.com/synthetic-tensors/syntensor-product-apps/issues/328
          if (moaComponents && moaComponents.items) {
            moaComponents.items = moaComponents.items.filter(
              (comp: IStudyComponent) => {
                return comp.componentType === "gene";
              }
            );
          }

          return moaComponents;
        } else {
          const fetchFilters = filters
            ? Object.keys(filters).reduce<Record<string, string[] | number[]>>(
                (acc, filterKey: string) => {
                  if (filters[filterKey]) {
                    acc[filterKey] = filters[filterKey] as string[] | number[];
                  }
                  return acc;
                },
                {}
              )
            : {};
          const params: {
            filters: Record<string, string[] | number[] | string>;
            sortBy: string;
            sortDir: ESortDir;
            after: string | number | undefined;
            target?: string;
          } = {
            filters: fetchFilters,
            sortBy,
            sortDir,
            after: pageParam,
          };

          if (componentType) {
            params.filters.componentType = componentType;
          }
          if (pathwayId) {
            params.filters.pathwayId = pathwayId;
          }
          if (target) {
            const isPathway = target.indexOf("PW_") === 0;
            if (isPathway) {
              params.filters.pathwayId = target;
            } else {
              params.target = target;
            }
          }

          //  for now fetching data always just for one cell line
          if (cellLineIds) {
            params.filters.cellLineId = cellLineIds[0];
          }

          //  we might be fetching data for overview
          if (ids && ids.length > 0) {
            params.filters.syntensorId = ids;
          }

          return await fetchStudyComponents(studyId, params);
        }
      }

      return [];
    },
    {
      ...reactQueryOptions,
      retry: false,
      keepPreviousData,
      getNextPageParam: (lastPage = { items: [], after: "" }) => {
        const { after } = lastPage;
        return after;
      },
    }
  );

  const handleSortClick = (sortBy: string, sortDir: ESortDir) => {
    if (sortBy) {
      setSortBy(sortBy);
    }
    if (sortDir) {
      setSortDir(sortDir);
    }
  };

  const handleHasColumnToggle = () => {
    setHasColumnsPopup(!hasColumnsPopup);
  };

  const handleToggleLegend = () => {
    setIsLegendDisplayed(!isLegendDisplayed);
  };

  const handleEditColumns = (
    toggleColumns: string | string[],
    isEnabled: boolean | boolean[]
  ) => {
    dispatch(updateComponentColumns({ toggleColumns, isEnabled }));
  };

  //  set initial sort to the positive compound or just first one
  //  and get initial columns
  useEffect(() => {
    if (!sortBy && cellLines.length && compounds.length) {
      //  set initial sort
      const sortCompoound =
        compounds.find((compound) => {
          return compound.control === "+";
        }) || compounds[0];
      const sortBy =
        componentType === "moa"
          ? `simulations.moaRank.${cellLineIds[0]}:${sortCompoound.compoundId}`
          : `simulations.perturbation.${cellLineIds[0]}:${sortCompoound.compoundId}`;
      setSortBy(sortBy);

      const sortDir = componentType === "moa" ? ESortDir.ASC : ESortDir.DESC;
      setSortDir(sortDir);
    }
  }, [cellLines, compounds]);

  const columns =
    componentType === "moa" || componentType === "interactingProtein"
      ? getComponentMoasColumns({
          cellLines: cellLineIds,
          compounds,
          projectId,
          studyId,
          sortBy,
          sortDir,
          onSortClick: handleSortClick,
        })
      : getComponentsColumns({
          cellLines: cellLineIds,
          componentType,
          compounds,
          projectId,
          studyId,
          sortBy,
          sortDir,
          onSortClick: handleSortClick,
        });

  const mergedColumns = columns.map((col) => {
    const isEnabled =
      col.key in dataGridColumns ? dataGridColumns[col.key] : col.isEnabled;
    return {
      ...col,
      isEnabled,
    };
  });

  //  ignore NotFound errors and just display error message
  if (error && error.statusCode !== 404) {
    //console.log(error.statusCode);
    return <Error error={error} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  const components =
    data && data.pages ? flatten(data.pages.map((p) => p.items)) : [];

  const displayedColumns = mergedColumns.filter((col) => {
    return col.isEnabled || typeof col.isEnabled === "undefined";
  });

  const formattedComponents = components
    ? components.map((comp) => {
        const { simulations } = comp;
        const { perturbation } = simulations;

        //  add number keys for simulation keys
        //  such as `A549:GW583340_10μM: -0.3079485906717885`
        const formattedComp: {
          [key: string]: IStudyComponent[keyof IStudyComponent] | number;
        } = {
          ...comp,
        };

        return Object.keys(perturbation).reduce((acc, cellLineIdcompoundId) => {
          const compPerturbation = perturbation[cellLineIdcompoundId];
          acc[cellLineIdcompoundId] = compPerturbation;
          return acc;
        }, formattedComp);
      })
    : [];

  const hasComponents = formattedComponents.length > 0;
  const renderedLegend =
    componentType === "moa" || componentType === "interactingProtein" ? (
      <MoaLegend />
    ) : (
      <GeneTranscriptLegend />
    );

  return (
    <>
      <>
        <GridHeader
          filterType={EFilterTypes.COMPONENTS_FILTER_TYPE}
          hasFilters={hasFilters}
          columns={mergedColumns}
          isLegendDisplayed={isLegendDisplayed}
          onEditColumns={handleHasColumnToggle}
          onToggleLegend={handleToggleLegend}
        />
        {isLegendDisplayed && (
          <div className={styles.legend}>{renderedLegend}</div>
        )}
        {hasComponents && (
          <DataGrid columns={displayedColumns} rows={formattedComponents} />
        )}
      </>
      {!hasComponents && (
        <p className={styles.emptyResult}>
          {getCopy(`studies_components_no-${componentType}`)}
        </p>
      )}
      {hasComponents && hasLoadMore && (
        <div className={styles.loadMoreBtn}>
          <LoadMoreBtn
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        </div>
      )}
      {hasColumnsPopup && (
        <DataGridColumns
          columns={mergedColumns}
          onToggle={handleEditColumns}
          onClose={handleHasColumnToggle}
          onConfirm={handleHasColumnToggle}
          onCancel={handleHasColumnToggle}
        />
      )}
      {/* Do we wanna display loader over stale content - typically to prevent
        layout from jumping in between queries */}
      {keepPreviousData && isPreviousData && <Loader />}
    </>
  );
}
