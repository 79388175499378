import { useEffect, useRef, useContext } from "react";
import cn from "classnames";

import { ReactComponent as DarkModeGraphic } from "./dark_mode.svg";
import { ReactComponent as LightModeGraphic } from "./light_mode.svg";
import { setExplicitColorMode, getColorModePreference } from "./fns";
import { DarkModeContext, DARK_MODE, LIGHT_MODE } from "./dark_mode_context";

import styles from "./dark_mode.module.css";

export interface IDarkModeToggleProps {
  hasDarkTheme?: boolean;
}

export default function DarkModeToggle({
  hasDarkTheme = false,
}: IDarkModeToggleProps) {
  const flamingoRef = useRef("");

  const { colorMode, setColorMode } = useContext(DarkModeContext);

  const handleClick = () => {
    const newColorMode = colorMode === LIGHT_MODE ? DARK_MODE : LIGHT_MODE;

    //  index.html template page should define util functions
    //  on global scope for handling dark-mode
    if (setExplicitColorMode && typeof setExplicitColorMode === "function") {
      setExplicitColorMode(newColorMode);
      setColorMode(newColorMode);
    }
  };

  //  set initial state
  useEffect(() => {
    //  index.html template page should define util functions
    //  on global scope for handling dark-mode
    const colorMode = getColorModePreference();
    if (colorMode) {
      setColorMode(colorMode);
    }
  }, [setColorMode]);

  //  add flamingo mode!
  useEffect(() => {
    if (window) {
      window.onkeypress = (evt) => {
        flamingoRef.current += evt.key;
        if (flamingoRef.current.indexOf("flamingo") > -1) {
          const colorMode = "flamingo";
          setExplicitColorMode(colorMode);
          setColorMode(colorMode);
          // dispatch(setColorMode(colorMode));
          flamingoRef.current = "";
        }
      };
    }
  }, [setColorMode]);

  const isDarkMode = colorMode === DARK_MODE;

  const classNames = cn(styles.graphic, {
    [styles.lightModeGraphic]: !isDarkMode,
    [styles.darkModeGraphic]: isDarkMode,
    [styles.darkThemeGraphic]: hasDarkTheme,
  });

  const graphic = isDarkMode ? <DarkModeGraphic /> : <LightModeGraphic />;

  return (
    <button className={styles.darkModeToggle} onClick={handleClick}>
      <span className={classNames}>{graphic}</span>
    </button>
  );
}
