import styles from "./error.module.css";

export interface IErrorProps {
  children?: React.ReactNode;
  msg?: string;
  error?: unknown;
}

export default function Error({
  children = null,
  msg = "",
  error = null,
}: IErrorProps) {
  let validatedMsg = msg;

  if (!msg && error && error instanceof Error) {
    if (
      typeof error === "object" &&
      "message" in error &&
      typeof error.message === "string"
    ) {
      validatedMsg = error.message;
    }
  }

  return (
    <div className={styles.error}>
      {validatedMsg}
      {children}
    </div>
  );
}
