import { ChangeEvent, useCallback, useState } from "react";
import { useQuery } from "react-query";

import Loader from "@syntensor/common/components/loader";
import Error from "@syntensor/common/components/error";
import { fetchGraphMetrics } from "@syntensor/common/data/fetch_data";
import MetricsBarChart from "@syntensor/common/components/metrics_bar_chart";
import useMemoMdxComponent from "@syntensor/common/hooks/use_memo_mdx_component";
import ViewToggle, {
  VIEWS_MAP,
} from "@syntensor/common/components/view_toggle";
import Select from "@syntensor/common/components/select";
import Table from "@syntensor/common/components/table";

import { reactQueryOptions } from "../../config";
import { mdxComponents } from "../markdown_components";
import CoverageContent from "../../../_content/metrics/coverage.mdx";

//  @TODO - problems with importing markdown documents
// import GRAPH_VERSION from "../../../_content/metrics/graph_version.md";

import styles from "../metrics.module.css";

// import Table from "@syntensor/common/components/table";

export const BAR_COLORS = ["var(--primary-blue)", "var(--amber)"];

export const VIEWS = [
  { icon: VIEWS_MAP.barchart.id },
  { icon: VIEWS_MAP.table.id },
];

export const SELECT_OPTIONS = [
  { id: "transcriptomics", name: "Transcriptomics" },
  { id: "proteomics", name: "Proteomics" },
];

export const GRAPH_VERSION = "RC_17.3.0";

export interface IMetricsGraphData {
  counts: Record<string, string>[];
  pathways: Record<string, string>[];
  signatures: Record<string, string>[];
  sources: Record<string, string>[];
}

export interface ISignaturesChartSectionProps {
  metricsGraphData: IMetricsGraphData;
  title: string;
  explainer: string;
  chartlabels: string;
  tablelabels: string;
  componentType: string;
  activeView: string;
  onActiveViewChange: () => void;
  onComponentTypeChange: () => void;
}

export function SignaturesChartSection({
  metricsGraphData,
  title,
  explainer,
  chartlabels,
  tablelabels,
  componentType,
  activeView,
  onActiveViewChange,
  onComponentTypeChange,
}: ISignaturesChartSectionProps) {
  const chartLabels = chartlabels ? chartlabels.split(",") : [];
  const tableLabels = tablelabels ? tablelabels.split(",") : [];

  const hasData = metricsGraphData && metricsGraphData.signatures;
  if (!hasData) {
    return null;
  }
  const { signatures } = metricsGraphData;

  const isSelectDisabled = activeView === VIEWS_MAP.table.id;
  const barchartValue = `${componentType}-signatures`;
  let dynamicChartColumn = chartLabels[1];
  if (dynamicChartColumn) {
    const reg = new RegExp(`%componentType%`);
    dynamicChartColumn = dynamicChartColumn.replace(reg, componentType);
  }

  console.log("signatures", signatures);

  return (
    <div className={styles.section} data-testid="metrics_coverage_signatures">
      <div className={styles.sectionHeader}>
        <h3 className={styles.sectionTitle}>{title}</h3>
        <div>
          <div className={styles.sectionControls}>
            <Select
              name="component-types"
              isDisabled={isSelectDisabled}
              value={componentType}
              options={SELECT_OPTIONS}
              onChange={onComponentTypeChange}
              size="small"
            />
            <ViewToggle
              onClick={onActiveViewChange}
              activeView={activeView}
              btns={[
                {
                  id: "barchart",
                  icon: VIEWS_MAP.barchart.id,
                },
                { id: "table", icon: VIEWS_MAP.table.id },
              ]}
            />
          </div>
        </div>
      </div>
      <p className={styles.sectionExplainer}>{explainer}</p>
      {hasData && activeView === VIEWS_MAP.barchart.id && (
        <MetricsBarChart
          data={signatures}
          hasRightValue={true}
          scaleType="log"
          nameProp="therapeutic area"
          valueProps={[barchartValue]}
          headers={[
            chartLabels[0] || "Therapeutic area",
            dynamicChartColumn || "Number of signatures",
          ]}
        />
      )}
      {hasData &&
        activeView === VIEWS_MAP.table.id &&
        Array.isArray(signatures) && (
          <Table
            config={{
              cols: [
                {
                  id: "therapeutic area",
                  name: tableLabels[0] || "Therapeutic area",
                  width: "50%",
                },
                {
                  id: "transcriptomics-signatures",
                  name: tableLabels[1] || "Transcriptomic signatures",
                  width: "25%",
                },
                {
                  id: "proteomics-signatures",
                  name: tableLabels[2] || "Proteomic signatures",
                  width: "25%",
                },
              ],
            }}
            data={signatures}
          />
        )}
    </div>
  );
}

export default function MetricsCoverage() {
  // const [isLoading, setIsLoading] = useState(false);
  const [signaturesComponentType, setSignaturesComponentType] = useState(
    SELECT_OPTIONS[0].id
  );
  const [signaturesView, setSignaturesView] = useState(VIEWS_MAP.barchart.id);

  const fetchMetrics = useCallback(async () => {
    return await fetchGraphMetrics(GRAPH_VERSION);
  }, []);

  const queryKey = ["metrics"];
  const {
    data: metricsGraphData = [],
    isLoading,
    error,
  } = useQuery(queryKey, fetchMetrics, reactQueryOptions);

  const onSignatureViewChange = useCallback((viewId: string) => {
    setSignaturesView(viewId);
  }, []);

  const onSignatureComponentTypeChange = useCallback(
    (evt: ChangeEvent<HTMLSelectElement>) => {
      const selectedId = evt.target.value;
      const selectedComponentType = SELECT_OPTIONS.find(
        (metric) => metric.id === selectedId
      );

      if (selectedComponentType) {
        setSignaturesComponentType(selectedComponentType.id);
      }
    },
    []
  );

  const SignaturesChart = useMemoMdxComponent(SignaturesChartSection, {
    metricsGraphData,
    componentType: signaturesComponentType,
    activeView: signaturesView,
    onActiveViewChange: onSignatureViewChange,
    onComponentTypeChange: onSignatureComponentTypeChange,
  });

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const components = { ...mdxComponents, SignaturesChart };
  return (
    <div className={styles.content}>
      <CoverageContent
        components={components}
        metricsGraphData={metricsGraphData}
      />
    </div>
  );
}
