import { useCallback, ReactNode, SyntheticEvent } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  deleteUrlParam,
  getBackUrl,
  navigateWithQueryParams,
} from "../../browser_history";

import { BackIcon } from "../icons";

import classes from "./back_btn.module.css";

export function getDefaultBackUrl(currUrl: string) {
  if (!currUrl) {
    return "";
  }

  const urlArr = currUrl.split("/");
  if (urlArr.length < 2) {
    return currUrl;
  }

  //  remove last part of url and return a string
  urlArr.pop();

  return urlArr.join("/");
}

export interface IBackBtnProps {
  to?: string;
  children?: ReactNode | null;
}

export default function BackBtn({ to = "", children = null }: IBackBtnProps) {
  const { pathname } = useLocation();

  //  unless passed down, get the back btn from url or use default
  let resolvedTo = to;

  if (!resolvedTo) {
    const toFromUrl = getBackUrl() as string;
    if (toFromUrl) {
      resolvedTo = toFromUrl;
    } else {
      resolvedTo = getDefaultBackUrl(pathname);
    }
  }

  const handleBackClick = useCallback((evt: SyntheticEvent) => {
    evt.preventDefault();

    const { currentTarget } = evt;
    const href = currentTarget.getAttribute("href");

    if (href) {
      //  remove back url cause it's most likely needed just once
      deleteUrlParam("b");
      navigateWithQueryParams(href);
    }
  }, []);

  return (
    <Link to={resolvedTo} className={classes.backBtn} onClick={handleBackClick}>
      <BackIcon />
      {children}
    </Link>
  );
}
