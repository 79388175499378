import { useRouteMatch } from "react-router-dom";
import { useContext } from "react";

import { IStudyContextData } from "@syntensor/common/types";
import getCopy from "@syntensor/common/data/copy";

import ViewToggle from "@syntensor/common/components/view_toggle";

import { StudyDataContext } from "..";
import StudiesTitle from "../title";
import { IStudyMatchParams, IStudyEndpointMatchParams } from "../types";
import StudyComponentsGrid from "../components/components_grid";
import StudyPathwaysGrid from "../pathways/pathways_grid";
import { STUDY_URL_PREFIX } from "../routes";
import CompoundsTable from "./compounds_table";

import styles from "../studies.module.css";
import overviewStyles from "../overview/overview.module.css";

export default function StudySensitivity() {
  const routeMatch = useRouteMatch<IStudyEndpointMatchParams>(
    `${STUDY_URL_PREFIX}/endpoints/:endpointId`
  );
  const { endpointId = "" } = routeMatch?.params || {};

  const studyMatch = useRouteMatch<IStudyMatchParams>(STUDY_URL_PREFIX);
  const url = studyMatch?.url;

  const studyData = useContext<IStudyContextData | null>(StudyDataContext);
  const { compounds = [], cellLines = [], endpoints = {} } = studyData || {};

  const cellLine = cellLines && cellLines.length > 0 ? cellLines[0].id : "";

  const endpointsData = endpoints ? endpoints[endpointId] : null;

  if (!endpointsData) {
    return <div>No endpoint data</div>;
  }

  const endpointCategories = Object.keys(endpoints);
  const viewBtns = endpointCategories.map((category) => {
    return {
      id: category,
      to: `${url}/endpoints/${category}`,
      exact: true,
      label: getCopy(`studies_endpoints_${category}`),
    };
  });

  return (
    <div>
      <div className={styles.titles}>
        <StudiesTitle />
      </div>
      <div className={overviewStyles.content}>
        <div className={overviewStyles.controls}>
          <div className={styles.noGrowControl}>
            <ViewToggle activeView={endpointId} btns={viewBtns} />
          </div>
        </div>
        <div className={overviewStyles.gridSection}>
          <h3 className={overviewStyles.gridTitle}>
            {getCopy(`studies_endpoints_${endpointId}-title`)}
          </h3>
          <p className={overviewStyles.gridDescription}>
            {getCopy(`studies_endpoints_${endpointId}-description`)}
          </p>
          <CompoundsTable
            keys={endpointsData.channels}
            compounds={compounds}
            cellLine={cellLine}
          />
        </div>
        <div className={overviewStyles.gridSection}>
          <h3 className={overviewStyles.gridTitle}>
            {getCopy(`studies_endpoints_${endpointId}-components-title`)}
          </h3>
          <p className={overviewStyles.gridDescription}>
            {getCopy(`studies_endpoints_${endpointId}-components-description`)}
          </p>
          <div className={overviewStyles.gridContent}>
            <StudyComponentsGrid
              ids={endpointsData.associatedComponents}
              hasFilters={false}
              hasLoadMore={false}
              keepPreviousData={true}
            />
          </div>
        </div>
        <div className={overviewStyles.gridSection}>
          <h3 className={overviewStyles.gridTitle}>
            {getCopy(`studies_endpoints_${endpointId}-pathways-title`)}
          </h3>
          <p className={overviewStyles.gridDescription}>
            {getCopy(`studies_endpoints_${endpointId}-pathways-description`)}
          </p>
          <div className={overviewStyles.gridContent}>
            <StudyPathwaysGrid
              ids={endpointsData.associatedPathways}
              hasFilters={false}
              hasLoadMore={false}
              keepPreviousData={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
