import { Link } from "react-router-dom";

import styles from "./projects.module.css";

export interface IProjectBoxProps {
  id: string;
  name: string;
  groupName: string;
  description: string;
}

export default function ProjectBox({
  id,
  name,
  groupName,
  description,
}: IProjectBoxProps) {
  return (
    <Link
      className={styles.project}
      to={`/projects/${id}`}
      data-testid="projects_project-box"
    >
      <h4 className={styles.group}>{groupName}</h4>
      <h3 className={styles.name}>{name}</h3>
      <p className={styles.description}>{description}</p>
    </Link>
  );
}
