import { Switch, Route, Redirect } from "react-router-dom";

import ProjectCompounds from "./compounds";
import ProjectLatestActivity from "./latest_activity";
import ProjectStudies from "./studies";
import ProjectTissues from "./tissues";
import ProjectEfficacy from "./efficacy";
import ProjectAdmeTox from "./admetox";
import ProjectManage from "./manage";
import ProjectSettings from "./settings";

export const PROJECT_URL_PREFIX = `/projects/:projectId`;

export default function ProjectRoutes() {
  return (
    <Switch>
      <Route
        path={`${PROJECT_URL_PREFIX}/latest-activity`}
        component={ProjectLatestActivity}
      />
      <Route
        path={`${PROJECT_URL_PREFIX}/compounds`}
        component={ProjectCompounds}
      />
      <Route
        path={`${PROJECT_URL_PREFIX}/studies`}
        component={ProjectStudies}
      />
      <Route
        path={`${PROJECT_URL_PREFIX}/tissues`}
        component={ProjectTissues}
      />
      <Route
        path={`${PROJECT_URL_PREFIX}/efficacy`}
        component={ProjectEfficacy}
      />
      <Route
        path={`${PROJECT_URL_PREFIX}/admetox`}
        component={ProjectAdmeTox}
      />

      <Route path={`${PROJECT_URL_PREFIX}/manage`} component={ProjectManage} />
      <Route
        path={`${PROJECT_URL_PREFIX}/settings`}
        component={ProjectSettings}
      />

      {/*
        for now 'studies' is the only one build, otherwise default
        should be latest-activity
      */}
      <Redirect
        from={PROJECT_URL_PREFIX}
        to={`${PROJECT_URL_PREFIX}/studies`}
      />
    </Switch>
  );
}
