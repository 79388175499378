import { ChangeEvent } from "react";

import Input from "@syntensor/common/components/input";
import Select from "@syntensor/common/components/select";
import { IStudyEndpoint } from "@syntensor/common/types";
import { EIconSize, TrashIcon } from "@syntensor/common/components/icons";
import getCopy from "@syntensor/common/data/copy";

import styles from "./endpoints.module.css";

export interface IStudyEndpointConfig extends IStudyEndpoint {
  name: string;
}

export interface INewEndpointFormProps {
  index: number;
  endpoint: IStudyEndpointConfig;
  availableEndpoints: string[];
  onChange: (
    index: number,
    endpoint: IStudyEndpointConfig,
    changeProped: string
  ) => void;
  onDelete: (index: number) => void;
  isDisabled?: boolean;
}

export default function NewEndpointForm(props: INewEndpointFormProps) {
  const {
    index,
    availableEndpoints,
    endpoint,
    onChange,
    onDelete,
    isDisabled,
  } = props;
  const { name, channels } = endpoint;

  const handleInputChange = (
    evt: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = evt.currentTarget;

    const isListTextArea =
      name === "channels" ||
      name === "associatedComponents" ||
      name === "associatedPathways";

    const formattedValue = isListTextArea ? value.split(",") : value;
    const newEndpointValue = {
      ...endpoint,
      [name]: formattedValue,
    };

    onChange(index, newEndpointValue, name);
  };

  const handleRemoveClick = () => {
    onDelete(index);
  };

  const availableEndpointsOptions = availableEndpoints.map((endpoint) => ({
    id: endpoint,
    name: endpoint,
  }));

  return (
    <div className={styles.endpointForm}>
      <button
        className={styles.endpointFormRemoveBtn}
        onClick={handleRemoveClick}
        type="button"
      >
        <TrashIcon size={EIconSize.SMALL} />
      </button>
      <div className={styles.endpointFormSection}>
        <h3 className={styles.endpointFormTitle}>
          {getCopy("studies_create_endpoint-name")}
        </h3>
        <Select
          name="name"
          value={name}
          options={availableEndpointsOptions}
          onChange={handleInputChange}
          isDisabled={isDisabled}
        />
      </div>
      <div className={styles.endpointFormSection}>
        <h3 className={styles.endpointFormTitle}>
          {getCopy("studies_create_endpoint-channels")}
        </h3>
        <Input
          placeholder="Channels"
          name="channels"
          elName="textarea"
          rows={2}
          value={channels}
          onChange={handleInputChange}
          disabled={isDisabled}
          required
        />
      </div>
    </div>
  );
}
