import { useEffect, useRef } from "react";
import { scaleLinear, scaleLog } from "d3-scale";
import { axisBottom } from "d3-axis";
import { select } from "d3-selection";
import { format } from "d3-format";
import cn from "classnames";

import BoxPlot from "@syntensor/common/components/box_plot";
import { getDomainFromData } from "@syntensor/common/utils/scale_utils";

import useDimensions from "../../hooks/use_dimensions";

import styles from "./metrics_bar_chart.module.css";

export function logValueValidator(d: number) {
  return Math.max(d, 1);
}

export interface IMetricsBarChartProps {
  data?: Record<string, unknown>[];
  headers?: string[];
  nameProp?: string;
  valueProps?: string[];
  scaleType?: string;
  hasRightValue?: boolean;
  hasLeftValue?: boolean;
  numberFormat?: string;
  hasBoxPlots?: boolean;
  isStacked?: boolean;
  styleFn?: (i: number) => Record<string, unknown> | null;
}

export default function MetricsBarChart({
  data = [],
  headers = ["abc", "bcd"],
  nameProp = "name",
  valueProps = ["Number of nodes"],
  scaleType = "linear",
  hasRightValue = false,
  hasLeftValue = false,
  numberFormat = ",",
  hasBoxPlots = false,
  isStacked = false,
  styleFn = (_: number) => null,
}: IMetricsBarChartProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [containerRef, dimensions, _, measure] = useDimensions();
  const svgAxisRef = useRef<SVGSVGElement>(null);

  const formattedData: { id: string; name: string; values: number[] }[] =
    data.map((datum) => {
      return {
        id: datum[nameProp] as string,
        name: datum[nameProp] as string,
        values: valueProps.reduce<number[]>((acc, prop) => {
          const value = datum[prop] as number;
          if (Array.isArray(value)) {
            return acc.concat(value);
          }
          acc.push(value);
          return acc;
        }, []),
      };
    });

  const values = formattedData.reduce<number[]>((acc, datum) => {
    if (isStacked) {
      //  if stacked bar chart, we want to get maximum value for the row total
      const rowTotal = datum.values.reduce((acc, d) => {
        return acc + parseFloat(d.toString());
      }, 0);
      acc.push(rowTotal as number);
      return acc;
    }

    //  otherwise get maximum value in the row
    //  (e.g. when displaying portions of a total)
    return acc.concat(
      datum.values.map((d) => {
        return +d;
      })
    );
  }, []);

  const dimensionsWidth = dimensions ? dimensions.width : 0;

  const [min, max] = getDomainFromData(values);
  const widthScale = scaleType === "log" ? scaleLog() : scaleLinear();

  //  box plots need more room on the right to allow for the right label
  const range = hasBoxPlots
    ? [0.001, dimensionsWidth - 75]
    : [0.001, dimensionsWidth];

  widthScale.range(range).nice();

  if (scaleType !== "log") {
    widthScale.domain([min, max]);
  } else {
    //  log scale cannot include zero
    widthScale.domain([logValueValidator(min), logValueValidator(max)]);
  }

  useEffect(() => {
    const axis = axisBottom(widthScale);

    if (svgAxisRef && svgAxisRef.current) {
      select(svgAxisRef.current).call(axis);
      measure();
    }
  }, [widthScale, measure]);

  //  might help with e2e not to render the chart untill we have valid scale
  const hasValidScale = widthScale.range().every((n: number) => n > 0);
  const hasValidData = data && hasValidScale;

  const formatterFn = format(numberFormat);

  return (
    <div className={styles.metricsBarChart}>
      {headers && headers.length && (
        <div className={styles.header}>
          {headers.map((header, i: number) => {
            return (
              <span key={i} className={styles.headerCell}>
                {header}
              </span>
            );
          })}
        </div>
      )}
      <div>
        {hasValidData &&
          formattedData.map((datum, i: number) => {
            //  position right label to the largest bar/box-plot there is
            const maxValue = Math.max(...datum.values);
            const rightWidth = widthScale(maxValue);
            const hasRightLabelRightAnchor =
              rightWidth > dimensionsWidth / 1.5 && !hasBoxPlots;
            const rightLabelStyles = cn(styles.rightLabel, {
              [styles.rightLabelRightAnchor]: hasRightLabelRightAnchor,
              [styles.boxPlotLabels]: hasBoxPlots,
            });

            const barOpacities = datum.values.length === 2 ? [10, 70] : [70];

            const boxPlotData =
              hasBoxPlots && datum.values.length > 3
                ? {
                    minimum: logValueValidator(datum.values[0]),
                    quartile1: logValueValidator(datum.values[1]),
                    mean: logValueValidator(datum.values[2]),
                    quartile3: logValueValidator(datum.values[3]),
                    maximum: logValueValidator(datum.values[4]),
                  }
                : null;

            const barClassNames = cn(styles.bar, {
              [styles.stackedBar]: isStacked,
            });

            return (
              <div className={styles.row} key={i}>
                <span className={styles.name}>{datum.name}</span>
                {hasLeftValue && (
                  <span className={styles.leftLabel}>
                    {!boxPlotData && datum.values[datum.values.length - 1]}
                    {boxPlotData && datum.values[2]}
                  </span>
                )}
                <span className={styles.bars}>
                  {!hasBoxPlots &&
                    datum.values.map((d, i2: number) => {
                      //  allow override
                      const style = styleFn(i2) || {};
                      return (
                        <span
                          key={i2}
                          className={barClassNames}
                          style={{
                            width: `${widthScale(d)}px`,
                            opacity: `${barOpacities[i2]}%`,
                            ...style,
                          }}
                          data-testid="metrics_bar-chart_bar"
                        />
                      );
                    })}
                  {hasBoxPlots && (
                    <div className={styles.boxPlot}>
                      <div className={styles.boxPlotInner}>
                        <BoxPlot
                          scale={widthScale}
                          data={boxPlotData}
                          color={"rgba(69,95,237)"}
                        />
                      </div>
                    </div>
                  )}
                  {hasRightValue && (
                    <span
                      className={rightLabelStyles}
                      style={{ left: `${rightWidth}px` }}
                    >
                      {formatterFn(datum.values[datum.values.length - 1])}
                    </span>
                  )}
                </span>
              </div>
            );
          })}
      </div>
      <div className={styles.footer}>
        <div className={styles.axisLabel}>
          {scaleType === "log" && <span>Logarithmic scale</span>}
        </div>
        {hasLeftValue && <div className={styles.axisLeftLabel} />}
        <div className={styles.axis} ref={containerRef}>
          <svg className={styles.axisSvg} ref={svgAxisRef} />
        </div>
      </div>
      {/* <div
        style={{
          position: "absolute",
          padding: "12px",
          border: "1px solid var(--outline-gray)",
          backgroundColor: "var(--primary-light)",
          bottom: "65px",
          right: "12px",
          textAlign: "right"
        }}
      >
        <span style={{ display: "block", color: "var(--primary-blue)" }}>Transcriptomics</span>
        <span style={{ display: "block", color: "var(--amber)" }}>Proteomics</span>
      </div> */}
    </div>
  );
}
