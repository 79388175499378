import { RefObject, useEffect } from "react";

export default function useOutsideClick(
  ref: RefObject<HTMLElement>,
  state: boolean,
  action: () => void
) {
  const eventAction = (ev: Event) => {
    if (
      ref.current &&
      ev.target instanceof Node &&
      !ref.current.contains(ev.target)
    ) {
      ev.preventDefault();
      action();
      removeGlobalListeners();
    }
  };

  const addGlobalListeners = () => {
    document.addEventListener("click", eventAction, false);
    document.addEventListener("touchend", eventAction, false);
    window.addEventListener("popstate", eventAction, false);
  };

  const removeGlobalListeners = () => {
    document.removeEventListener("click", eventAction, false);
    document.removeEventListener("touchend", eventAction, false);
    window.removeEventListener("popstate", eventAction, false);
  };

  useEffect(() => {
    if (state) {
      addGlobalListeners();
    }

    return function cleanup() {
      removeGlobalListeners();
    };
  });
}

// export default useOutsideClick;
