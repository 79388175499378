import {
  useCallback,
  useEffect,
  useState,
  RefObject,
  ChangeEvent,
} from "react";
import { debounce } from "throttle-debounce";
import ClipLoader from "react-spinners/ClipLoader";

import { EIconSize, SearchIcon } from "../icons";
import Input from "../input";

import styles from "./search_input.module.css";

export const MIN_CHARS = 3;
export const DEBOUNCE_DELAY = 500;

export interface SearchInputProps {
  isLoading?: boolean;
  placeholder?: string;
  defaultValue?: string | number;
  onChange?: (_: string) => void;
  onSearch?: (_: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  inputRef?: RefObject<HTMLInputElement> | null;
  hasLeftOffset?: boolean;
  size?: string;
  minChars?: number;
  name?: string;
  autoComplete?: string;
  disabled?: boolean;
  debounceDelay?: number;
}

export default function SearchInput({
  placeholder,
  onChange,
  onSearch = () => null,
  onFocus,
  onBlur,
  defaultValue = "",
  hasLeftOffset = false,
  isLoading = false,
  debounceDelay = DEBOUNCE_DELAY,
  minChars = MIN_CHARS,
  inputRef = null,
  size = "small",
  name = "search",
  autoComplete = "on",
  disabled = false,
}: SearchInputProps) {
  const [value, setValue] = useState(defaultValue);
  const [hadSearch, setHadSearch] = useState(false);

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const input = evt.currentTarget.value;
    setValue(input);

    if (onChange) {
      onChange(input);
    }

    //  check whether minimum chars to initiate search
    const hasMinLength = input.length >= minChars;

    //  all good initiate search
    if (hasMinLength) {
      debouncedOnSearch(input);
      setHadSearch(true);
    } else if (hadSearch) {
      setHadSearch(false);
    }
  };

  const debouncedOnSearch = useCallback(
    debounce(debounceDelay, onSearch, { atBegin: false }),
    [debounceDelay, onSearch]
  );

  const handleInputFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };
  const handleInputBlur = () => {
    if (onBlur) {
      onBlur();
    }
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const classNames = [styles.searchInput, size === "small" && styles[size]];
  const inputClassNames = [styles.input];

  if (hasLeftOffset) {
    inputClassNames.push(styles.inputWithLeftOffset);
  }

  const clipLoaderSize = size === "small" ? 18 : 22;

  //  do not use large icon if size large since
  //  it wouldn't fit the input, default size works fine
  //  for large input
  const searchIconSize = size === "small" ? EIconSize.SMALL : EIconSize.DEFAULT;

  return (
    <label className={classNames.join(" ")}>
      {!isLoading && (
        <div className={styles.icon}>
          <SearchIcon size={searchIconSize} />
        </div>
      )}
      {isLoading && (
        <div className={styles.preloader}>
          <ClipLoader size={clipLoaderSize} />
        </div>
      )}
      <Input
        value={value}
        className={inputClassNames.join(" ")}
        type="text"
        size={size}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        name={name}
        autoComplete={autoComplete}
        disabled={disabled}
        ref={inputRef}
      />
    </label>
  );
}
