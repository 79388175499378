import Input from "@syntensor/common/components/input";

import styles from "./profile_input.module.css";

export interface IProfileInputProps {
  name: string;
  className?: string;
  label?: string;
  [key: string]: unknown;
}

export default function ProfileInput({
  name,
  className = "",
  label = "",
  ...props
}: IProfileInputProps) {
  const classNames = [styles.label, className];

  return (
    <label className={classNames.join(" ")} htmlFor={name}>
      {label && <span className={styles.inputLabel}>{label}</span>}
      <Input name={name} {...props} />
    </label>
  );
}
