import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IDataGridColumn } from "@syntensor/common/components/data_grid_columns";
import { EDataGridColumnType } from "@syntensor/common/components/data_grid_columns";
import Select from "@syntensor/common/components/select";
import getCopy from "@syntensor/common/data/copy";

import {
  selectComponentFilters,
  selectPathwayFilters,
  updateComponentFilters,
  updatePathwayFilters,
} from "../../store/filters";
import Filter from "./filter";
import { IStoreState } from "../../store/types";

import styles from "./filters.module.css";

export type TFilterValue = (string | number | never)[] | null;

export interface IFilterOption {
  id: string;
  name: string;
  // value: string | number | boolean;
  isEnabled: boolean;
}

export interface IFilterDropdownProps {
  options?: IFilterOption[];
  onFilterValueChange?: (value: (string | number)[]) => void;
  onFilterCancel: () => void;
}

export interface IFilter {
  id: string;
  name?: string;
  options?: IFilterOption[];
  value?: TFilterValue;
  type?: EDataGridColumnType;
}

export enum EFilterTypes {
  COMPONENTS_FILTER_TYPE,
  PATHWAYS_FILTER_TYPE,
}

export interface IFiltersProps {
  columns: IDataGridColumn[];
  type: EFilterTypes;
}

export default function Filters({
  columns,
  type = EFilterTypes.COMPONENTS_FILTER_TYPE,
}: IFiltersProps) {
  const [openFilter, setOpenFilter] = useState<string | null>(null);

  const dispatch = useDispatch();
  const filters: Record<string, TFilterValue> = useSelector(
    (state: IStoreState) =>
      type === EFilterTypes.COMPONENTS_FILTER_TYPE
        ? selectComponentFilters(state)
        : selectPathwayFilters(state)
  );

  const dispatchUpdateFilters = (payload: Record<string, TFilterValue>) => {
    if (type === EFilterTypes.COMPONENTS_FILTER_TYPE) {
      dispatch(updateComponentFilters(payload));
    } else if (type === EFilterTypes.PATHWAYS_FILTER_TYPE) {
      dispatch(updatePathwayFilters(payload));
    }
  };

  const handleAddFilter = (evt: ChangeEvent<HTMLSelectElement>) => {
    const payload = {
      ...filters,
      [evt.target.value]: [],
    };

    //  idealy we'd set the open filter to the evt.target.value
    //  but due to React and Redux updates not being batched together
    //  we need to wait for the filter to be added to the store,
    //  and then use the useEffect below to set the open filter
    //  @see https://github.com/synthetic-tensors/syntensor-product-apps/issues/278
    dispatchUpdateFilters(payload);
  };

  const handleRemoveClick = (id: string) => {
    const payload = {
      ...filters,
      [id]: null,
    };
    dispatchUpdateFilters(payload);
  };

  const handleFilterChange = (id: string, value: TFilterValue) => {
    const payload = {
      ...filters,
      [id]: value,
    };
    dispatchUpdateFilters(payload);
  };

  const handleFilterOpenToggle = (id: string) => {
    setOpenFilter((prevOpenFilter) => {
      if (prevOpenFilter === id) {
        return null;
      }
      return id;
    });
  };

  const handleFilterCancel = () => {
    setOpenFilter(null);
  };

  const addedFilters = Object.keys(filters);
  const availableFilters = columns.filter((column) => {
    //  exclude already added filters and those columns which
    //  are not meant to be filterable
    return !addedFilters.includes(column.key) && column.isFilterable !== false;
  });
  const addFilterOptions = [
    {
      id: "placeholder",
      name: getCopy("studies_filter_add-filter"),
      disabled: true,
    },
  ].concat(
    availableFilters.map((column) => {
      return {
        ...column,
        name: column.name as string,
        id: column.key,
        disabled: false,
      };
    })
  );

  useEffect(() => {
    //  do we have an empty filter which has been added and needs opening?
    const emptyFilterKey = Object.keys(filters).find((key) => {
      return Array.isArray(filters[key]) && (filters[key] as []).length === 0;
    });
    if (emptyFilterKey) {
      setOpenFilter(emptyFilterKey);
    }
  }, [filters]);

  const hasAddingFilter = availableFilters.length > 0;

  return (
    <div className={styles.filters}>
      {filters &&
        Object.keys(filters).map((filterKey) => {
          const column = columns.find((column) => column.key === filterKey);
          const { name, options, type } = column || {};
          const value = filters[filterKey];

          return (
            <Filter
              id={filterKey}
              key={filterKey}
              name={name}
              value={value}
              type={type}
              options={options as IFilterOption[]}
              isOpen={openFilter === filterKey}
              onFilterChange={handleFilterChange}
              onFilterRemove={handleRemoveClick}
              onFilterOpenToggle={handleFilterOpenToggle}
              onFilterCancel={handleFilterCancel}
            />
          );
        })}
      {hasAddingFilter && (
        <Select
          size="small"
          value="placeholder"
          options={addFilterOptions}
          onChange={handleAddFilter}
        />
      )}
    </div>
  );
}
