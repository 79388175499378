import { useHistory, useLocation } from "react-router-dom";
import TrackingPopup, {
  ITrackingPopupProps,
} from "@syntensor/common/components/tracking_popup";
import {
  encodeQueryParamsToSearchString,
  parseUrlSearchQuery,
} from "@syntensor/common/utils/url_utils";

export interface IAnalyticsPopup {
  popup: Omit<ITrackingPopupProps, "onClose">;
}

/**
 * Simple analytics for now
 */
export default function Analytics({ popup }: IAnalyticsPopup) {
  //  need both
  const history = useHistory();
  const location = useLocation();

  const onManageTrackingClose = () => {
    //  do not create a new entry in the browser history
    const query = parseUrlSearchQuery(history.location.search);
    delete query.privacy;
    history.replace({ search: encodeQueryParamsToSearchString(query) });
  };

  const query = parseUrlSearchQuery(location.search);

  const hasPopupDisplayed = query && query.privacy === "true";

  return (
    <>
      {hasPopupDisplayed && (
        <TrackingPopup {...popup} onClose={onManageTrackingClose} />
      )}
    </>
  );
}
