import { useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";

import { initBrowserHistory } from "@syntensor/common/browser_history";
import { injectDarkModeScripts } from "@syntensor/common/components/dark_mode_toggle/fns";
import useExternalScripts from "@syntensor/common/hooks/use_external_scripts";
import DarkModeProvider from "@syntensor/common/components/dark_mode_toggle/dark_mode_context";
import initSentryMonitoring from "@syntensor/common/components/monitoring/sentry";

import App from "./app";
import Analytics from "./analytics";
import { ANALYTICS_COPY } from "./analytics/copy";

// import { updateStateFromUrl } from "./store/update_from_url";

export const MONITORING_SENTRY_DSN =
  process.env.REACT_APP_MONITORING_SENTRY_DSN;

const history = initBrowserHistory();

//  for now we don't want browser history
// history.listen(updateStateFromUrl);

function Root() {
  useExternalScripts({ scriptString: injectDarkModeScripts });

  useEffect(() => {
    if (MONITORING_SENTRY_DSN) {
      initSentryMonitoring({ dsn: MONITORING_SENTRY_DSN });
    }
  }, []);

  // useEffect(() => {
  //   initAnalytics();
  // }, []);

  return (
    <DarkModeProvider>
      <div className="App">
        <Router history={history}>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
          <Analytics popup={ANALYTICS_COPY} />
        </Router>
      </div>
    </DarkModeProvider>
  );
}

export default Root;
