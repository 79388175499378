import { useCallback, useContext } from "react";
import { AuthContext } from "../auth/auth_context";
import { navigateTo } from "@syntensor/common/browser_history";

import SubmitBtn from "@syntensor/common/components/submit_btn";
import useFormInput from "@syntensor/common/hooks/use_form_input";
import useFormSubmit from "@syntensor/common/hooks/use_form_submit";
import getCopy from "@syntensor/common/data/copy";

import Input from "./profile_input";
import StatusMsgs from "./status_msgs";

import classes from "./profile.module.css";

export default function ForgotPassword() {
  const { sendCode, setUserEmail } = useContext(AuthContext);

  const { inputs, handleInputChange } = useFormInput();

  const onSubmit = useCallback(async () => {
    if (!inputs.email) {
      throw new Error("Missing email");
    }

    if (sendCode && setUserEmail) {
      setUserEmail(inputs.email);
      await sendCode(inputs.email);
      await navigateTo("/profile/reset-password");
    }
  }, [inputs, setUserEmail, sendCode]);

  const { isLoading, errorMsg, handleSubmit } = useFormSubmit({
    onSubmit,
  });

  return (
    <div className={classes.forgotPassword}>
      <div className={classes.content}>
        <h2
          className={classes.title}
          dangerouslySetInnerHTML={{
            __html: getCopy("profile_forgot-password_title"),
          }}
        />
        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.row}>
            <Input
              required
              type="email"
              name="email"
              placeholder={getCopy("profile_forgot-password_email")}
              autoComplete="email"
              value={inputs.email}
              onChange={handleInputChange}
            />
          </div>
          <StatusMsgs errorMsg={errorMsg} />
          <div className={classes.buttonRow}>
            <SubmitBtn role="secondary" isLoading={isLoading} type="submit">
              {getCopy("profile_forgot-password_cta")}
            </SubmitBtn>
          </div>
        </form>
      </div>
    </div>
  );
}
