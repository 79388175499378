import { navigateTo } from "@syntensor/common/browser_history";
import TreeView from "@syntensor/common/components/tree_view";
import { IPathway } from "@syntensor/common/types";

import { useStudyUrl } from "../../../hooks/use_routes";
import { getPathwayDetailExplorerUrl } from "../../routes";

export interface IPathwayHierarchyTreeProps {
  hierarchy: IPathway[];
  activePathwayId?: string;
}

export default function PathwayHierarchyTree({
  hierarchy,
  activePathwayId = "",
}: IPathwayHierarchyTreeProps) {
  const studyUrl = useStudyUrl();

  const handlePathwayClick = (node: unknown) => {
    const { syntensorId } = node as IPathway;

    if (studyUrl && syntensorId) {
      const url = getPathwayDetailExplorerUrl(studyUrl, syntensorId);
      navigateTo(url);
    }
  };

  //  use syntensorId as the id
  const formattedActiveItem = activePathwayId.replace("PW_", "");

  return (
    <TreeView
      data={hierarchy}
      activeItemId={formattedActiveItem}
      onNodeClick={handlePathwayClick}
    />
  );
}
