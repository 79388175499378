import getCopy from "@syntensor/common/data/copy";
import { GENE_COLORS_SCALE, GENE_MAX } from "../columns";

import styles from "./legends.module.css";

export const GRADIENT_BACKGROUND = `linear-gradient(
  90deg,
  ${GENE_COLORS_SCALE(-1 * GENE_MAX)},
  ${GENE_COLORS_SCALE(0)},
  ${GENE_COLORS_SCALE(GENE_MAX)}
)`;

export function GeneTranscriptLegendScale() {
  const background = GRADIENT_BACKGROUND;

  return (
    <div className={styles.scale}>
      <div className={styles.scaleGraphics}>
        <div className={styles.scaleGraphic}>
          <div className={styles.scaleGraphicBar} style={{ background }} />
          <div className={styles.scaleGraphicLabels}>
            <span className={styles.scaleGraphicLabel}>
              &lt;=-{getCopy("studies_genes-legend_unit")}
            </span>
            <span className={styles.scaleGraphicLabel}>
              {getCopy("studies_genes-legend_unit-zero")}
            </span>
            <span className={styles.scaleGraphicLabel}>
              &gt;={getCopy("studies_genes-legend_unit")}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.scaleLabels}>
        <span>{getCopy("studies_genes-legend_downregulated")}</span>
        <span>{getCopy("studies_genes-legend_zero")}</span>
        <span>{getCopy("studies_genes-legend_upregulated")}</span>
      </div>
    </div>
  );
}

export default function GeneTranscriptLegend() {
  return (
    <div className={styles.geneTranscriptLegend}>
      <div className={styles.subtitle}>
        {getCopy("studies_genes-legend_title")}
      </div>
      <div className={styles.scaleWrapper}>
        <GeneTranscriptLegendScale />
      </div>
      <div className={styles.title}>
        {getCopy("studies_genes-legend_legend")}
      </div>
    </div>
  );
}
