import { ChangeEvent } from "react";

import styles from "./data_grid_columns.module.css";

export interface IDataGridColumnsItemProps {
  id: string;
  name: string;
  isEnabled: boolean;
  onToggle: (id: string, isChecked: boolean) => void;
}

export default function DataGridColumnsItem({
  id,
  name,
  isEnabled,
  onToggle,
}: IDataGridColumnsItemProps) {
  const handleToggle = (evt: ChangeEvent<HTMLInputElement>) => {
    onToggle(id, evt.target.checked);
  };

  return (
    <div className={styles.listItem} key={id}>
      <label className={styles.column}>
        <input type="checkbox" checked={isEnabled} onChange={handleToggle} />
        <span className={styles.columnSpan}>{name}</span>
      </label>
    </div>
  );
}
