import { ChangeEvent, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import cn from "classnames";

import Input from "@syntensor/common/components/input";
import { ICompoundDefinition } from "@syntensor/common/types";
import { EIconSize, TrashIcon } from "@syntensor/common/components/icons";
import getCopy from "@syntensor/common/data/copy";

import { ICompoundInput } from "../use_create_study_form";

import styles from "./compounds.module.css";

export interface INewEndpointFormProps {
  index: number;
  compound: ICompoundInput;
  onChange: (index: number, compound: ICompoundDefinition) => void;
  onDelete: (index: number) => void;
  onSmilesEdit: (index: number, smiles: string) => void;
  isDisabled?: boolean;
}

export default function NewCompoundForm(props: INewEndpointFormProps) {
  const [hasDirtySmiles, setHasDirtySmiles] = useState(false);

  const { index, compound, onChange, onSmilesEdit, onDelete, isDisabled } =
    props;
  const { name, smiles, isBeingValidated = false, isValid = null } = compound;

  const handleInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.currentTarget;

    const compoundValue = {
      ...compound,
      [name]: value,
    };

    if (name === "smiles") {
      setHasDirtySmiles(true);
    }

    onChange(index, compoundValue);
  };

  const handleRemoveClick = () => {
    onDelete(index);
  };

  const handleBlur = () => {
    if (hasDirtySmiles) {
      onSmilesEdit(index, smiles);
    }
  };

  const isInvalid = isValid === false;
  const smilesClasses = cn(styles.smilesInput, {
    [styles.invalidInput]: isInvalid,
    [styles.validInput]: isValid,
  });
  const isSmilesInputDisabled = isDisabled || isBeingValidated;

  return (
    <div className={styles.compoundForm}>
      <button
        className={styles.compoundFormRemoveBtn}
        onClick={handleRemoveClick}
        type="button"
      >
        <TrashIcon size={EIconSize.SMALL} />
      </button>
      <div className={styles.compoundFormSection}>
        <h3 className={styles.compoundFormTitle}>
          {getCopy("studies_create_compound-name")}
        </h3>
        <Input
          size="small"
          placeholder={getCopy("studies_create_compound-name")}
          name="name"
          value={name}
          onChange={handleInputChange}
          disabled={isDisabled}
        />
      </div>
      <div className={styles.compoundFormSection}>
        <h3 className={styles.compoundFormTitle}>
          {getCopy("studies_create_compound-smiles")}
        </h3>
        <Input
          className={smilesClasses}
          size="small"
          placeholder={getCopy("studies_create_compound-smiles")}
          name="smiles"
          value={smiles}
          onChange={handleInputChange}
          disabled={isSmilesInputDisabled}
          required
          onBlur={handleBlur}
        />
        {isInvalid && (
          <p className={styles.errorMsg}>
            {getCopy("studies_create_compound-smiles-error")}
          </p>
        )}
        {isBeingValidated && (
          <div className={styles.preloader}>
            <ClipLoader size="18px" />
          </div>
        )}
      </div>
    </div>
  );
}
