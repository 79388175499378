import { format } from "date-fns";
import ReactMarkdown from "react-markdown";

import Button from "@syntensor/common/components/button";
import getCopy from "@syntensor/common/data/copy";
import Loader from "@syntensor/common/components/loader";
import { EStudyStatus } from "@syntensor/common/types";
import {
  BookmarksIcon,
  EIconSize,
  TrashIcon,
} from "@syntensor/common/components/icons";

import { IsUserInAdminGroup } from "../../auth/auth_context";

import styles from "./study_box.module.css";

export interface IStudyBoxProps {
  studyId: string;
  projectId: string;
  status: EStudyStatus;
  title: string;
  type?: string;
  description?: string;
  dosages?: string[];
  cellLines: string[];
  compounds: string[];
  tags: string[];
  createdAt?: string;
  createdBy?: string;
  isDeleteInProgress?: boolean;
  onDelete?: (_: string) => void;
  onClone?: (studyId: string) => void;
}

export default function StudyBox({
  projectId,
  studyId,
  status,
  title,
  description,
  type = "",
  tags,
  createdAt,
  createdBy,
  cellLines = [],
  compounds = [],
  isDeleteInProgress = false,
  // onRetry = () => null,
  onClone = () => null,
  onDelete = () => null,
}: IStudyBoxProps) {
  const isUserInAdminGroup = IsUserInAdminGroup();

  const onDeleteClick = async () => {
    if (!isDeleteInProgress) {
      const confirmed = window.confirm(
        getCopy("projects_project_delete-confirm", { title })
      );
      if (confirmed) {
        if (onDelete) {
          onDelete(studyId);
        }
      }
    }
  };

  const onCloneClick = () => {
    if (onClone) {
      onClone(studyId);
    }
  };

  const formattedDate = createdAt
    ? format(new Date(createdAt), "yyyy-MM-dd hh:mm:dd")
    : "";
  const studyMetadata = [createdBy, formattedDate].filter((d) => d);
  const studyCanBeOpened = status === EStudyStatus.COMPLETED;

  return (
    <div className={styles.studyBox} data-testid="projects_project_study-box">
      {isUserInAdminGroup && (
        <div className={styles.topBtns}>
          <button
            className={styles.topBtn}
            onClick={onCloneClick}
            title={getCopy("projects_project_clone")}
          >
            <BookmarksIcon size={EIconSize.SMALL} />
          </button>
          <button
            className={styles.topBtn}
            onClick={onDeleteClick}
            disabled={isDeleteInProgress}
            title={getCopy("projects_project_delete")}
          >
            {isDeleteInProgress && <Loader size="18px" />}
            {!isDeleteInProgress && <TrashIcon size={EIconSize.SMALL} />}
          </button>
        </div>
      )}
      <div className={styles.header}>
        <h3 className={styles.title}>{title}</h3>
        {/* <p className={styles.dosage}>{dosages.join(", ")}</p> */}
        <ul className={styles.tags}>
          {tags &&
            tags.map((tag, i) => {
              return (
                <li key={i} className={styles.tag}>
                  {tag}
                </li>
              );
            })}
        </ul>
      </div>
      <div className={styles.meta}>
        <span>{getCopy(`studies_type_${type.toLowerCase()}`)}</span>
        <span>
          <span>{studyMetadata.join(" | ")}</span>
          {status && (
            <span> | {getCopy(`studies_status_${status.toLowerCase()}`)}</span>
          )}
        </span>
      </div>
      <div className={styles.description}>
        {description && <ReactMarkdown>{description}</ReactMarkdown>}
      </div>
      <div className={styles.footer}>
        <div>
          <p>
            {getCopy("projects_study_footer", {
              numCellLines: cellLines.length,
              numCompounds: compounds.length,
            })}
            {/* | Show all */}
          </p>
        </div>
        <div className={styles.footerBtns}>
          {isUserInAdminGroup && (
            <Button
              to={`/projects/${projectId}/studies/${studyId}/edit`}
              role="subtle"
              data-testid="projects_project_study-configure"
            >
              {getCopy("projects_project_configure")}
            </Button>
          )}
          <Button
            to={`/projects/${projectId}/studies/${studyId}`}
            role="primary"
            data-testid="projects_project_study-open"
            isDisabled={!studyCanBeOpened}
          >
            {getCopy("projects_project_open")}
          </Button>
        </div>
      </div>
    </div>
  );
}
