import { ChangeEvent, useState } from "react";

import Select from "@syntensor/common/components/select";
import Table from "@syntensor/common/components/table";
import ViewToggle, {
  VIEWS_MAP,
} from "@syntensor/common/components/view_toggle";
import MetricsBarChart from "@syntensor/common/components/metrics_bar_chart";
import getCopy from "@syntensor/common/data/copy";

import styles from "../metrics.module.css";

export interface IComponentTypeStatisticsProps {
  title: string;
  selectLabels: string[];
  tableLabels: string[];
  data: Record<string, unknown>[];
}

export default function ComponentTypeStatistics({
  title,
  selectLabels,
  tableLabels,
  data,
}: IComponentTypeStatisticsProps) {
  const metricsSelectValues = [
    {
      id: "nodes",
      name: selectLabels[0] || "Number of nodes",
      config: {
        hasRightValue: true,
        hasLeftValue: false,
        headers: ["Node type", "Number of nodes"],
        valueProps: ["Number of nodes"],
      },
    },
    {
      id: "total",
      name: selectLabels[1] || "Total node degree",
      config: {
        hasRightValue: true,
        hasLeftValue: false,
        headers: ["Node type", "Total degree node"],
        valueProps: ["Total node degree"],
      },
    },
    {
      id: "disconnected_nodes",
      name: selectLabels[2] || "Disconnected nodes",
      config: {
        hasRightValue: false,
        hasLeftValue: true,
        headers: ["Node type", "Disconnected nodes"],
        valueProps: ["Number of nodes", "Number of disconnected nodes"],
      },
    },
    {
      id: "mean_max",
      name: selectLabels[3] || "Max and mean node degree",
      config: {
        hasRightValue: true,
        hasLeftValue: true,
        headers: ["Node type", "Mean", "Distribution and max node degree"],
        valueProps: ["Quantiles node degree", "Maximum node degree"],
        hasBoxPlots: true,
        scaleType: "log",
      },
    },
  ];

  const [displayedMetric, setDisplayedMetric] = useState(
    metricsSelectValues[0]
  );
  const [activeView, setActiveView] = useState(VIEWS_MAP.barchart.id);

  if (!data) {
    return null;
  }

  const onClick = (viewId: string) => {
    setActiveView(viewId);
  };

  const onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    const selectedId = evt.target.value;
    const selectedMetric = metricsSelectValues.find(
      (metric) => metric.id === selectedId
    );
    if (selectedMetric) {
      setDisplayedMetric(selectedMetric);
    }
  };

  //  resolve labels
  const formattedData = data.map((d) => {
    const name = getCopy(`metrics_component-type_${d["Component Type"]}`);
    return { ...d, "Component Type": name };
  });

  const isSelectDisabled = activeView === VIEWS_MAP.table.id;

  return (
    <div
      className={styles.section}
      data-testid="metrics_graph_component-type-statistics"
    >
      <div className={styles.sectionHeader}>
        <h3 className={styles.sectionTitle}>{title}</h3>
        <div>
          <div className={styles.sectionControls}>
            <Select
              name="metric"
              isDisabled={isSelectDisabled}
              value={displayedMetric.id}
              options={metricsSelectValues}
              onChange={onChange}
              size="small"
            />
            <ViewToggle
              onClick={onClick}
              activeView={activeView}
              btns={[
                {
                  // exact: true,
                  // to: url,
                  id: "barchart",
                  icon: VIEWS_MAP.barchart.id,
                },
                { id: "table", icon: VIEWS_MAP.table.id },
              ]}
            />
          </div>
        </div>
      </div>
      {data && activeView === VIEWS_MAP.barchart.id && (
        <MetricsBarChart
          data={formattedData}
          nameProp="Component Type"
          {...displayedMetric.config}
        />
      )}
      {data && activeView === VIEWS_MAP.table.id && (
        <Table
          config={{
            cols: [
              {
                id: "Component Type",
                name: tableLabels[0] || "Component Type",
              },
              { id: "Number of nodes", name: tableLabels[1] || "Nodes" },
              {
                id: "Total node degree",
                name: tableLabels[2] || "Total node degree",
              },
              {
                id: "Number of disconnected nodes",
                name: tableLabels[3] || "Disconnected Nodes",
              },
              {
                id: "Maximum node degree",
                name: tableLabels[4] || "Max node degree",
              },
              {
                id: "Mean node degree",
                name: tableLabels[5] || "Mean node degree",
              },
            ],
          }}
          data={data}
        />
      )}
    </div>
  );
}
