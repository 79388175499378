export const ANALYTICS_COPY = {
  title: "<strong>Manage</strong> consent",
  cookies: [
    {
      title: "Functional cookies",
      note: "Not in use",
      description:
        "Functional cookies remember preferences in order to provide the best possible user experience. For example, we will recognize your username and apply any content preferences you provided.",
    },
    {
      title: "Analytical cookies",
      note: "",
      description:
        "Analytics cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously. For example, we use Matomo Analytics to view anonymous, aggregated data about visitors to our website.",
    },
    {
      title: "Cookie-less tracking",
      note: "",
      description:
        "Matomo enables website owners to track anonymous data without using cookies to improve user experience. For example, we can understand which pages are most popular and what percentage of users completes a form.",
    },
    {
      title: "Necessary cookies",
      note: "Always on",
      description:
        "Necessary cookies are permitted by law to make website features usable. For example, these cookies let us recognize that you have created an account and have logged in.",
    },
  ],
  cookiePolicy:
    "<a href='https://syntensor.com/privacy/cookies/' target='_blank'>Cookie Policy</a>",
  confirmLabel: "Confirm my choices",
  acceptAll: "Accept all",
};
