import { ChangeEvent, useCallback, useState } from "react";

export type TInputChange =
  | ChangeEvent<HTMLInputElement | HTMLSelectElement>
  | Record<string, any>[];

export default function useFormInput({ initialState = {} } = {}): {
  inputs: Record<string, any>;
  handleInputChange: (evt: TInputChange) => void;
  setAllInputs: (inputs: Record<string, any>) => void;
} {
  const [inputs, setInputs] = useState(() => {
    return initialState;
  });

  const handleInputChange = useCallback(
    (evt: TInputChange) => {
      if (Array.isArray(evt)) {
        //  allow setting up multiple inputs at the same
        const newInputs = evt.reduce(
          (acc, input) => {
            acc[input.name as string] = input.value;
            return acc;
          },
          { ...inputs }
        );
        setInputs(newInputs);
      } else {
        const { target } = evt;
        const name = target.name;

        //  we might have a checkbox
        let value: string | number | boolean = target.value;
        if (Object.prototype.hasOwnProperty.call(target, "checked")) {
          value = (target as EventTarget & HTMLInputElement).checked;
        }

        const newInputs = { ...inputs, [name]: value };
        setInputs(newInputs);
      }
    },
    [inputs]
  );

  return { inputs, handleInputChange, setAllInputs: setInputs };
}
