import Button from "@syntensor/common/components/button";
import ReportsContent from "../../../_content/metrics/reports.mdx";
import { mdxComponents } from "../markdown_components";

import styles from "./reports.module.css";

export interface IIndicationReport {
  name: string;
  download: string;
  image: string;
  date: string;
}

export function IndicationReport({
  name,
  download,
  image,
  date,
}: IIndicationReport) {
  return (
    <div className={styles.indicationReport}>
      <div className={styles.indicationReportHeader}>
        <h4 className={styles.indicationReportTitle}>{name}</h4>
        <span>
          <Button to={download} role="secondary" size="small" download>
            Download PDF
          </Button>
        </span>
      </div>
      <div className={styles.indicationReportImage}>
        <img src={image} className={styles.image} alt={name} />
      </div>
      <p className={styles.indicationReportDate}>{date}</p>
    </div>
  );
}

export default function Reports() {
  const components = mdxComponents;
  return (
    <div className={styles.content}>
      <ReportsContent components={components} />
    </div>
  );
}
