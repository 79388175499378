import { useState } from "react";
import { EdgeDefinition, NodeDefinition } from "cytoscape";

import getCopy from "@syntensor/common/data/copy";
import {
  DownIcon,
  EIconSize,
  UpIcon,
} from "@syntensor/common/components/icons";

import { getContentForNodeClass, getArrowShapeForClass } from "../stylesheets";
import EdgesLegend from "./edges_legend";
import LettersLegend from "./letters_legend";
import ColorsLegend from "./colors_legend";

import styles from "./legend.module.css";

export function resolveName(name: string) {
  const resolvedName = getCopy(`studies_pathway-explorer-type_${name}`);
  return resolvedName || name;
}

export type TPathwayDetailExplorerLegendLookup = Record<string, string[]>;

export interface IPathwayDetailExplorerLegendProps {
  nodes: NodeDefinition[];
  edges: EdgeDefinition[];
  letters?: TPathwayDetailExplorerLegendLookup;
  arrows?: TPathwayDetailExplorerLegendLookup;
}

export default function PathwayDetailExplorerLegend({
  nodes = [],
  edges = [],
}: IPathwayDetailExplorerLegendProps) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleCollapseClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  const letters: TPathwayDetailExplorerLegendLookup = {};
  const arrows: TPathwayDetailExplorerLegendLookup = {};

  nodes.forEach((node: NodeDefinition) => {
    //  collect node data for legend
    const className = node.data.class;
    const letterSymbol = getContentForNodeClass(className);
    if (!letters[letterSymbol]) {
      letters[letterSymbol] = [];
    }
    if (!letters[letterSymbol].includes(className)) {
      letters[letterSymbol].push(className);
    }
  });

  edges.forEach((edge: EdgeDefinition) => {
    const className = edge.data.class;
    const arrowShape = getArrowShapeForClass(className);
    if (!arrows[arrowShape]) {
      arrows[arrowShape] = [];
    }
    if (!arrows[arrowShape].includes(className)) {
      arrows[arrowShape].push(className);
    }
  });

  return (
    <div className={styles.legend}>
      <div className={styles.header}>
        <button className={styles.collapseBtn} onClick={handleCollapseClick}>
          {getCopy("studies_pathway-explorer-legend_legend")}
        </button>
        <div className={styles.collapseIcon}>
          {isCollapsed && <DownIcon size={EIconSize.TINY} />}
          {!isCollapsed && <UpIcon size={EIconSize.TINY} />}
        </div>
      </div>

      {!isCollapsed && (
        <div className={styles.content}>
          {letters && <LettersLegend {...letters} />}
          {arrows && <EdgesLegend {...arrows} />}
          <ColorsLegend />
        </div>
      )}
    </div>
  );
}
