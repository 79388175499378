import { scaleLinear } from "d3-scale";
import cn from "classnames";
import { NavLink } from "react-router-dom";

import getCopy from "@syntensor/common/data/copy";
import { ESortDir, IStudyCompound } from "@syntensor/common/types";
import NameCell from "@syntensor/common/components/data_grid/name_cell";
import { getPropValue } from "@syntensor/common/utils/obj_utils";
import {
  EDataGridColumnType,
  IDataGridColumn,
} from "@syntensor/common/components/data_grid_columns";

import { getSortableHeaderRenderer } from "../components/columns";
import { PATHWAY_CATEGORIES } from "./categories";
import {
  getPathwayDetailExplorerUrl,
  getPathwayDetailUrl,
  getStudyUrl,
} from "../routes";

import styles from "../studies.module.css";

export const PATHWAYS_MAX = 1000;
export const PATHWAYS_COLORS_SCALE = scaleLinear<string, string, string>()
  .domain([1, PATHWAYS_MAX])
  .range(["#1343EE", "#FFFFFF"])
  .unknown("#ccc")
  .clamp(true);

export function getPathwayCellBackgroundColor(value: number) {
  //  d3 scalelinear does not use unknown value for empty string inputs
  return Number.isFinite(value)
    ? PATHWAYS_COLORS_SCALE(value)
    : PATHWAYS_COLORS_SCALE(NaN);
}

export function isPathwayDarkColor(value: number | string) {
  if (!Number.isFinite(value)) {
    return false;
  }
  const diff = PATHWAYS_MAX - Math.abs(value as number);
  return diff > PATHWAYS_MAX / 2;
}

export function getReactomeLinkColumn(): IDataGridColumn {
  return {
    key: "reactomeLink",
    name: getCopy("studies_pathways_col-reactome"),
    width: 150,
    formatter({ row }) {
      const { reactomeLink } = row;
      return (
        <a
          href={reactomeLink}
          target="_blank"
          rel="noreferrer"
          className={styles.linkCell}
        >
          {getCopy("studies_pathways_reactome-link")}
        </a>
      );
    },
    resizable: true,
    isFilterable: false,
  };
}

export function getCompoundPathwayRankName(compound: IStudyCompound) {
  let compoundName = `${compound.name} ${compound.dose}`;
  if (compound.control) {
    const compoundControl =
      compound.control === "+"
        ? getCopy("studies_components_col-positive-control-compound")
        : getCopy("studies_components_col-negative-control-compound");
    compoundName += compoundControl;
  }

  return getCopy("studies_pathways_col-dt-rank", {
    compoundName,
  });
}

export function getPathwaysColumns({
  cellLines,
  compounds,
  projectId,
  studyId,
  sortBy,
  sortDir,
  onSortClick,
  firstColWidth = 450,
}: {
  cellLines: string[];
  compounds: IStudyCompound[];
  projectId: string;
  studyId: string;
  sortBy: string;
  sortDir: ESortDir;
  onSortClick: (sortBy: string, sortDir: ESortDir) => void;
  firstColWidth?: number;
}) {
  const columns: IDataGridColumn[] = [
    {
      key: "displayName",
      width: firstColWidth,
      frozen: true,
      name: getCopy("studies_pathways_col-display-name"),
      headerRenderer: getSortableHeaderRenderer({
        sortDir,
        sortBy,
        onSortClick,
      }),
      formatter({ row = {} }) {
        const studyUrl = getStudyUrl(projectId, studyId);
        const url = getPathwayDetailUrl(studyUrl, row.syntensorId);

        // //  display protein MoA rank
        // const moaTooltip = getMoaTooltip(row.componentType);

        return <NameCell {...row} url={url} />;
      },
      resizable: true,
      isFilterable: false,
    },
    {
      key: "category",
      name: getCopy("studies_pathways_col-category"),
      headerRenderer: getSortableHeaderRenderer({
        sortDir,
        sortBy,
        onSortClick,
      }),
      options: PATHWAY_CATEGORIES,
      resizable: true,
      type: EDataGridColumnType.MULTISELECT,
    },
  ];

  cellLines.forEach((cellLine) => {
    compounds.forEach((compound) => {
      columns.push({
        key: `simulations.dtRank.${cellLine}:${compound.compoundId}`,
        name: getCompoundPathwayRankName(compound),
        headerRenderer: getSortableHeaderRenderer({
          sortDir,
          sortBy,
          onSortClick,
        }),
        formatter({ row, column }) {
          const value = getPropValue(row, column.key);
          const isDarkColor = isPathwayDarkColor(value);
          const backgroundColor = getPathwayCellBackgroundColor(value);
          const className = cn(styles.valueCell, {
            [styles.valueCellDark]: isDarkColor,
          });
          const formattedValue = value !== "" ? value : "-";

          return (
            <div className={className} style={{ backgroundColor }}>
              <span>{formattedValue}</span>
            </div>
          );
        },
        resizable: true,
        minWidth: 120,
      });
      columns.push({
        key: `simulations.totalDownregulatedGenes.${cellLine}:${compound.id}`,
        name: getCopy("studies_pathways_col-downregulated-rank", {
          compoundName: compound.name,
        }),
        headerRenderer: getSortableHeaderRenderer({
          sortDir,
          sortBy,
          onSortClick,
        }),
        formatter({ row, column }) {
          const value = getPropValue(row, column.key);
          return <span>{value}</span>;
        },
        isEnabled: false,
        resizable: true,
        minWidth: 120,
      });
      columns.push({
        key: `simulations.totalUpregulatedGenes.${cellLine}:${compound.id}`,
        name: getCopy("studies_pathways_col-upregulated-rank", {
          compoundName: compound.name,
        }),
        headerRenderer: getSortableHeaderRenderer({
          sortDir,
          sortBy,
          onSortClick,
        }),
        formatter({ row, column }) {
          const value = getPropValue(row, column.key);
          return <span>{value}</span>;
        },
        isEnabled: false,
        resizable: true,
        minWidth: 120,
      });
    });

    columns.push({
      key: "pathwayExplorer",
      name: getCopy("studies_pathways_col-explorer-link"),
      width: 150,
      formatter({ row }) {
        const { syntensorId } = row;
        const studyUrl = getStudyUrl(projectId, studyId);
        const url = getPathwayDetailExplorerUrl(studyUrl, syntensorId);

        return (
          <NavLink to={url} className={styles.linkCell}>
            {getCopy("studies_pathways_explorer-link")}
          </NavLink>
        );
      },
      resizable: true,
      isFilterable: false,
    });

    // columns.push(getReactomeLinkColumn());
  });

  return columns;
}
