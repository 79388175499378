import { useEffect } from "react";

export default function useKeyDown(keys: string[], callback: () => void) {
  const onKeyDown = (evt: KeyboardEvent) => {
    const wasAnyKeyPressed = keys.some((key) => evt.key === key);
    if (wasAnyKeyPressed) {
      evt.preventDefault();
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);
}
