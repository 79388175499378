import cn from "classnames";

import Table from "@syntensor/common/components/table";
import { ArrowRightIcon, EIconSize } from "@syntensor/common/components/icons";
import MoaRank from "@syntensor/common/components/moa_rank";
import Tooltip from "@syntensor/common/components/tooltip";
import Select from "@syntensor/common/components/select";
import getCopy from "@syntensor/common/data/copy";
import {
  IStudyCompound,
  IPathway,
  IStudyPathwaySimulations,
} from "@syntensor/common/types";
import ViewToggle, {
  VIEWS_MAP,
} from "@syntensor/common/components/view_toggle";

import { getMoaTooltip } from "../../utils/tooltip_utils";
import {
  getPathwayCellBackgroundColor,
  getCompoundPathwayRankName,
  isPathwayDarkColor,
} from "../pathways/columns";
import PathwayHierarchyBreadcrumbs from "./hierarchy/breadcrumbs";

import styles from "../component_detail/component_detail.module.css";
import studiesStyles from "../studies.module.css";

export interface IPathwayDetailHeader {
  displayName: string;
  category: string;
  simulations: IStudyPathwaySimulations;
  parents: IPathway[];
  activeView?: string;
  url?: string;
  componentType?: string;
  reactomeLink?: string;
  compounds?: IStudyCompound[];
  cellLines?: string[];
  moaRank?: number | null;
  isOverview?: boolean;
}

export default function PathwayDetailHeader({
  componentType = "",
  displayName,
  simulations,
  activeView = "",
  url = "",
  reactomeLink = "",
  parents = [],
  compounds = [],
  cellLines = [],
  moaRank = null,
  isOverview = false,
}: IPathwayDetailHeader) {
  const cellLine = cellLines && cellLines.length ? cellLines[0] : "";

  const tableCols = compounds.map((compound: IStudyCompound) => {
    return {
      id: `${cellLine}:${compound.compoundId}`,
      name: getCompoundPathwayRankName(compound),
      cellRenderer: (value: number) => {
        const isDarkColor = isPathwayDarkColor(value);
        const backgroundColor = getPathwayCellBackgroundColor(value);
        const className = cn(studiesStyles.tableValueCell, {
          [studiesStyles.valueCellDark]: isDarkColor,
        });
        const formattedValue = Number.isFinite(value) ? value : "-";

        return (
          <div className={className} style={{ backgroundColor }}>
            <span>{formattedValue}</span>
          </div>
        );
      },
    };
  });
  const tableData = simulations ? [simulations.dtRank] : [];
  const hasMoaRank = Number.isFinite(moaRank);
  const moaTooltip = hasMoaRank ? getMoaTooltip(componentType) : "";

  return (
    <>
      <div className={studiesStyles.controls}>
        <div className={studiesStyles.controlRow}>
          <div className={studiesStyles.mainControl}>
            <div className={styles.headerTitles}>
              <div className={styles.headerTitleWrapper}>
                {hasMoaRank && moaRank && (
                  <div className={styles.tooltip}>
                    <Tooltip tooltip={moaTooltip}>
                      <MoaRank rank={moaRank} size="large" />
                    </Tooltip>
                  </div>
                )}
                <h3
                  className={styles.headerTitle}
                  data-testid="app_pathway-detail_title"
                >
                  {displayName}
                </h3>
              </div>
              <div className={styles.description}>
                <span>{getCopy("studies_pathway-detail_component-type")}</span>
                <PathwayHierarchyBreadcrumbs parents={parents} />
              </div>
            </div>
          </div>
          <div className={studiesStyles.rightSideControls}>
            <div>
              <ViewToggle
                activeView={activeView}
                btns={[
                  {
                    id: "table",
                    to: url,
                    exact: true,
                    icon: VIEWS_MAP.table.id,
                  },
                  {
                    id: "network",
                    to: `${url}/explorer`,
                    icon: VIEWS_MAP.network.id,
                  },
                ]}
              />
            </div>
            {!isOverview && (
              <div>
                <Select
                  label={getCopy("studies_components_cellline-dropdown")}
                  size="small"
                  options={cellLines.map((cellLine: string) => {
                    return { id: cellLine, name: cellLine };
                  })}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={studiesStyles.controlRow}>
        <div className={studiesStyles.mainControl}>
          <h4 className={styles.tableTitle}>
            {getCopy("studies_pathway-detail_table-title")}
          </h4>
          <Table
            config={{
              cols: tableCols,
            }}
            data={tableData}
          />
        </div>
        <div>
          <ul className={styles.headerList}>
            <li>
              <a
                href={`https://pubmed.ncbi.nlm.nih.gov/?term=${displayName}`}
                className={styles.headerLink}
                target="_blank"
                rel="noreferrer"
              >
                {getCopy("studies_pathway-detail_pubmed")}
                <ArrowRightIcon size={EIconSize.SMALL} />
              </a>
            </li>
            {reactomeLink && (
              <li>
                <a
                  href={reactomeLink}
                  className={styles.headerLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {getCopy("studies_pathway-detail_reactome")}
                  <ArrowRightIcon size={EIconSize.SMALL} />
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}
