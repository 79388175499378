import { useCallback, useContext } from "react";

import { AuthContext } from "../auth/auth_context";
import { navigateTo } from "@syntensor/common/browser_history";
import Input from "@syntensor/common/components/input";
import SubmitBtn from "@syntensor/common/components/submit_btn";
import getCopy from "@syntensor/common/data/copy";
import useFormInput from "@syntensor/common/hooks/use_form_input";
import useFormSubmit from "@syntensor/common/hooks/use_form_submit";

import StatusMsgs from "./status_msgs";

import classes from "./profile.module.css";

export default function ResetPassword() {
  const { userEmail, forgotPassword } = useContext(AuthContext);

  const { inputs, handleInputChange } = useFormInput();

  const onSubmit = useCallback(async () => {
    if (forgotPassword && userEmail) {
      await forgotPassword(userEmail, inputs.code, inputs.password);
      await navigateTo("/profile/");
    }
  }, [userEmail, inputs, forgotPassword]);

  const { isLoading, errorMsg, handleSubmit } = useFormSubmit({
    onSubmit,
  });

  //  add more recovery information if
  const hasInvalidCodeMsg =
    errorMsg && errorMsg.indexOf("verification code") > -1;
  const validatedErrorMsg = hasInvalidCodeMsg
    ? `${errorMsg} ${getCopy("profile_reset-password_new-code")}`
    : errorMsg;

  return (
    <div className={classes.forgotPassword}>
      <div className={classes.content}>
        <h2
          className={classes.title}
          dangerouslySetInnerHTML={{
            __html: getCopy("profile_reset-password_title"),
          }}
        />
        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.row}>
            <Input
              required
              name="code"
              autoComplete="one-time-code"
              placeholder={getCopy("profile_reset-password_code")}
              value={inputs.code}
              onChange={handleInputChange}
            />
          </div>
          <div className={classes.row}>
            <Input
              required
              name="password"
              autoComplete="new-password"
              placeholder={getCopy("profile_reset-password_password")}
              type="password"
              value={inputs.password}
              onChange={handleInputChange}
            />
          </div>
          <StatusMsgs errorMsg={validatedErrorMsg} />
          <div className={classes.buttonRow}>
            <SubmitBtn role="secondary" isLoading={isLoading} type="submit">
              {getCopy("profile_reset-password_cta")}
            </SubmitBtn>
          </div>
        </form>
      </div>
    </div>
  );
}
