import { configureStore } from "@reduxjs/toolkit";

import { loadFromStorage, saveToStorage } from "../local_storage";

import columns from "./columns";
import filters from "./filters";
import comments from "./comments";
import ui from "./ui";

export const FILTERS_STORAGE_KEY = "syntensor-product-app-filters";
export const COLUMNS_STORAGE_KEY = "syntensor-product-app-columns";

export const preloadedState = {
  filters: loadFromStorage(FILTERS_STORAGE_KEY),
  columns: loadFromStorage(COLUMNS_STORAGE_KEY),
};

const store = configureStore({
  reducer: {
    columns,
    filters,
    comments,
    ui,
  },
  preloadedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
});

let currFilters = {};
let currColumns = {};

store.subscribe(() => {
  //  check if workspace settings have changed
  const state = store.getState();

  if (state) {
    //  persist filters and columns changes into the local storage
    //  so that they can be loaded the next time the app is opened
    //  for the given user
    const filtersHasChanged =
      JSON.stringify(currFilters) !== JSON.stringify(state.filters);
    if (filtersHasChanged) {
      saveToStorage(FILTERS_STORAGE_KEY, state.filters);
      currFilters = state.filters;
    }

    const columnsHasChanged =
      JSON.stringify(currColumns) !== JSON.stringify(state.columns);
    if (columnsHasChanged) {
      saveToStorage(COLUMNS_STORAGE_KEY, state.columns);
      currColumns = state.columns;
    }
  }
});

export default store;
