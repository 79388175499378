import { AnyAction, createAction, createSelector } from "@reduxjs/toolkit";
import { IStoreState } from "./types";

export enum EColumnTypes {
  COMPONENTS = "components",
  PATHWAYS = "pathways",
}

export type TColumnTypeState = Record<string, boolean>;

export interface IColumnsState {
  [EColumnTypes.COMPONENTS]: TColumnTypeState;
  [EColumnTypes.PATHWAYS]: TColumnTypeState;
}

export interface IUpdateColumnsPayload {
  toggleColumns: string | string[];
  isEnabled: boolean | boolean[];
}

export const selectColumns = (state: IStoreState) => state.columns;

export const selectComponentColumns = createSelector(
  selectColumns,
  (columns) => {
    return columns.components;
  }
);

export const selectPathwayColumns = createSelector(selectColumns, (columns) => {
  return columns.pathways;
});

export const updateComponentColumns = createAction<IUpdateColumnsPayload>(
  "UPDATE_COMPONENT_COLUMNS"
);
export const updatePathwayColumns = createAction<IUpdateColumnsPayload>(
  "UPDATE_PATHWAY_COLUMNS"
);

export function reduceUpdateColumns(
  state: IColumnsState,
  storeSlice: EColumnTypes,
  payload: IUpdateColumnsPayload
) {
  const { toggleColumns, isEnabled } = payload;

  const sliceColumns = state[storeSlice];
  const toggleColumnsArr = Array.isArray(toggleColumns)
    ? toggleColumns
    : [toggleColumns];

  const updatedColumns = toggleColumnsArr.reduce<Record<string, boolean>>(
    (acc, col, index) => {
      const isColEnabled = Array.isArray(isEnabled)
        ? isEnabled[index]
        : isEnabled;

      acc[col] = isColEnabled;
      return acc;
    },
    {}
  );

  return {
    ...state,
    [storeSlice]: {
      ...sliceColumns,
      ...updatedColumns,
    },
  };
}

export const initialState: IColumnsState = {
  [EColumnTypes.COMPONENTS]: {},
  [EColumnTypes.PATHWAYS]: {},
};

export default function filters(state = initialState, action: AnyAction) {
  switch (action.type) {
    case updateComponentColumns.type:
      return action.payload
        ? reduceUpdateColumns(state, EColumnTypes.COMPONENTS, action.payload)
        : state;
    case updatePathwayColumns.type:
      return action.payload
        ? reduceUpdateColumns(state, EColumnTypes.PATHWAYS, action.payload)
        : state;
    default:
      return state;
  }
}
