import { useEffect, useState } from "react";

import Loader from "@syntensor/common/components/loader";
import { filterItemsBySearch } from "@syntensor/common/utils/search_utils";
import SearchForm from "@syntensor/common/components/search_form";
import { fetchProjects } from "@syntensor/common/data/fetch_data";
import getCopy from "@syntensor/common/data/copy";
import { IProject } from "@syntensor/common/types";

import ProjectBox from "./project_box";

import classes from "./projects.module.css";

export default function Projects() {
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    async function asyncFetch() {
      try {
        setIsLoading(true);
        const projects = await fetchProjects();
        setProjects(projects);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    }

    asyncFetch();
  }, []);

  const handleSearch = (search: string) => {
    setSearch(search);
  };

  const handleChange = (newSearch: string) => {
    if (search && newSearch === "") {
      //  cleared input box
      setSearch("");
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  //  we might be rendering subset of the components
  //  due to search term being input in the search form
  const filteredProjects = search
    ? (filterItemsBySearch(projects, search) as IProject[])
    : projects;

  const hasResults = filteredProjects && filteredProjects.length > 0;

  return (
    <div className={classes.projects}>
      <div className={classes.header}>
        <div>
          <h2
            className={classes.headerTitle}
            dangerouslySetInnerHTML={{
              __html: getCopy("projects_overview_title"),
            }}
            data-testid="projects_title"
          />
        </div>
      </div>
      <div className={classes.search}>
        <SearchForm
          onSearch={handleSearch}
          onChange={handleChange}
          placeholder={getCopy("projects_overview_search-placeholder")}
          debounceDelay={0}
          name="search-projects"
          minChars={1}
          size="small"
        />
      </div>
      <div className={classes.list}>
        <ul className={classes.grid}>
          {filteredProjects &&
            filteredProjects.map((project) => {
              return (
                <li key={project.id}>
                  <ProjectBox {...project} />
                </li>
              );
            })}
        </ul>
        {!hasResults && <div>{getCopy("projects_overview_empty-result")}</div>}
      </div>
    </div>
  );
}
