import { ChangeEvent, useState } from "react";

import Select from "@syntensor/common/components/select";
import getCopy from "@syntensor/common/data/copy";
import Table from "@syntensor/common/components/table";
import InfoBtn from "@syntensor/common/components/info_btn";

import styles from "../metrics.module.css";

export interface IMostConnectedNodesProps {
  title: string;
  infoText: string;
  tableLabels: string[];
  nodes: Record<string, Record<string, string>[]>;
}

export default function MostConnectedNodes({
  title,
  infoText,
  tableLabels = [],
  nodes,
}: IMostConnectedNodesProps) {
  const [componentType, setComponentType] = useState("all");

  if (!nodes) {
    return null;
  }

  const types = Object.keys(nodes).map((type) => {
    return { id: type, name: getCopy(`component_type_${type}`) };
  });

  const onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    setComponentType(evt.target.value);
  };

  const typeData = nodes[componentType];
  const formattedTypeData = typeData.map((type: Record<string, string>) => {
    return {
      ...type,
      component_type: getCopy(`component_type_${type.component_type}`),
    };
  });

  return (
    <div className={styles.section}>
      <div className={styles.sectionHeader}>
        <h3 className={styles.sectionTitle}>
          {title} {infoText && <InfoBtn>{infoText}</InfoBtn>}
        </h3>
        <div>
          <Select
            name="component-type"
            value={componentType}
            options={types}
            onChange={onChange}
            size="small"
          />
        </div>
      </div>
      {typeData && (
        <Table
          config={{
            cols: [
              {
                id: "display_name",
                name: tableLabels[0] || "Name",
                width: "60%",
              },
              {
                id: "compartment",
                name: tableLabels[1] || "Compartment",
                cellRenderer: (compartment: string) => {
                  const compartmentLabel =
                    getCopy(`component_compartment_${compartment}`) ||
                    compartment;
                  return <>{compartmentLabel}</>;
                },
                width: "20%",
              },
              {
                id: "component_type",
                name: tableLabels[2] || "Component type",
                width: "20%",
              },
            ],
          }}
          data={formattedTypeData}
        />
      )}
    </div>
  );
}
