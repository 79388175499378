import {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactElement,
} from "react";

import * as cognito from "@syntensor/common/libs/auth/cognito";
import {
  ICognitoUserToken,
  ICognitoNewPasswordRequireResponse,
} from "@syntensor/common/libs/auth/cognito";

import {
  CognitoUserAttribute,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

export const AuthStatus = {
  Loading: "AUTH_STATUS-LOADING",
  SignedIn: "AUTH_STATUS-SIGNED_IN",
  TempSignedIn: "AUTH_STATUS-TEMP_SIGNED_ID",
  SignedOut: "AUTH_STATUS-SIGNED_OUT",
};

export interface IAuthContent {
  attrInfo?: CognitoUserAttribute[];
  authStatus: (typeof AuthStatus)[keyof typeof AuthStatus];
  userEmail?: string | null;
  changePassword?: (oldPassword: string, newPassword: string) => void;
  confirmUser?: (username: string, email: string, password: string) => void;
  finishSignup?: () => void;
  forgotPassword?: (username: string, code: string, password: string) => void;
  getAttributes?: () => void;
  getSession?: () => void;
  replaceTempPassword?: (newPassword: string) => void;
  sendCode?: (username: string) => void;
  sessionInfo?: {
    accessToken: string;
    refreshToken: string;
    idToken: ICognitoUserToken;
  } | null;
  setAttributes?: (attr: Record<string, string>) => Promise<string>;
  signInWithEmail?: (
    username: string,
    password: string
  ) => Promise<CognitoUserSession | ICognitoNewPasswordRequireResponse>;
  signOut?: () => void;
  setUserEmail?: (email: string) => void;
}

const defaultState: IAuthContent = {
  sessionInfo: null,
  authStatus: AuthStatus.Loading,
};

export const AuthContext = createContext(defaultState);

export const AuthIsSignedIn = ({ children }: { children: ReactElement }) => {
  const { authStatus } = useContext(AuthContext);
  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
};

export const AuthIsTempSignedIn = ({
  children,
}: {
  children: ReactElement;
}) => {
  const { authStatus } = useContext(AuthContext);
  return <>{authStatus === AuthStatus.TempSignedIn ? children : null}</>;
};

export function AuthIsNotSignedIn({ children }: { children: ReactElement }) {
  const { authStatus } = useContext(AuthContext);
  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
}

export function getUserGroupsFromSession(
  sessionInfo: IAuthContent["sessionInfo"]
) {
  try {
    const groups = sessionInfo?.idToken.payload["cognito:groups"];
    return Array.isArray(groups) ? groups : [];
  } catch (err) {
    console.error(
      `Error getting user groups from sessionInfo.idToken.payload for ${sessionInfo}`
    );
    console.error(err);
    return [];
  }
}

export function IsUserInAdminGroup() {
  const { sessionInfo } = useContext(AuthContext);
  if (sessionInfo) {
    const userGroups = getUserGroupsFromSession(sessionInfo);
    return userGroups.includes("admins");
  }

  return false;
}

export function getCurrentUserSub() {
  const { attrInfo = [] } = useContext(AuthContext);
  const subObject = attrInfo.find((attr) => attr.Name === "sub");
  return subObject?.Value;
}

//  @TODO - display content only for the correct group
// export function AuthIsInGroup({ children }) {
//   const { authStatus } = useContext(AuthContext);
//   return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
// }

export default function AuthProvider({
  children,
}: {
  children: ReactElement | ReactElement[];
}) {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
  const [sessionInfo, setSessionInfo] = useState<
    IAuthContent["sessionInfo"] | null
  >(null);
  const [attrInfo, setAttrInfo] = useState<CognitoUserAttribute[] | undefined>(
    []
  );
  const [userEmail, setUserEmail] = useState<string | null>(null);

  useEffect(() => {
    async function getSessionInfo() {
      try {
        const session = await getSession();
        setSessionInfo({
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken ? session.refreshToken.token : "",
          idToken: session.idToken,
        });
        const attr = await getAttributes();

        setAttrInfo(attr);
        setAuthStatus(AuthStatus.SignedIn);
      } catch (err) {
        //  if we are in the temp signed in stage
        if (authStatus !== AuthStatus.TempSignedIn) {
          setAuthStatus(AuthStatus.SignedOut);
        }
      }
    }
    getSessionInfo();
  }, [setAuthStatus, authStatus]);

  if (authStatus === AuthStatus.Loading) {
    return null;
  }

  async function signInWithEmail(username: string, password: string) {
    try {
      const resp = await cognito.signInWithEmail(username, password);
      if (
        resp &&
        "action" in resp &&
        resp.action === cognito.NEW_PASSWORD_REQUIRED
      ) {
        setAuthStatus(AuthStatus.TempSignedIn);

        //  store user attributes for when we are setting new password
        //  remove custom attribute (since it would make cognito API call fail)
        const attrInfo: any = { ...resp };
        delete attrInfo.action;

        setAttrInfo(attrInfo);
      } else {
        setAuthStatus(AuthStatus.SignedIn);
      }

      return resp;
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
      throw err;
    }
  }

  async function confirmUser(
    username: string,
    email: string,
    password: string
  ) {
    await cognito.signUpUserWithEmail(username, email, password);
  }

  function signOut() {
    cognito.signOut();
    setAuthStatus(AuthStatus.SignedOut);
  }

  async function getSession() {
    const session = await cognito.getSession();
    return session;
  }

  async function getAttributes() {
    const attr = await cognito.getAttributes();
    return attr;
  }

  async function setAttributes(attr: Record<string, string>) {
    console.log("settingAttribute", attr);
    const res = await cognito.setAttributes(attr);

    //  make sure we don't have stale attributes in the state
    const attrInfo = await cognito.getAttributes();
    setAttrInfo(attrInfo);

    return res;
  }

  async function sendCode(username: string) {
    await cognito.sendCode(username);
  }

  async function forgotPassword(
    username: string,
    code: string,
    password: string
  ) {
    await cognito.forgotPassword(username, code, password);
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    await cognito.changePassword(oldPassword, newPassword);
  }

  async function replaceTempPassword(newPassword: string) {
    await cognito.completeNewPasswordChallenge(newPassword, attrInfo);

    //  expecting to call finishSignup once setup finished in the product API as well
  }

  async function finishSignup() {
    //  finish onboarding
    setAuthStatus(AuthStatus.SignedIn);
  }

  const state = {
    authStatus,
    sessionInfo,
    attrInfo,
    confirmUser,
    finishSignup,
    signInWithEmail,
    signOut,
    getSession,
    sendCode,
    forgotPassword,
    changePassword,
    replaceTempPassword,
    getAttributes,
    setAttributes,
    setUserEmail,
    userEmail,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
}
