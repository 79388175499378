import cn from "classnames";
import { scaleLinear } from "d3-scale";

import Table from "@syntensor/common/components/table";
import { EStudyType, IStudyCompound } from "@syntensor/common/types";
import getCopy from "@syntensor/common/data/copy";
import InfoBtn from "@syntensor/common/components/info_btn";

import { getHorizontalCompoundTableCols } from "../component_detail/header";
import { GENE_COLORS_SCALE } from "../components/columns";

import styles from "./compounds_table.module.css";

export const POTENCY_AUC_COLORS_SCALE = scaleLinear<string, string, string>()
  .domain([0, 10000000])
  .range(["#FFFFFF", "#a54ddb"])
  .unknown("#ccc")
  .clamp(true);

export function getCompoundPotencyAucColors(value: number) {
  return POTENCY_AUC_COLORS_SCALE(value);
}

export function getCompoundBooleanPropColors(value: string | number | boolean) {
  const green = GENE_COLORS_SCALE(1);
  const red = GENE_COLORS_SCALE(-1);
  if (typeof value === "boolean") {
    return value ? green : red;
  }

  return value === "active" ? green : red;
}

export function cellRendererFn(
  studyType: EStudyType,
  value: string | boolean | number
) {
  if (typeof value === "undefined") {
    return "-";
  }

  const cellRenderStyles = cn(styles.compoundTableValueCell);

  const backgroundColor =
    studyType === EStudyType.POTENCY && Number.isFinite(value as number)
      ? getCompoundPotencyAucColors(value as number)
      : getCompoundBooleanPropColors(value);

  const formattedValue = Number.isFinite(parseFloat(value as string))
    ? Math.round(value as number)
    : value.toString();

  return (
    <div className={cellRenderStyles} style={{ backgroundColor }}>
      {formattedValue}
    </div>
  );
}

export interface ICompoundsTableProps {
  compounds: IStudyCompound[];
  cellLine: string;
  keys: string[];
  studyType?: EStudyType;
}

export default function CompoundsTable({
  compounds,
  cellLine,
  keys,
  studyType = EStudyType.TOXICITY,
}: ICompoundsTableProps) {
  //  @TEMP - solution for hiding dosage until we sort compound management
  //  https://github.com/synthetic-tensors/syntensor-product-apps/issues/406
  const hasDosage = studyType === EStudyType.MOA_DECONVOLUTION;
  const tableCols = getHorizontalCompoundTableCols(
    compounds,
    cellLine,
    (value: string | number | boolean) => cellRendererFn(studyType, value),
    hasDosage
  );
  const firstCell = {
    id: "id",
    name: getCopy("studies_endpoints_endpoint"),
    cellRenderer: (d: string) => {
      const description = getCopy(`studies_endpoints_${d}-tooltip`);
      return (
        <div className={styles.compoundNameCell}>
          <div>{getCopy(`studies_endpoints_${d}`)}</div>
          {description && (
            <div className={styles.tooltip}>
              <InfoBtn>{description}</InfoBtn>
            </div>
          )}
        </div>
      );
    },
  };
  tableCols.unshift(firstCell);

  const tableData = keys.map((key) => {
    return compounds.reduce<Record<string, string | number | boolean>>(
      (acc, comp: IStudyCompound) => {
        if (comp.potency && key in comp.potency) {
          acc[`${cellLine}:${comp.compoundId}`] = comp.potency[key];
        } else if (comp.toxicity && key in comp.toxicity) {
          acc[`${cellLine}:${comp.compoundId}`] = comp.toxicity[key];
        }
        return acc;
      },
      { id: key }
    );
  });

  return (
    <Table
      config={{
        cols: tableCols,
      }}
      data={tableData}
    />
  );
}
