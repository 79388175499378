import { createAction, AnyAction } from "@reduxjs/toolkit";

import { TFilterValue } from "../studies/filters";
import { IFiltersState, IStoreState } from "./types";

export const selectFilters = (state: IStoreState) => state.filters;

export const selectComponentFilters = (state: IStoreState) => {
  const filters = selectFilters(state);
  return filters.components;
};

export const selectPathwayFilters = (state: IStoreState) => {
  const filters = selectFilters(state);
  return filters.pathways;
};

export const updateComponentFilters = createAction<
  Record<string, TFilterValue>
>("UPDATE_COMPONENT_FILTERS");
export const updatePathwayFilters = createAction<Record<string, TFilterValue>>(
  "UPDATE_PATHWAY_FILTERS"
);

export function maybeConvertToNumber(value: unknown) {
  //  weird js edge case
  if (value === null) {
    return value;
  }

  const number = Number(value);
  return Number.isNaN(number) ? value : number;
}

/**
 * Aggregate filters and convert single key values
 * into an array.
 */
export function reduceUpdateFilters(
  state: IFiltersState,
  storeSlice: string,
  filters: Record<string, TFilterValue>
) {
  const newSliceState = Object.keys(filters).reduce<
    Record<string, TFilterValue>
  >((acc, filterKey) => {
    //  remove key if passed an empty value
    let filterValue: TFilterValue = filters[filterKey];
    if (!filterValue) {
      return acc;
    }

    filterValue = filterValue
      .map(maybeConvertToNumber)
      .filter((d) => d !== null && d !== undefined) as TFilterValue;

    acc[filterKey] = filterValue;

    return acc;
  }, {});

  return {
    ...state,
    [storeSlice]: newSliceState,
  };
}

export const initialState = {
  components: {},
  pathways: {},
};

export default function filters(state = initialState, action: AnyAction) {
  switch (action.type) {
    case updateComponentFilters.type:
      return action.payload
        ? reduceUpdateFilters(state, "components", action.payload)
        : state;
    case updatePathwayFilters.type:
      return action.payload
        ? reduceUpdateFilters(state, "pathways", action.payload)
        : state;
    default:
      return state;
  }
}
