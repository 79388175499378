import cytoscape, {
  NodeSingular,
  EdgeSingular,
  NodeDefinition,
  EdgeDefinition,
  Css,
  Stylesheet,
} from "cytoscape";
import sbgnStylesheet from "cytoscape-sbgn-stylesheet";

import { ICellLine } from "@syntensor/common/types";

import { GENE_COLORS_SCALE, isGeneDarkColor } from "../../components/columns";

export const NODE_COLORS = [
  "#3662ff",
  "#f05b2a",
  "#eea933",
  "#00ba88",
  "#b95cf1",
  "#a54ddb",
  "#5c47e5",
  "#1343ee",
  "#9a9a9a",
];

export function getUniqueClasses(
  components: (NodeDefinition | EdgeDefinition)[]
) {
  return [...new Set(components.map((comp) => comp.data.class))];
}

export function getShapeForClass(className: string) {
  const SHAPE_MAP: Record<string, Css.NodeShape> = {
    compartment: "rectangle",
    submap: "rectangle",
    complex: "round-rectangle",
  };

  return SHAPE_MAP[className] || "ellipse";
}

export function getArrowShapeForClass(className: string) {
  //  ['Pathway', 'binding', 'output', 'input', 'transition', 'regulator', 'catalyst', 'dissociation']
  //  vaguely based on https://reactome.org/dev/diagram/pathway-diagram-specs
  const ARROW_STYLE_MAP: Record<string, Css.ArrowShape> = {
    binding: "vee",
    input: "none",
    output: "vee",
    catalyst: "circle",
    inhibitor: "tee",
    association: "circle",
    dissociation: "square",
    process: "tee",
  };

  // "tee", "vee", "triangle", "triangle-tee", "circle-triangle", "triangle-cross",
  // "triangle-backcurve", "square", "circle", "diamond", "chevron", "none";

  return ARROW_STYLE_MAP[className] || "vee";
}

export function getColorForSyntensorClass(syntensorClass: string) {
  const COLOR_STYLE_MAP: Record<string, string> = {
    output: NODE_COLORS[0],
    input: NODE_COLORS[1],
    transition: NODE_COLORS[2],
    catalyst: NODE_COLORS[3],
    inhibitor: NODE_COLORS[4],
    binding: NODE_COLORS[5],
    dissociation: NODE_COLORS[6],
    regulator: NODE_COLORS[7],
    omitted: NODE_COLORS[8],
  };
  return COLOR_STYLE_MAP[syntensorClass] || "#fff";
}

export const NODE_CLASS_SHORTHAND_MAP: Record<string, string> = {
  "simple chemical": "SC",
  "omitted process": "OP",
  compartment: "COMP",
};

export function getContentForNodeClass(className: string) {
  if (className) {
    return NODE_CLASS_SHORTHAND_MAP[className] || className[0].toUpperCase();
  }

  return className;
}

export function getPerturbationValue(
  node: NodeSingular,
  compounds: string[] | null,
  cellLines: ICellLine[] | null
) {
  if (
    node.data("syntensor") &&
    node.data("syntensor").simulations &&
    compounds &&
    cellLines
  ) {
    const compoundId = compounds[0];
    const cellLine = cellLines[0]?.id;
    const value =
      node.data("syntensor").simulations.perturbation[
        `${cellLine}:${compoundId}`
      ];
    return value as number;
  }

  return null;
}

export function getDataColor(
  node: NodeSingular,
  compounds: string[] = [],
  cellLines: ICellLine[] | null
) {
  const value = getPerturbationValue(node, compounds, cellLines);
  if (value !== null && Number.isFinite(value)) {
    return GENE_COLORS_SCALE(value);
  }
  return null;
}

export function getSbgnStylesheet(
  compounds: string[] | null,
  cellLines: ICellLine[] | null,
  minVersion = true
): Stylesheet[] {
  cytoscape.use(sbgnStylesheet);

  const sbgnArrowMap = new Map()
    .set("necessary stimulation", "triangle-cross")
    .set("inhibition", "tee")
    .set("catalysis", "circle")
    .set("stimulation", "triangle")
    .set("production", "triangle")
    .set("modulation", "diamond");

  const dimensionFn = (node: NodeSingular) => {
    return node.data("isSubnode") ? 15 : 30;
  };

  return [
    {
      selector: ".hidden",
      style: {
        display: "none",
      },
    },
    {
      selector: "edge",
      style: {
        "curve-style": "bezier",
        width: 0.2,
        "border-color": "#ffffff",
        "border-opacity": 0.3,
        "target-arrow-shape": (node: EdgeSingular) => {
          return getArrowShapeForClass(node.data("class"));
        },
        "target-arrow-fill": "filled",
        "arrow-scale": 0.5,
      },
    },
    {
      selector: "node",
      style: {
        "font-size": 11,
        width: dimensionFn,
        height: dimensionFn,
        // shape: "ellipse",
        "border-width": 1,
        "border-style": "solid",
        "border-color": "#767676",
        "z-index": 1,
        "text-valign": "center",
        "text-halign": "center",
        content: (node: NodeSingular) => {
          return !node.data("isSubnode")
            ? getContentForNodeClass(node.data("class"))
            : "";
        },
        color: (node: NodeSingular) => {
          if (node.data("syntensor") && node.data("syntensor").simulations) {
            const value = getPerturbationValue(node, compounds, cellLines);
            const isDarkColor = value && isGeneDarkColor(value);
            return isDarkColor ? "#fff" : "#000";
          }

          return "#767676";
        },
        "background-color": (node: NodeSingular) => {
          if (minVersion && compounds) {
            return getDataColor(node, compounds, cellLines) || "#1d1d1d";
          }

          if (node.data("syntensor")) {
            return getColorForSyntensorClass(node.data("syntensor").class);
          }

          //  no match for syntensor data
          return "#1d1d1d";
        },
        "background-opacity": (node: NodeSingular) => {
          return node.data("syntensor") && node.data("syntensor").simulations
            ? 1
            : 0;
        },
        shape: (node: NodeSingular) => getShapeForClass(node.data("class")),
      },
    },
    {
      selector: "node:selected",
      style: {
        content: (node: NodeSingular) => {
          return `${node.data("class")} - ${node.data("label")}`;
        },
      },
    },
    {
      selector: "node.selected",
      style: {
        "border-width": 3,
        "border-style": "solid",
        "border-color": "#000",
      },
    },
    {
      selector: `node[class="compartment"]`,
      style: {
        "background-color": "#000",
        "background-opacity": 0,
        "border-color": "#767676",
        "border-width": 1,
        "border-opacity": 0.3,
        "border-style": "dashed",
        "text-valign": "top",
        "z-index": 0,
        events: "no",
        content: (node: NodeSingular) => {
          return node.data("label");
        },
        width: (node: NodeSingular) => {
          return node.data("bbox").w;
        },
        height: (node: NodeSingular) => {
          return node.data("bbox").h;
        },
      },
    },
    {
      selector: `node[class="submap"]`,
      style: {
        "background-color": "#1d1d1d",
        // "border-color": "#fff",
        // "border-width": 1,
        "background-opacity": 0,
        "border-style": "dashed",
        "text-valign": "center",
        "text-wrap": "wrap",
        "text-max-width": "80",
        content: (node: NodeSingular) => {
          return node.data("label");
        },
        width: (node: NodeSingular) => {
          return node.data("bbox").w;
        },
        height: (node: NodeSingular) => {
          return node.data("bbox").h;
        },
      },
    },
    {
      selector: "node[class='hidden']",
      style: {
        display: "none",
      },
    },
    {
      selector: "edge",
      style: {
        "curve-style": "bezier",
        width: 0.6,
        "border-color": "#ffffff",
        "border-opacity": 0.3,
        "target-arrow-shape": (edge: EdgeSingular) =>
          sbgnArrowMap.get(edge.data("class")) || "vee",
        "target-arrow-fill": "filled",
      },
    },
    // {
    //   selector: "edge:selected",
    //   style: {
    //     // content: (edge: EdgeSingular) => edge.data("class"),
    //     "font-size": 12,
    //     color: "#ffffff",
    //   },
    // },
  ];
}
