import { useRef, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";

import getCopy from "@syntensor/common/data/copy";
import Button from "@syntensor/common/components/button";
import Select from "@syntensor/common/components/select";
import { fetchProject } from "@syntensor/common/data/fetch_data";
import Error from "@syntensor/common/components/error";
import Loader from "@syntensor/common/components/loader";
import { IProject } from "@syntensor/common/types";
import { deleteStudy } from "@syntensor/common/data/fetch_data";
import { navigateWithQueryParams } from "@syntensor/common/browser_history";

import StudyBox from "./study_box";
import { reactQueryOptions } from "../../config";
import { ProjectDataContext } from "../project";
import { IsUserInAdminGroup } from "../../auth/auth_context";
import { IProjectMatchParams } from "../types";

import styles from "../project.module.css";

export default function ProjectStudies() {
  const { url, params } = useRouteMatch<IProjectMatchParams>();
  const { projectId } = params;

  const queryClient = useQueryClient();

  const [deleteInProgressIds, setDeleteInProgressIds] = useState<string[]>([]);
  const dirtyCache = useRef(false);
  const isUserInAdminGroup = IsUserInAdminGroup();

  const queryKey = ["projects", projectId];
  const {
    data: projectData = null,
    isLoading,
    error,
  } = useQuery<IProject | null>(
    queryKey,
    async () => {
      //  @TEMP - always request with no cache
      const fetchOpts: RequestInit = {};
      fetchOpts.headers = { "cache-control": "no-cache" };

      //  if we have modified studies in any way, make sure to get
      //  the next request fresh from the server
      // if (dirtyCache.current) {
      //   fetchOpts.headers = { "cache-control": "no-cache" };
      //   dirtyCache.current = false;
      // }

      return await fetchProject(projectId, {}, fetchOpts);
    },
    reactQueryOptions
  );

  const handleCloneStudy = async (studyId: string) => {
    navigateWithQueryParams(`/projects/${projectId}/studies/create`, {
      templateId: studyId,
    });
  };

  const handleDeleteStudy = async (studyId: string) => {
    dirtyCache.current = true;

    setDeleteInProgressIds([...deleteInProgressIds, studyId]);

    try {
      await deleteStudy(studyId);
    } catch (err) {
      alert("Error deleting study, please try again.");
      console.error(err);
    }

    queryClient.invalidateQueries({ queryKey });

    setDeleteInProgressIds((deleteIds: string[]) => {
      return deleteIds.filter((id) => id !== studyId);
    });
  };

  if (error) {
    return <Error error={error} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  const { name, studies } = projectData || {};

  return (
    <>
      <ProjectDataContext.Provider value={projectData}>
        <div className={styles.titleWrapper}>
          <h2 className={styles.title} data-testid="projects_project_title">
            {name}
          </h2>
          <div>
            {isUserInAdminGroup && (
              <Button to={`${url}/create`} role="secondary">
                {getCopy("projects_project_new-study")}
              </Button>
            )}
          </div>
        </div>
        <div className={styles.filters}>
          <div />
          <div>
            <Select
              options={[
                { id: "all", name: getCopy("projects_project_filter-all") },
                {
                  id: "cell-lines",
                  name: getCopy("projects_project_filter-cell-line"),
                  disabled: true,
                },
                {
                  id: "compounds",
                  name: getCopy("projects_project_filter-compound"),
                  disabled: true,
                },
              ]}
              size="small"
            />
          </div>
        </div>
        <div className={styles.subcontent}>
          <ul className={styles.list}>
            {studies &&
              studies.map((study) => {
                const createdBy = study.createdBy
                  ? study.createdBy.displayName
                  : "";

                return (
                  <li key={study.id}>
                    <StudyBox
                      projectId={projectId}
                      studyId={study.id}
                      status={study.status}
                      title={study.displayName}
                      type={study.type}
                      description={study.description}
                      cellLines={study.cellLines}
                      compounds={study.compounds}
                      isDeleteInProgress={deleteInProgressIds.includes(
                        study.id
                      )}
                      tags={[]}
                      createdAt={study.createdAt}
                      createdBy={createdBy}
                      onDelete={handleDeleteStudy}
                      onClone={handleCloneStudy}
                    />
                  </li>
                );
              })}
          </ul>
        </div>
      </ProjectDataContext.Provider>
    </>
  );
}
