import { SyntheticEvent, useCallback, useContext } from "react";
import { AuthContext } from "../auth/auth_context";
import { navigateTo } from "@syntensor/common/browser_history";

import Button from "@syntensor/common/components/button";
import SubmitBtn from "@syntensor/common/components/submit_btn";
import useFormInput from "@syntensor/common/hooks/use_form_input";
import useFormSubmit from "@syntensor/common/hooks/use_form_submit";
import DarkModeToggle from "@syntensor/common/components/dark_mode_toggle";
import getCopy from "@syntensor/common/data/copy";

import StatusMsgs from "./status_msgs";
import Input from "./profile_input";
import PrivacyFooter from "../privacy/privacy_footer";

import styles from "./profile.module.css";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";

export function findAttributeByName(
  name: string,
  attributes: CognitoUserAttribute[]
) {
  if (!attributes || !attributes.length) {
    return "";
  }

  const attr = attributes.find((attr: CognitoUserAttribute) => {
    return attr.Name === name;
  });

  return attr ? attr.Value : "";
}

export default function ChangeDetails() {
  const { attrInfo, signOut, setAttributes } = useContext(AuthContext);
  const initialInputs = attrInfo
    ? {
        company: findAttributeByName("custom:company", attrInfo),
        role: findAttributeByName("custom:role", attrInfo),
        email: findAttributeByName("email", attrInfo),
        firstName: findAttributeByName("given_name", attrInfo),
        lastName: findAttributeByName("family_name", attrInfo),
      }
    : {};
  const { inputs, handleInputChange } = useFormInput({
    initialState: initialInputs,
  });

  const onSubmit = useCallback(async () => {
    if (inputs.company && inputs.role && inputs.firstName && inputs.lastName) {
      if (setAttributes) {
        await setAttributes({
          "custom:company": inputs.company,
          "custom:role": inputs.role,
          given_name: inputs.firstName,
          family_name: inputs.lastName,
        });
      }
    } else {
      throw new Error(getCopy("profile_details_validating-error-msg"));
    }
  }, [inputs, setAttributes]);

  const { isLoading, errorMsg, successMsg, setErrorMsg, handleSubmit } =
    useFormSubmit({
      onSubmit,
      onSuccessMsg: getCopy("profile_details_success-msg"),
    });

  const handleLogout = useCallback(
    async (evt: SyntheticEvent) => {
      evt.preventDefault();

      try {
        if (signOut) {
          await signOut();
          navigateTo("/app");
        }
      } catch (err: unknown) {
        console.log(err);
        if (err && err instanceof Error && err.message) {
          setErrorMsg(err.message);
        }
      }
    },
    [signOut, setErrorMsg]
  );

  return (
    <div className={styles.changeDetails}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h2
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: getCopy("profile_details_title"),
            }}
          />
          <DarkModeToggle />
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.row}>
            <Input
              required
              label={getCopy("profile_details_email")}
              name="email"
              readOnly
              type="email"
              value={inputs.email}
              autoComplete="email"
            />
          </div>
          <div className={styles.row}>
            <Input
              required
              label={getCopy("profile_details_first-name")}
              name="firstName"
              value={inputs.firstName}
              autoComplete="given-name"
              onChange={handleInputChange}
            />
            <Input
              required
              label={getCopy("profile_details_last-name")}
              name="lastName"
              value={inputs.lastName}
              autoComplete="family-name"
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.row}>
            <Input
              required
              label={getCopy("profile_details_company")}
              name="company"
              value={inputs.company}
              autoComplete="organisation"
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.row}>
            <Input
              required
              label={getCopy("profile_details_role")}
              name="role"
              value={inputs.role}
              autoComplete="organization-title"
              onChange={handleInputChange}
            />
          </div>
          <StatusMsgs errorMsg={errorMsg} successMsg={successMsg} />
          <div className={styles.row}>
            <div>
              <SubmitBtn
                isLoading={isLoading}
                type="submit"
                role="secondary"
                onClick={handleSubmit}
              >
                {getCopy("profile_details_cta")}
              </SubmitBtn>
            </div>
            <div>
              <Button role="subtle" to="/profile/password">
                {getCopy("profile_details_change-password")}
              </Button>
            </div>
            <div>
              <Button role="subtle" onClick={handleLogout}>
                {getCopy("profile_details_logout")}
              </Button>
            </div>
          </div>
          <div className={styles.detailsFooter}>
            <PrivacyFooter hasAccount={true} />
          </div>
        </form>
      </div>
    </div>
  );
}
