import { TInputChange } from "@syntensor/common/hooks/use_form_input";

import styles from "./create.module.css";

export interface ICreateNewStudyEndpointsFormProps {
  value: string[];
  onChange: (evt: TInputChange) => void;
}

export const COMPONENT_TYPES = [
  { id: "gene", name: "Gene" },
  // { id: "transcript", name: "Transcript", disabled: true },
  { id: "protein", name: "Protein" },
  { id: "complex", name: "Complex" },
];

export default function CreateNewStudyComponentTypesForm({
  value,
  onChange,
}: ICreateNewStudyEndpointsFormProps) {
  const handleInputChange = (evt: any) => {
    const newComponentTypes = value.includes(evt.target.value)
      ? value.filter((id: string) => id !== evt.target.value)
      : [...value, evt.target.value];

    onChange([{ name: "componentTypes", value: newComponentTypes }]);
  };

  // const formattedValue = JSON.stringify(value, null, 2);

  return (
    <div className={styles.formSection}>
      <h3 className={styles.formTitle}>Component Types</h3>
      <div className={styles.componentTypes}>
        {COMPONENT_TYPES.map(({ id, name }) => {
          return (
            <div key={id}>
              <input
                id={id}
                type="checkbox"
                name={name}
                value={id}
                onChange={handleInputChange}
                checked={value.includes(id)}
              />
              <label htmlFor={id}>{name}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
