import { ChangeEvent, useEffect, useState } from "react";
import cn from "classnames";

import Button from "@syntensor/common/components/button";
import { CheckMarkIcon, XIcon } from "@syntensor/common/components/icons";

import { IFilterOption } from "./";

import styles from "./filters.module.css";

export interface IMultiselectFilterProps {
  options?: IFilterOption[];
  onFilterValueChange?: (value: (string | number)[]) => void;
  onFilterCancel?: () => void;
}

export default function MultiselectFilter({
  options = [],
  onFilterValueChange = () => undefined,
  onFilterCancel = () => undefined,
}: IMultiselectFilterProps) {
  const [localOptions, setLocalOptions] = useState<IFilterOption[]>([]);

  const handleFilterValueChange = (optionId: string, isEnabled: boolean) => {
    setLocalOptions((prevOptions) => {
      return prevOptions.map((option) => {
        if (option.id === optionId) {
          return {
            ...option,
            isEnabled,
          };
        }
        return option;
      });
    });
  };

  const handleSubmit = () => {
    //  serialise filter value
    const enabledOptions = localOptions
      ? localOptions.filter((option) => option.isEnabled)
      : [];
    const filterValue = enabledOptions.map((opt) => opt.id);
    onFilterValueChange(filterValue);
  };

  const handleCancelClick = () => {
    //  reset state from external props
    if (options) {
      setLocalOptions(
        options.map((opt) => {
          return { ...opt };
        })
      );
    }
    onFilterCancel();
  };

  useEffect(() => {
    //  reset state if external props updated
    if (options) {
      setLocalOptions(
        options.map((opt) => {
          return { ...opt };
        })
      );
    }
  }, [options]);

  //  depending on number of options chnage styling so that the dropdown is not too tall
  const classNames = cn(styles.multiselectFilter, {
    [styles.multiselectFilterWide]: localOptions.length > 10,
  });

  return (
    <form className={classNames} onSubmit={handleSubmit}>
      <ul className={styles.filterList}>
        {localOptions.map((option) => {
          const { id, name, isEnabled = false } = option;
          return (
            <li className={styles.filterListItem} key={id}>
              <label className={styles.filterListLabel}>
                <input
                  type="checkbox"
                  checked={isEnabled}
                  onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                    handleFilterValueChange(id, evt.target.checked)
                  }
                />
                <span className={styles.columnSpan}>{name}</span>
              </label>
            </li>
          );
        })}
      </ul>
      <div className={styles.filterBtns}>
        <Button type="submit" role="primary" size="small">
          <CheckMarkIcon />
        </Button>
        <Button onClick={handleCancelClick} role="subtle" size="small">
          <XIcon />
        </Button>
      </div>
    </form>
  );
}
