import * as cognito from "@syntensor/common/libs/auth/cognito";
import {
  IStudyComponentsPayload,
  IStudyPathwaysPayload,
  ICreateStudyPayload,
  ICognitoUser,
} from "@syntensor/common/types";
import { encodeQueryParamsToSearchString } from "@syntensor/common/utils/url_utils";

import ApiFetchError from "./api_fetch_error";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export function formatApiError(error: string | { msg: string }[]) {
  if (!error) {
    return "API error";
  }

  if (Array.isArray(error)) {
    return error.map((e) => e.msg).join(".");
  }

  return error;
}

export function parseLinkHeader(linkHeader: string | null) {
  if (!linkHeader) {
    return null;
  }

  const linkHeadersArray = linkHeader
    .split(", ")
    .map((header: string) => header.split("; "));
  const linkHeadersMap = linkHeadersArray.map((header: string[]) => {
    const thisHeaderRel = header[1].replace(/"/g, "").replace("rel=", "");
    const thisHeaderUrl = header[0].slice(1, -1);
    return [thisHeaderRel, thisHeaderUrl];
  });
  return Object.fromEntries(linkHeadersMap);
}

export function hasResponsePagination(resp: Response) {
  const { headers } = resp;
  const link = parseLinkHeader(headers.get("Link"));
  return !!link && !!link.next;
}

export function formatPaginationPayload(payload: unknown, resp: Response) {
  const { headers } = resp;
  const link = parseLinkHeader(headers.get("Link"));

  //  is paginated response
  if (link && link.next) {
    //  get after parameter from the response Link header
    const parsedNextLink = new URL(link.next);
    const after = parsedNextLink.searchParams.get("after");
    return { items: payload, after };
  }
}

export async function fetchApiData(
  path: string,
  params = {},
  fetchOpts: RequestInit = {}
) {
  //  see if we're fetching local json file or hitting the REST API
  const url = path.indexOf(".json") > -1 ? path : new URL(path);

  if (!fetchOpts.headers) {
    fetchOpts.headers = {};
  }

  if (params && Object.keys(params).length > 0) {
    if (fetchOpts.method !== "POST" && fetchOpts.method !== "PATCH") {
      url.search = encodeQueryParamsToSearchString(params);
    } else {
      //  POST requests need params in body
      fetchOpts.body = JSON.stringify(params);
      (fetchOpts.headers as Record<string, string>)["Content-Type"] =
        "application/json";
    }
  }

  //  make sure the fetch request is made with httponly cookie (from auth)
  // fetchOpts.credentials = "include";
  // fetchOpts.credentials = "same-origin";

  //  fetch access token from congito cookie
  const session = await cognito.getSession();
  const token = session && session.idToken ? session.idToken.jwtToken : "";

  (
    fetchOpts.headers as Record<string, string>
  ).Authorization = `Bearer ${token}`;

  const resp = await fetch(url, fetchOpts);

  //  make sure we throw on anything but 2xx responses
  if (!resp.ok) {
    const body = await resp.json();
    const errorMsg =
      body && body.error ? formatApiError(body.error) : "Error loading data";
    const err = new ApiFetchError(errorMsg, resp.status);
    throw err;
  }

  const data = await resp.json();
  const payload = Object.hasOwnProperty.call(data, "data") ? data.data : [];

  const hasPaginatedContent = hasResponsePagination(resp);
  return hasPaginatedContent ? formatPaginationPayload(payload, resp) : payload;
}

/**
 * Users
 */
export function fetchUsers(): Promise<ICognitoUser[]> {
  const url = `${API_BASE_URL}/admin/users`;
  return fetchApiData(url);
}

export function fetchCreateNewUser(params: Record<string, string | string[]>) {
  const url = `${API_BASE_URL}/admin/users`;
  return fetchApiData(url, params, { method: "POST" });
}

export function fetchDeleteUser(awsSub: string) {
  const url = `${API_BASE_URL}/admin/users`;
  return fetchApiData(url, { awsSub }, { method: "DELETE" });
}

export function fetchRoles() {
  const url = `${API_BASE_URL}/admin/roles`;
  return fetchApiData(url);
}

export function fetchGroups() {
  const url = `${API_BASE_URL}/admin/groups`;
  return fetchApiData(url);
}

export function fetchResendInvite(email: string) {
  const url = `${API_BASE_URL}/admin/users/resend-invite`;
  return fetchApiData(url, { email }, { method: "POST" });
}

export function fetchUserScope(hypothesisId: string) {
  const url = `${API_BASE_URL}/user/scope/${hypothesisId}`;
  return fetchApiData(url);
}

export function fetchProjects() {
  const url = `${API_BASE_URL}/projects`;
  return fetchApiData(url);
}

export function deleteStudy(studyId: string) {
  const url = `${API_BASE_URL}/studies/${studyId}`;
  return fetchApiData(url, {}, { method: "DELETE" });
}

export function fetchGraphMetrics(graphVersion = "") {
  let url = `${API_BASE_URL}/metrics/graphs`;
  if (graphVersion) {
    url += `/${graphVersion}`;
  }
  return fetchApiData(url);
}

export function fetchRunMetrics(runId = null) {
  let url = `${API_BASE_URL}/metrics/runs`;
  if (runId) {
    url += `/${runId}`;
  }
  return fetchApiData(url);
}

export function fetchRunMetricsHistory(runId: string, metricsIds: string[]) {
  if (!runId || !metricsIds || !Array.isArray(metricsIds)) {
    console.error(
      `Fetch run metrics history needs runId and metricsId as an array, got ${runId} and ${metricsIds}`
    );
    return false;
  }

  const metricsStr = metricsIds.join(",");
  const url = `${API_BASE_URL}/metrics/runs/${runId}/history?ids=${metricsStr}`;
  return fetchApiData(url);
}

export function fetchProject(
  projectId: string,
  params = {},
  fetchOpts: RequestInit = {}
) {
  const url = `${API_BASE_URL}/projects/${projectId}`;
  return fetchApiData(url, params, fetchOpts);
}

export function fetchMetaPathways(versionId: string) {
  const url = `${API_BASE_URL}/meta/pathways/${versionId}`;
  return fetchApiData(url);
}

export function fetchSearch(studyId: string, params = {}) {
  const url = `${API_BASE_URL}/studies/${studyId}/search`;
  return fetchApiData(url, params);
}

/**
 * Studies
 */
export function fetchStudy(studyId: string) {
  const url = `${API_BASE_URL}/studies/${studyId}`;
  return fetchApiData(url);
}

export function fetchCreateStudy(params: ICreateStudyPayload) {
  const url = `${API_BASE_URL}/studies`;
  return fetchApiData(url, params, { method: "POST" });
}

export function fetchEditStudy(studyId: string, params: ICreateStudyPayload) {
  const url = `${API_BASE_URL}/studies/${studyId}`;
  return fetchApiData(url, params, { method: "PATCH" });
}

export function fetchStudyPathways(
  studyId: string,
  queryParams: IStudyPathwaysPayload
) {
  const url = `${API_BASE_URL}/studies/${studyId}/pathways`;
  return fetchApiData(url, queryParams);
}

export function fetchStudyComponents(
  studyId: string,
  queryParams: IStudyComponentsPayload
) {
  const url = `${API_BASE_URL}/studies/${studyId}/components`;
  return fetchApiData(url, queryParams);
}

export function fetchStudyMoaComponents(
  studyId: string,
  queryParams: {
    sortBy?: string;
    sortDir?: string;
    after?: string;
  }
) {
  const url = `${API_BASE_URL}/studies/${studyId}/moa`;
  return fetchApiData(url, queryParams);
}

export function fetchStudyComponentDetail(
  studyId: string,
  componentId: string,
  queryParams: {
    compounds?: string[];
    cellLines?: string[];
  }
) {
  const url = `${API_BASE_URL}/studies/${studyId}/components/${componentId}`;
  return fetchApiData(url, queryParams);
}

export function fetchStudyPathwayDetail(
  studyId: string,
  pathwayId: string,
  queryParams: {
    compounds?: string[];
    cellLines?: string[];
    sortBy?: string;
    sortDir?: string;
  }
) {
  const url = `${API_BASE_URL}/studies/${studyId}/pathways/${pathwayId}`;
  return fetchApiData(url, queryParams);
}

export function fetchStudyUpdate(
  studyId: string,
  params: Record<string, unknown>
) {
  const url = `${API_BASE_URL}/studies/${studyId}`;
  return fetchApiData(url, params, { method: "PATCH" });
}

export async function confirmUser() {
  const url = `${API_BASE_URL}/user/confirm`;
  return fetchApiData(url, {}, { method: "POST" });
}

/**
 * Comments
 */
export function fetchStudyComments(
  studyId: string,
  queryParams: { resolved?: boolean; path?: string } = {},
  fetchOpts: RequestInit = {}
) {
  const url = `${API_BASE_URL}/studies/${studyId}/comments`;
  return fetchApiData(url, queryParams, fetchOpts);
}

export function postNewStudyComment(
  studyId: string,
  queryParams: { text?: string; path?: string; parentCommentId?: string } = {}
) {
  const url = `${API_BASE_URL}/studies/${studyId}/comments`;
  return fetchApiData(url, queryParams, { method: "POST" });
}

export function editStudyComment(
  studyId: string,
  commentId: string,
  text: string,
  path?: string
) {
  const url = `${API_BASE_URL}/studies/${studyId}/comments/${commentId}`;
  return fetchApiData(url, { text, path }, { method: "PATCH" });
}

export function resolveStudyComment(
  studyId: string,
  commentId: string,
  isResolved: boolean
) {
  const url = `${API_BASE_URL}/studies/${studyId}/comments/${commentId}/resolve`;
  return fetchApiData(url, { isResolved }, { method: "POST" });
}

export function deleteStudyComment(studyId: string, commentId: string) {
  const url = `${API_BASE_URL}/studies/${studyId}/comments/${commentId}`;
  return fetchApiData(url, {}, { method: "DELETE" });
}

export function fetchValidateSmiles(smiles: string[]) {
  const url = `${API_BASE_URL}/compounds/smiles/validate`;
  return fetchApiData(url, smiles, { method: "POST" });
}
