import { useRouteMatch } from "react-router-dom";

import MainNavLink from "@syntensor/common/components/main_nav_link";

import styles from "./header.module.css";

export default function MetricsHeader() {
  const { url } = useRouteMatch();

  const graphRouteMatch = useRouteMatch({
    exact: true,
    path: `/metrics`,
  });
  const graphDynamicsRouteMatch = useRouteMatch({
    path: `/metrics/dynamics`,
  });
  const coverageRouteMatch = useRouteMatch({
    path: `/metrics/coverage`,
  });
  const coverageSamplingRouteMatch = useRouteMatch({
    path: `/metrics/coverage/sampling`,
  });
  // const modelRouteMatch = useRouteMatch({
  //   path: `/metrics/model`,
  // });
  // const modelDynamicRouteMatch = useRouteMatch({
  //   path: `/metrics/coverage/sampling`,
  // });

  const baseUrl = url;

  const isGraphLinkActive = !!graphRouteMatch || graphDynamicsRouteMatch;
  // const isModelLinkActive = !!modelRouteMatch;
  const isCoverageLinkActive =
    !!coverageRouteMatch || coverageSamplingRouteMatch;

  return (
    <header className={styles.header}>
      <nav className={styles.nav}>
        <ul className={styles.list}>
          <li>
            <MainNavLink exact isActive={() => isGraphLinkActive} to={baseUrl}>
              Graph
            </MainNavLink>
          </li>
          <li>
            <MainNavLink
              exact
              isActive={() => isCoverageLinkActive}
              to="/metrics/coverage"
            >
              Coverage
            </MainNavLink>
          </li>
          {/*
            Removed access to metrics model for now 
            until sorted metrics better
          <li>
            <MainNavLink
              exact
              isActive={() => isModelLinkActive}
              to="/metrics/model"
            >
              Model
            </MainNavLink>
          </li> */}
          <li>
            <MainNavLink exact to="/metrics/reports">
              Reports
            </MainNavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
}
