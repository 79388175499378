import { useCallback, useState, SyntheticEvent } from "react";

export default function useFormSubmit({
  onSubmit,
  onSuccessMsg = "Data updated",
}: {
  onSubmit: () => Promise<void>;
  onSuccessMsg?: string;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");

  const handleSubmit = useCallback(
    async (evt: SyntheticEvent) => {
      evt.preventDefault();

      setIsLoading(true);
      setSuccessMsg("");
      setErrorMsg("");
      try {
        await onSubmit();
        setSuccessMsg(onSuccessMsg);
      } catch (err: unknown) {
        console.log(err);
        if (err && err instanceof Error && err.message) {
          setErrorMsg(err.message);
        }
      }
      setIsLoading(false);
    },
    [onSubmit, onSuccessMsg]
  );

  return {
    isLoading,
    successMsg,
    errorMsg,
    setSuccessMsg,
    setErrorMsg,
    handleSubmit,
  };
}
