import { MouseEvent } from "react";
import cn from "classnames";

import {
  ArrowDownIcon,
  ArrowUpDownIcon,
  ArrowUpIcon,
  EIconSize,
} from "../icons";
import { ESortDir } from "../../types";

import styles from "./sort_control.module.css";

export interface ISortControlProps {
  id: string;
  isActive: boolean;
  sortDir: string;
  isMultiDir?: boolean;
  onSortClick: (id: string, sortDir: ESortDir) => void;
}

export default function SortControl({
  id,
  isActive,
  isMultiDir = false,
  sortDir = "",
  onSortClick,
}: ISortControlProps) {
  let icon = null;

  const handleSortClick = (evt: MouseEvent<HTMLButtonElement>) => {
    let sortDir = evt.currentTarget?.dataset?.sortDir as ESortDir;

    if (isMultiDir && isActive) {
      sortDir = sortDir === ESortDir.ASC ? ESortDir.DESC : ESortDir.ASC;
    }

    if (sortDir) {
      onSortClick(id, sortDir);
    }
  };

  if (!isMultiDir) {
    if (sortDir === "asc") {
      icon = <ArrowUpIcon size={EIconSize.SMALL} />;
    } else if (sortDir === "desc") {
      icon = <ArrowDownIcon size={EIconSize.SMALL} />;
    }
  } else {
    icon = <ArrowUpDownIcon size={EIconSize.SMALL} />;
  }

  const className = cn(styles.sortButton, {
    [styles.activeSortButton]: isActive && !isMultiDir,
    [styles.activeSortUpButton]: isActive && isMultiDir && sortDir === "asc",
    [styles.activeSortDownButton]: isActive && isMultiDir && sortDir === "desc",
  });

  return (
    <div className={styles.sortControl}>
      <button
        className={className}
        data-sort-dir={sortDir}
        onClick={handleSortClick}
      >
        {icon}
      </button>
    </div>
  );
}
