import { createBrowserHistory, History } from "history";

import {
  encodeQueryParamsToSearchString,
  parseUrlSearchQuery,
} from "./utils/url_utils";

export function getCurrentUrl() {
  return window && window.location ? window.location.href : "";
}

export function getCurrentHash() {
  const { location } = history;
  const hash = location.hash;

  //  get rid of hash
  return hash ? hash.replace("#", "") : "";
}

export function getQueryString() {
  const { location } = history;
  return location.search;
}

export function getCurrentParams() {
  const { location } = history;

  return parseUrlSearchQuery(location.search);
}

export function navigateWithQueryParams(
  to: string,
  newParams: Record<string, unknown> | null = null
) {
  const oldParams = getCurrentParams();
  const params = Object.assign(oldParams, newParams);

  //  get rid of the query params which are just empty strings
  const filteredParams = Object.keys(params).reduce<{ [key: string]: unknown }>(
    (acc, key) => {
      const value = params[key];
      if (value !== "") {
        acc[key] = value;
      }
      return acc;
    },
    {}
  );

  const search = encodeQueryParamsToSearchString(filteredParams);

  const historyObj = {
    pathname: to,
    search,
  };

  // if (hash) {
  //   historyObj.hash = hash;
  // }

  history.push(historyObj);
}

export function getCurrentParam(paramName: string) {
  const params = getCurrentParams();
  return params[paramName] || null;
}

export function scrollToHash(hash: string) {
  const elToScroll = document.getElementById(hash);
  if (elToScroll) {
    const OFFSET = 220;
    const topOfElement = elToScroll.offsetTop - OFFSET;
    window.scroll({ top: topOfElement, behavior: "smooth" });
    // elToScroll.scrollIntoView({ behavior: "smooth" });
  }
}

export function getBackUrl() {
  return getCurrentParam("b");
}

export function goBack() {
  if (history && typeof history.goBack === "function") {
    history.goBack();
  }
}

export function deleteUrlParam(paramName: string) {
  const params = getCurrentParams();
  delete params[paramName];

  const search = encodeQueryParamsToSearchString(params);
  history.push({ search });
}

export function updateUrl(newParams: Record<string, unknown>) {
  const oldParams = getCurrentParams();
  const params = Object.assign(oldParams, newParams);

  //  get rid of the query params which are just empty strings
  const filteredParams = Object.keys(params).reduce<Record<string, unknown>>(
    (acc, key) => {
      const value = params[key];
      if (value !== "") {
        acc[key] = value;
      }
      return acc;
    },
    {}
  );

  const search = encodeQueryParamsToSearchString(filteredParams);
  history.push({ search });
}

export function setCommentIdQueryParam(commentId = "") {
  if (commentId) {
    updateUrl({ commentId });
  } else {
    deleteUrlParam("commentId");
  }
}

export function navigateTo(to: string, shouldReplace = false) {
  if (!history) {
    console.error("Missing history object, might not be using react-router");
    return;
  }

  if (shouldReplace) {
    history.replace(to);
  } else {
    history.push(to);
  }
}

let history: History;

export function initBrowserHistory(basename?: string) {
  history = createBrowserHistory({ basename });
  return history;
}
