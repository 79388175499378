import { useContext } from "react";

import { IStudyContextData } from "@syntensor/common/types";
import getCopy from "@syntensor/common/data/copy";
import Select from "@syntensor/common/components/select";

import { StudyDataContext } from "..";
import StudiesTitle from "../title";
import StudyComponentsGrid from "../components/components_grid";
import StudyPathwaysGrid from "../pathways/pathways_grid";
import CompoundsTable from "./compounds_table";

import styles from "../studies.module.css";
import overviewStyles from "../overview/overview.module.css";

export default function StudyPotency() {
  const endpointId = "potency";

  const studyData = useContext<IStudyContextData | null>(StudyDataContext);
  if (!studyData) {
    return null;
  }

  const { compounds = [], cellLines = [], endpoints = {} } = studyData;

  const cellLine = cellLines && cellLines.length > 0 ? cellLines[0].id : "";

  const endpointsData = endpoints ? endpoints[endpointId] : null;
  if (!endpointsData) {
    return <div>No endpoint data</div>;
  }

  return (
    <div>
      <div className={styles.titles}>
        <StudiesTitle />
      </div>
      <div className={overviewStyles.content}>
        <div className={overviewStyles.controls}>
          <div className={styles.noGrowControl}>
            <Select
              label={getCopy("studies_components_cellline-dropdown")}
              options={cellLines}
              size="small"
            />
          </div>
        </div>
        <div className={overviewStyles.gridSection}>
          <h3 className={overviewStyles.gridTitle}>
            {getCopy("studies_endpoints_potency-title")}
          </h3>
          <p className={overviewStyles.gridDescription}>
            {getCopy("studies_endpoints_potency-description")}
          </p>
          <CompoundsTable
            studyType={studyData.type}
            compounds={compounds}
            cellLine={cellLine}
            keys={endpointsData.channels}
          />
        </div>
        <div className={overviewStyles.gridSection}>
          <h3 className={overviewStyles.gridTitle}>
            {getCopy("studies_endpoints_potency-components-title")}
          </h3>
          <p className={overviewStyles.gridDescription}>
            {getCopy("studies_endpoints_potency-components-description")}
          </p>
          <div className={overviewStyles.gridContent}>
            <StudyComponentsGrid
              ids={endpointsData.associatedComponents}
              hasFilters={false}
              hasLoadMore={false}
              keepPreviousData={true}
            />
          </div>
        </div>
        <div className={overviewStyles.gridSection}>
          <h3 className={overviewStyles.gridTitle}>
            {getCopy("studies_endpoints_potency-pathways-title")}
          </h3>
          <p className={overviewStyles.gridDescription}>
            {getCopy("studies_endpoints_potency-pathways-description")}
          </p>
          <div className={overviewStyles.gridContent}>
            <StudyPathwaysGrid
              ids={endpointsData.associatedPathways}
              hasFilters={false}
              hasLoadMore={false}
              keepPreviousData={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
