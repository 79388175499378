import { useState } from "react";

import Button from "@syntensor/common/components/button";
import Loader from "@syntensor/common/components/loader";
import { fetchResendInvite } from "@syntensor/common/data/fetch_data";
import getCopy from "@syntensor/common/data/copy";

import styles from "./list_users.module.css";

export interface IReinviteButtonProps {
  email: string;
}

export default function ReinviteButton({ email }: IReinviteButtonProps) {
  const defaultMsg = getCopy("admin_users_reinvite");
  const [isLoading, setIsLoading] = useState(false);
  const [btnLabel, setBtnLabel] = useState(defaultMsg);
  const [errorMsg, setErrorMsg] = useState("");

  const handleResendClick = async () => {
    setIsLoading(true);
    try {
      await fetchResendInvite(email);
      const successMsg = getCopy("admin_users_invite");
      setBtnLabel(successMsg);
    } catch (err) {
      const errorMsg = getCopy("admin_users_reinvite-error", { email });
      console.error(errorMsg);
      console.error(err);
      setErrorMsg(errorMsg);
    }
    setIsLoading(false);
  };

  const role = defaultMsg === btnLabel ? "subtle" : "white";

  return (
    <div className={styles.reinviteButton}>
      {errorMsg && <span className={styles.errorBtnMsg}>{errorMsg}</span>}
      <Button disabled={isLoading} onClick={handleResendClick} role={role}>
        {isLoading && <Loader size="32px" />}
        {btnLabel}
      </Button>
    </div>
  );
}
