import { useCallback, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useLocation, useRouteMatch } from "react-router-dom";

import useFormInput from "@syntensor/common/hooks/use_form_input";
import { fetchValidateSmiles } from "@syntensor/common/data/fetch_data";
import {
  ICompoundDefinition,
  EStudyType,
  ICreateStudyPayload,
  IStudyEndpoint,
} from "@syntensor/common/types";
import { IStudyResolved } from "@syntensor/common/types";
import { fetchStudy } from "@syntensor/common/data/fetch_data";
import {
  fetchCreateStudy,
  fetchEditStudy,
} from "@syntensor/common/data/fetch_data";
import { navigateTo } from "@syntensor/common/browser_history";
import useFormSubmit from "@syntensor/common/hooks/use_form_submit";
import getCopy from "@syntensor/common/data/copy";

import { IStudyMatchParams } from "../types";
import { getTemplateFromStudyData } from "./formatting";
import { reactQueryOptions } from "../../config";
import { IStudyEndpointConfig } from "./endpoints/endpoint";

export interface ICompoundInput extends ICompoundDefinition {
  isBeingValidated?: boolean;
  isValid?: boolean;
}

export function isValidList(listString: string) {
  if (!listString) {
    return false;
  }

  const list = listString.split(",");
  const nonEmptyList = list.filter((l) => !!l);
  return nonEmptyList.length > 0;
}

export function formatStudySubmission(
  projectId: string,
  inputs: Record<string, any>,
  compounds: ICompoundInput[]
) {
  const {
    displayName,
    description,
    type,
    cellLine,
    targetExternalComponents,
    targetExternalPathways,
    componentTypes,
    endpoints,
  } = inputs;

  if (!displayName || !description || !type) {
    //  should not happen as long as inputs are "required"
    throw new Error(getCopy("studies_create_error-field-missing"));
  }

  //  toxicity studies are run only in HEPG2 and we're hiding the cell line field
  const validatedCellLine = type === EStudyType.TOXICITY ? "HEPG2" : cellLine;
  if (!validatedCellLine) {
    throw new Error(getCopy("studies_create_error-cell-line-missing"));
  }

  const hasValidCompounds = compounds.every((comp) => {
    return !comp.isBeingValidated && comp.isValid !== false;
  }, []);
  if (compounds.length === 0) {
    throw new Error(getCopy("studies_create_error-compounds-missing"));
  }
  if (!hasValidCompounds) {
    throw new Error(getCopy("studies_create_error-compounds-invalid"));
  }
  if (componentTypes.length === 0) {
    throw new Error(getCopy("studies_create_error-component-types-invalid"));
  }

  if (!isValidList(targetExternalComponents)) {
    throw new Error(getCopy("studies_create_error-components-invalid"));
  }
  if (!isValidList(targetExternalPathways)) {
    throw new Error(getCopy("studies_create_error-pathways-invalid"));
  }

  const shouldHaveEndpoints =
    type == EStudyType.TOXICITY || type == EStudyType.POTENCY;
  if (shouldHaveEndpoints && (!endpoints || endpoints.length === 0)) {
    throw new Error(getCopy("studies_create_error-endpoints-missing"));
  }

  const payload: ICreateStudyPayload = {
    projectId,
    displayName,
    type,
    description,
    cellLines: [validatedCellLine],
    compounds,
    targetExternalComponents: targetExternalComponents.split(","),
    targetExternalPathways: targetExternalPathways.split(","),
    componentTypes,
  };

  if (shouldHaveEndpoints) {
    //  convert endpoints from an array to a dict use in the backend
    payload.endpoints = inputs.endpoints.reduce(
      (acc: Record<string, IStudyEndpoint>, endpoint: IStudyEndpointConfig) => {
        const { name, ...rest } = endpoint;
        acc[name] = rest;
        return acc;
      },
      {}
    );
  }

  return payload;
}

export default function useCreateStudyForm({
  initialState,
}: {
  initialState: Record<string, any>;
}) {
  const { params } = useRouteMatch<IStudyMatchParams>();
  const { projectId, studyId } = params;

  const isEditing = !!studyId;

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const templateId = queryParams.get("templateId") || studyId;

  const backUrl = `/projects/${projectId}`;

  const { inputs, handleInputChange, setAllInputs } = useFormInput({
    initialState,
  });
  const [compounds, setCompounds] = useState<ICompoundInput[]>(
    initialState.compounds
  );

  const queryClient = useQueryClient();

  const handleCompoundChange = async (newCompounds: ICompoundDefinition[]) => {
    setCompounds(newCompounds);
  };

  const onSubmit = useCallback(async () => {
    const payload = formatStudySubmission(projectId, inputs, compounds);

    if (!isEditing) {
      //  construct payload for the REST call
      await fetchCreateStudy(payload);
    } else {
      await fetchEditStudy(studyId, payload);
    }

    //  make sure next time we fetch project studies on project page
    //  we get the new study
    queryClient.invalidateQueries(["projects", projectId]);
    if (isEditing) {
      queryClient.invalidateQueries(["studies", studyId]);
    }

    navigateTo(backUrl);
  }, [inputs, compounds, backUrl]);

  const {
    isLoading: isFormLoading,
    errorMsg,
    handleSubmit,
  } = useFormSubmit({
    onSubmit,
    onSuccessMsg: "",
  });

  const validateSmiles = async (index: number, smiles: string) => {
    let isValid = false;

    setCompounds((compounds) => {
      const newValue = [...compounds];
      newValue[index] = { ...newValue[index], isBeingValidated: true };
      return newValue;
    });

    try {
      const validationResp = await fetchValidateSmiles([smiles]);
      isValid = validationResp[0];
    } catch (err) {
      console.error(err);
    }

    setCompounds((compounds) => {
      const newValue = [...compounds];
      newValue[index] = {
        ...newValue[index],
        isValid,
        isBeingValidated: false,
      };
      return newValue;
    });
  };

  const {
    data: templateData,
    isLoading: isTemplateLoading,
    error: templateLoadError,
  } = useQuery<IStudyResolved>(
    ["studies", templateId],
    async () => {
      if (templateId) {
        return await fetchStudy(templateId);
      }
      return null;
    },
    reactQueryOptions
  );

  useEffect(() => {
    if (templateData) {
      const template = getTemplateFromStudyData(templateData);
      setAllInputs(template);
      setCompounds(template.compounds);
    }
  }, [templateData]);

  const newInputs: Record<string, any> & { compounds: ICompoundInput[] } = {
    ...inputs,
    compounds,
  };

  return {
    inputs: newInputs,
    onSubmit: handleSubmit,
    isFormLoading,
    errorMsg,
    validateSmiles,
    handleInputChange,
    handleCompoundChange,
    isTemplateLoading,
    templateLoadError,
  };
}
