import { TInputChange } from "@syntensor/common/hooks/use_form_input";
import Button from "@syntensor/common/components/button";
import { EStudyType } from "@syntensor/common/types";
import getCopy from "@syntensor/common/data/copy";

import NewEndpointForm, { IStudyEndpointConfig } from "./endpoint";

import allPotencyEndpoints from "./potency.endpoints.json";
import allToxicityEndpoints from "./toxicity.endpoints.json";

import styles from "./endpoints.module.css";

export function getAllAvailableEndpointsForStudyType(type: EStudyType) {
  return type === EStudyType.POTENCY
    ? allPotencyEndpoints
    : allToxicityEndpoints;
}

export interface ICreateNewStudyEndpointsFormProps {
  type: EStudyType;
  onChange: (evt: TInputChange) => void;
  endpoints: IStudyEndpointConfig[];
  isDisabled?: boolean;
}

export default function CreateNewStudyEndpointsForm({
  type,
  onChange,
  endpoints = [],
  isDisabled = false,
}: ICreateNewStudyEndpointsFormProps) {
  const allEndpoints = getAllAvailableEndpointsForStudyType(type);
  const allEndpointNames = allEndpoints.map((endpoint) => endpoint.name);
  const existingKeys = endpoints.map((endpoint) => endpoint.name);
  const availableEndpointNames = allEndpointNames.filter(
    (key) => !existingKeys.includes(key)
  );

  const handleEndpointChange = (
    index: number,
    endpoint: IStudyEndpointConfig,
    changedProp: string
  ) => {
    const newValue = [...endpoints];

    if (changedProp !== "name") {
      newValue[index] = endpoint;
    } else {
      //  reset endpoint
      const endpointTemplate = allEndpoints.find(
        (template) => template.name === endpoint.name
      );
      if (endpointTemplate) {
        newValue[index] = endpointTemplate;
      }
    }

    onChange([{ name: "endpoints", value: newValue }]);
  };

  const handleRemoveEndpoint = (index: number) => {
    const confirmed = window.confirm(
      getCopy("studies_create_endpoint-delete-confirmation")
    );
    if (confirmed) {
      const newValue = [...endpoints];
      newValue.splice(index, 1);
      onChange([{ name: "endpoints", value: newValue }]);
    }
  };

  const handleAddEndpoint = () => {
    //  we need a mock endpoint id which will be unique
    const endpointId = availableEndpointNames[0];
    const endpointTemplate = allEndpoints.find(
      (endpoint) => endpoint.name === endpointId
    );

    if (endpointTemplate) {
      //  pick the endpoint
      const newValue = [...endpoints];
      newValue.push(endpointTemplate);
      onChange([{ name: "endpoints", value: newValue }]);
    }
  };

  return (
    <div className={styles.formSection}>
      <h3 className={styles.formSectionTitle}>
        {getCopy("studies_create_endpoints")}
      </h3>
      <div className={styles.endpoints}>
        <>
          {endpoints.map((endpoint, index: number) => {
            return (
              <div key={endpoint.name}>
                <div className={styles.endpoint}>
                  <NewEndpointForm
                    index={index}
                    availableEndpoints={[endpoint.name].concat(
                      availableEndpointNames
                    )}
                    endpoint={endpoint}
                    onChange={handleEndpointChange}
                    onDelete={handleRemoveEndpoint}
                    isDisabled={isDisabled}
                  />
                </div>
              </div>
            );
          })}
        </>
        {availableEndpointNames.length > 0 && (
          <div>
            <div className={styles.endpointEmpty}>
              <Button
                className={styles.addEndpointBtn}
                onClick={handleAddEndpoint}
                role="subtle"
              >
                {getCopy("studies_create_add-endpoint")}
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className={styles.formFooter}></div>
    </div>
  );
}
