import { ReactNode } from "react";
import cn from "classnames";

import Button from "../button/index";
import { XIcon } from "../icons";

import styles from "./popup.module.css";

export interface IPopupWindowProps {
  children: ReactNode;
  classNames?: string[];
  closeBtnClassNames?: string[];
  onClose?: () => void;
}

export default function PopupWindow({
  children,
  classNames = [],
  closeBtnClassNames = [],
  onClose = () => null,
}: IPopupWindowProps) {
  const handleOnCloseClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const hasCloseButton = onClose && typeof onClose === "function";

  const fullClassNames = cn(styles.popup, ...classNames);
  const fullCloseBtnClassNames = cn(styles.closeBtn, ...closeBtnClassNames);

  return (
    <div className={fullClassNames} data-testid="popup">
      {hasCloseButton && (
        <div className={fullCloseBtnClassNames}>
          <Button onClick={handleOnCloseClick}>
            <XIcon />
          </Button>
        </div>
      )}
      {children}
    </div>
  );
}
