import { Column } from "react-data-grid";

import Popup from "@syntensor/common/components/popup";
import Overlay from "@syntensor/common/components/popup/overlay";

import DataGridColumnsPopup from "./data_grid_columns_popup";

import styles from "./data_grid_columns.module.css";

export enum EDataGridColumnType {
  NUMERIC,
  MULTISELECT,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IDataGridColumn extends Column<any, any> {
  name: string;
  isEnabled?: boolean;
  isFilterable?: boolean;
  options?: unknown[];
  type?: EDataGridColumnType;
}

export interface IDataGridColumnsProps {
  columns: IDataGridColumn[];
  onToggle: (
    toggleColumns: string | string[],
    isEnabled: boolean | boolean[]
  ) => void;
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
}

export default function DataGridColumns({
  columns,
  onToggle,
  onClose,
  onCancel,
  onConfirm,
}: IDataGridColumnsProps) {
  return (
    <Overlay classNames={[styles.overlay]} onClick={onClose}>
      <Popup
        classNames={[styles.popup]}
        closeBtnClassNames={[styles.closeBtn]}
        onClose={onClose}
      >
        <DataGridColumnsPopup
          columns={columns}
          onToggle={onToggle}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      </Popup>
    </Overlay>
  );
}
