import { MouseEvent } from "react";
import cn from "classnames";

import {
  EIconSize,
  ExpandLessIcon,
  ExpandMoreIcon,
  NestedIcon,
} from "../icons";
import Button from "../button";
import { ITreeNode } from ".";

import styles from "./tree_view_item.module.css";

export interface ITreeItemProps {
  node: ITreeNode;
  expandedNodes: Set<string>;
  toggleCollapse: (node: ITreeNode) => void;
  onNodeClick?: (node: ITreeNode) => void;
  activeItemId?: string;
  depth?: number;
}

export default function TreeItem({
  node,
  expandedNodes,
  toggleCollapse,
  onNodeClick,
  activeItemId = "",
  depth = 0,
}: ITreeItemProps) {
  if (!node) {
    return null;
  }

  const isActive = node.id === activeItemId;
  const isTopLevel = node.isTopLevel || false;

  const children = node.children || [];
  const isRoot = depth === 0;
  const paddingLeft = `calc(${depth} * var(--space-sm)`;

  const handleNodeClick = (evt: MouseEvent<HTMLDivElement>) => {
    if (!isTopLevel) {
      //  do not trigger node callback if the expand button toggled
      const clickedButton = (evt.target as HTMLElement).closest("button");
      if (!clickedButton && onNodeClick) {
        onNodeClick(node);
      }
    }
  };

  const handleToggleCollapse = () => {
    toggleCollapse(node);
  };

  const hasChildren = children && children.length > 0;

  const classNames = cn(styles.treeItem, {
    [styles.treeItemTopLevel]: isTopLevel,
    [styles.treeItemActive]: isActive,
  });

  return (
    <>
      <div
        className={classNames}
        style={{ paddingLeft }}
        onClick={handleNodeClick}
        data-id={node.id}
      >
        <div className={styles.treeItemHeader}>
          <div className={styles.treeItemLabelWrapper}>
            {!isRoot && (
              <span className={styles.nestedIcon}>
                <NestedIcon size={EIconSize.MICRO} />
              </span>
            )}
            <div className={styles.treeItemLabel}>{node.name}</div>
          </div>
          <div>
            {hasChildren && (
              <Button className={styles.btn} onClick={handleToggleCollapse}>
                {expandedNodes.has(node.id) ? (
                  <ExpandLessIcon size={EIconSize.SMALL} />
                ) : (
                  <ExpandMoreIcon size={EIconSize.SMALL} />
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
      <>
        {expandedNodes.has(node.id) &&
          hasChildren &&
          children.map((child) => {
            return (
              <TreeItem
                key={child.id}
                node={child}
                activeItemId={activeItemId}
                expandedNodes={expandedNodes}
                toggleCollapse={toggleCollapse}
                onNodeClick={onNodeClick}
                depth={depth + 1}
              />
            );
          })}
      </>
    </>
  );
}
