import { SyntheticEvent } from "react";

import Button from "../button";

import styles from "./search_form_filters.module.css";

export const FILTER_KEYS = [
  "all",
  "gene",
  "transcript",
  "protein",
  "complex",
  "pathway",
];

export interface SearchFormFiltersProps {
  activeComponentType: string;
  onSelect: (type: string) => void;
}

export default function SearchFormFilters({
  activeComponentType,
  onSelect,
}: SearchFormFiltersProps) {
  const handleClick = (evt: SyntheticEvent<HTMLElement>) => {
    const { currentTarget } = evt;
    if (currentTarget.dataset["type"] && onSelect) {
      onSelect(currentTarget.dataset["type"]);
    }
  };

  return (
    <ul className={styles.searchFormFilters}>
      {FILTER_KEYS.map((filterKey) => {
        const isActive = filterKey === activeComponentType;
        const buttonClassName = isActive ? styles.activeButton : "";

        return (
          <li key={filterKey}>
            <Button
              className={buttonClassName}
              isActive={isActive}
              role="subtle"
              size="small"
              data-type={filterKey}
              onClick={handleClick}
            >
              {filterKey}
            </Button>
          </li>
        );
      })}
    </ul>
  );
}
