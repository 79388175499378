import cn from "classnames";

import styles from "./moa_rank.module.css";

export interface IMoaRankProps {
  rank: number;
  theme?: string;
  size?: string;
}

export default function MoaRank({
  rank,
  theme = "secondary",
  size = "small",
}: IMoaRankProps) {
  const classNames = cn(styles.moaRank, styles[theme], styles[size]);
  const hasRank = Number.isFinite(rank);

  return (
    <div className={classNames}>
      <span className={styles.label}>MoA</span>
      {hasRank && <span className={styles.value}>{rank}</span>}
    </div>
  );
}
