import { Route, Switch } from "react-router-dom";

import ChangeDetails from "./change_details";
import ChangePassword from "./change_password";

export default function Profile() {
  return (
    <>
      <Switch>
        <Route path={`/profile/password`} component={ChangePassword} />
        <Route exact={true} path={`/profile`} component={ChangeDetails} />
      </Switch>
    </>
  );
}
