import { ReactNode, SyntheticEvent } from "react";
import { NavLink } from "react-router-dom";

import cn from "classnames";

import styles from "./main_nav_link.module.css";

export interface IMainNavLinkProps {
  children?: ReactNode;
  className?: string;
  activeClassName?: string;
  isDisabled?: boolean;
  to?: string;
  [key: string]: unknown;
}

export default function MainNavLink({
  children = null,
  className = "",
  activeClassName = "",
  isDisabled = false,
  to = "",
  ...linkProps
}: IMainNavLinkProps) {
  const classNames = cn(styles.mainNavLink, {
    [styles.disabled]: isDisabled,
    [className]: !!className,
  });
  const activeClassNames = cn(styles.mainNavLinkActive, {
    [activeClassName]: !!activeClassName,
  });

  const onClick = isDisabled
    ? (evt: SyntheticEvent) => {
        evt.preventDefault();
      }
    : () => null;

  return (
    <NavLink
      to={to}
      className={classNames}
      activeClassName={activeClassNames}
      onClick={onClick}
      {...linkProps}
    >
      {children}
    </NavLink>
  );
}
