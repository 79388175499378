import { NavLink, useRouteMatch } from "react-router-dom";

import Table from "@syntensor/common/components/table";
import { IStudyComponent, IStudyCompound } from "@syntensor/common/types";

import { IStudyMatchParams } from "../types";
import { getHorizontalCompoundTableCols } from "../component_detail/header";
import { STUDY_URL_PREFIX } from "../routes";

import overviewStyles from "./overview.module.css";

export function RenderProteinFn(comp: IStudyComponent, url: string) {
  return (
    <NavLink
      className={overviewStyles.topProteinCell}
      to={`${url}/components/${comp.syntensorId}`}
    >
      {comp.displayName}
    </NavLink>
  );
}

export function getFirstMoaRank(
  proteins: IStudyComponent[],
  compoundId: string
) {
  return proteins.find((comp) => {
    const moaRank = comp?.simulations?.moaRank || {};
    return moaRank[compoundId] === 1;
  });
}

export interface IOverviewTopMoaProteinTableProps {
  compounds: IStudyCompound[];
  cellLine: string;
  proteins: IStudyComponent[];
}

export default function OverviewTopMoaProteinTable({
  compounds,
  cellLine,
  proteins,
}: IOverviewTopMoaProteinTableProps) {
  const studyMatch = useRouteMatch<IStudyMatchParams>(STUDY_URL_PREFIX);
  const url = studyMatch?.url || "";

  const tableCols = getHorizontalCompoundTableCols(
    compounds,
    cellLine,
    (comp: IStudyComponent) => {
      return RenderProteinFn(comp, url);
    }
  );
  const tableData = [
    tableCols.reduce<Record<string, IStudyComponent>>((acc, tableCol) => {
      if (tableCol.id) {
        const protein = getFirstMoaRank(proteins, tableCol.id);
        if (protein) {
          acc[tableCol.id] = protein;
        }
      }

      return acc;
    }, {}),
  ];

  return (
    <Table
      config={{
        cols: tableCols,
      }}
      data={tableData}
    />
  );
}
