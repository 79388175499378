import { ReactNode, createContext, useState } from "react";

export const LIGHT_MODE = "light";
export const DARK_MODE = "dark";

const initialContextState: {
  colorMode: string;
  setColorMode: (newColorMode: string) => void;
} = {
  colorMode: DARK_MODE,
  setColorMode: () => null,
};

export const DarkModeContext = createContext(initialContextState);

export interface IDarkModeProviderProps {
  children: ReactNode;
  initialMode?: string;
}

export default function DarkModeProvider({
  children,
  initialMode = DARK_MODE,
}: IDarkModeProviderProps) {
  const [colorMode, setColorMode] = useState(initialMode);

  const state = {
    colorMode,
    setColorMode,
  };

  return (
    <DarkModeContext.Provider value={state}>
      {children}
    </DarkModeContext.Provider>
  );
}
