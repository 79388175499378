import { Fragment, useState } from "react";
import { NodeSingular } from "cytoscape";
import cn from "classnames";

import { ICellLine, ISelectableCompound } from "@syntensor/common/types";

import Compound from "./compound";
import { getCompoundColName } from "../../../components/columns";

import styles from "./compounds.module.css";

interface PerturbationData {
  [key: string]: number;
}

interface ICytoscapeComponentCompoundsProps {
  node: NodeSingular;
  compounds: ISelectableCompound[];
  cellLines: ICellLine[];
  perturbation: PerturbationData;
  selectedCompoundId: string;
  onMouseOver?: (compoundId: string, node: NodeSingular) => void;
  onMouseOut?: (compoundId: string, node: NodeSingular) => void;
  onClick?: (compoundId: string, node: NodeSingular) => void;
  displayOnHover?: boolean;
  displayLabel?: boolean;
}

export default function CytoscapeComponentCompounds({
  node,
  compounds,
  cellLines,
  perturbation,
  selectedCompoundId,
  onMouseOver,
  onMouseOut,
  onClick,
  displayOnHover = false,
  displayLabel = true,
}: ICytoscapeComponentCompoundsProps) {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseOver = (compoundId: string) => {
    if (onMouseOver) {
      onMouseOver(compoundId, node);
    }
  };

  const handleMouseOut = (compoundId: string) => {
    if (onMouseOut) {
      onMouseOut(compoundId, node);
    }
  };

  const handleClick = (compoundId: string) => {
    if (onClick) {
      onClick(compoundId, node);
    }
  };

  const handleParentMouseOver = () => {
    setIsHovered(true);
    // if (onMouseOver) {
    //   onMouseOver("", node);
    // }
  };

  const handleParentMouseOut = () => {
    setIsHovered(false);
    // if (onMouseOut) {
    //   onMouseOut("", node);
    // }
  };

  const selectedCompounds = compounds.filter((comp) => comp.isSelected);

  const shouldDisplayDetail = !displayOnHover || isHovered;

  //  prioritise syntensor label if we have one
  const label = node.data("syntensor")
    ? node.data("syntensor").displayName
    : node.data("label");

  const classNames = cn(styles.compounds, {
    [styles.subnode]: node.data("isSubnode"),
  });

  return (
    <div
      className={classNames}
      onMouseEnter={handleParentMouseOver}
      onMouseLeave={handleParentMouseOut}
    >
      {displayLabel && <div className={styles.name}>{label}</div>}
      {displayOnHover && <div className={styles.interactionPlaceholder} />}
      {shouldDisplayDetail && (
        <ul className={styles.list}>
          {cellLines &&
            cellLines.map((cellLine) => {
              return (
                <Fragment key={cellLine.id}>
                  {selectedCompounds &&
                    selectedCompounds.map((compound) => {
                      const isSelected =
                        selectedCompoundId === compound.compoundId;

                      //  different styling for when other compound is selected
                      const isDeselected = !!selectedCompoundId && !isSelected;

                      const perturbationKey = `${cellLine.id}:${compound.compoundId}`;
                      const compoundPerturbation =
                        perturbation[perturbationKey];
                      const label = getCompoundColName(compound);

                      return (
                        <li
                          key={perturbationKey}
                          className={styles.compoundItem}
                        >
                          <Compound
                            id={compound.id}
                            label={label}
                            value={compoundPerturbation}
                            isSelected={isSelected}
                            isDeselected={isDeselected}
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                            onClick={handleClick}
                          />
                        </li>
                      );
                    })}
                </Fragment>
              );
            })}
        </ul>
      )}
    </div>
  );
}
