import cn from "classnames";
import { scaleLinear } from "d3-scale";

import Table from "@syntensor/common/components/table";
import { IStudyCompound } from "@syntensor/common/types";
import getCopy from "@syntensor/common/data/copy";
import InfoBtn from "@syntensor/common/components/info_btn";

import { getHorizontalCompoundTableCols } from "../component_detail/header";

import studiesStyles from "../studies.module.css";
import styles from "../endpoints/compounds_table.module.css";

export const DILI_TOXICITY_COLORS_SCALE = scaleLinear<string, string, string>()
  .domain([0, 1])
  .range(["#FFFFFF", "#a54ddb"])
  .unknown("#ccc")
  .clamp(true);

export function isToxicityDarkColor(value: number) {
  if (!value) {
    return false;
  }

  return value > 0.7;
}

export function cellRendererFn(value: string | boolean | number) {
  if (typeof value === "boolean" || typeof value === "string") {
    return <div>{value.toString()}</div>;
  }

  const isDarkColor = isToxicityDarkColor(value);

  //  get correct unknown color
  const backgroundColor = DILI_TOXICITY_COLORS_SCALE(value);
  const className = cn(studiesStyles.tableValueCell, {
    [studiesStyles.valueCellDark]: isDarkColor,
  });

  return (
    <div className={className} style={{ backgroundColor }}>
      <span>{value}</span>
    </div>
  );
}

export interface IDiliTableProps {
  compounds: IStudyCompound[];
  cellLine: string;
}

export default function DiliTable({ compounds, cellLine }: IDiliTableProps) {
  const tableCols = getHorizontalCompoundTableCols(
    compounds,
    cellLine,
    (value: string | number | boolean) => cellRendererFn(value),
    false
  );

  const firstCell = {
    id: "id",
    name: getCopy("studies_endpoints_endpoint"),
    cellRenderer: (d: string) => {
      const description = getCopy(`studies_endpoints_${d}-tooltip`);
      return (
        <div className={styles.compoundNameCell}>
          <div>{getCopy(`studies_endpoints_${d}`)}</div>
          {description && (
            <div className={styles.tooltip}>
              <InfoBtn>{description}</InfoBtn>
            </div>
          )}
        </div>
      );
    },
  };
  tableCols.unshift(firstCell);

  const keys = ["diliTF", "diliConfidence"];
  const tableData = keys.map((key) => {
    return compounds.reduce<Record<string, string | number | boolean>>(
      (acc, comp: IStudyCompound) => {
        if (comp.toxicity && key in comp.toxicity) {
          acc[`${cellLine}:${comp.compoundId}`] = comp.toxicity[key];
        }
        return acc;
      },
      { id: key }
    );
  });

  return (
    <Table
      config={{
        cols: tableCols,
      }}
      data={tableData}
    />
  );
}
