import {
  FormEvent,
  KeyboardEvent,
  useState,
  SyntheticEvent,
  useRef,
  useEffect,
} from "react";
import cn from "classnames";

import Input from "@syntensor/common/components/input";

import { ArrowUpIcon, EIconSize } from "../icons";
import Loader from "../loader";

import styles from "./add_comment_form.module.css";

export interface IAddCommentFormProps {
  text?: string;
  focusOnMount?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  parentCommentId?: string;
  error?: string;
  theme?: string;
  onSubmit?: (comment: string, parentCommentId?: string) => void;
}

export default function AddCommentForm({
  text = "",
  isLoading = false,
  focusOnMount = false,
  placeholder = "",
  parentCommentId = "",
  error = "",
  theme = "large",
  onSubmit,
}: IAddCommentFormProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState(text);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    //  blur might be triggered because of submitting a button
    //  in which case we want to keep the focus until
    //  submission is completed
    setTimeout(() => {
      if (submitBtnRef.current !== document.activeElement) {
        setIsFocused(false);
      }
    });
  };

  const handleInput = (evt: SyntheticEvent<HTMLTextAreaElement>) => {
    setValue(evt.currentTarget.value);
  };

  const handleKeyDown = (evt: KeyboardEvent<HTMLTextAreaElement>) => {
    //  allow slack-style submission with enter but shift+enter
    //  reserved for inserting new line characters
    if (evt.key === "Enter" && !evt.shiftKey) {
      //  do not insert new line character
      evt.preventDefault();

      if (formRef.current && !isLoading) {
        handleSubmit();
      }
    }
  };

  const handleSubmit = (evt?: FormEvent<HTMLFormElement>) => {
    if (evt) {
      evt.preventDefault();
    }

    if (onSubmit && typeof onSubmit === "function") {
      if (value) {
        onSubmit(value, parentCommentId);
      }
    }
  };

  useEffect(() => {
    if (focusOnMount && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, []);

  //  automatically resize textarea for its content
  useEffect(() => {
    if (textAreaRef.current && value) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  }, [value]);

  const isSubmitDisabled = !value && isLoading;

  const addCommentFormClassName = cn(styles.addCommentForm, {
    [styles.addCommentFormSmall]: theme === "small",
  });

  const submitBtnClassName = cn(styles.submitBtn, {
    [styles.submitBtnVisible]: isFocused,
  });
  const textAreaClassName = cn(styles.textArea, {
    [styles.textAreaFocused]: isFocused,
    [styles.textAreaError]: !!error,
  });

  return (
    <form
      ref={formRef}
      className={addCommentFormClassName}
      onSubmit={handleSubmit}
    >
      <textarea
        ref={textAreaRef}
        className={textAreaClassName}
        id="commentInput"
        placeholder={placeholder}
        onInput={handleInput}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        value={value}
      />

      {error && <p className={styles.errorMsg}>{error}</p>}
      {parentCommentId && (
        <Input id="parentCommentId" type="hidden" value={parentCommentId} />
      )}
      <button
        disabled={isSubmitDisabled}
        ref={submitBtnRef}
        className={submitBtnClassName}
      >
        {isLoading && <Loader size="18px" />}
        {!isLoading && <ArrowUpIcon size={EIconSize.SMALL} />}
      </button>
    </form>
  );
}
