import { Switch, Route, useLocation, Redirect } from "react-router-dom";

import Projects from "./projects";
import Project from "./projects/project";

import Metrics from "./metrics";

import Placeholder from "./app/placeholder";

import Profile from "./profile";
import Signout from "./profile/signout";
import PrivacyTerms from "./privacy/terms";
import PrivacyPolicy from "./privacy/policy";
import PrivacyCookies from "./privacy/cookies";
import Landing from "./app/landing";
import { IsUserInAdminGroup } from "./auth/auth_context";
import AdminUsers from "./admin/users";
import Study from "./studies";
import StudyCreate from "./studies/create";

// import PerturbApp from "./perturb_app";

export function AppRoutes() {
  const { pathname } = useLocation();
  const isUserInAdminGroup = IsUserInAdminGroup();

  return (
    <Switch>
      {/* remove trailing slashes to make composing paths little more predictable */}
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

      {/* for now a new study can be created only by admins */}
      {isUserInAdminGroup && (
        <Route
          path="/projects/:projectId/studies/create*"
          component={StudyCreate}
        />
      )}
      {isUserInAdminGroup && (
        <Route
          path="/projects/:projectId/studies/:studyId/edit"
          component={StudyCreate}
        />
      )}

      <Route path="/projects/:projectId/studies/:studyId" component={Study} />

      <Route path="/projects" exact component={Projects} />
      <Route path="/projects/:projectId" component={Project} />

      <Route path="/metrics" component={Metrics} />

      {/* In case already logged-in user lands on a new signup page */}
      <Route path="/profile/signup" component={Signout} />
      <Route path="/profile" component={Profile} />
      <Route path="/privacy/terms" component={PrivacyTerms} />
      <Route path="/privacy/policy" component={PrivacyPolicy} />
      <Route path="/privacy/cookies" component={PrivacyCookies} />

      {/* ADMIN ROUTES */}
      {isUserInAdminGroup && <Route path="/users" component={AdminUsers} />}

      <Route path="/placeholder" component={Placeholder} />
      <Route path="/" component={Landing} />
    </Switch>
  );
}
