import cn from "classnames";

import { ReactComponent as AccountCircleGraphic } from "./assets/account_circle.svg";
import { ReactComponent as ArrowUpDownGraphic } from "./assets/arrow_up_down.svg";
import { ReactComponent as ArrowDownGraphic } from "./assets/arrow_down.svg";
import { ReactComponent as ArrowUpGraphic } from "./assets/arrow_up.svg";
import { ReactComponent as ArrowLeftGraphic } from "./assets/arrow_left.svg";
import { ReactComponent as ArrowRightGraphic } from "./assets/arrow_right.svg";
import { ReactComponent as FilterGraphic } from "./assets/filter.svg";
import { ReactComponent as BackGraphic } from "./assets/back.svg";
import { ReactComponent as BookmarksGraphic } from "./assets/bookmarks.svg";
import { ReactComponent as ChartsGraphic } from "./assets/charts.svg";
import { ReactComponent as CheckIndeterminateGraphic } from "./assets/check_indeterminate.svg";
import { ReactComponent as CheckMarkGraphic } from "./assets/check_mark.svg";
import { ReactComponent as CommandGraphic } from "./assets/command.svg";
import { ReactComponent as EditGraphic } from "./assets/edit.svg";
import { ReactComponent as EqualizerGraphic } from "./assets/equalizer.svg";
import { ReactComponent as ExpandLessGraphic } from "./assets/expand_less.svg";
import { ReactComponent as ExpandMoreGraphic } from "./assets/expand_more.svg";
import { ReactComponent as HeatMapGraphic } from "./assets/heat_map.svg";
import { ReactComponent as HexagonMapGraphic } from "./assets/hexagon_map.svg";
import { ReactComponent as LineChartGraphic } from "./assets/line_chart.svg";
import { ReactComponent as LocationGraphic } from "./assets/location.svg";
import { ReactComponent as ListGraphic } from "./assets/list.svg";
import { ReactComponent as LinkGraphic } from "./assets/link.svg";
import { ReactComponent as MenuGraphic } from "./assets/menu.svg";
import { ReactComponent as MenuOpenGraphic } from "./assets/menu_open.svg";
import { ReactComponent as NavigateNextGraphic } from "./assets/navigate_next.svg";
import { ReactComponent as NetworkGraphic } from "./assets/network.svg";
import { ReactComponent as NestedGraphic } from "./assets/nested.svg";
import { ReactComponent as PlusGraphic } from "./assets/plus.svg";
import { ReactComponent as PresetsGraphic } from "./assets/pre_sets.svg";
import { ReactComponent as ProjectsGraphic } from "./assets/projects.svg";
import { ReactComponent as SearchGraphic } from "./assets/search.svg";
import { ReactComponent as SpeechBubbleGraphic } from "./assets/speech_bubble.svg";
import { ReactComponent as SettingsGraphic } from "./assets/settings.svg";
import { ReactComponent as SwapVertGraphic } from "./assets/swap_vert.svg";
import { ReactComponent as TriangeDownGraphic } from "./assets/triange_down.svg";
import { ReactComponent as TreeGraphic } from "./assets/tree.svg";
import { ReactComponent as UpDownGraphic } from "./assets/up_down.svg";
import { ReactComponent as UpGraphic } from "./assets/up.svg";
import { ReactComponent as DownGraphic } from "./assets/down.svg";
import { ReactComponent as VisibilityGraphic } from "./assets/visibility.svg";
import { ReactComponent as VisibilityOffGraphic } from "./assets/visibility_off.svg";
import { ReactComponent as XGraphic } from "./assets/x.svg";
import { ReactComponent as UsersGraphic } from "./assets/users.svg";
import { ReactComponent as TrashGraphic } from "./assets/trash.svg";
import { ReactComponent as AlertGraphic } from "./assets/alert.svg";

import styles from "./icons.module.css";

export enum EIconSize {
  MICRO = "micro",
  TINY = "tiny",
  SMALL = "small",
  LARGE = "large",
  DEFAULT = "",
}

export interface IIconProps {
  size?: EIconSize;
}

export interface IBaseIconProps extends IIconProps {
  Component: React.FC<React.SVGProps<SVGSVGElement>>;
}

export function Icon({ Component, size }: IBaseIconProps) {
  const classNames = cn(styles.icon, {
    [styles.iconMicro]: size === EIconSize.MICRO,
    [styles.iconTiny]: size === EIconSize.TINY,
    [styles.iconSmall]: size === EIconSize.SMALL,
    [styles.iconLarge]: size === EIconSize.LARGE,
  });

  return <Component className={classNames} />;
}

export function AccountIcon({ size = EIconSize.LARGE, ...props }: IIconProps) {
  return <Icon {...props} Component={AccountCircleGraphic} size={size} />;
}

export function ArrowDownIcon(props: IIconProps) {
  return <Icon Component={ArrowDownGraphic} {...props} />;
}

export function ArrowUpIcon(props: IIconProps) {
  return <Icon Component={ArrowUpGraphic} {...props} />;
}

export function ArrowUpDownIcon(props: IIconProps) {
  return <Icon Component={ArrowUpDownGraphic} {...props} />;
}

export function ArrowLeftIcon(props: IIconProps) {
  return <Icon Component={ArrowLeftGraphic} {...props} />;
}

export function ArrowRightIcon(props: IIconProps) {
  return <Icon Component={ArrowRightGraphic} {...props} />;
}

export function AlertIcon(props: IIconProps) {
  return <Icon Component={AlertGraphic} {...props} />;
}

export function BackIcon(props: IIconProps) {
  return <Icon Component={BackGraphic} {...props} />;
}

export function BookmarksIcon(props: IIconProps) {
  return <Icon Component={BookmarksGraphic} {...props} />;
}

export function ChartsIcon(props: IIconProps) {
  return <Icon Component={ChartsGraphic} {...props} />;
}

export function CheckIndeterminateIcon(props: IIconProps) {
  return <Icon Component={CheckIndeterminateGraphic} {...props} />;
}

export function CheckMarkIcon(props: IIconProps) {
  return <Icon Component={CheckMarkGraphic} {...props} />;
}

export function CommandIcon(props: IIconProps) {
  return <Icon Component={CommandGraphic} {...props} />;
}

export function EditIcon(props: IIconProps) {
  return <Icon Component={EditGraphic} {...props} />;
}

export function EqualizerIcon(props: IIconProps) {
  return <Icon Component={EqualizerGraphic} {...props} />;
}

export function ExpandLessIcon(props: IIconProps) {
  return <Icon Component={ExpandLessGraphic} {...props} />;
}

export function ExpandMoreIcon(props: IIconProps) {
  return <Icon Component={ExpandMoreGraphic} {...props} />;
}

export function FilterIcon(props: IIconProps) {
  return <Icon Component={FilterGraphic} {...props} />;
}

export function HeatMapIcon(props: IIconProps) {
  return <Icon Component={HeatMapGraphic} {...props} />;
}

export function HexagonMapIcon(props: IIconProps) {
  return <Icon Component={HexagonMapGraphic} {...props} />;
}

export function LineChartIcon(props: IIconProps) {
  return <Icon Component={LineChartGraphic} {...props} />;
}

export function ListIcon(props: IIconProps) {
  return <Icon Component={ListGraphic} {...props} />;
}

export function LinkIcon(props: IIconProps) {
  return <Icon Component={LinkGraphic} {...props} />;
}

export function LocationIcon({ size = EIconSize.SMALL, ...props }: IIconProps) {
  return <Icon Component={LocationGraphic} size={size} {...props} />;
}

export function MenuIcon(props: IIconProps) {
  return <Icon Component={MenuGraphic} {...props} />;
}

export function MenuOpenIcon(props: IIconProps) {
  return <Icon Component={MenuOpenGraphic} {...props} />;
}

export function NavigateNextIcon(props: IIconProps) {
  return <Icon Component={NavigateNextGraphic} {...props} />;
}

export function NetworkIcon(props: IIconProps) {
  return <Icon Component={NetworkGraphic} {...props} />;
}

export function NestedIcon(props: IIconProps) {
  return <Icon Component={NestedGraphic} {...props} />;
}

export function PresetsIcon(props: IIconProps) {
  return <Icon Component={PresetsGraphic} {...props} />;
}

export function ProjectsIcon(props: IIconProps) {
  return <Icon Component={ProjectsGraphic} {...props} />;
}

export function PlusIcon(props: IIconProps) {
  return <Icon Component={PlusGraphic} {...props} />;
}

export function SettingsIcon(props: IIconProps) {
  return <Icon Component={SettingsGraphic} {...props} />;
}

export function SearchIcon(props: IIconProps) {
  return <Icon Component={SearchGraphic} {...props} />;
}

export function SpeechBubbleIcon(props: IIconProps) {
  return <Icon Component={SpeechBubbleGraphic} {...props} />;
}

export function SwapVertIcon(props: IIconProps) {
  return <Icon Component={SwapVertGraphic} {...props} />;
}

export function TriangeDownIcon(props: IIconProps) {
  return <Icon Component={TriangeDownGraphic} {...props} />;
}

export function TreeIcon(props: IIconProps) {
  return <Icon Component={TreeGraphic} {...props} />;
}

export function UpDownIcon(props: IIconProps) {
  return <Icon Component={UpDownGraphic} {...props} />;
}

export function UpIcon(props: IIconProps) {
  return <Icon Component={UpGraphic} {...props} />;
}

export function VisibilityIcon({
  size = EIconSize.SMALL,
  ...props
}: IIconProps) {
  return <Icon Component={VisibilityGraphic} size={size} {...props} />;
}

export function VisibilityOffIcon({
  size = EIconSize.SMALL,
  ...props
}: IIconProps) {
  return <Icon Component={VisibilityOffGraphic} size={size} {...props} />;
}

export function DownIcon(props: IIconProps) {
  return <Icon Component={DownGraphic} {...props} />;
}

export function XIcon(props: IIconProps) {
  return <Icon Component={XGraphic} {...props} />;
}

export function UsersIcon(props: IIconProps) {
  return <Icon Component={UsersGraphic} {...props} />;
}

export function TrashIcon(props: IIconProps) {
  return <Icon Component={TrashGraphic} {...props} />;
}
