import { useEffect, useState } from "react";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import { isValid, formatDistanceToNow } from "date-fns";

import { IStudyComment } from "@syntensor/common/types";
import {
  ArrowRightIcon,
  CheckMarkIcon,
  EIconSize,
  EditIcon,
  XIcon,
} from "@syntensor/common/components/icons";
import Loader from "@syntensor/common/components/loader";

import getCopy from "../../data/copy";
import { insecureStripHtml } from "../../utils/string_utils";
import AddCommentForm from "./add_comment_form";

import styles from "./comment.module.css";

export interface ICommentProps extends IStudyComment {
  needsPageLink?: boolean;
  canManage?: boolean;
  isUpdating?: boolean;
  areRepliesDisplayed?: boolean;
  replies?: IStudyComment[];
  isClickable?: boolean;
  onClick?: (id: string, parentCommentId: string) => void;
  onDelete?: (id: string) => void;
  onEdit?: (id: string, text: string) => void;
  onResolve?: (id: string, isResolved: boolean) => void;
  onToggleRepliesDisplay?: (
    commentId: string,
    areRepliesDisplayed: boolean
  ) => void;
}

export default function Comment({
  id,
  createdBy,
  createdAt,
  text,
  path,
  needsPageLink = false,
  canManage = false,
  areRepliesDisplayed = false,
  parentCommentId = "",
  replies = [],
  isClickable = false,
  isResolved = false,
  isUpdating = false,
  onToggleRepliesDisplay,
  onClick,
  onDelete,
  onEdit,
  onResolve,
}: ICommentProps) {
  const [isEditing, setIsEditing] = useState(false);
  const { displayName } = createdBy || {};

  const handleClick = () => {
    if (isClickable && onClick && typeof onClick === "function") {
      onClick(id, parentCommentId);
    }
  };

  const handleDelete = () => {
    if (onDelete && typeof onDelete === "function") {
      onDelete(id);
    }
  };

  const handleEdit = (text: string) => {
    if (onEdit && typeof onEdit === "function") {
      if (text) {
        onEdit(id, text);
      }
    }
  };

  const handleResolve = () => {
    if (onResolve && typeof onResolve === "function") {
      onResolve(id, !isResolved);
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const toggleShowReplies = () => {
    if (
      onToggleRepliesDisplay &&
      typeof onToggleRepliesDisplay === "function"
    ) {
      onToggleRepliesDisplay(id, !areRepliesDisplayed);
    }
  };

  useEffect(() => {
    if (isUpdating) {
      setIsEditing(false);
    }
  }, [isUpdating]);

  const classNames = cn(styles.comment, {
    [styles.resolved]: isResolved,
    [styles.reply]: !!parentCommentId,
    [styles.clickableComment]: isClickable,
  });
  const btnsClassNames = cn(styles.btns, {
    [styles.btnsUpdating]: isUpdating,
  });
  const btnListClassNames = cn(styles.btnList, {
    [styles.btnListHidden]: isUpdating,
  });

  const createdDate = new Date(createdAt);
  const timeAgo = isValid(createdDate)
    ? formatDistanceToNow(createdDate, { addSuffix: true })
    : "";

  return (
    <>
      <div className={classNames} onClick={handleClick}>
        <div className={styles.inner}>
          <div className={btnsClassNames}>
            {isUpdating && <Loader className={styles.loader} size="22px" />}
            {canManage && (
              <ul className={btnListClassNames}>
                <li>
                  <button
                    className={styles.btn}
                    disabled={isUpdating}
                    onClick={handleResolve}
                  >
                    <CheckMarkIcon size={EIconSize.SMALL} />
                  </button>
                </li>
                <li>
                  <button
                    className={styles.btn}
                    disabled={isUpdating}
                    onClick={handleEditToggle}
                  >
                    <EditIcon size={EIconSize.SMALL} />
                  </button>
                </li>
                <li>
                  <button
                    className={styles.btn}
                    disabled={isUpdating}
                    onClick={handleDelete}
                  >
                    <XIcon size={EIconSize.SMALL} />
                  </button>
                </li>
              </ul>
            )}
          </div>
          <div className={styles.header}>
            <div>{displayName}</div>
            <div>{timeAgo}</div>
          </div>
          <div className={styles.content}>
            <div className={styles.text}>
              {isEditing && (
                <div className={styles.editForm}>
                  <AddCommentForm
                    focusOnMount={true}
                    /* make sure we're not showing highlight spans */
                    text={insecureStripHtml(text)}
                    theme="small"
                    onSubmit={handleEdit}
                  />
                </div>
              )}
              {!isEditing && (
                <div
                  className={styles.textValue}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              )}
            </div>
          </div>
          {!parentCommentId && (
            <div className={styles.footer}>
              <div>
                {!areRepliesDisplayed && (
                  <button
                    className={styles.footerBtn}
                    onClick={toggleShowReplies}
                  >
                    {replies.length === 0 && (
                      <span>{getCopy("studies_comments_reply-cta")}</span>
                    )}
                    {replies.length > 0 && (
                      <span>
                        {getCopy(
                          `studies_comments_reply-count${
                            replies.length > 1 ? "s" : ""
                          }`,
                          {
                            count: replies.length,
                          }
                        )}
                      </span>
                    )}
                  </button>
                )}
                {areRepliesDisplayed && (
                  <button
                    className={styles.footerBtn}
                    onClick={toggleShowReplies}
                  >
                    {replies.length > 0 && (
                      <span>{getCopy("studies_comments_hide-replies")}</span>
                    )}
                    {replies.length === 0 && (
                      <span>{getCopy("studies_comments_hide-reply")}</span>
                    )}
                  </button>
                )}
              </div>
              {needsPageLink && (
                <NavLink to={path} className={styles.footerBtn}>
                  <span>{getCopy("studies_comments_go-to-link")}</span>
                  <ArrowRightIcon size={EIconSize.SMALL} />
                </NavLink>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
