import { NavLink } from "react-router-dom";

import getCopy from "@syntensor/common/data/copy";

import { IsUserInAdminGroup } from "../auth/auth_context";

import styles from "./project_submenu.module.css";

export interface IProjectSubmenuProps {
  projectId: string;
}

export default function ProjectSubmenu({ projectId }: IProjectSubmenuProps) {
  const isUserInAdminGroup = IsUserInAdminGroup();

  return (
    <ul className={styles.submenu}>
      <li className={styles.submenuItem}>
        <NavLink
          exact
          className={styles.projectSubmenuLink}
          activeClassName={styles.linkActive}
          to={`/projects/${projectId}/studies`}
        >
          {getCopy("global_sidebar_project")}
        </NavLink>
      </li>
      {isUserInAdminGroup && (
        <li className={styles.submenuItem}>
          <NavLink
            exact
            className={styles.projectSubmenuLink}
            activeClassName={styles.linkActive}
            to={`/projects/${projectId}/studies/create`}
          >
            {getCopy("global_sidebar_create-new-study")}
          </NavLink>
        </li>
      )}
      {isUserInAdminGroup && (
        <li className={styles.submenuItemDisabled}>
          <NavLink
            exact
            className={styles.projectSubmenuLink}
            activeClassName={styles.linkActive}
            to={`/projects/${projectId}/manage`}
          >
            {getCopy("global_sidebar_manage-project")}
          </NavLink>
        </li>
      )}
      <li className={styles.submenuItemDisabled}>
        <NavLink
          exact
          className={styles.projectSubmenuLink}
          to={`/projects/${projectId}/settings`}
        >
          {getCopy("global_sidebar_settings")}
        </NavLink>
      </li>
    </ul>
  );
}
