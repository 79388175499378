import { useQuery } from "react-query";

import { fetchUsers } from "@syntensor/common/data/fetch_data";
import Loader from "@syntensor/common/components/loader";
import { ICognitoUser } from "@syntensor/common/types";
import Table from "@syntensor/common/components/table";
import Button from "@syntensor/common/components/button";
import getCopy from "@syntensor/common/data/copy";
import Error from "@syntensor/common/components/error";

import { reactQueryOptions } from "../../config";
import { IsUserInAdminGroup } from "../../auth/auth_context";
import DeleteButton from "./delete_user_btn";
import ReinviteButton from "./reinvite_user_btn";

import styles from "./list_users.module.css";

export function renderStatus(value: "CONFIRMED" | "FORCE_CHANGE_PASSWORD") {
  const labelMap = {
    CONFIRMED: "activated",
    FORCE_CHANGE_PASSWORD: "invited",
  };

  const label = labelMap[value] || value;
  return <span>{label}</span>;
}

export function renderActions(_: unknown, row: ICognitoUser) {
  const hasReinviteBtn = row.status === "FORCE_CHANGE_PASSWORD";

  return (
    <div className={styles.actions}>
      {hasReinviteBtn && <ReinviteButton {...row} />}
      <DeleteButton {...row} />
    </div>
  );
}

export default function AdminListUsers() {
  const isUserInAdminGroup = IsUserInAdminGroup();

  const queryKey = ["admin", "users"];
  const {
    data: users = [],
    isLoading,
    error,
  } = useQuery<ICognitoUser[], Error>(queryKey, fetchUsers, {
    ...reactQueryOptions,
    // make sure we get a fresh listing if we've just created a user
    refetchOnMount: "always",
  });

  if (isLoading) {
    return <Loader />;
  }

  if (!isUserInAdminGroup) {
    return <div>You don&apos;t have permission to create users.</div>;
  }

  const tableCols = [
    {
      id: "email",
      name: getCopy("admin_users_list-email"),
    },
    {
      id: "given_name",
      name: getCopy("admin_users_list-first-name"),
    },
    {
      id: "family_name",
      name: getCopy("admin_users_list-last-name"),
    },
    {
      id: "custom:company",
      name: getCopy("admin_users_list-company"),
    },
    {
      id: "custom:role",
      name: getCopy("admin_users_list-role"),
    },
    {
      id: "status",
      name: getCopy("admin_users_list-status"),
      cellRenderer: renderStatus,
    },
    {
      id: "actions",
      name: getCopy("admin_users_list-actions"),
      cellRenderer: renderActions,
    },
  ];

  console.log("users", users);

  return (
    <div className={styles.listUsers}>
      <header className={styles.header}>
        <h1 className={styles.title}>{getCopy("admin_users_title")}</h1>
      </header>
      <div className={styles.content}>
        <div className={styles.contentHeader}>
          <div>
            <h2 className={styles.contentTitle}>
              {getCopy("admin_users_list-title")}
            </h2>
          </div>
          <div>
            <Button role="primary" to="/users/new">
              {getCopy("admin_users_invite")}
            </Button>
          </div>
        </div>
        <div className={styles.error}>{error && <Error error={error} />}</div>
        <div className={styles.table}>
          <Table
            config={{
              cols: tableCols,
            }}
            data={users}
          />
        </div>
      </div>
    </div>
  );
}
