import { createAction, AnyAction } from "@reduxjs/toolkit";
import { IStoreState } from "./types";

export const setIsPathwayHierarchyOpen = createAction<boolean>(
  "SET_IS_PATHWAY_HIERARCHY_OPEN"
);

export const selectUi = (state: IStoreState) => state.ui;

export const selectIsPathwayHierarchyOpen = (state: IStoreState) => {
  const ui = selectUi(state);
  return ui.isPathwayHierarchyOpen;
};

export const initialState = {
  isPathwayHierarchyOpen: false,
};

export default function ui(state = initialState, action: AnyAction) {
  switch (action.type) {
    case setIsPathwayHierarchyOpen.type:
      return {
        ...state,
        isPathwayHierarchyOpen: action.payload,
      };
    default:
      return state;
  }
}
