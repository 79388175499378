import getCopy from "@syntensor/common/data/copy";

export interface IComponentTypeTab {
  id: string;
  to: string;
  exact: boolean;
  isDisabled: boolean;
  label: string;
}

export function getGeneTab(url: string, availableComponentTypes: string[]) {
  return {
    id: "gene",
    to: `${url}/genes`,
    exact: true,
    isDisabled: !availableComponentTypes.includes("gene"),
    label: getCopy("studies_component-detail_associated-genes"),
  };
}

export function getTranscriptTab(
  url: string,
  availableComponentTypes: string[]
) {
  return {
    id: "transcript",
    to: `${url}/transcripts`,
    exact: true,
    isDisabled: !availableComponentTypes.includes("transcript"),
    label: getCopy("studies_component-detail_associated-transcripts"),
  };
}

export function getAssociatedProteinTab(
  url: string,
  availableComponentTypes: string[]
) {
  return {
    id: "protein",
    to: `${url}/proteins`,
    exact: true,
    isDisabled: !availableComponentTypes.includes("protein"),
    label: getCopy("studies_component-detail_associated-proteins"),
  };
}

export function getEncodedProteinTab(
  url: string,
  availableComponentTypes: string[]
) {
  return {
    id: "encoded-protein",
    to: `${url}/encoded-proteins`,
    exact: true,
    isDisabled: !availableComponentTypes.includes("protein"),
    label: getCopy("studies_component-detail_associated-encoded-proteins"),
  };
}

export function getInteractingProteinTab(
  url: string,
  availableComponentTypes: string[]
) {
  return {
    id: "interacting-protein",
    to: `${url}/interacting-proteins`,
    exact: true,
    isDisabled: !availableComponentTypes.includes("protein"),
    label: getCopy("studies_component-detail_associated-interacting-proteins"),
  };
}

export function getComplexTab(url: string, availableComponentTypes: string[]) {
  return {
    id: "complex",
    to: `${url}/complexes`,
    exact: true,
    isDisabled: !availableComponentTypes.includes("complex"),
    label: getCopy("studies_component-detail_associated-complexes"),
  };
}

export function getPathwayTab(url: string) {
  return {
    id: "pathway",
    to: `${url}/pathways`,
    exact: true,
    isDisabled: false,
    label: getCopy("studies_component-detail_associated-pathways"),
  };
}

export function getAssociatedTabsForComponentType(
  componentType: string,
  url: string,
  availableComponentTypes: string[]
) {
  let tabs: IComponentTypeTab[] = [];

  switch (componentType) {
    case "gene":
      tabs = [
        getTranscriptTab(url, availableComponentTypes),
        getEncodedProteinTab(url, availableComponentTypes),
        getInteractingProteinTab(url, availableComponentTypes),
        getComplexTab(url, availableComponentTypes),
        getPathwayTab(url),
      ];
      break;
    case "transcript":
      tabs = [
        getGeneTab(url, availableComponentTypes),
        getEncodedProteinTab(url, availableComponentTypes),
        getInteractingProteinTab(url, availableComponentTypes),
        getComplexTab(url, availableComponentTypes),
        getPathwayTab(url),
      ];
      break;
    case "protein":
      tabs = [
        getGeneTab(url, availableComponentTypes),
        getTranscriptTab(url, availableComponentTypes),
        getInteractingProteinTab(url, availableComponentTypes),
        getComplexTab(url, availableComponentTypes),
        getPathwayTab(url),
      ];
      break;
    case "complex":
      tabs = [
        getAssociatedProteinTab(url, availableComponentTypes),
        getPathwayTab(url),
      ];
      break;
  }

  return tabs;
}
