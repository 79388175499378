import { NavLink, useLocation } from "react-router-dom";

import getCopy from "@syntensor/common/data/copy";
import { IPathway } from "@syntensor/common/types";

import { useStudyUrl } from "../../../hooks/use_routes";
import { getPathwayDetailUrl, getPathwayDetailExplorerUrl } from "../../routes";

import styles from "./breadcrumbs.module.css";

export interface IPathwayHierarchyBreadcrumbsProps {
  parents: IPathway[];
}

export default function PathwayHierarchyBreadcrumbs({
  parents = [],
}: IPathwayHierarchyBreadcrumbsProps) {
  const { pathname } = useLocation();
  const studyUrl = useStudyUrl();

  //  we might be on an explorer page and we want to stay there
  //  when navigating via breadcrumbs to another
  const isExplorer = pathname.endsWith("explorer");

  //  do not mutate array from props
  const breadcrumbs = [...parents].reverse();

  return (
    <>
      {breadcrumbs && (
        <ul className={styles.hierarchyBreadcrumbs}>
          {breadcrumbs.map((parent, i) => {
            const isTopLevel = i === 0;
            if (!studyUrl || !parent.syntensorId) {
              //  not a valid breadcrumb
              return null;
            }

            const breadcrumbUrl = !isExplorer
              ? getPathwayDetailUrl(studyUrl, parent.syntensorId)
              : getPathwayDetailExplorerUrl(studyUrl, parent.syntensorId);

            return (
              <li key={parent.syntensorId} className={styles.item}>
                {isTopLevel && (
                  <span>
                    {getCopy("studies_pathway-detail_category-label", {
                      category: parent.name,
                    })}
                  </span>
                )}
                {!isTopLevel && (
                  <NavLink to={breadcrumbUrl}>{parent.name}</NavLink>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
}
