import { useState } from "react";

import Button from "@syntensor/common/components/button";
import Loader from "@syntensor/common/components/loader";
import { fetchDeleteUser } from "@syntensor/common/data/fetch_data";
import getCopy from "@syntensor/common/data/copy";

import styles from "./list_users.module.css";

export const DEFAULT_MSG = "Delete user";

export interface IDeleteButtonProps {
  sub: string;
  email: string;
}

export default function DeleteButton({ sub, email }: IDeleteButtonProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [btnLabel, setBtnLabel] = useState(DEFAULT_MSG);
  const [errorMsg, setErrorMsg] = useState("");

  const handleDeleteClick = async () => {
    if (sub && email) {
      const isConfirmed = window.confirm(
        getCopy("admin_users_delete-confirm", { email })
      );
      if (isConfirmed) {
        setIsLoading(true);
        try {
          await fetchDeleteUser(sub);
          const successMsg = getCopy("admin_users_delete-done");
          setBtnLabel(successMsg);
          // refresh page
          if (window) {
            window.location.reload();
          }
        } catch (err) {
          const errorMsg = getCopy("admin_users_delete-error", { email });
          console.error(errorMsg);
          console.error(err);
          setErrorMsg(errorMsg);
        }
      }
      setIsLoading(false);
    }
  };

  const role = DEFAULT_MSG === btnLabel ? "secondary" : "white";
  return (
    <div className={styles.deleteButton}>
      {errorMsg && <span className={styles.errorBtnMsg}>{errorMsg}</span>}
      <Button disabled={isLoading} onClick={handleDeleteClick} role={role}>
        {isLoading && <Loader size="32px" />}
        {getCopy("admin_users_delete-user")}
      </Button>
    </div>
  );
}
