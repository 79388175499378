import { Switch, Route, Redirect } from "react-router-dom";

import { EStudyType, IStudyEndpoint } from "@syntensor/common/types";

import StudyOverview from "./overview";
import StudiesPathways from "./pathways";
import StudiesPathwayDetail from "./pathway_detail";
import StudiesComponents from "./components";
import StudiesComponentDetail from "./component_detail";
import StudiesPinned from "./pinned";
import StudyMoa from "./moa";
import StudyPotency from "./endpoints/potency";
import StudyToxicity from "./endpoints/toxicity";

export const STUDY_URL_PREFIX = `/projects/:projectId/studies/:studyId`;

export function getStudyUrl(projectId: string, studyId: string) {
  return STUDY_URL_PREFIX.replace(":projectId", projectId).replace(
    ":studyId",
    studyId
  );
}

export function getComponentsUrl(studyUrl: string) {
  return `${studyUrl}/components`;
}

export function getComponentDetailUrl(studyUrl: string, componentId: string) {
  return `${studyUrl}/components/${componentId}`;
}

export function getPathwayDetailUrl(studyUrl: string, pathwayId: string) {
  return `${studyUrl}/pathways/${pathwayId}`;
}

export function getPathwayDetailExplorerUrl(
  studyUrl: string,
  pathwayId: string
) {
  return `${getPathwayDetailUrl(studyUrl, pathwayId)}/explorer`;
}

export interface IStudiesRoutesProps {
  studyType: EStudyType;
  endpoints?: Record<string, IStudyEndpoint>;
}

export default function StudiesRoutes({
  studyType,
  endpoints,
}: IStudiesRoutesProps) {
  const endpointKeys = endpoints ? Object.keys(endpoints) : [];
  const defaultEndpointRoute =
    studyType === EStudyType.POTENCY
      ? "potency"
      : studyType === EStudyType.TOXICITY && endpointKeys.length > 0
      ? endpointKeys[0]
      : "";

  return (
    <Switch>
      <Route path={`${STUDY_URL_PREFIX}/overview`} component={StudyOverview} />
      <Route path={`${STUDY_URL_PREFIX}/moa`} component={StudyMoa} />

      {/* Potency study type route */}
      <Route
        path={`${STUDY_URL_PREFIX}/endpoints/potency`}
        component={StudyPotency}
      />
      {/* Toxicity study type routes - have generic route */}
      <Route
        path={`${STUDY_URL_PREFIX}/endpoints/:endpointId`}
        component={StudyToxicity}
      />
      {defaultEndpointRoute && (
        <Redirect
          from={`${STUDY_URL_PREFIX}/endpoints`}
          to={`${STUDY_URL_PREFIX}/endpoints/${defaultEndpointRoute}`}
        />
      )}

      <Route
        exact
        path={[
          `${STUDY_URL_PREFIX}/pathways`,
          `${STUDY_URL_PREFIX}/pathways/explorer`,
        ]}
        component={StudiesPathways}
      />
      <Route
        path={`${STUDY_URL_PREFIX}/pathways/:pathwayId`}
        component={StudiesPathwayDetail}
      />

      <Route
        exact
        path={[
          `${STUDY_URL_PREFIX}/components`,
          `${STUDY_URL_PREFIX}/components/(genes|transcripts|proteins|complexes)`,
        ]}
        component={StudiesComponents}
      />
      <Route
        path={`${STUDY_URL_PREFIX}/components/:componentId`}
        component={StudiesComponentDetail}
      />

      <Route path={`${STUDY_URL_PREFIX}/pinned`} component={StudiesPinned} />

      <Redirect from={STUDY_URL_PREFIX} to={`${STUDY_URL_PREFIX}/overview`} />
    </Switch>
  );
}
