import Button from "@syntensor/common/components/button";
import Logo from "@syntensor/common/components/logo";
import {
  ProjectsIcon,
  EqualizerIcon,
  EIconSize,
} from "@syntensor/common/components/icons";
import getCopy from "@syntensor/common/data/copy";

import PrivacyFooter from "../../privacy/privacy_footer";

import styles from "./landing.module.css";

export default function Landing() {
  return (
    <div className={styles.landing}>
      <div className={styles.content}>
        <h1 className={styles.title}>{getCopy("landing_header_title")}</h1>
        <div className={styles.body}>
          <div className={styles.logo}>
            <Logo />
          </div>
          <div className={styles.header}>
            <h2 className={styles.subtitle}>
              {getCopy("landing_content_subtitle")}
            </h2>
            <p
              className={styles.lastUpdated}
              dangerouslySetInnerHTML={{
                __html: getCopy("landing_content_date"),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: getCopy("landing_content_intro"),
              }}
            />
          </div>
          <div className={styles.cols}>
            <div className={styles.col}>
              <div className={styles.section}>
                <h3 className={styles.sectionTitle}>
                  <span className={styles.typeBadge}>Tg</span>
                  {getCopy("landing_first-col_title")}
                </h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: getCopy("landing_first-col_content"),
                  }}
                />
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.section}>
                <h3 className={styles.sectionTitle}>
                  <span className={styles.icon}>
                    <ProjectsIcon size={EIconSize.LARGE} />
                  </span>
                  {getCopy("landing_second-col_title")}
                </h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: getCopy("landing_second-col_content"),
                  }}
                />
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.section}>
                <h3 className={styles.sectionTitle}>
                  <span className={styles.icon}>
                    <EqualizerIcon size={EIconSize.LARGE} />
                  </span>
                  {getCopy("landing_third-col_title")}
                </h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: getCopy("landing_third-col_content"),
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className={styles.accessBtn}>
              <Button
                role="primary"
                to="/projects"
                data-testid="landing_cta-btn"
              >
                {getCopy("landing_cta_label")}
              </Button>
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.copyright}>
              <p>{getCopy("landing_footer_copyright")}</p>
            </div>
            <div className={styles.privacyFooter}>
              <PrivacyFooter hasAccount={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
