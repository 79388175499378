import * as Sentry from "@sentry/react";

export const DEFAULT_SENTRY_CONFIG = {
  dsn: "", // to be passed from envs
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};

export interface ISentryConfig {
  dsn: string;
  [key: string]: unknown;
}

export default function initSentryMonitoring({
  dsn,
  ...config
}: ISentryConfig) {
  console.log(`Initialising sentry monitoring with: ${dsn}.`);
  Sentry.init({
    ...DEFAULT_SENTRY_CONFIG,
    dsn,
    ...config,
  });
}
