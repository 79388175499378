import { Fragment, RefObject, useEffect, useRef, useState } from "react";
import { Core, NodeCollection, NodeSingular } from "cytoscape";

import { ISelectableCompound, ICellLine } from "@syntensor/common/types";

import CytoscapeComponentCompounds from "./compounds";
import { ICytoscapeCallback } from "../cytoscape";

import styles from "./overlay.module.css";

export function updateCanvasPanAndZoom(
  cyt: Core,
  canvasRef: RefObject<HTMLDivElement>
) {
  const pan = cyt.pan();
  const zoom = cyt.zoom();

  const transform = `translate(${pan.x}px,${pan.y}px) scale(${zoom})`;
  if (canvasRef.current) {
    canvasRef.current.style.transform = transform;
  }
}

export function calculateTransformFromPosition(
  position: [number, number],
  offset: number[] = [24, -12]
) {
  const x = position[0].toFixed(2);
  const y = position[1].toFixed(2);

  //  add offset straight on the parent component (as oppose on child)
  //  for the div overlay not to get in the way of the canvas
  //  mouseovers below
  const translateX = parseFloat(x) + offset[0];
  const translateY = parseFloat(y) + offset[1];
  const transform = `translate(${translateX}px, ${translateY}px)`;

  return transform;
}

export interface ICytoscapeOverlayProps {
  cyt: Core;
  compounds?: ISelectableCompound[];
  cellLines?: ICellLine[];
  callbacks?: Record<string, ICytoscapeCallback>;
  displayOnHover?: boolean;
}

export const DEFAULT_TRANSFORM = [-24, -14];
export const DEFAULT_LABEL_TRANSFORM = [-12, 0];

export default function CytoscapeOverlay({
  cyt,
  compounds = [],
  cellLines = [],
  callbacks,
  displayOnHover = false,
}: ICytoscapeOverlayProps) {
  const canvasRef = useRef<HTMLDivElement>(null);
  const componentsRefs = useRef<Record<string, HTMLDivElement>>({});
  const [selectedCompoundId, setSelectedCompoundId] = useState<string>("");

  cyt.on("remove", "node", (evt) => {
    //  remove the component from references
    const cyNode = evt.target;
    const id = cyNode.id();
    delete componentsRefs.current[id];
  });

  cyt.on("pan zoom", () => {
    updateCanvasPanAndZoom(cyt, canvasRef);
  });

  const updateLabelPosition = (cyNode: NodeSingular) => {
    const id = cyNode.id();
    const dom = componentsRefs.current[id];
    if (dom) {
      const position = cyNode.position();
      const transform = calculateTransformFromPosition(
        [position.x, position.y],
        DEFAULT_TRANSFORM
      );
      dom.style.transform = transform;
      dom.style.visibility = "visible";
    }
  };

  cyt.on("position bounds", "node", (evt) => {
    const cyNode = evt.target;
    updateLabelPosition(cyNode);
  });

  useEffect(() => {
    //  initial position of the canvas
    updateCanvasPanAndZoom(cyt, canvasRef);

    cyt.elements().forEach(updateLabelPosition);
  }, [cyt]);

  //  display for complexes only
  const componentsToDisplay: NodeCollection = cyt.nodes().filter((node) => {
    const hasInference =
      node.data("syntensor") && node.data("syntensor").simulations;
    return hasInference && node.data("class") !== "hidden";
  });

  const handleMouseOver = (compoundId: string, node: NodeSingular) => {
    setSelectedCompoundId(compoundId);

    if (callbacks && callbacks.onNodeMouseOver) {
      callbacks.onNodeMouseOver(node, node.renderedPosition());
    }
  };
  const handleMouseOut = () => {
    setSelectedCompoundId("");

    if (callbacks && callbacks.onNodeMouseOut) {
      callbacks.onNodeMouseOut();
    }
  };
  const handleClick = (node: NodeSingular) => {
    if (callbacks && callbacks.onNodeClick) {
      callbacks.onNodeClick(node);
    }
  };

  return (
    <div ref={canvasRef} className={styles.overlay}>
      {componentsToDisplay &&
        componentsToDisplay.map((comp) => {
          const position = comp.position();
          const data = comp.data();
          const id = comp.id();

          const transform = calculateTransformFromPosition(
            [position.x, position.y],
            DEFAULT_TRANSFORM
          );

          return (
            <Fragment key={id}>
              <div
                data-testid="cytoscape-node-with-data"
                className={styles.componentWrapper}
                style={{ transform }}
                ref={(el) => {
                  if (el) {
                    componentsRefs.current[comp.id()] = el;
                  }
                }}
                onClick={() => handleClick(comp)}
              >
                <div className={styles.component}>
                  <CytoscapeComponentCompounds
                    node={comp}
                    compounds={compounds}
                    cellLines={cellLines}
                    perturbation={data.syntensor.simulations.perturbation}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    selectedCompoundId={selectedCompoundId}
                    displayOnHover={displayOnHover}
                  />
                </div>
              </div>
            </Fragment>
          );
        })}
    </div>
  );
}
