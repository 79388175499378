import Button from "@syntensor/common/components/button";
import { ICompoundDefinition } from "@syntensor/common/types";
import getCopy from "@syntensor/common/data/copy";

import NewCompoundForm from "./compound";

import styles from "./compounds.module.css";

export interface ICreateNewStudyCompoundsFormProps {
  onChange: (compounds: ICompoundDefinition[]) => void;
  validateSmiles: (index: number, smiles: string) => void;
  compounds: ICompoundDefinition[];
  isDisabled?: boolean;
}

export default function CreateNewStudyCompoundsForm({
  onChange,
  validateSmiles,
  compounds = [],
  isDisabled = false,
}: ICreateNewStudyCompoundsFormProps) {
  const handleCompoundChange = (
    index: number,
    compound: ICompoundDefinition
  ) => {
    const newCompounds = compounds.map((c, i) => {
      if (i === index) {
        return { ...compound };
      }
      return c;
    });

    onChange(newCompounds);
  };

  const handleRemoveCompound = (index: number) => {
    const confirmed = window.confirm(
      getCopy("studies_create_compound-delete-confirmation")
    );
    if (confirmed) {
      const newCompounds = [...compounds];
      newCompounds.splice(index, 1);
      onChange(newCompounds);
    }
  };

  const handleAddEndpoint = () => {
    const newCompounds = [...compounds];
    newCompounds.push({ name: "", smiles: "" });
    onChange(newCompounds);
  };

  const handleSmilesEdit = (index: number, smiles: string) => {
    validateSmiles(index, smiles);
  };

  return (
    <div className={styles.compoundFormSection}>
      <h3 className={styles.formTitle}>
        {getCopy("studies_create_compounds")}
      </h3>
      <div className={styles.compounds}>
        <>
          {compounds.map((compound, index: number) => {
            return (
              <div key={index} className={styles.compound}>
                <NewCompoundForm
                  key={index}
                  compound={compound}
                  index={index}
                  onSmilesEdit={handleSmilesEdit}
                  onChange={handleCompoundChange}
                  onDelete={handleRemoveCompound}
                  isDisabled={isDisabled}
                />
              </div>
            );
          })}
        </>

        <div className={styles.compoundEmpty}>
          <Button
            className={styles.addCompoundBtn}
            onClick={handleAddEndpoint}
            role="subtle"
          >
            {getCopy("studies_create_add-compound")}
          </Button>
        </div>
      </div>
      <div className={styles.formFooter}></div>
    </div>
  );
}
