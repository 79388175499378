import {
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import cn from "classnames";
import Markdown from "react-markdown";

import {
  CheckMarkIcon,
  EIconSize,
  EditIcon,
  XIcon,
} from "@syntensor/common/components/icons";

import Loader from "../loader";

import styles from "./editable_text_field.module.css";

export interface IEditableTextFieldProps {
  value: string;
  placeholder: string;
  isEditEnabled?: boolean;
  updateAction: (newValue: string) => Promise<unknown>;
}

export default function EditableTextField({
  placeholder,
  value,
  updateAction,
  isEditEnabled = true,
}: IEditableTextFieldProps) {
  const isDirty = useRef(false);
  const formEl = useRef<HTMLFormElement | null>(null);
  const textAreaEl = useRef<HTMLTextAreaElement | null>(null);
  const [currValue, setCurrValue] = useState(value);
  const [prevValue, setPrevValue] = useState(value);
  const [isEdited, setIsEdited] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isSubmitting, setIsSubmitted] = useState(false);

  const handleChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    setCurrValue(evt.target.value);
    isDirty.current = true;
  };

  const handleEdit = () => {
    setIsEdited(true);
  };

  const cancelUpdate = () => {
    setCurrValue(prevValue);
    setIsEdited(false);
  };

  const handleKeyDown = (evt: KeyboardEvent<HTMLFormElement>) => {
    if (evt.key === "Escape") {
      cancelUpdate();
    }

    if (evt.key === "Enter" && evt.shiftKey) {
      evt.preventDefault();
      handleUpdateText(evt);
    }
  };

  const handleUpdateText = async (evt: FormEvent | KeyboardEvent) => {
    evt.preventDefault();

    if (!formEl.current) {
      return null;
    }

    const text = formEl.current.text.value;
    if (updateAction && typeof updateAction === "function") {
      setIsSubmitted(true);

      try {
        await updateAction(text);
        setPrevValue(currValue);
        setHasError(false);
        setIsEdited(false);
      } catch (err) {
        console.error(err);
        setHasError(true);
      }

      setIsSubmitted(false);
    }
  };

  useEffect(() => {
    if (!isDirty.current) {
      setCurrValue(value);
      setPrevValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (textAreaEl.current) {
      // reset the height to 'auto' to get the correct scrollHeight
      textAreaEl.current.style.height = "auto";
      textAreaEl.current.style.height = `${textAreaEl.current.scrollHeight}px`;
    }
  }, [value, isEdited]);

  const classNames = cn(styles.editableTextField, {
    [styles.hasError]: hasError,
  });

  const text = currValue || placeholder;

  return (
    <div className={classNames}>
      {!isEdited && (
        <div className={styles.value}>
          <Markdown>{text}</Markdown>
          {isEditEnabled && (
            <div className={styles.btns}>
              <button className={styles.editBtn} onClick={handleEdit}>
                <EditIcon size={EIconSize.SMALL} />
              </button>
            </div>
          )}
        </div>
      )}
      {isEdited && (
        <form
          ref={formEl}
          onSubmit={handleUpdateText}
          onKeyDown={handleKeyDown}
        >
          <textarea
            ref={textAreaEl}
            name="text"
            placeholder={placeholder}
            className={styles.textArea}
            value={currValue}
            onChange={handleChange}
          />
          <div className={styles.btns}>
            {isSubmitting && (
              <div className={styles.loader}>
                <Loader size="1.111rem" />
              </div>
            )}
            {!isSubmitting && (
              <>
                <button
                  className={styles.submitBtn}
                  // disabled={!isSubmitEnabled}
                  type="submit"
                >
                  <CheckMarkIcon size={EIconSize.SMALL} />
                </button>
                <button className={styles.cancelBtn} onClick={cancelUpdate}>
                  <XIcon size={EIconSize.SMALL} />
                </button>
              </>
            )}
          </div>
        </form>
      )}
    </div>
  );
}
