export function capitalize(s: unknown) {
  if (typeof s !== "string") {
    return "";
  }

  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function lowerise(s: unknown) {
  if (typeof s !== "string") {
    return "";
  }

  return s.charAt(0).toLowerCase() + s.slice(1);
}

export function convertStringToId(str: string): string {
  // Replace all spaces with dashes
  return str.replace(/\s+/g, "-");
}

export function insecureStripHtml(str: string): string {
  //  must be used only for trusted input
  return str.replace(/<[^>]*>?/gm, "");
}
