import cytoscape, {
  Core,
  ElementsDefinition,
  EventObject,
  NodeCollection,
  NodeSingular,
  Stylesheet,
} from "cytoscape";
import SyntensorCoseLayout from "./layouts/syntensor_cose_layout";

export function getVisibleNodes(cyt: Core) {
  const fn = (ele: NodeSingular) => {
    return !ele.hasClass("hidden");
  };
  return cyt.nodes().filter(fn);
}

export function fitViewportToVisible(cyt: Core, padding = 10 * 10) {
  cyt.fit(getVisibleNodes(cyt), padding);
}

export const INITIAL_HIDE = false;

export interface ICytoscapeCallback {
  (
    node?: NodeSingular,
    position?: { x: number; y: number },
    evt?: EventObject
  ): void;
}

export interface ICytoscapeOptions {
  graph: ElementsDefinition;
  style?: Stylesheet[];
  layoutId?: string;
  callbacks?: Record<string, ICytoscapeCallback>;
  dynamicLayout?: boolean;
}

export function initCytoscape({
  graph,
  style = [],
  layoutId = "preset",
  callbacks = {},
  dynamicLayout = false,
}: ICytoscapeOptions): Core | null {
  const cyGraph = graph;
  cytoscape("layout", "syntensor-cose", SyntensorCoseLayout);

  const layout = { name: layoutId };

  try {
    const cyt = cytoscape({
      container: document.getElementById("cy"),
      elements: cyGraph,
      layout,
      style,
    });
    const cytContainer = cyt.container();
    // cyt.domNode();

    const nodeSuccessors = cyt
      .nodes()
      .reduce<Record<string, NodeCollection>>((acc, node) => {
        const id = node.data("id");
        const outgoers = node.outgoers("node");
        const edges = node.edgesTo(outgoers);
        const firstDegreeSuccessors = outgoers.union(edges);

        acc[id] = firstDegreeSuccessors;
        return acc;
      }, {});

    //  find root element
    const root = cyt.nodes().roots().first();

    if (INITIAL_HIDE) {
      //  hide everything but the first degree successors
      nodeSuccessors[root.data("id")]
        .union(root)
        .absoluteComplement()
        .addClass("hidden");
    }
    //fitViewportToVisible(cyt);

    //  lock nodes which have a position
    const nodesWithPosition = (cyt.nodes() as unknown as NodeSingular[]).filter(
      (ele) => {
        const hasPosition = ele.position().x !== 0 || ele.position().y;
        return hasPosition;
      }
    );
    nodesWithPosition.forEach((el) => el.lock());

    //  fit into view all
    //fitViewportToVisible(cyt);

    //  do we have some unpositioned nodes for whatever reason
    //  if we do, we need to run the dynamic layout
    const needsDynamicLayout = nodesWithPosition.length !== cyt.nodes().length;
    if (dynamicLayout || needsDynamicLayout) {
      cyt
        .elements()
        .layout({
          name: "syntensor-cose",
        })
        .run();
    }

    cyt.on("mouseover", "node", (evt) => {
      const node = evt.target as NodeSingular;
      const position = node.renderedPosition();

      if (callbacks && callbacks.onNodeMouseOver) {
        callbacks.onNodeMouseOver(node, position, evt);
      }

      if (cytContainer && node.data("class") !== "compartment") {
        cytContainer.style.cursor = "pointer";
        // node.style("border-width", 10);
        // node.style("background-opacity", 1);
      }
    });

    cyt.on("mouseout", "node", (evt) => {
      const node = evt.target as NodeSingular;
      const position = node.renderedPosition();

      if (callbacks && callbacks.onNodeMouseOut) {
        callbacks.onNodeMouseOut(node, position, evt);
      }

      if (cytContainer && node.data("class") !== "compartment") {
        cytContainer.style.cursor = "default";
        node.style("border-width", 1);
      }
    });

    cyt.on("click", "node", (evt) => {
      const node = evt.target as NodeSingular;
      const position = node.renderedPosition();

      if (callbacks && callbacks.onNodeClick) {
        callbacks.onNodeClick(node, position, evt);
      }
    });

    cyt.on("dblclick", "node", (evt) => {
      const node = evt.target as NodeSingular;
      const position = node.renderedPosition();

      if (callbacks && callbacks.onNodeClick) {
        callbacks.onNodeDblClick(node, position, evt);
      }

      // debugger;
      // const astar = cyt
      //   .nodes()
      //   .aStar({ root, goal: node, weight: () => 1, directed: false });
      // console.log("astar 2", astar);

      //  css implementation of hiding
      const successors = nodeSuccessors[node.data("id")];
      if (successors) {
        if (successors.filter(".hidden").length === 0) {
          node.successors().addClass("hidden");
        } else {
          //  get collection from the cache
          successors.removeClass("hidden");
        }
      }
    });

    return cyt;
  } catch (err) {
    console.error("Error creating cytoscape.js graph");
    console.error(err);
  }

  return null;
}
