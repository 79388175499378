import Fuse from "fuse.js";

export const DEFAULT_SEARCH_OPTIONS = {
  includeScore: true,
  threshold: 0.1,
  keys: ["name"],
  ignoreLocation: true,
};

export function matchSearch(
  input: string,
  searchCollection: unknown[],
  options = DEFAULT_SEARCH_OPTIONS
) {
  const fuse = new Fuse(searchCollection, options);
  const searchResults = fuse.search(input);
  return searchResults.map((result) => {
    return result.item;
  });
}

export function escapeRegExpString(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function highlightMatchTerm(name: string, searchString: string) {
  const escapedSearchString = escapeRegExpString(searchString);

  //  do match&replace in a way that preservers the case of the original string
  const re = new RegExp(escapedSearchString, "gi");
  const matches = name.match(re);
  return name && matches && matches.length
    ? name.replace(re, `<span>${matches[0]}</span>`)
    : name;
}

export function escapeRegex(string: string) {
  return string.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
}

export function filterItemsBySearch(
  items: unknown[],
  search: string,
  threshold = 0.25
): unknown[] {
  if (!items) {
    return items;
  }

  //  using fuzzy search to allow for typos
  const matchOptions = { ...DEFAULT_SEARCH_OPTIONS, threshold };
  return matchSearch(search, items, matchOptions);
}
