import getCopy from "../../data/copy";
import Button from "../button";

export interface ILoadMoreBtnProps {
  fetchNextPage: () => void;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
}

export default function LoadMoreBtn({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}: ILoadMoreBtnProps) {
  return (
    <Button
      data-testid="load-more-btn"
      role="light"
      onClick={() => fetchNextPage()}
      disabled={!hasNextPage || isFetchingNextPage}
    >
      {isFetchingNextPage
        ? getCopy("studies_load-more_loading")
        : hasNextPage
        ? getCopy("studies_load-more_label")
        : getCopy("studies_load-more_none")}
    </Button>
  );
}
