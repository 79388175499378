export function isEmpty(variable: unknown) {
  return typeof variable === "undefined" || variable === null;
}

export function isNotEmpty(variable: unknown) {
  return !isEmpty(variable);
}

export function isObject(obj: unknown) {
  return obj !== null && typeof obj === "object" && !Array.isArray(obj);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(
  target: Record<string, unknown>,
  ...sources: Record<string, unknown>[]
): Record<string, unknown> {
  if (!sources.length || !Array.isArray(sources)) {
    return target;
  }
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        mergeDeep(
          target[key] as Record<string, unknown>,
          source[key] as Record<string, unknown>
        );
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getPropValue(sourceObject: any, dotNotationPath: string) {
  let returnData = sourceObject;

  dotNotationPath.split(".").forEach((subPath) => {
    returnData = returnData[subPath] || "";
  });

  return returnData;
}

export function groupByKey(array: { key: string; value: unknown }[]) {
  return array.reduce<Record<string, unknown>>((acc, value) => {
    acc[value.key] = value.value;
    return acc;
  }, {});
}
