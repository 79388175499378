import { MouseEvent } from "react";
import { NavLink, useHistory } from "react-router-dom";

import getCopy from "@syntensor/common/data/copy";

import { navigateToConsentPopup } from "./privacy_content";

import styles from "./privacy_footer.module.css";

export default function InfoPrivacyFooter({
  hasAccount = false,
}: {
  hasAccount?: boolean;
}) {
  const history = useHistory();

  const handleManageConsentClick = (evt: MouseEvent) => {
    evt.preventDefault();
    navigateToConsentPopup(history);
  };

  return (
    <footer className={styles.privacyFooter}>
      <div className={styles.footerCol}>
        <h3 className={styles.subtitle}>{getCopy("profile_footer_title")}</h3>
        <ul className={styles.list}>
          <li>
            <a className={styles.listLink} href="mailto:info@syntensor.com">
              {getCopy("profile_footer_info-email")}
            </a>
          </li>
          <li>
            <a className={styles.listLink} href="mailto:founders@syntensor.com">
              {getCopy("profile_footer_founders-email")}
            </a>
          </li>
        </ul>
      </div>
      <div className={styles.footerCol}>
        <h3 className={styles.subtitle}>
          {getCopy("profile_footer_subtitle")}
        </h3>
        <ul className={styles.list}>
          <li>
            <NavLink className={styles.listLink} to={`/privacy/terms`}>
              {getCopy("profile_footer_terms")}
            </NavLink>
          </li>
          <li>
            <NavLink className={styles.listLink} to={`/privacy/policy`}>
              {getCopy("profile_footer_privacy-policy")}
            </NavLink>
          </li>
        </ul>
      </div>
      {hasAccount && (
        <div className={styles.footerCol}>
          <h3 className={styles.subtitle}>
            {getCopy("profile_footer_account-title")}
          </h3>
          <ul className={styles.list}>
            <li>
              <a
                className={styles.linkHighlight}
                href={getCopy("profile_footer_delete-account-msg")}
              >
                {getCopy("profile_footer_delete-account")}
              </a>
            </li>
            <li>
              <a
                className={styles.listLink}
                href="/#popup"
                onClick={handleManageConsentClick}
              >
                Manage Consent
              </a>
            </li>
          </ul>
        </div>
      )}
    </footer>
  );
}
