import cn from "classnames";

import getCopy from "@syntensor/common/data/copy";

import { TPathwayDetailExplorerLegendLookup, resolveName } from "./index";

import styles from "./legend.module.css";

export default function EdgesSection(
  edges: TPathwayDetailExplorerLegendLookup
) {
  return (
    <div className={styles.section}>
      <div className={styles.sectionTitle}>
        {getCopy("studies_pathway-explorer-legend_edges")}
      </div>
      <div className={styles.sectionContent}>
        {edges &&
          Object.entries(edges).map(([edge, names]) => {
            const symbolClassName = cn(styles.edgeGraphicSymbol, {
              [styles.veeGraphicSymbol]: edge === "vee",
              [styles.teeGraphicSymbol]: edge === "tee",
              [styles.circleGraphicSymbol]: edge === "circle",
            });

            //  map Reactome names to a potential custom nomenclature
            //  or leave as is if no custom nomenclature is available
            const resolvedNames = names.map(resolveName);

            return (
              <div key={edge} className={styles.edge}>
                <span className={styles.edgeGraphic}>
                  <span className={styles.edgeGraphicLine} />
                  <span className={symbolClassName} />
                </span>
                <span className={styles.label}>{resolvedNames.join(", ")}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
}
