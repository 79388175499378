import { getRendererForType } from "./renderers";

import styles from "./suggestions_list.module.css";

export const MAX_DISPLAY = 20;

export interface ISuggestionsListProps<SuggestionType = unknown> {
  suggestions: SuggestionType[] | null;
  selectedIndex: number;
  onItemSelected?: (item: SuggestionType) => void;
  maxDisplay?: number;
}

export default function SuggestionsList<SuggestionType>({
  suggestions = [],
  selectedIndex,
  onItemSelected,
  maxDisplay = MAX_DISPLAY,
}: ISuggestionsListProps<SuggestionType>) {
  const handleSuggestionClick = (suggestion: SuggestionType) => {
    if (!suggestion) {
      return;
    }

    if (onItemSelected) {
      onItemSelected(suggestion);
    }
  };

  const suggestionsToDisplay = suggestions
    ? suggestions.slice(0, maxDisplay)
    : null;

  const hasNoResults =
    suggestionsToDisplay && suggestionsToDisplay.length === 0;
  if (hasNoResults) {
    suggestionsToDisplay.push({
      id: "noResults",
      type: "noResults",
    } as SuggestionType);
  }

  return (
    <div className={styles.suggestionsList}>
      {suggestionsToDisplay && (
        <ul className={styles.list}>
          {suggestionsToDisplay &&
            suggestionsToDisplay.map((suggest: SuggestionType, i: number) => {
              if (!suggest) {
                console.error(`Null suggestion at ${i}`);
                return null;
              }

              let key = i.toString();
              let type = "";
              if (typeof suggest === "object") {
                if ("type" in suggest && typeof suggest.type === "string") {
                  type = suggest.type;
                }

                if ("id" in suggest && typeof suggest.id === "string") {
                  key = suggest.id;

                  if (
                    "compoundId" in suggest &&
                    typeof suggest.compoundId === "string"
                  ) {
                    key += `_${suggest.compoundId}`;
                  }
                }
              }

              const isSelected = selectedIndex === i;
              const itemClasses = [
                styles.item,
                isSelected && styles.itemSelected,
              ];

              //  suggestions might be of different type
              //  so pick the most appropriate renderer
              const Renderer = getRendererForType(type);
              if (!Renderer) {
                return null;
              }

              return (
                <li
                  key={key}
                  className={itemClasses.join(" ")}
                  onClick={() => handleSuggestionClick(suggest)}
                  data-testid="suggestions_list-item"
                >
                  <Renderer {...suggest} />
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
}
