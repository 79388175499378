import { createContext, useCallback, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";

import Error from "@syntensor/common/components/error";
import Loader from "@syntensor/common/components/loader";
import {
  fetchMetaPathways,
  fetchProject,
  fetchStudy,
} from "@syntensor/common/data/fetch_data";
import {
  IStudyContextData,
  IStudyContextMeta,
  IStudyResolved,
  IProject,
} from "@syntensor/common/types";

import StudiesRoutes from "./routes";
import Header from "./header";
import { reactQueryOptions } from "../config";
import { IStudyMatchParams } from "./types";
import { ProjectDataContext } from "../projects/project";
import Search from "./search";
import Comments from "../comments";

import styles from "./studies.module.css";

export const StudyDataContext = createContext<IStudyContextData | null>(null);
export const StudyMetaContext = createContext<IStudyContextMeta | null>(null);

export default function Study() {
  const queryClient = useQueryClient();
  const [isSearchOn, setIsSearchOn] = useState(false);

  const { params } = useRouteMatch<IStudyMatchParams>();
  const { projectId, studyId } = params;

  const handleSearchClick = useCallback(() => {
    setIsSearchOn(true);
  }, []);

  const handleSearchCancel = useCallback(() => {
    setIsSearchOn(false);
  }, []);

  const projectQueryKey = ["projects", projectId];
  const {
    data: projectData,
    isLoading: projectIsLoading,
    error: projectError,
  } = useQuery<IProject>(
    projectQueryKey,
    async () => {
      return await fetchProject(projectId);
    },
    reactQueryOptions
  );

  const refreshStudyData = () => {
    //  wait for 1 second before invalidating the query
    //  to allow for ES refresh interval
    setTimeout(() => {
      queryClient.invalidateQueries({
        queryKey: ["studies", studyId],
      });
    }, 1000);
  };

  const queryKey = ["studies", studyId];
  const {
    data: studyData,
    isLoading,
    error,
  } = useQuery<IStudyResolved>(
    queryKey,
    async () => {
      return await fetchStudy(studyId);
    },
    reactQueryOptions
  );

  const { pathways: pathwaysVersionId /*ers: ersVersionId */ } =
    studyData?.dataVersions || {};

  const metaQueryKey = ["meta", pathwaysVersionId];
  const { data: metaData = null, error: metaError } = useQuery(
    metaQueryKey,
    async () => {
      if (!pathwaysVersionId) {
        //  we don't know which version to fetch yet
        //  bail early
        return Promise.resolve(null);
      }

      const pathways = await fetchMetaPathways(pathwaysVersionId);

      return {
        pathways,
      };
    },
    reactQueryOptions
  );

  if (isLoading && projectIsLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error error={error} />;
  }
  if (projectError) {
    return <Error error={projectError} />;
  }
  if (metaError) {
    return <Error error={metaError} />;
  }

  if (!studyData || !projectData) {
    return null;
  }

  return (
    <ProjectDataContext.Provider value={projectData}>
      <StudyDataContext.Provider value={{ ...studyData, refreshStudyData }}>
        <StudyMetaContext.Provider value={metaData}>
          <div className={styles.study}>
            <div className={styles.header}>
              <Header
                studyType={studyData.type}
                onSearchClick={handleSearchClick}
              />
              {isSearchOn && (
                <div className={styles.search}>
                  <Search onSearchCancel={handleSearchCancel} />
                </div>
              )}
            </div>
            <div className={styles.content}>
              {studyData.type && (
                <StudiesRoutes
                  studyType={studyData.type}
                  endpoints={studyData.endpoints}
                />
              )}
            </div>
          </div>
          <div className={styles.comments}>
            <Comments />
          </div>
        </StudyMetaContext.Provider>
      </StudyDataContext.Provider>
    </ProjectDataContext.Provider>
  );
}
