import { ReactNode } from "react";
import { scaleLinear } from "d3-scale";
import { format } from "d3-format";
import cn from "classnames";
import { Column } from "react-data-grid";

import {
  ESortDir,
  IStudyCompound,
  IStudyComponent,
} from "@syntensor/common/types";
import NameCell from "@syntensor/common/components/data_grid/name_cell";
import SortableHeaderCell from "@syntensor/common/components/data_grid/sortable_header_cell";
import { getPropValue } from "@syntensor/common/utils/obj_utils";
import getCopy from "@syntensor/common/data/copy";
import {
  IDataGridColumn,
  EDataGridColumnType,
} from "@syntensor/common/components/data_grid_columns";

import {
  getComponentDetailUrl,
  getPathwayDetailUrl,
  getStudyUrl,
} from "../routes";

import styles from "../studies.module.css";

export const GENE_MAX = 5;
export const GENE_COLORS_SCALE = scaleLinear<string>()
  .domain([-1 * GENE_MAX, 0, GENE_MAX])
  .range(["#1343EE", "#FFFFFF", "#F0532A"])
  .unknown("#ccc")
  .clamp(true);

export function isGeneDarkColor(value: number) {
  const diff = GENE_MAX - Math.abs(value);
  return diff < 1.5;
}

export const PROTEIN_MAX = 50;
export const PROTEIN_COLORS_SCALE = scaleLinear<string, string, string>()
  .domain([PROTEIN_MAX, 1])
  .range(["#FFFFFF", "#a54ddb"])
  .unknown("#ccc")
  .clamp(true);

export function isProteinDarkColor(value: number) {
  if (!value) {
    return false;
  }

  const diff = PROTEIN_MAX - Math.abs(value);
  return diff > 25;
}

export const VALUE_FORMATTER = format(".4f");

export function getSortableHeaderRenderer({
  sortBy = "",
  sortDir = "",
  onSortClick,
  isSortable = true,
  isMultiDir = true,
  beforeContent = null,
  afterContent = null,
}: {
  sortBy: string;
  sortDir: string;
  onSortClick: (sortBy: string, sortDir: ESortDir) => void;
  beforeContent?: ReactNode;
  afterContent?: ReactNode;
  isSortable?: boolean;
  isMultiDir?: boolean;
}) {
  const SortableHeaderRenderer = ({
    column,
  }: {
    column: Column<string, string>;
  }) => {
    return (
      <SortableHeaderCell
        id={column.key}
        isSortable={isSortable}
        isMultiDir={isMultiDir}
        sortDir={sortDir}
        sortBy={sortBy}
        onSortClick={onSortClick}
      >
        {beforeContent}
        {column.name}
        {afterContent}
      </SortableHeaderCell>
    );
  };
  return SortableHeaderRenderer;
}

export function getCompoundColName(compound: IStudyCompound, hasDosage = true) {
  let compoundName = hasDosage
    ? `${compound.name} ${compound.dose}${getCopy(
        "studies_components_col-dose-unit"
      )}`
    : `${compound.name}`;
  if (compound.control) {
    const compoundControl =
      compound.control === "+"
        ? getCopy("studies_components_col-positive-control-compound")
        : getCopy("studies_components_col-negative-control-compound");
    compoundName += compoundControl;
  }

  return compoundName;
}

export function getComponentsColumns({
  cellLines,
  compounds,
  projectId,
  studyId,
  sortBy,
  sortDir,
  onSortClick,
  includeComponentType = false,
}: {
  cellLines: string[];
  componentType?: string;
  compounds: IStudyCompound[];
  projectId: string;
  studyId: string;
  sortBy: string;
  sortDir: string;
  onSortClick: (sortBy: string, sortDir: ESortDir) => void;
  includeComponentType?: boolean;
}) {
  const columns: IDataGridColumn[] = [
    {
      key: "displayName",
      name: getCopy("studies_components_col-display-name"),
      width: 450,
      frozen: true,
      headerRenderer: getSortableHeaderRenderer({
        sortDir,
        sortBy,
        onSortClick,
      }),
      formatter({ row = {} }: { row: Partial<IStudyComponent> }) {
        const studyUrl = getStudyUrl(projectId, studyId);
        const isPathway = row.componentType === "pathway";
        if (!row.syntensorId) {
          return null;
        }

        const url = isPathway
          ? getPathwayDetailUrl(studyUrl, row.syntensorId)
          : getComponentDetailUrl(studyUrl, row.syntensorId);
        return <NameCell {...row} url={url} />;
      },
      isFilterable: false,
    },
  ];

  columns.push({
    key: "componentType",
    name: getCopy("studies_components_col-component-type"),
    isEnabled: includeComponentType,
    options: [
      {
        id: "gene",
        name: getCopy("studies_components_genes"),
      },
      {
        id: "transcript",
        name: getCopy("studies_components_transcripts"),
      },
      {
        id: "protein",
        name: getCopy("studies_components_proteins"),
      },
    ],
    type: EDataGridColumnType.MULTISELECT,
    isFilterable: false, // we probably already have a navigation subtab for filtering by component type
  });

  columns.push({
    key: "compartment",
    name: getCopy("studies_components_col-compartment"),
    isEnabled: false,
    options: [
      {
        id: "c",
        name: "cytosol",
      },
      {
        id: "n",
        name: "nucleus",
      },
      {
        id: "l",
        name: "lysosome",
      },
      { id: "m", name: "mitochondria" },
      {
        id: "r",
        name: "endoplasmic reticulum",
      },
      {
        id: "e",
        name: "extracellular space",
      },
      {
        id: "x",
        name: "peroxisome;glyoxysome",
      },
      { id: "g", name: "golgi apparatus" },
      { id: "i", name: "inner mitochondrial compartment" },
      { id: "v", name: "vesicle" },
      { id: "b", name: "lipid membrane" },
    ],
    type: EDataGridColumnType.MULTISELECT,
  });

  cellLines.forEach((cellLine) => {
    columns.push({
      key: `cellLinesExpressionMetadata.${cellLine}`,
      isEnabled: true,
      width: 150,
      name: getCopy("studies_components_col-baseline-expression"),
      headerRenderer: getSortableHeaderRenderer({
        sortDir,
        sortBy,
        onSortClick,
      }),
      formatter({ row, column }) {
        const value = getPropValue(row, column.key);
        const formattedValue = Number.isFinite(value)
          ? VALUE_FORMATTER(value)
          : "-";

        return (
          <div className={styles.baseLineExpresssionCell}>
            <span>{formattedValue}</span>
          </div>
        );
      },
    });

    compounds.forEach((compound) => {
      const compoundName = getCompoundColName(compound);
      columns.push({
        key: `simulations.perturbation.${cellLine}:${compound.compoundId}`,
        name: compoundName,
        headerRenderer: getSortableHeaderRenderer({
          sortDir,
          sortBy,
          onSortClick,
        }),
        formatter({ row, column }) {
          const value = getPropValue(row, column.key);
          const isDarkColor = isGeneDarkColor(value);
          const backgroundColor = GENE_COLORS_SCALE(value);
          const className = cn(styles.valueCell, {
            [styles.valueCellDark]: isDarkColor,
          });

          return (
            <div className={className} style={{ backgroundColor }}>
              <span>{VALUE_FORMATTER(value)}</span>
            </div>
          );
        },
        minWidth: 120,
      });
    });
  });

  return columns;
}

export function getComponentMoasColumns({
  cellLines,
  compounds,
  projectId,
  studyId,
  sortBy,
  sortDir,
  onSortClick,
}: {
  cellLines: string[];
  componentType?: string;
  compounds: IStudyCompound[];
  projectId: string;
  studyId: string;
  sortBy: string;
  sortDir: string;
  onSortClick: (sortBy: string, sortDir: ESortDir) => void;
  includeComponentType?: boolean;
}) {
  const columns: IDataGridColumn[] = [
    {
      key: "displayName",
      name: getCopy("studies_components_col-moa-display-name"),
      width: 350,
      headerRenderer: getSortableHeaderRenderer({
        sortDir,
        sortBy,
        onSortClick,
      }),
      formatter({ row = {} }: { row: Partial<IStudyComponent> }) {
        const studyUrl = getStudyUrl(projectId, studyId);
        const isPathway = row.componentType === "pathway";
        if (!row.syntensorId) {
          return null;
        }

        const url = isPathway
          ? getPathwayDetailUrl(studyUrl, row.syntensorId)
          : getComponentDetailUrl(studyUrl, row.syntensorId);
        return <NameCell {...row} url={url} />;
      },
    },
  ];

  cellLines.forEach((cellLine) => {
    compounds.forEach((compound) => {
      let compoundName = `${compound.name} MoA rank`;
      if (compound.control) {
        compoundName += ` / Control (${compound.control})`;
      }

      columns.push({
        key: `simulations.moaRank.${cellLine}:${compound.compoundId}`,
        name: compoundName,
        headerRenderer: getSortableHeaderRenderer({
          sortDir,
          sortBy,
          onSortClick,
        }),
        // formatter({ row, column }: any) {
        //   const value = getPropValue(row, column.key);

        //   return <span>{VALUE_FORMATTER(value)}</span>;
        // },
        formatter({ row, column }) {
          const value = getPropValue(row, column.key);
          const isDarkColor = isProteinDarkColor(value);

          //  get correct unknown color
          const backgroundColor = value
            ? PROTEIN_COLORS_SCALE(value)
            : PROTEIN_COLORS_SCALE(NaN);
          const className = cn(styles.valueCell, {
            [styles.valueCellDark]: isDarkColor,
          });

          return (
            <div className={className} style={{ backgroundColor }}>
              <span>{value || "-"}</span>
            </div>
          );
        },
        resizable: true,
        minWidth: 120,
      });
    });
  });

  return columns;
}
