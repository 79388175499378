import cn from "classnames";
import { format } from "d3-format";

import Table, {
  TCellRenderer,
  ITableCol,
} from "@syntensor/common/components/table";
import {
  ArrowRightIcon,
  AlertIcon,
  EIconSize,
} from "@syntensor/common/components/icons";
import getCopy from "@syntensor/common/data/copy";
import Select from "@syntensor/common/components/select";
import {
  IStudyCompound,
  TStudyComponentDetail,
  TStudyToxicityPotency,
} from "@syntensor/common/types";
import Tooltip from "@syntensor/common/components/tooltip";

import { capitalize } from "../search/match_search";
import {
  VALUE_FORMATTER,
  GENE_COLORS_SCALE,
  isGeneDarkColor,
  getCompoundColName,
} from "../components/columns";

import styles from "./component_detail.module.css";
import studiesStyles from "../studies.module.css";

export function ToxicityTooltip(toxicity: TStudyToxicityPotency) {
  const isDiliToxic = toxicity && toxicity.diliTF === true;
  if (!isDiliToxic) {
    return null;
  }

  return (
    <span>
      {getCopy(`studies_endpoints_diliTooltip`, {
        diliConfidence: toxicity.diliConfidence,
      })}
    </span>
  );
}

export function renderToxicityComponent(toxicity: TStudyToxicityPotency) {
  const isDiliToxic = toxicity && toxicity.diliTF === true;
  return isDiliToxic ? (
    <Tooltip tooltip={<ToxicityTooltip {...toxicity} />}>
      <AlertIcon />
    </Tooltip>
  ) : null;
}

export function cellRendererFn(value: number) {
  const isDarkColor = isGeneDarkColor(value);
  const scale = GENE_COLORS_SCALE;
  const backgroundColor = value ? scale(value) : scale(NaN);
  const className = cn(studiesStyles.tableValueCell, {
    [studiesStyles.valueCellDark]: isDarkColor,
  });

  return (
    <div className={className} style={{ backgroundColor }}>
      <span>{VALUE_FORMATTER(value)}</span>
    </div>
  );
}

export function compoundHeaderRendererFn(
  col: ITableCol,
  compound: IStudyCompound
) {
  const toxicityIcon = compound.toxicity
    ? renderToxicityComponent(compound.toxicity)
    : null;
  return (
    <span className={styles.compoundTableHeaderCell}>
      {col.name} {toxicityIcon}
    </span>
  );
}

export function getHorizontalCompoundTableCols(
  compounds: IStudyCompound[],
  cellLine: string,
  customCellRendererFn?: TCellRenderer,
  hasDosage = true
): ITableCol[] {
  if (!compounds) {
    return [];
  }

  return compounds.map((compound: IStudyCompound) => {
    const name = getCompoundColName(compound, hasDosage);
    return {
      id: `${cellLine}:${compound.compoundId}`,
      name,
      headerRenderer: (col) => compoundHeaderRendererFn(col, compound),
      cellRenderer: customCellRendererFn || cellRendererFn,
    };
  });
}

export interface IComponentDetailHeaderProps extends TStudyComponentDetail {
  links: { url: string; label: string }[];
  compounds?: IStudyCompound[];
  cellLines?: string[];
  description?: string;
  synonym?: string;
  isOverview?: boolean;
}

export default function ComponentDetailHeader({
  displayName,
  descriptiveName,
  simulations,
  compounds = [],
  componentType = "",
  description = "",
  cellLinesExpressionMetadata,
  synonym = "",
  cellLines = [],
  links,
  isOverview = false,
}: IComponentDetailHeaderProps) {
  const cellLine = cellLines && cellLines.length ? cellLines[0] : "";

  const tableCols = getHorizontalCompoundTableCols(compounds, cellLine);
  let tableData: Record<string, number>[] = [];
  if (simulations) {
    tableData = [simulations.perturbation];
  }

  const name = descriptiveName || displayName;

  // const cellLineExpression
  const cellLineExpressionMetadata = cellLinesExpressionMetadata
    ? cellLinesExpressionMetadata[cellLine]
    : null;

  const metadataFormatter = format(".2f");

  return (
    <div className={studiesStyles.controls}>
      <div className={studiesStyles.controlRow}>
        <div className={studiesStyles.mainControl}>
          <div className={styles.headerTitles}>
            <div className={styles.headerTitleWrapper}>
              <h3
                className={styles.headerTitle}
                data-testid="app_component-detail_title"
              >
                {name}
              </h3>
            </div>
            <div className={styles.description}>
              <span className={styles.descriptionValue}>
                {capitalize(componentType)}
              </span>
              {description && (
                <span className={styles.descriptionValue}>{description}</span>
              )}
              {synonym && (
                <span>
                  {getCopy("studies_component-detail_synonyms")}&nbsp;
                  <span className={styles.descriptionValue}>{synonym}</span>
                </span>
              )}
              {cellLineExpressionMetadata && (
                <>
                  <span>
                    {getCopy("studies_component-detail_baseline-expression")}
                    <span className={styles.descriptionValue}>
                      {metadataFormatter(
                        cellLineExpressionMetadata.baselineExpression
                      )}
                      &nbsp; ({cellLineExpressionMetadata.baselineUnit})
                    </span>
                  </span>
                  <span>
                    {getCopy("studies_component-detail_perturbation-frequency")}
                    <span className={styles.descriptionValue}>
                      {metadataFormatter(
                        cellLineExpressionMetadata.perturbationFrequency
                      )}
                      &nbsp;(
                      {cellLineExpressionMetadata.perturbationFrequencyUnit})
                    </span>
                  </span>
                  <span>
                    {getCopy("studies_component-detail_mean-perturbation")}
                    <span className={styles.descriptionValue}>
                      {metadataFormatter(
                        cellLineExpressionMetadata.meanPerturbation
                      )}
                      &nbsp;({cellLineExpressionMetadata.meanPerturbationUnit})
                    </span>
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        {!isOverview && (
          <div className={studiesStyles.noGrowControl}>
            <Select
              size="small"
              label={getCopy("studies_components_cellline-dropdown")}
              options={cellLines.map((cellLine: string) => {
                return { id: cellLine, name: cellLine };
              })}
            />
          </div>
        )}
      </div>
      <div className={studiesStyles.controlRow}>
        <div className={studiesStyles.mainControl}>
          <h4 className={styles.tableTitle}>
            {getCopy("studies_component-detail_table-title")}
          </h4>
          <Table
            config={{
              cols: tableCols,
            }}
            data={tableData}
          />
        </div>
        <div>
          <ul className={styles.headerList}>
            {links &&
              links.map((link, i: number) => {
                const { url, label } = link;
                return (
                  <li key={i}>
                    <a
                      href={url}
                      className={styles.headerLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {label} <ArrowRightIcon size={EIconSize.SMALL} />
                    </a>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}
