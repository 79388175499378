import { useContext } from "react";
import { useQuery } from "react-query";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { IStudyPathwayDetailMatchParams } from "../types";
import Loader from "@syntensor/common/components/loader";
import Error from "@syntensor/common/components/error";
import { fetchStudyPathwayDetail } from "@syntensor/common/data/fetch_data";
import ViewToggle, {
  VIEWS_MAP,
} from "@syntensor/common/components/view_toggle";
import { IStudyPathwayDetail } from "@syntensor/common/types";
import getCopy from "@syntensor/common/data/copy";

import StudiesTitle from "../title";
import { StudyDataContext } from "..";
import { reactQueryOptions } from "../../config";
import Header from "./header";
import { STUDY_URL_PREFIX } from "../routes";
import StudyComponentsGrid from "../components/components_grid";
import PathwayDetailExplorer from "./explorer";
import StudyPathwaysGrid from "../pathways/pathways_grid";

import styles from "../studies.module.css";

export interface IStudyPathwayDetailProps {
  isOverview?: boolean;
  baseUrl?: string;
}

export default function StudyPathwayDetail({
  isOverview = false,
  baseUrl = `${STUDY_URL_PREFIX}/pathways`,
}: IStudyPathwayDetailProps) {
  const { params = { pathwayId: "", studyId: "" }, url = "" } =
    useRouteMatch<IStudyPathwayDetailMatchParams>(`${baseUrl}/:pathwayId`) ||
    {};
  const explorerMatch = useRouteMatch<IStudyPathwayDetailMatchParams>(
    `${baseUrl}/:pathwayId/explorer`
  );

  const { pathwayId = "", studyId = "" } = params;

  const studyData = useContext(StudyDataContext);
  const {
    compounds = [],
    cellLines = [],
    componentTypes: availableComponentTypes = [],
  } = studyData || {};

  const cellLineIds = cellLines.map((cellLine) => cellLine.id);
  const cellLinesQueryKey = cellLineIds.join(",");

  const queryKey = [
    "studies",
    "pathways",
    studyId,
    pathwayId,
    cellLinesQueryKey,
  ];

  const {
    data: pathwayDetail,
    isLoading,
    error,
  } = useQuery<IStudyPathwayDetail>(
    queryKey,
    async () => {
      if (pathwayId && studyId && compounds.length && cellLines.length) {
        const pathwayDetail = await fetchStudyPathwayDetail(
          studyId,
          pathwayId,
          {
            cellLines: cellLineIds,
          }
        );
        return pathwayDetail;
      }

      return Promise.resolve(null);
    },

    { ...reactQueryOptions, retry: false }
  );

  if (error) {
    return <Error error={error} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (!pathwayDetail) {
    /* something probably went wrong or we're not ready to fetch the data yet */
    return null;
  }

  const activeView = explorerMatch ? VIEWS_MAP.network.id : VIEWS_MAP.table.id;
  const { children = [] } = pathwayDetail;
  const childrenIds = children.reduce<string[]>((acc, s) => {
    if (s.syntensorId) {
      acc.push(s.syntensorId);
    }
    return acc;
  }, []);

  return (
    <>
      {!isOverview && (
        <div className={styles.titles}>
          <StudiesTitle />
        </div>
      )}
      <div className={styles.sectionHeader}>
        <Header
          {...pathwayDetail}
          url={url}
          activeView={activeView}
          compounds={compounds}
          cellLines={cellLineIds}
          isOverview={isOverview}
        />
      </div>
      <div
        className={styles.sectionHeader}
        style={{ marginBottom: "calc(-1 * var(--space-xxl))" }}
      >
        {!explorerMatch && (
          <div className={styles.controls}>
            <div className={styles.controlRow}>
              <div className={styles.mainControl}>
                <ViewToggle
                  theme="mainNav"
                  btns={[
                    {
                      id: "genes",
                      to: `${url}/genes`,
                      exact: true,
                      isDisabled: !availableComponentTypes.includes("gene"),
                      label: getCopy("studies_components_genes"),
                    },
                    {
                      id: "transcripts",
                      to: `${url}/transcripts`,
                      exact: true,
                      isDisabled:
                        !availableComponentTypes.includes("transcript"),
                      label: getCopy("studies_components_transcripts"),
                    },
                    {
                      id: "proteins",
                      to: `${url}/proteins`,
                      exact: true,
                      isDisabled: !availableComponentTypes.includes("protein"),
                      label: getCopy("studies_components_proteins"),
                    },
                    {
                      id: "complexes",
                      to: `${url}/complexes`,
                      exact: true,
                      isDisabled:
                        !availableComponentTypes.includes("complexes"),
                      label: getCopy("studies_components_complexes"),
                    },
                    {
                      id: "pathways",
                      to: `${url}/pathways`,
                      exact: true,
                      isDisabled: children.length === 0,
                      label: getCopy(
                        "studies_pathway-detail_children-pathways"
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.sectionContent}>
        <Switch>
          {availableComponentTypes.includes("gene") && (
            <Route
              path={`${baseUrl}/:pathwayId/genes`}
              render={() => (
                <StudyComponentsGrid
                  baseUrl={baseUrl}
                  hasFilters={false}
                  pathwayId={pathwayId}
                  componentType="gene"
                />
              )}
            />
          )}
          {availableComponentTypes.includes("transcript") && (
            <Route
              path={`${baseUrl}/:pathwayId/transcripts`}
              render={() => (
                <StudyComponentsGrid
                  baseUrl={baseUrl}
                  hasFilters={false}
                  pathwayId={pathwayId}
                  componentType="transcript"
                />
              )}
            />
          )}
          {availableComponentTypes.includes("protein") && (
            <Route
              path={`${baseUrl}/:pathwayId/proteins`}
              render={() => (
                <StudyComponentsGrid
                  baseUrl={baseUrl}
                  hasFilters={false}
                  pathwayId={pathwayId}
                  componentType="protein"
                />
              )}
            />
          )}
          {availableComponentTypes.includes("complex") && (
            <Route
              path={`${baseUrl}/:pathwayId/complexes`}
              render={() => (
                <StudyComponentsGrid
                  hasFilters={false}
                  pathwayId={pathwayId}
                  componentType="complex"
                />
              )}
            />
          )}
          <Route
            path={`${baseUrl}/:pathwayId/pathways`}
            render={() => {
              return <StudyPathwaysGrid ids={childrenIds} hasFilters={false} />;
            }}
          />
          <Route
            path={`${baseUrl}/:pathwayId/explorer`}
            render={() => (
              <PathwayDetailExplorer baseUrl={baseUrl} data={pathwayDetail} />
            )}
          />
          <Redirect
            from={`${baseUrl}/:pathwayId`}
            to={`${baseUrl}/:pathwayId/${availableComponentTypes[0]}s`}
          />
        </Switch>
      </div>
    </>
  );
}
