import cn from "classnames";
import { ReactNode } from "react";

// import { FilterIcon } from "../icons";
import SortControl from "@syntensor/common/components/sort_control";

import { ESortDir } from "../../types";

import styles from "./data_grid.module.css";

export interface ISortableHeaderCellProps {
  id: string;
  label?: string;
  children?: ReactNode;
  isMultiDir?: boolean;
  isSortable?: boolean;
  onSortClick: (id: string, sortDir: ESortDir) => void;
  sortDir: string;
  sortBy: string;
}

export default function SortableHeaderCell({
  id,
  label = "",
  children = null,
  isMultiDir = false,
  isSortable = false,
  onSortClick,
  sortDir = "desc",
  sortBy = "",
}: // hasFilter,
ISortableHeaderCellProps) {
  // make sure we display correct highlight
  const isActiveSort = id === sortBy;

  // extra margin
  const labelStyles = cn(styles.label, {
    [styles.sortableLabel]: isSortable,
  });

  //  add title to the cell in case text is elippsed and can't be read
  const title = label
    ? label
    : children && typeof children === "string"
    ? children
    : "";

  return (
    <div className={styles.sortableHeaderCell} title={title}>
      <span className={labelStyles}>
        {label && <span dangerouslySetInnerHTML={{ __html: label }} />}
        {children}
      </span>
      {/*
        FOR NOW NOT DISPLAYING FILTER INDICATOR
        {hasFilter && (
        <span className={styles.filterIndicator}>
          <FilterIcon size="small" />
        </span>
      )} */}
      {isSortable && (
        <SortControl
          id={id}
          isMultiDir={isMultiDir}
          isActive={isActiveSort}
          sortDir={sortDir}
          onSortClick={onSortClick}
        />
      )}
    </div>
  );
}
