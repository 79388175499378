//  react-data-grid doesn't support
import "core-js/features/global-this";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";

import "./index.css";

import Root from "./root";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

//  instantiate react-query client
const queryClient = new QueryClient();

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Root />
      </QueryClientProvider>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
