import { scaleLinear, scaleOrdinal } from "d3-scale";
import { min, max } from "d3-array";

export function getDomainFromData(data: number[]) {
  return [min(data) || 0, max(data) || Infinity];
}

export function getScaleFromData(
  data: (number | string)[],
  shouldClamp = true
) {
  //  find out which scale we have
  const isCategorical = data.some((d) => {
    return typeof d !== "number";
  });

  let scale;
  if (isCategorical) {
    scale = scaleOrdinal();
    scale.domain(data as string[]);
  } else {
    //  get linear scale
    scale = scaleLinear();
    scale.domain(getDomainFromData(data as number[]));
    scale.clamp(shouldClamp);
  }

  //  default range to [0,100], can be overriden
  scale.range([0, 100]);

  return scale;
}
