import { useEffect, useState } from "react";

export interface ICopyItem {
  Copy: string;
  ID: string;
  Notes: string;
  Page: string;
  Section: string;
}

export function parseCopy(values: ICopyItem[]) {
  return values.reduce<Record<string, string>>((acc, row) => {
    const { Page, Section, ID, Copy } = row;
    const key = `${Page}_${Section}_${ID}`;
    acc[key] = Copy;
    return acc;
  }, {});
}

export default function useCopy(copies: ICopyItem[]) {
  const [values, setValues] = useState({});

  useEffect(() => {
    const formattedResults = parseCopy(copies);
    setValues(formattedResults);
  }, [copies]);

  return values;
}
