export const KEY_CODES: Record<string, string> = {
  13: "enter",
  27: "escape",
  32: "space",
  38: "up",
  40: "down",
};

export const KEY_MAP = Object.keys(KEY_CODES).reduce<Record<string, number>>(
  (acc, keyCode: string) => {
    acc[KEY_CODES[keyCode]] = +keyCode;
    return acc;
  },
  {}
);
