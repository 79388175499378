import getCopy from "@syntensor/common/data/copy";

import { GeneTranscriptLegendScale } from "../../../components/legends/gene_transcript_legend";

import styles from "./legend.module.css";

export default function ColorsSection() {
  return (
    <div className={styles.section}>
      <h3 className={styles.sectionTitle}>
        {getCopy("studies_genes-legend_title")}
      </h3>
      <GeneTranscriptLegendScale />
    </div>
  );
}
