export const COPY: Record<string, string> = {};

let copy = COPY;

export function stripHtml(string: string) {
  return string ? string.replace(/(<([^>]+)>)/gi, "") : "";
}

export const addDynamicCopy = (dynamicCopy: Record<string, string>) => {
  copy = { ...copy, ...dynamicCopy };
};

export const getCopyId = (text: string) =>
  Object.keys(copy).find((key) => copy[key] === text);

export default function getCopy(
  copyId: string,
  data: { [key: string]: string | number | boolean } | null = null,
  shouldStripHtml = false
) {
  let templateString = copy[copyId] !== "undefined" ? copy[copyId] : copyId;

  //  replace any dynamic vars
  if (data) {
    Object.keys(data).forEach((key) => {
      const reg = new RegExp(`%${key}%`);
      templateString = templateString
        ? templateString.replace(reg, data[key].toString())
        : templateString;
    });
  }

  return shouldStripHtml ? stripHtml(templateString) : templateString;
}
