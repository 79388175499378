import cn from "classnames";

import getCopy from "@syntensor/common/data/copy";

import { TPathwayDetailExplorerLegendLookup, resolveName } from "./index";

import styles from "./legend.module.css";

export default function LettersSection(
  letters: TPathwayDetailExplorerLegendLookup
) {
  return (
    <div className={styles.section}>
      <div className={styles.sectionTitle}>
        {getCopy("studies_pathway-explorer-legend_nodes")}
      </div>
      <div className={styles.sectionContent}>
        {letters &&
          Object.entries(letters).map(([letter, names]) => {
            const isCompartment = names[0] === "compartment";
            const isComplex = letter === "C";
            const nodeClass = cn(styles.node, {
              [styles.complexNode]: isComplex,
              [styles.compartmentNode]: isCompartment,
            });

            //  map Reactome names to a potential custom nomenclature
            //  or leave as is if no custom nomenclature is available
            const resolvedNames = names.map(resolveName);

            return (
              <div key={letter} className={styles.legendItem}>
                <span className={nodeClass}>{letter}</span>
                <span className={styles.label}>{resolvedNames.join(", ")}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
}
