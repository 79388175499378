import { Switch, Route } from "react-router-dom";

import GraphMetrics from "./graph";
import GraphMetricsDynamics from "./graph/dynamics";
import GraphMetricsReports from "./reports";
import CoverageMetrics from "./coverage";
import CoverageMetricsSampling from "./coverage/sampling";

export default function MetricsRoutes() {
  //  there should be one version of all pages for:
  //  - all mlflows
  //  - or simulations
  //  - or just graph version

  //  URL:
  //  metrics/runs/:runId/
  //  metrics/simulations/:simulationId/
  //  metrics/ - just latest version of everything

  //  need to make sure that the static parts respect the URLs

  //  add a couple of options for
  //  @see https://github.com/pillarjs/path-to-regexp/issues/106
  //  const OPTIONAL_PROJECT_PATH = ":projectId(projects/[^/]+)?/";
  // const OPTIONAL_SIMULATION_PATH = ":simulationId(simulations/[^/]+)?/";

  return (
    <Switch>
      <Route
        // path="/metrics/(simulations/:simulationId/)?coverage/sampling"
        path={`/metrics/coverage/sampling`}
        component={CoverageMetricsSampling}
      />
      <Route path="/metrics/coverage" component={CoverageMetrics} />
      <Route path="/metrics/dynamics" component={GraphMetricsDynamics} />
      <Route path="/metrics/reports" component={GraphMetricsReports} />
      <Route path="/metrics" component={GraphMetrics} />
    </Switch>
  );
}
