import { useCallback, useContext } from "react";

import Button from "@syntensor/common/components/button";
import SubmitBtn from "@syntensor/common/components/submit_btn";
import useFormInput from "@syntensor/common/hooks/use_form_input";
import useFormSubmit from "@syntensor/common/hooks/use_form_submit";
import getCopy from "@syntensor/common/data/copy";

import { AuthContext } from "../auth/auth_context";
import Input from "./profile_input";
import StatusMsgs from "./status_msgs";

import classes from "./profile.module.css";

export default function ChangePassword() {
  const { changePassword } = useContext(AuthContext);
  const { inputs, handleInputChange } = useFormInput();

  const onSubmit = useCallback(async () => {
    if (inputs.old && inputs.new && changePassword) {
      await changePassword(inputs.old, inputs.new);
    } else {
      throw new Error(getCopy("profile_change-password_validation-error-msg"));
    }
  }, [inputs, changePassword]);

  const { isLoading, errorMsg, successMsg, handleSubmit } = useFormSubmit({
    onSubmit,
    onSuccessMsg: getCopy("profile_change-password_success-msg"),
  });

  return (
    <div className={classes.changePassword}>
      <div className={classes.content}>
        <h2
          className={classes.title}
          dangerouslySetInnerHTML={{
            __html: getCopy("profile_change-password_title"),
          }}
        />
        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.row}>
            <Input
              required
              name="old"
              placeholder={getCopy("profile_change-password_old")}
              type="password"
              autoComplete="current-password"
              onChange={handleInputChange}
            />
          </div>
          <div className={classes.row}>
            <Input
              required
              name="new"
              placeholder={getCopy("profile_change-password_new")}
              type="password"
              autoComplete="new-password"
              onChange={handleInputChange}
            />
          </div>
          <StatusMsgs errorMsg={errorMsg} successMsg={successMsg} />
          <div className={classes.row}>
            <div>
              <SubmitBtn isLoading={isLoading} role="secondary">
                {getCopy("profile_change-password_cta")}
              </SubmitBtn>
            </div>
            <div>
              <Button to="/profile" role="subtle">
                {getCopy("profile_change-password_cancel")}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
