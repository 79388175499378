import Button from "../button";
import Loader from "../loader";

import classes from "./submit_btn.module.css";

export interface ISubmitBtnProps {
  children?: React.ReactNode;
  isLoading?: boolean;
  isDisabled?: boolean;
  type?: string;
  [key: string]: unknown;
}

export default function SubmitBtn({
  children = null,
  isLoading = false,
  isDisabled = false,
  type = "submit",
  ...btnProps
}: ISubmitBtnProps) {
  const normIsDisabled = isDisabled || isLoading;
  const contentClassName = isLoading ? classes.contentLoading : classes.content;

  return (
    <div className={classes.submitBtn}>
      <Button type={type} isDisabled={normIsDisabled} {...btnProps}>
        <span className={contentClassName}>{children}</span>
        {isLoading && <Loader size={24} />}
      </Button>
    </div>
  );
}
