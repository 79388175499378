import getCopy from "@syntensor/common/data/copy";

import classes from "./renderers.module.css";

export interface IRenderSuggestionProps {
  name?: string;
  compartment?: string;
  componentType?: string;
}

export function GeneSuggestion({
  name,
  compartment,
  componentType,
}: IRenderSuggestionProps) {
  const compartmentValue = compartment ? `[${compartment}]` : " - ";

  return (
    <div className={classes.componentRenderer}>
      <div
        className={classes.name}
        dangerouslySetInnerHTML={{
          __html: `${name}`,
        }}
      />
      <div className={classes.type}>{componentType}</div>
      <div className={classes.compartment}>{compartmentValue}</div>
    </div>
  );
}

export function ProteinSuggestion({
  name,
  compartment,
  componentType,
}: IRenderSuggestionProps) {
  const compartmentValue = compartment ? `[${compartment}]` : " - ";

  return (
    <div className={classes.componentRenderer}>
      <div
        className={classes.name}
        dangerouslySetInnerHTML={{
          __html: `${name}`,
        }}
      />
      <div className={classes.type}>{componentType}</div>
      <div className={classes.compartment}>{compartmentValue}</div>
    </div>
  );
}

export function NoResultsSuggestion() {
  return (
    <div className={classes.noResultsRenderer}>
      <div className={classes.renderCol}>
        <div className={classes.innerCol}>
          <div>{getCopy("studies_search_no-results")}</div>
        </div>
      </div>
    </div>
  );
}

export function PathwaySuggestion({ name }: IRenderSuggestionProps) {
  return (
    <div className={classes.pathwayRenderer}>
      <div
        className={classes.name}
        dangerouslySetInnerHTML={{
          __html: `${name}`,
        }}
      />
      <div className={classes.type}>Pathway</div>
    </div>
  );
}

export function DefaultSuggestion({ name }: IRenderSuggestionProps) {
  return (
    <div className={classes.defaultRenderer}>
      <div className={classes.renderCol}>
        <div className={classes.innerCol}>
          <div>{name}</div>
        </div>
      </div>
    </div>
  );
}

const RENDERER_MAP = {
  gene: GeneSuggestion,
  transcript: GeneSuggestion,
  protein: ProteinSuggestion,
  complex: ProteinSuggestion,
  pathway: PathwaySuggestion,
  noResults: NoResultsSuggestion,
};

export function getRendererForType(type: string) {
  if (type && type in RENDERER_MAP) {
    return RENDERER_MAP[type as keyof typeof RENDERER_MAP];
  }

  return DefaultSuggestion;
}
