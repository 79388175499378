import { useRouteMatch } from "react-router-dom";

import Button from "@syntensor/common/components/button";

import styles from "../project.module.css";

export default function Tissues() {
  const { url } = useRouteMatch();

  return (
    <div className={styles.tissues}>
      <div className={styles.title}>
        <h2>Project 1</h2>
        <div>
          <Button to={`${url}/create`} role="secondary">
            Create new study
          </Button>
        </div>
      </div>
      <div>
        <h3>Tissues</h3>
        <div>
          <a href={`${url}/akbD62dnkb`}>Tissues study</a>
        </div>
      </div>
    </div>
  );
}
