import { ReactElement } from "react";

import InfoBtn from "@syntensor/common/components/info_btn";
import Button from "@syntensor/common/components/button";

import styles from "./metrics.module.css";

export interface IReactElement extends ReactElement {
  tagName: string;
}

export interface IMarkdownElement {
  [key: string]: any;
  className?: string;
  text?: string;
  node?: {
    children: IReactElement[];
  };
}

export default function markdownComponents() {
  return {
    div: ({ ...props }) => {
      return <div {...props} className={styles[props.className]} />;
    },
    h2: ({ ...props }) => {
      return <h2 {...props} className={styles.title} />;
    },
    h3: ({ ...props }) => {
      return <h3 {...props} className={styles.sectionTitle} />;
    },
    h4: ({ ...props }) => {
      return <h3 {...props} className={styles.imageTitle} />;
    },
    p: ({ node, ...props }: IMarkdownElement) => {
      if (!node) {
        return null;
      }

      //  default class name
      const className = props.className || styles.sectionParagraph;

      //  see if we it's a dummy paragraph which should not be inserted
      const { children = [] } = node;
      const hasTextChildren =
        children.some((child) => child.type === "text") ||
        children.some(
          (child) => child.type === "element" && child.tagName === "strong"
        );

      if (!hasTextChildren) {
        //  rendering some component so don't need wrapper p
        return <>{props.children}</>;
      }

      return <p {...props} className={className} />;
    },
    img: ({ className = "", ...props }: IMarkdownElement) => {
      const style = styles[className] || styles.img;
      return <img {...props} className={style} />;
    },
    row: ({ ...props }) => {
      return <div {...props} className={styles.row} />;
    },
    button: ({ ...props }) => {
      const { children } = props;
      return (
        <span>
          <Button {...props}>{children}</Button>
        </span>
      );
    },
    explainer: ({ ...props }) => {
      return <div {...props} className={styles.sectionExplainer} />;
    },
    info: ({ text }: IMarkdownElement) => {
      return text ? <InfoBtn>{text}</InfoBtn> : null;
    },
    section: ({ ...props }) => {
      return <section {...props} className={styles.section} />;
    },
    footnote: ({ ...props }) => {
      return <p {...props} className={styles.sectionSubtext} />;
    },
  };
}

export const mdxComponents = {
  Div: ({ className = "", ...props }: IMarkdownElement) => {
    return <div {...props} className={styles[className]} />;
  },
  h2: (props: IMarkdownElement) => {
    return <h2 {...props} className={styles.title} />;
  },
  h3: (props: IMarkdownElement) => {
    return <h3 {...props} className={styles.sectionTitle} />;
  },
  h4: (props: IMarkdownElement) => {
    return <h3 {...props} className={styles.imageTitle} />;
  },
  p: (props: IMarkdownElement) => {
    //  default class name
    const className = props.className || styles.sectionParagraph;

    //  see if we it's a dummy paragraph which should not be inserted
    // const { children = [] } = props;
    // const hasTextChildren =
    //   children.some((child) => child.type === "text") ||
    //   children.some(
    //     (child) => child.type === "element" && child.tagName === "strong"
    //   );

    // if (!hasTextChildren) {
    //   //  rendering some component so don't need wrapper p
    //   return <>{props.children}</>;
    // }

    return <p {...props} className={className} />;
  },
  img: ({ className = "", ...props }: IMarkdownElement) => {
    const style = styles[className] || styles.img;
    return <img {...props} className={style} />;
  },
  Button: (props: IMarkdownElement) => {
    return (
      <span>
        <Button {...props} />
      </span>
    );
  },
  Row: ({ ...props }) => {
    return <div {...props} className={styles.row} />;
  },
  Explainer: ({ ...props }) => {
    return <div {...props} className={styles.sectionExplainer} />;
  },
  Section: ({ ...props }) => {
    return <section {...props} className={styles.section} />;
  },
  Info: ({ text }: IMarkdownElement) => {
    return text ? <InfoBtn>{text}</InfoBtn> : null;
  },
  Footnote: ({ ...props }) => {
    return <p {...props} className={styles.sectionSubtext} />;
  },
};

export function div({ ...props }) {
  return <div {...props} className={styles[props.className]} />;
}

export function h2({ ...props }) {
  return <h2 {...props} className={styles.title} />;
}

export function h3({ ...props }) {
  return <h3 {...props} className={styles.sectionTitle} />;
}

export function h4({ ...props }) {
  return <h3 {...props} className={styles.imageTitle} />;
}

export function p({ node, ...props }: IMarkdownElement) {
  if (!node) {
    return null;
  }

  //  default class name
  const className = props.className || styles.sectionParagraph;

  //  see if we it's a dummy paragraph which should not be inserted
  const { children = [] } = node;
  const hasTextChildren =
    children.some((child) => child.type === "text") ||
    children.some(
      (child) => child.type === "element" && child.tagName === "strong"
    );

  if (!hasTextChildren) {
    //  rendering some component so don't need wrapper p
    return <>{props.children}</>;
  }

  return <p {...props} className={className} />;
}

export function img({ className = "", alt = "", ...props }: IMarkdownElement) {
  const style = styles[className] || styles.img;
  return <img {...props} alt={alt} className={style} />;
}

export function row({ ...props }) {
  return <div {...props} className={styles.row} />;
}

export function button({ ...props }: IMarkdownElement) {
  const { children, ...restProps } = props;
  return (
    <span>
      <Button {...restProps}>{children}</Button>
    </span>
  );
}

export function Section({ ...props }) {
  return <section {...props} className={styles.section} />;
}

export function Explainer({ ...props }) {
  return <div {...props} className={styles.sectionExplainer} />;
}

export function info({ text }: IMarkdownElement) {
  return text ? <InfoBtn>{text}</InfoBtn> : null;
}

export function footnote({ ...props }) {
  return <p {...props} className={styles.sectionSubtext} />;
}
