import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";

import Button from "@syntensor/common/components/button";
import Input from "@syntensor/common/components/input";
import { CheckMarkIcon, XIcon } from "@syntensor/common/components/icons";
import getCopy from "@syntensor/common/data/copy";

import { TFilterValue } from ".";

import styles from "./filters.module.css";

export interface INumericFilterProps {
  value?: TFilterValue;
  onFilterValueChange?: (value: TFilterValue) => void;
  onFilterCancel?: () => void;
}

export function validateInputValue(value: string) {
  if (!value) {
    return false;
  }

  //  strip all the allowed characters
  const allowedChars = [">=", "<=", ">", "<"];
  const strippedValue = allowedChars.reduce((acc, char) => {
    return acc.replace(char, "");
  }, value);

  const num = Number.parseFloat(strippedValue);
  return Number.isFinite(num);
}

export default function NumericFilter({
  value = [],
  onFilterValueChange = () => undefined,
  onFilterCancel = () => undefined,
}: INumericFilterProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [localValue, setLocalValue] = useState<TFilterValue>([]);
  const [hasError, setHasError] = useState(false);

  const handleFilterValueChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value;

    //  always store value as an array
    setLocalValue([value]);

    const isValidValue = validateInputValue(value);
    setHasError(!isValidValue);
  };

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();

    //  serialise filter value
    if (!hasError) {
      onFilterValueChange(localValue);
    }
  };

  const handleCancelClick = () => {
    //  reset state from external props
    if (value) {
      setLocalValue(value);
    }
    onFilterCancel();
  };

  useEffect(() => {
    //  reset state if external props updated
    if (value) {
      setLocalValue(value);
    }
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [value]);

  //  extra validation for input value to avoid React errors
  const inputValue = localValue && localValue[0] ? localValue : "";
  const inputClass = hasError ? styles.inputError : "";

  return (
    <form className={styles.numericFilter} onSubmit={handleSubmit}>
      <label>
        <span className={styles.instructions}>
          {getCopy("studies_filter_numeric-instructions")}
        </span>
        <Input
          ref={inputRef}
          className={inputClass}
          size="small"
          placeholder="Expression"
          value={inputValue}
          onChange={handleFilterValueChange}
        />
      </label>
      <div className={styles.filterBtns}>
        <Button type="submit" role="primary" size="small">
          <CheckMarkIcon />
        </Button>
        <Button onClick={handleCancelClick} role="subtle" size="small">
          <XIcon />
        </Button>
      </div>
    </form>
  );
}
