import Table, { linkCellRenderer } from "@syntensor/common/components/table";

import MostConnectedNodes from "./most_connected_nodes";
import ComponentTypeStatistics from "./component_type_statistics";

import styles from "../metrics.module.css";

export function GraphOverviewTableSection({
  allNodes,
  labels,
}: {
  allNodes: Record<string, unknown>[];
  labels: string;
}) {
  const labelsArr = labels ? labels.split(",") : [];
  return (
    allNodes && (
      <div data-testid="metrics_graphoverview_table">
        <Table
          config={{
            cols: [
              {
                id: "Number of nodes",
                name: labelsArr[0] || "Nodes",
                width: "20%",
              },
              {
                id: "Total node degree",
                name: labelsArr[1] || "Total node degree",
                width: "20%",
              },
              {
                id: "Number of disconnected nodes",
                name: labelsArr[2] || "Number of disconnected nodes",
                width: "20%",
              },
              {
                id: "Maximum node degree",
                name: labelsArr[3] || "Maximum node degree",
                width: "20%",
              },
              {
                id: "Mean node degree",
                name: labelsArr[4] || "Mean node degree",
                width: "20%",
              },
            ],
          }}
          data={[allNodes]}
        />
      </div>
    )
  );
}

export function ComponentTypeStatisticsSection({
  nonAllTypes,
  title,
  selectlabels = "",
  tablelabels = "",
}: {
  nonAllTypes: Record<string, unknown>[];
  title: string;
  selectlabels: string;
  tablelabels: string;
}) {
  const selectLabelsArr = selectlabels ? selectlabels.split(",") : [];
  const tableLabelsArr = tablelabels ? tablelabels.split(",") : [];

  return (
    <ComponentTypeStatistics
      title={title}
      selectLabels={selectLabelsArr}
      tableLabels={tableLabelsArr}
      data={nonAllTypes}
    />
  );
}

export function PathwaysTable({
  metricsGraphData,
  labels = "",
}: {
  metricsGraphData: Record<string, unknown>;
  labels: string;
}) {
  const labelsArr = labels ? labels.split(",") : [];

  if (
    !metricsGraphData ||
    !metricsGraphData.pathways ||
    !Array.isArray(metricsGraphData.pathways)
  ) {
    return null;
  }

  return (
    <div data-testid="metrics_pathways_table">
      <Table
        config={{
          cols: [
            {
              id: "display_name",
              name: labelsArr[0] || "Pathway",
              cellRenderer: (_: unknown, d: Record<string, string>) => {
                return (
                  <a
                    className={styles.tableLink}
                    href={d["link"]}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {d["display_name"]}
                  </a>
                );
              },
            },
          ],
        }}
        data={metricsGraphData.pathways}
      />
    </div>
  );
}

export function MostConnectedNodesSection({
  mostConnectedNodes,
  title,
  infotext = "",
  tablelabels,
}: {
  title: string;
  infotext: string;
  tablelabels: string;
  mostConnectedNodes: Record<string, Record<string, string>[]>;
}) {
  const tableLabelsArr = tablelabels ? tablelabels.split(",") : [];

  return (
    <MostConnectedNodes
      title={title}
      infoText={infotext}
      tableLabels={tableLabelsArr}
      nodes={mostConnectedNodes}
    />
  );
}

export function DatasourcesTable({
  metricsGraphData,
  labels,
}: {
  labels: string;
  metricsGraphData: Record<string, unknown>;
}) {
  if (
    !metricsGraphData ||
    !metricsGraphData.sources ||
    !Array.isArray(metricsGraphData.sources)
  ) {
    return null;
  }

  const labelsArr = labels ? labels.split(",") : [];

  return (
    <div data-testid="metrics_datasources_table">
      <Table
        config={{
          cols: [
            {
              id: "Data Source",
              name: labelsArr[0] || "Data source",
              width: "25%",
            },
            {
              id: "Link",
              name: labelsArr[1] || "Link",
              cellRenderer: linkCellRenderer,
              width: "50%",
            },
            {
              id: "Category",
              name: labelsArr[2] || "Category",
              width: "25%",
            },
          ],
        }}
        data={metricsGraphData.sources}
      />
    </div>
  );
}
