import { ForwardedRef, createElement, forwardRef } from "react";

import styles from "./input.module.css";

export interface IInputProps {
  className?: string;
  name: string;
  elName?: string;
  [key: string]: unknown;
}

function Input(
  { className, name, elName = "input", ...props }: IInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const classNames = [className, styles.input];
  return createElement(elName, {
    ref,
    className: classNames.join(" "),
    name: name,
    ...props,
  });
}

export default forwardRef(Input);
