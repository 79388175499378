import { MouseEvent } from "react";
import cn from "classnames";

import { EIconSize, IIconProps } from "../icons";
import Button from "../button";

import styles from "./view_toggle.module.css";

export type TIconProp = (_: IIconProps) => JSX.Element;

export interface IViewToggleBtnProps {
  id: string;
  activeView: string;
  onClick: (evt: MouseEvent<HTMLElement>) => void;
  Icon?: TIconProp;
  label?: string;
  theme: string;
  isDisabled?: boolean;
  [key: string]: unknown;
}

export default function ViewToggleBtn({
  id,
  activeView,
  onClick,
  Icon,
  label = "",
  theme,
  isDisabled = false,
  ...btnProps
}: IViewToggleBtnProps) {
  const isActiveView = id === activeView;

  const stylesClassNames = cn(styles.btn, {
    [styles.btnActive]: isActiveView,
    [styles.mainNavBtn]: theme === "mainNav",
  });

  const handleClick = (evt: MouseEvent<HTMLElement>) => {
    if (onClick && typeof onClick === "function" && !isDisabled) {
      onClick(evt);
    }
  };

  return (
    <Button
      role="light"
      className={stylesClassNames}
      activeClassName={styles.btnActive}
      data-view-id={id}
      onClick={handleClick}
      isDisabled={isDisabled}
      {...btnProps}
    >
      {Icon && <Icon size={EIconSize.SMALL} />}
      {label && <span>{label}</span>}
    </Button>
  );
}
