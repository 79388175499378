import MetricsRoutes from "./routes";
import MetricsHeader from "./header";

import styles from "./metrics.module.css";

export default function Metrics() {
  return (
    <div className={styles.metrics}>
      <MetricsHeader />
      <MetricsRoutes />
    </div>
  );
}
