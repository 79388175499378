const CELL_LINES_AVAILABLE = [
  "VCAP",
  "A549",
  "A375",
  "PC3",
  "MCF7",
  "HT29",
  "HA1E",
  "MCF10A",
];
export default CELL_LINES_AVAILABLE;
