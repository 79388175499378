/*
  Port of https://github.com/Swizec/useDimensions/
  with removing the on scroll, which messes things up
*/

import { useState, useCallback, useLayoutEffect, useRef } from "react";

export function getDimensionObject(node: HTMLElement) {
  return node.getBoundingClientRect();
}

export function hasDimensionsChanged(
  newDimensions: DOMRect,
  oldDimensions: DOMRect | null
) {
  if (!oldDimensions) {
    return true;
  }

  return !Object.keys(newDimensions).every((dimKey) => {
    return (
      oldDimensions[dimKey as keyof DOMRect] ===
      newDimensions[dimKey as keyof DOMRect]
    );
  });
}

export default function useDimensions(): [
  (node: HTMLElement | null) => void,
  DOMRect | null,
  HTMLElement | null,
  () => void
] {
  const [dimensions, setDimensions] = useState<DOMRect | null>(null);
  const cachedDimensionsRef = useRef<DOMRect | null>(null);
  const [node, setNode] = useState<HTMLElement | null>(null);

  const ref = useCallback((node: HTMLElement | null) => {
    setNode(node);
  }, []);

  const measure = useCallback(() => {
    if (node) {
      window.requestAnimationFrame(() => {
        const newDimensions = getDimensionObject(node);
        const dimensionsChanged = hasDimensionsChanged(
          newDimensions,
          cachedDimensionsRef.current
        );
        cachedDimensionsRef.current = newDimensions;
        if (dimensionsChanged) {
          setDimensions(newDimensions);
        }
      });
    }
  }, [node]);

  useLayoutEffect(() => {
    measure();

    window.addEventListener("resize", measure);

    return () => {
      window.removeEventListener("resize", measure);
    };
  }, [node, measure]);

  return [ref, dimensions, node, measure];
}
