import getCopy from "@syntensor/common/data/copy";
import { IStudyComment } from "@syntensor/common/types";

export function groupCommentsToReplies(comments: IStudyComment[]) {
  //  first get all top level comments
  const replyComments: IStudyComment[] = [];

  const commentsDict = comments.reduce(
    (
      acc: Record<string, IStudyComment & { replies?: IStudyComment[] }>,
      comment: IStudyComment
    ) => {
      if (!comment.parentCommentId) {
        acc[comment.id] = {
          ...comment,
          replies: [],
        };
      } else {
        replyComments.push(comment);
      }

      return acc;
    },
    {}
  );

  //  add replies to top level comments
  replyComments.forEach((reply) => {
    if (reply.parentCommentId) {
      if (commentsDict[reply.parentCommentId]) {
        commentsDict[reply.parentCommentId].replies?.push(reply);
      } else {
        //  if we don't have the parent comment, we might be searching
        commentsDict[reply.id] = reply;
      }
    }
  });

  return Object.values(commentsDict);
}

export function getNoCommentMessage({
  searchTerm = "",
  viewId = "",
  shouldDisplayAll = false,
}) {
  if (searchTerm) {
    return getCopy("studies_comments_no-comments-search");
  }

  const resolvedKey = shouldDisplayAll ? "all" : "unresolved";
  return getCopy(`studies_comments_no-comments-${viewId}-${resolvedKey}`);
}
