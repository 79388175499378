import { ChangeEvent, useCallback } from "react";
import { useQuery } from "react-query";

import {
  fetchCreateNewUser,
  fetchRoles,
  fetchGroups,
} from "@syntensor/common/data/fetch_data";
import useFormInput from "@syntensor/common/hooks/use_form_input";
import useFormSubmit from "@syntensor/common/hooks/use_form_submit";
import BackBtn from "@syntensor/common/components/back_btn";
import Loader from "@syntensor/common/components/loader";
import Button from "@syntensor/common/components/button";
import Input from "@syntensor/common/components/input";
import { navigateTo } from "@syntensor/common/browser_history";
import Table from "@syntensor/common/components/table";
import Select, { ISelectOption } from "@syntensor/common/components/select";
import { XIcon } from "@syntensor/common/components/icons";
import getCopy from "@syntensor/common/data/copy";

import { reactQueryOptions } from "../../config";
import { IsUserInAdminGroup } from "../../auth/auth_context";

import styles from "./new_user_form.module.css";

export function getDefaultRoleGroup() {
  return { group: "1", role: "3" };
}

export function renderIndex(
  _: unknown,
  __: unknown,
  ___: unknown,
  index: number
) {
  return <span>{index + 1}.</span>;
}

export function renderRole(
  _: unknown,
  row: Record<string, string> = {},
  col: {
    roles?: ISelectOption[];
    onChange?: (index: number, value: string) => void;
  } = {},
  index = 0
) {
  const { role } = row;
  const { roles, onChange } = col;

  return (
    <>
      {roles && (
        <Select
          name="role"
          value={role}
          options={roles}
          onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
            if (onChange) {
              onChange(index, evt.target.value);
            }
          }}
          size="small"
        />
      )}
    </>
  );
}

export function renderGroup(
  _: unknown,
  row: Record<string, string> = {},
  col: {
    groups?: ISelectOption[];
    onChange?: (index: number, value: string) => void;
  } = {},
  index = 0
) {
  const { group } = row;
  const { groups, onChange } = col;

  return (
    <>
      {groups && (
        <Select
          name="group"
          value={group}
          options={groups}
          onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
            if (onChange) {
              onChange(index, evt.target.value);
            }
          }}
          size="small"
        />
      )}
    </>
  );
}

export function renderDelete(
  _: unknown,
  __: unknown,
  col: {
    onClick?: (index: number) => void;
  } = {},
  index = 0
) {
  const { onClick } = col;
  return (
    <div className={styles.deleteBtn}>
      <Button
        role="white"
        onClick={() => {
          if (onClick) {
            onClick(index);
          }
        }}
      >
        <XIcon />
      </Button>
    </div>
  );
}

export default function AdminNewUserForm() {
  const backUrl = `/users`;

  const isUserInAdminGroup = IsUserInAdminGroup();

  const rolesQueryKey = ["admin", "users", "roles"];
  const {
    data: roles = [],
    isLoading: isRolesLoading,
    // error,
  } = useQuery(rolesQueryKey, fetchRoles, reactQueryOptions);

  const groupsQueryKey = ["admin", "users", "groups"];
  const {
    data: groups = [],
    isLoading: isGroupsLoading,
    // error,
  } = useQuery(groupsQueryKey, fetchGroups, reactQueryOptions);

  const { inputs, handleInputChange } = useFormInput({
    initialState: { roleGroups: [getDefaultRoleGroup()] },
  });

  const handleNewGroup = useCallback(() => {
    const newInputs = [
      {
        name: "roleGroups",
        value: [...inputs.roleGroups, getDefaultRoleGroup()],
      },
    ];
    handleInputChange(newInputs);
  }, [inputs, handleInputChange]);

  const handleRoleChange = useCallback(
    (index: number, value: string) => {
      const roleGroups = [...inputs.roleGroups];
      roleGroups[index].role = value;
      const newInputs = [{ name: "roleGroups", value: roleGroups }];
      handleInputChange(newInputs);
    },
    [inputs, handleInputChange]
  );

  const handleGroupChange = useCallback(
    (index: number, value: string) => {
      const roleGroups = [...inputs.roleGroups];
      roleGroups[index].group = value;
      const newInputs = [{ name: "roleGroups", value: roleGroups }];
      handleInputChange(newInputs);
    },
    [inputs, handleInputChange]
  );

  const handleDeleteClick = useCallback(
    (index: number) => {
      const roleGroups = [...inputs.roleGroups];
      roleGroups.splice(index, 1);
      const newInputs = [{ name: "roleGroups", value: roleGroups }];
      handleInputChange(newInputs);
    },
    [inputs, handleInputChange]
  );

  const onSubmit = useCallback(async () => {
    const { email, firstName, lastName, role, company, roleGroups } = inputs;
    const isValidSubmission =
      email &&
      firstName &&
      lastName &&
      role &&
      company &&
      Array.isArray(roleGroups);

    if (roleGroups.length === 0) {
      throw new Error("User needs to be assigned to some groups.");
    }

    if (isValidSubmission) {
      const roleIds = roleGroups.map(({ role }) => role);
      const groupIds = roleGroups.map(({ group }) => group);
      const payload = {
        email,
        firstName,
        lastName,
        role,
        company,
        roleIds: roleIds.join(","),
        groupIds: groupIds.join(","),
      };

      //  construct payload for the REST call
      await fetchCreateNewUser(payload);
      navigateTo(backUrl);
    } else {
      throw new Error("Missing params for simulation");
    }
  }, [inputs, backUrl]);

  const {
    isLoading: isFormLoading,
    errorMsg,
    handleSubmit,
  } = useFormSubmit({
    onSubmit,
    onSuccessMsg: "",
  });

  if (isRolesLoading && isGroupsLoading) {
    return <Loader />;
  }

  //  make sure user has permission to
  //  has the user have permissions to delete and (un)publish
  //  just to provide the UI or not (API is doing its own authentication)
  if (!isUserInAdminGroup) {
    return <div>You don&apos;t have permission to create users.</div>;
  }

  const tableCols = [
    {
      id: "index",
      name: "",
      cellRenderer: renderIndex,
    },
    {
      id: "group",
      name: "Group",
      groups,
      cellRenderer: renderGroup,
      onChange: handleGroupChange,
    },
    {
      id: "role",
      name: "Role",
      roles,
      cellRenderer: renderRole,
      onChange: handleRoleChange,
    },
    {
      id: "delete",
      name: "Remove",
      width: 50,
      cellRenderer: renderDelete,
      onClick: handleDeleteClick,
    },
  ];

  return (
    <div className={styles.newUserForm}>
      <header className={styles.header}>
        <div className={styles.backBtn}>
          <BackBtn to={backUrl} />
        </div>
        <h1 className={styles.title}>{getCopy("admin_users_title")} |&nbsp;</h1>
        <h2 className={styles.subtitle}>{getCopy("admin_users_new-title")}</h2>
      </header>
      <div className={styles.content}>
        <form onSubmit={handleSubmit}>
          <div className={styles.formSection}>
            <h3 className={styles.formTitle}>
              {getCopy("admin_users_list-email")}
            </h3>
            <Input
              placeholder="ceo@big-company.com"
              name="email"
              type="email"
              value={inputs.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.formSection}>
            <h3 className={styles.formTitle}>
              {getCopy("admin_users_list-first-name")}
            </h3>
            <Input
              placeholder="Karen"
              name="firstName"
              value={inputs.firstName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.formSection}>
            <h3 className={styles.formTitle}>
              {getCopy("admin_users_list-last-name")}
            </h3>
            <Input
              placeholder="Smith"
              name="lastName"
              value={inputs.lastName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.formSection}>
            <h3 className={styles.formTitle}>
              {getCopy("admin_users_list-role")}
            </h3>
            <Input
              placeholder="Role in the big company"
              name="role"
              value={inputs.role}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.formSection}>
            <h3 className={styles.formTitle}>
              {getCopy("admin_users_list-company")}
            </h3>
            <Input
              placeholder="Big company name"
              name="company"
              value={inputs.company}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.formSection}>
            <div className={styles.formHeader}>
              <div>
                <h3 className={styles.formTitle}>
                  {getCopy("admin_users_new-user-group-title")}
                </h3>
              </div>
              <div>
                <Button role="secondary" onClick={handleNewGroup}>
                  {getCopy("admin_users_new-user-group")}
                </Button>
              </div>
            </div>
            <div>
              <Table
                config={{
                  cols: tableCols,
                }}
                data={inputs.roleGroups}
              />
            </div>
          </div>
          <div className={styles.btn}>
            <Button isDisabled={isFormLoading} role="primary" type="submit">
              {isFormLoading && <Loader size="32px" />}
              {getCopy("admin_users_new-user-confirm")}
            </Button>
          </div>
          {errorMsg && <span className={styles.error}>{errorMsg}</span>}
        </form>
      </div>
    </div>
  );
}
