//  @TODO - this should be versioned and coming from ST Graph API endpoint
export const PATHWAY_CATEGORIES = [
  { id: "Autophagy", name: "Autophagy" },
  { id: "Cell Cycle", name: "Cell Cycle" },
  { id: "Cell-Cell communication", name: "Cell-Cell communication" },
  {
    id: "Cellular responses to stimuli",
    name: "Cellular responses to stimuli",
  },
  { id: "Chromatin organization", name: "Chromatin organization" },
  { id: "Circadian Clock", name: "Circadian Clock" },
  { id: "Developmental Biology", name: "Developmental Biology" },
  { id: "Digestion and absorption", name: "Digestion and absorption" },
  { id: "Disease", name: "Disease" },
  { id: "DNA Repair", name: "DNA Repair" },
  { id: "DNA Replication", name: "DNA Replication" },
  { id: "Drug ADME", name: "Drug ADME" },
  {
    id: "Extracellular matrix organization",
    name: "Extracellular matrix organization",
  },
  {
    id: "Gene expression (Transcription)",
    name: "Gene expression (Transcription)",
  },
  { id: "Hemostasis", name: "Hemostasis" },
  { id: "Immune System", name: "Immune System" },
  { id: "Metabolism", name: "Metabolism" },
  { id: "Metabolism of proteins", name: "Metabolism of proteins" },
  { id: "Metabolism of RNA", name: "Metabolism of RNA" },
  { id: "Muscle contraction", name: "Muscle contraction" },
  { id: "Neuronal System", name: "Neuronal System" },
  {
    id: "Organelle biogenesis and maintenance",
    name: "Organelle biogenesis and maintenance",
  },
  { id: "Programmed Cell Death", name: "Programmed Cell Death" },
  { id: "Protein localization", name: "Protein localization" },
  { id: "Reproduction", name: "Reproduction" },
  { id: "Sensory Perception", name: "Sensory Perception" },
  { id: "Signal Transduction", name: "Signal Transduction" },
  { id: "Signaling Pathways", name: "Signaling Pathways" },
  {
    id: "Transport of small molecules",
    name: "Transport of small molecules",
  },
  { id: "Vesicle-mediated transport", name: "Vesicle-mediated transport" },
];
