import { useRouteMatch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import cn from "classnames";

import MainNavLink from "@syntensor/common/components/main_nav_link";
import getCopy from "@syntensor/common/data/copy";
import {
  SearchIcon,
  SpeechBubbleIcon,
} from "@syntensor/common/components/icons";
import Button from "@syntensor/common/components/button";
import { fetchStudyComments } from "@syntensor/common/data/fetch_data";
import { EStudyType } from "@syntensor/common/types";

import {
  selectComments,
  setHasPageComments,
  setHasStudyComments,
  toggleAreCommentsOpen,
} from "../../store/comments";
import { IStudyMatchParams } from "../types";

import styles from "./header.module.css";

export interface IHeaderProps {
  studyType: EStudyType;
  onSearchClick?: () => void;
}

export default function Header({ studyType, onSearchClick }: IHeaderProps) {
  const { url, params } = useRouteMatch<IStudyMatchParams>();
  const { studyId } = params;

  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const { areCommentsOpen, hasStudyComments, hasPageComments } =
    useSelector(selectComments);

  const handleCommentsClick = () => {
    dispatch(toggleAreCommentsOpen(!areCommentsOpen));
  };

  //  all comments  query
  const allCommentsQueryKey = ["comments", studyId];
  useQuery<void>(allCommentsQueryKey, async () => {
    const comments = await fetchStudyComments(studyId);
    const hasStudyComments = comments.length > 0;
    dispatch(setHasStudyComments(hasStudyComments));
  });

  //  page comments query
  const pageCommentsQueryKey = ["comments", studyId, "page", pathname];
  useQuery<void>(pageCommentsQueryKey, async () => {
    const comments = await fetchStudyComments(studyId, { path: pathname });
    const hasPageComments = comments.length > 0;
    dispatch(setHasPageComments(hasPageComments));
  });

  const commentsBtnClassName = cn(styles.iconBtn, {
    [styles.commentsBtnActive]: areCommentsOpen,
  });

  const hasEndpoints =
    studyType === EStudyType.POTENCY || studyType == EStudyType.TOXICITY;

  return (
    <header className={styles.header}>
      <nav className={styles.nav}>
        <ul className={styles.list}>
          <li>
            <MainNavLink
              to={`${url}/overview`}
              activeClassName={styles.activeLink}
            >
              {getCopy("studies_menu_overview")}
            </MainNavLink>
          </li>
          {hasEndpoints && (
            <li>
              <MainNavLink
                to={`${url}/endpoints`}
                activeClassName={styles.activeLink}
              >
                {getCopy("studies_menu_endpoints")}
              </MainNavLink>
            </li>
          )}
          <li>
            <MainNavLink to={`${url}/moa`} activeClassName={styles.activeLink}>
              {getCopy("studies_menu_moa")}
            </MainNavLink>
          </li>
          <li>
            <MainNavLink
              to={`${url}/pathways`}
              activeClassName={styles.activeLink}
            >
              {getCopy("studies_menu_pathways")}
            </MainNavLink>
          </li>
          <li>
            <MainNavLink
              to={`${url}/components`}
              activeClassName={styles.activeLink}
            >
              {getCopy("studies_menu_components")}
            </MainNavLink>
          </li>
          <li className={styles.iconItem}>
            <Button
              className={styles.iconBtn}
              onClick={onSearchClick}
              data-testid="header_search-btn"
            >
              <SearchIcon />
            </Button>
          </li>
          <li className={styles.iconItem}>
            <Button
              className={commentsBtnClassName}
              onClick={handleCommentsClick}
              data-testid="header_comments-btn"
            >
              <SpeechBubbleIcon />
              {hasStudyComments && (
                <span
                  className={`${styles.commentsDot} ${
                    hasPageComments && styles.pageComments
                  }`}
                />
              )}
            </Button>
          </li>
        </ul>
      </nav>
    </header>
  );
}
