import { MouseEvent, useCallback } from "react";

import ViewToggleBtn, { TIconProp } from "./view_toggle_btn";
import {
  ChartsIcon,
  HeatMapIcon,
  HexagonMapIcon,
  LineChartIcon,
  ListIcon,
  NetworkIcon,
  PresetsIcon,
} from "../icons";

import styles from "./view_toggle.module.css";

export const VIEWS_MAP: Record<string, { id: string; Icon: TIconProp }> = {
  table: {
    id: "table",
    Icon: ListIcon,
  },
  heatmap: {
    id: "heatmap",
    Icon: HeatMapIcon,
  },
  linechart: {
    id: "linechart",
    Icon: LineChartIcon,
  },
  presents: {
    id: "presents",
    Icon: PresetsIcon,
  },
  barchart: {
    id: "barchart",
    Icon: ChartsIcon,
  },
  hexagon: {
    id: "hexagon",
    Icon: HexagonMapIcon,
  },
  network: {
    id: "network",
    Icon: NetworkIcon,
  },
};

export interface IViewToggleProps {
  btns: {
    id: string;
    label?: string;
    isDisabled?: boolean;
    icon?: string;
    to?: string;
    exact?: boolean;
  }[];
  onClick?: (viewId: string) => void;
  activeView?: string;
  theme?: string;
}

export default function ViewToggle({
  activeView = "activeView",
  onClick,
  btns,
  theme = "subNav",
}: IViewToggleProps) {
  const handleClick = useCallback(
    (evt: MouseEvent<HTMLElement>) => {
      if (onClick && typeof onClick === "function") {
        const viewId = evt.currentTarget.dataset["viewId"];
        if (viewId) {
          onClick(viewId);
        }
      }
    },
    [onClick]
  );

  const btnComponents = btns.reduce<{ id: string; [key: string]: unknown }[]>(
    (acc, btn) => {
      if (typeof btn === "string") {
        acc.push(VIEWS_MAP[btn]); // || btn;
      }

      if (btn) {
        if (btn.icon) {
          acc.push({
            ...VIEWS_MAP[btn.icon],
            ...btn,
          });
        } else {
          acc.push(btn);
        }
      }

      return acc;
    },
    []
  );
  //  const validBtnComponents = btnComponents.filter((btn) => btn !== null);

  return (
    <ul className={styles.viewToggle}>
      {btnComponents.map((btnProps, i: number) => {
        return (
          <li key={i}>
            <ViewToggleBtn
              {...btnProps}
              size="small"
              theme={theme}
              activeView={activeView}
              onClick={handleClick}
            />
          </li>
        );
      })}
    </ul>
  );
}
