// @ts-ignore missing type for lib
import Cookies from "js-cookie";
import { hasCookie, setCookie } from "../libs/cookie";

import { getCurrentUrl } from "../utils/url_utils";

export const FUCTIONAL_COOKIE_TYPE = "SYNTENSOR_FUCTIONAL_COOKIE_TYPE";
export const ANALYTICAL_COOKIE_TYPE = "SYNTENSOR_ANALYTICAL_COOKIE_TYPE";

export const GA_ID =
  process.env.REACT_APP_GA_MEASUREMENT_ID ||
  process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;
export const HAS_SITE_TRACKING_ENABLED =
  process.env.REACT_APP_TRACKING_ENABLED === "true" ||
  process.env.NEXT_PUBLIC_TRACKING_ENABLED === "true";

export const hasFunctionalCookiesEnabled = true;
export const hasAnalyticalCookiesEnabled = true;

//  necessary cookies always on
export const HAS_NECESSARY_COOKIES_ENABLED = true;

export const RANK_CHANGED = "RANK-CHANGED";
export const FILTER_CHANGED = "FILTER-CHANGED";

//  necessary types for globals added by analytics libraries
declare global {
  interface Window {
    // ⚠️ notice that "Window" is capitalized here
    Intercom: (evtName: string, params?: unknown, metadata?: unknown) => void;
    gtag: (evtName: string, params?: unknown, metadata?: unknown) => void;
    _paq: unknown[];
  }
}

export function hasIntercom() {
  return window && window.Intercom && typeof window.Intercom === "function";
}

export function hasGA() {
  return window && window.gtag && typeof window.gtag === "function";
}

export function hasMatomo() {
  return window && window._paq && typeof window._paq === "object";
}

export async function hasTrackingEnabled() {
  const isOptedOut = await getOptOutStatus();
  return !isOptedOut;
}

export function initAnalytics() {
  if (hasMatomo()) {
    //  We want cookie consent, tracking requests will be
    //  always sent. However, cookies will be only used if
    //  consent for storing and using cookies was given by the user.
    window._paq.push(["requireCookieConsent"]);
  }
}

export function updateAnalyticsPreference(cookiesToEnable: string[] = []) {
  if (cookiesToEnable.includes(FUCTIONAL_COOKIE_TYPE)) {
    Cookies.set(FUCTIONAL_COOKIE_TYPE, "true");
  } else {
    Cookies.remove(FUCTIONAL_COOKIE_TYPE);
  }
  if (cookiesToEnable.includes(ANALYTICAL_COOKIE_TYPE)) {
    Cookies.set(ANALYTICAL_COOKIE_TYPE, "true");
  } else {
    Cookies.remove(ANALYTICAL_COOKIE_TYPE);
  }
}

export async function trackEvent(event: unknown, metadata = {}) {
  const shouldTrack = await hasTrackingEnabled();
  if (!shouldTrack) {
    return;
  }

  if (hasIntercom()) {
    window.Intercom("trackEvent", event, metadata);
  }
  if (hasGA()) {
    //  convert metadata to ga format which
    //  is accepted
    const gaMetadata = Object.keys(metadata).reduce<Record<string, string>>(
      (acc, key, index) => {
        if (key === "category") {
          acc["event_category"] = acc[key];
        } else if (key === "label") {
          acc["event_label"] = acc[key];
        } else if (index === 0) {
          //  if no standardised names, just generic
          //  tracking info
          acc["event_label"] = acc[key];
        }
        return acc;
      },
      {}
    );
    window.gtag("event", event, gaMetadata);
  }
  if (hasMatomo()) {
    //  metadata for a matomo event is supposed to be an array which
    //  corresponds to: [Event Action, Event Name, Event Value (numeric)]
    //  the event param is then Event Category
    if (Array.isArray(metadata)) {
      const matomoEvtData = ["trackEvent", event, ...metadata];
      window._paq.push(matomoEvtData);
    } else {
      console.error("Passing invalid param for tracking matomo event");
    }
  }
}

export async function trackGoal(goalId: string | number) {
  const shouldTrack = await hasTrackingEnabled();
  if (!shouldTrack) {
    return;
  }

  if (hasMatomo()) {
    const matomoEvtData = ["trackGoal", goalId];
    window._paq.push(matomoEvtData);
  }
}

export async function showFeedbackForm() {
  const shouldTrack = await hasTrackingEnabled();
  if (!shouldTrack) {
    return;
  }

  if (hasIntercom()) {
    window.Intercom("showNewMessage");
  }
}

export async function trackPageView() {
  const shouldTrack = await hasTrackingEnabled();
  if (!shouldTrack) {
    return;
  }

  if (hasIntercom()) {
    window.Intercom("update", { url: getCurrentUrl() });
  }
  if (hasGA()) {
    window.gtag("config", GA_ID, { page_path: getCurrentUrl() });
  }
  if (hasMatomo()) {
    // window._paq.push(['setDocumentTitle', 'My New Title']);
    window._paq.push(["trackPageView"]);
  }
}

export async function trackMatomo(args: unknown) {
  if (hasMatomo()) {
    window._paq.push(args);
  }
}

//  GPDR requires 1-year
export const TRACKING_PREFERENCE_EXPIRATION_IN_HOURS = 24 * 365;
export const TRACKING_PREFERENCE_SET = "syntensor_tracking_preference_set";

export function setTrackingPreference() {
  setCookie(
    TRACKING_PREFERENCE_SET,
    "true",
    TRACKING_PREFERENCE_EXPIRATION_IN_HOURS
  );
}

export function isTrackingPreferenceSet() {
  return hasCookie(TRACKING_PREFERENCE_SET);
}

export function giveAnalyticalCookieConsent() {
  if (hasMatomo()) {
    window._paq.push([
      "rememberCookieConsentGiven",
      TRACKING_PREFERENCE_EXPIRATION_IN_HOURS,
    ]);

    //  @TODO - should automatically override optout
    cancelOptOutTracking();
  }
}

export function revokeAnalyticalCookieConsent() {
  if (hasMatomo()) {
    window._paq.push(["forgetCookieConsentGiven"]);
  }
}

export function optOutTracking() {
  if (hasMatomo()) {
    window._paq.push(["optUserOut"]);
  }
}

export function cancelOptOutTracking() {
  if (hasMatomo()) {
    window._paq.push(["forgetUserOptOut"]);
  }
}

export function getAnalyticalCookieConsent(): Promise<boolean> {
  return new Promise((resolve) => {
    if (hasMatomo()) {
      window._paq.push([
        function () {
          //  do we have a timestamp of given consent
          const hasRememberedCookieConsent =
            // @ts-ignore this missing annotation
            this.getRememberedCookieConsent() > 0;
          resolve(hasRememberedCookieConsent);
        },
      ]);
    } else {
      resolve(false);
    }
  });
}

export function getOptOutStatus(): Promise<boolean> {
  return new Promise((resolve) => {
    if (hasMatomo()) {
      window._paq.push([
        function () {
          // @ts-ignore this missing annotation
          if (this.isUserOptedOut()) {
            resolve(true);
          } else {
            resolve(false);
          }
        },
      ]);
    } else {
      resolve(false);
    }
  });
}
