import { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";

import ViewToggle from "@syntensor/common/components/view_toggle";
import { IStudyContextData } from "@syntensor/common/types";
import getCopy from "@syntensor/common/data/copy";
import Select from "@syntensor/common/components/select";

import StudiesTitle from "../title";
import { STUDY_URL_PREFIX } from "../routes";
import StudyComponentsGrid from "./components_grid";
import { IStudyMatchParams } from "../types";
import { StudyDataContext } from "..";

import styles from "../studies.module.css";

export default function StudyComponents() {
  const studyData = useContext<IStudyContextData | null>(StudyDataContext);
  const { cellLines = [], componentTypes: availableComponentTypes = [] } =
    studyData || {};
  const cellLineIds = cellLines.map((cellLine) => cellLine.id);

  const studyMatch = useRouteMatch<IStudyMatchParams>(
    `${STUDY_URL_PREFIX}/components`
  );
  const url = studyMatch?.url;

  return (
    <>
      <div className={styles.titles}>
        <StudiesTitle />
      </div>
      <div className={styles.sectionHeader}>
        <div className={styles.controls}>
          <div className={styles.controlRow}>
            <div className={styles.mainControl}>
              <ViewToggle
                theme="mainNav"
                btns={[
                  {
                    id: "genes",
                    to: `${url}/genes`,
                    exact: true,
                    isDisabled: !availableComponentTypes.includes("gene"),
                    label: getCopy("studies_components_genes"),
                  },
                  {
                    id: "transcripts",
                    to: `${url}/transcripts`,
                    exact: true,
                    isDisabled: !availableComponentTypes.includes("transcript"),
                    label: getCopy("studies_components_transcripts"),
                  },
                  {
                    id: "proteins",
                    to: `${url}/proteins`,
                    exact: true,
                    isDisabled: !availableComponentTypes.includes("protein"),
                    label: getCopy("studies_components_proteins"),
                  },
                  {
                    id: "complexes",
                    to: `${url}/complexes`,
                    exact: true,
                    isDisabled: !availableComponentTypes.includes("complex"),
                    label: getCopy("studies_components_complexes"),
                  },
                ]}
              />
            </div>
            <div className={styles.noGrowControl}>
              <Select
                size="small"
                label={getCopy("studies_components_cellline-dropdown")}
                options={cellLineIds.map((cellLineId) => {
                  return { id: cellLineId, name: cellLineId };
                })}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sectionContent}>
        <Switch>
          {availableComponentTypes.includes("gene") && (
            <Route
              path={`${STUDY_URL_PREFIX}/components/genes`}
              component={() => <StudyComponentsGrid componentType="gene" />}
            />
          )}
          {availableComponentTypes.includes("transcript") && (
            <Route
              path={`${STUDY_URL_PREFIX}/components/transcripts`}
              component={() => (
                <StudyComponentsGrid componentType="transcript" />
              )}
            />
          )}
          {availableComponentTypes.includes("protein") && (
            <Route
              path={`${STUDY_URL_PREFIX}/components/proteins`}
              component={() => <StudyComponentsGrid componentType="protein" />}
            />
          )}
          {availableComponentTypes.includes("complex") && (
            <Route
              path={`${STUDY_URL_PREFIX}/components/complexes`}
              component={() => <StudyComponentsGrid componentType="complex" />}
            />
          )}
          {availableComponentTypes[0] && (
            <Redirect
              from={`${STUDY_URL_PREFIX}/components`}
              to={`${STUDY_URL_PREFIX}/components/${availableComponentTypes[0]}s`}
            />
          )}
        </Switch>
      </div>
    </>
  );
}
