import { ReactNode } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import styles from "./loader.module.css";

export interface ILoaderProps {
  className?: string;
  children?: ReactNode;
  size?: number | string;
}

export default function Loader({
  className = "",
  children = null,
  size = "44px",
}: ILoaderProps) {
  const hasChildren = !!children;

  return (
    <div className={`${styles.loader} ${className}`}>
      {!hasChildren && (
        <div className={styles.preloader}>
          <ClipLoader size={size} color={"var(--primary-dark)"} />
        </div>
      )}
      {hasChildren && children}
    </div>
  );
}
