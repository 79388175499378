import {
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { AuthContext } from "../auth/auth_context";
import {
  getCurrentParam,
  navigateTo,
  navigateWithQueryParams,
} from "@syntensor/common/browser_history";
import Input from "@syntensor/common/components/input";
import SubmitBtn from "@syntensor/common/components/submit_btn";
import { confirmUser } from "@syntensor/common/data/fetch_data";
import useFormInput from "@syntensor/common/hooks/use_form_input";
import useFormSubmit from "@syntensor/common/hooks/use_form_submit";
import StatusMsgs from "./status_msgs";
import getCopy from "@syntensor/common/data/copy";

import classes from "./profile.module.css";

export default function Signup() {
  const elRef = useRef<HTMLDivElement>(null);

  const { replaceTempPassword, finishSignup } = useContext(AuthContext);
  const [termsAgreed, setTermsAgreed] = useState(
    () => !!getCurrentParam("termsAgreed")
  );

  const { inputs, handleInputChange } = useFormInput({
    initialState: {},
  });

  const onSubmit = useCallback(async () => {
    if (!inputs.password) {
      throw new Error(getCopy("profile_signup_password-validation-error-msg"));
    }

    if (!termsAgreed) {
      throw new Error(getCopy("profile_signup_tc-validation-error-msg"));
    }

    try {
      if (replaceTempPassword && finishSignup) {
        //  confirm user on congito
        await replaceTempPassword(inputs.password);

        //  confirm in the app REST API
        await confirmUser();

        //  set correct status on auth content
        await finishSignup();
        navigateTo("/");
      } else {
        throw new Error("Missing required functions");
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        //  throw so that useFormInput hook picks up
        //  the error and it gets displayed on the form
        throw new Error(err.message);
      }
    }
  }, [inputs, termsAgreed, replaceTempPassword, finishSignup]);

  const { isLoading, errorMsg, handleSubmit } = useFormSubmit({
    onSubmit,
  });

  const handleTermsAgreed = useCallback(() => {
    setTermsAgreed(!termsAgreed);
  }, [termsAgreed]);

  const handleTermsClick = useCallback((evt: MouseEvent) => {
    evt.preventDefault();
    if (evt.target instanceof HTMLElement) {
      const href = evt.target.getAttribute("href");
      if (href) {
        navigateWithQueryParams(href);
      }
    }
  }, []);

  useLayoutEffect(() => {
    if (elRef.current) {
      const tcLink = elRef.current.querySelector("#tc-link") as HTMLElement;

      if (tcLink) {
        tcLink.onclick = handleTermsClick;
      }

      const termsLink = elRef.current.querySelector(
        "#terms-link"
      ) as HTMLElement;
      if (termsLink) {
        termsLink.onclick = handleTermsClick;
      }
    }

    return;
  }, [handleTermsClick]);

  return (
    <div ref={elRef} className={classes.changeDetails}>
      <div className={classes.content}>
        <h2
          className={classes.title}
          dangerouslySetInnerHTML={{ __html: getCopy("profile_signup_title") }}
        />
        <div
          className={classes.description}
          dangerouslySetInnerHTML={{
            __html: getCopy("profile_signup_description"),
          }}
        />
        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.row}>
            <Input
              required
              name="password"
              placeholder={getCopy("profile_signup_password")}
              type="password"
              value={inputs.password}
              onChange={handleInputChange}
              autoComplete="new-password"
            />
          </div>
          <div className={classes.row}>
            <label>
              <input
                className={classes.checkbox}
                type="checkbox"
                onChange={handleTermsAgreed}
                defaultChecked={termsAgreed}
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: getCopy("profile_signup_link"),
                }}
              />
            </label>
          </div>
          <StatusMsgs errorMsg={errorMsg} />
          <div className={classes.buttonRow}>
            <SubmitBtn isLoading={isLoading} role="secondary" type="submit">
              {getCopy("profile_signup_cta")}
            </SubmitBtn>
          </div>
        </form>
      </div>
    </div>
  );
}
