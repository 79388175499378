import { useCallback } from "react";
import { useQuery } from "react-query";

import { reactQueryOptions } from "../../config";
import Loader from "@syntensor/common/components/loader";
import Error from "@syntensor/common/components/error";

import { fetchGraphMetrics } from "@syntensor/common/data/fetch_data";
import useMemoMdxComponent from "@syntensor/common/hooks/use_memo_mdx_component";
import {
  GraphOverviewTableSection,
  ComponentTypeStatisticsSection,
  PathwaysTable,
  MostConnectedNodesSection,
  DatasourcesTable,
} from "./sections";

//  @TODO - problems with importing markdown documents
// import GRAPH_VERSION from "../../../_content/metrics/graph_version.md";
import { GRAPH_VERSION } from "../coverage";

import GraphContent from "../../../_content/metrics/graph.mdx";
import { mdxComponents } from "../markdown_components";

import styles from "../metrics.module.css";

export default function MetricsGraph() {
  const fetchMetrics = useCallback(async () => {
    return await fetchGraphMetrics(GRAPH_VERSION);
  }, []);

  const queryKey = ["metrics"];
  const {
    data: metricsGraphData = [],
    isLoading,
    error,
  } = useQuery(queryKey, fetchMetrics, reactQueryOptions);

  //  get all nodes statistics
  const allNodes =
    metricsGraphData && metricsGraphData.counts
      ? metricsGraphData.counts.find(
          (count: Record<string, string>) => count["Component Type"] === "all"
        )
      : null;
  const nonAllTypes =
    metricsGraphData && metricsGraphData.counts
      ? metricsGraphData.counts.filter(
          (count: Record<string, string>) => count["Component Type"] !== "all"
        )
      : null;

  const mostConnectedNodes =
    metricsGraphData && metricsGraphData.counts
      ? metricsGraphData.counts.reduce(
          (acc: Record<string, unknown>, count: Record<string, unknown>) => {
            acc[count["Component Type"] as string] =
              count["Top 10 most connected nodes"];
            return acc;
          },
          {}
        )
      : null;

  const GraphOverviewTable = useMemoMdxComponent(GraphOverviewTableSection, {
    allNodes,
  });

  const ComponentTypeStatistics = useMemoMdxComponent(
    ComponentTypeStatisticsSection,
    {
      nonAllTypes,
    }
  );

  const Pathways = useMemoMdxComponent(PathwaysTable, { metricsGraphData });

  const MostConnectedNodes = useMemoMdxComponent(MostConnectedNodesSection, {
    mostConnectedNodes,
  });

  const Datasources = useMemoMdxComponent(DatasourcesTable, {
    metricsGraphData,
  });

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const components = {
    ...mdxComponents,
    GraphOverviewTable,
    ComponentTypeStatistics,
    Pathways,
    MostConnectedNodes,
    Datasources,
  };

  return (
    <div className={styles.content}>
      <GraphContent
        components={components}
        allNodes={allNodes}
        nonAllTypes={nonAllTypes}
        mostConnectedNodes={mostConnectedNodes}
        metricsGraphData={metricsGraphData}
      />
    </div>
  );
}
