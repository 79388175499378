import { IStudyResolved } from "@syntensor/common/types";

export function getTemplateFromStudyData(templateStudy: IStudyResolved) {
  const {
    displayName,
    description,
    type,
    cellLines,
    compounds,
    componentTypes,
    targetExternalComponents,
    targetExternalPathways,
    endpoints,
  } = templateStudy;

  const cellLine = cellLines && cellLines.length ? cellLines[0].id : "";
  const targetExternalComponentIds = targetExternalComponents
    ? targetExternalComponents.join(",")
    : [];
  const targetExternalPathwayIds = targetExternalPathways
    ? targetExternalPathways.join(",")
    : [];

  //  convert endpoints to an array used in the frontend
  const formattedEndpoints = endpoints
    ? Object.keys(endpoints).map((endpointName) => {
        return {
          name: endpointName,
          ...endpoints[endpointName],
        };
      })
    : null;

  return {
    displayName,
    description,
    type,
    cellLine,
    compounds,
    targetExternalComponents: targetExternalComponentIds,
    targetExternalPathways: targetExternalPathwayIds,
    componentTypes,
    endpoints: formattedEndpoints,
  };
}
