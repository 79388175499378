import { useCallback } from "react";
import { Route, Switch } from "react-router-dom";

import { addDynamicCopy } from "@syntensor/common/data/copy";
import { navigateTo } from "@syntensor/common/browser_history";
import useCopy from "@syntensor/common/hooks/use_copy";
import Loader from "@syntensor/common/components/loader";

import Login from "../profile/login";
import Sidebar from "./sidebar";
import { AppRoutes } from "../routes";
import AuthProvider, {
  AuthIsNotSignedIn,
  AuthIsSignedIn,
  AuthIsTempSignedIn,
} from "../auth/auth_context";
import Signup from "../profile/signup";
import PrivacyTerms from "../privacy/terms";
import PrivacyPolicy from "../privacy/policy";
import PrivacyCookies from "../privacy/cookies";
import ForgotPassword from "../profile/forgot_password";
import ResetPassword from "../profile/reset_password";

import globalCsvCopy from "../../_content/global.csv";
import landingCsvCopy from "../../_content/landing.csv";
import placeholderCsvCopy from "../../_content/placeholder.csv";
import studiesCsvCopy from "../../_content/studies/studies.csv";
import studiesCommentsCsvCopy from "../../_content/studies/comments.csv";
import studiesEndpointsCsvCopy from "../../_content/studies/endpoints.csv";
import studiesOverviewCsvCopy from "../../_content/studies/overview.csv";
import metricsCsvCopy from "../../_content/metrics/metrics.csv";
import profileCsvCopy from "../../_content/profile.csv";
import adminCsvCopy from "../../_content/admin.csv";

import componentsCsvCopy from "@syntensor/common/_content/components.csv";

import classes from "./app.module.css";

const copyImports = [
  globalCsvCopy,
  landingCsvCopy,
  placeholderCsvCopy,
  studiesCsvCopy,
  studiesOverviewCsvCopy,
  studiesEndpointsCsvCopy,
  studiesCommentsCsvCopy,
  metricsCsvCopy,
  profileCsvCopy,
  adminCsvCopy,
  componentsCsvCopy,
];
const COPIES = copyImports.reduce((acc, copy) => {
  return acc.concat(copy);
}, []);

export default function App() {
  const handleLoginSuccessful = useCallback(() => {
    navigateTo("/projects");
  }, []);

  const handleTempLoginSuccessful = useCallback(() => {
    navigateTo("/profile/signup");
  }, []);

  const copy = useCopy(COPIES);
  addDynamicCopy(copy);

  const isLoading = copy ? Object.keys(copy).length === 0 : false;
  if (isLoading) {
    return <Loader />;
  }

  return (
    <AuthProvider>
      <AuthIsSignedIn>
        <div className={classes.app}>
          <div className={classes.sidebar}>
            <Sidebar />
          </div>
          <div className={classes.content}>
            <AppRoutes />
          </div>
        </div>
      </AuthIsSignedIn>
      <AuthIsTempSignedIn>
        <Switch>
          <Route path="/privacy/terms" component={PrivacyTerms} />
          <Route path="/privacy/policy" component={PrivacyPolicy} />
          <Route path="/privacy/cookies" component={PrivacyCookies} />
          <Route path="/profile/signup" component={Signup} />
        </Switch>
      </AuthIsTempSignedIn>
      <AuthIsNotSignedIn>
        <Switch>
          <Route path={`/profile/forgot-password`} component={ForgotPassword} />
          <Route path={`/profile/reset-password`} component={ResetPassword} />
          <Route
            render={() => {
              return (
                <Login
                  onLoginSuccessful={handleLoginSuccessful}
                  onTempLoginSuccessful={handleTempLoginSuccessful}
                />
              );
            }}
          />
        </Switch>
      </AuthIsNotSignedIn>
    </AuthProvider>
  );
}
