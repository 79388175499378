import { createContext, useContext } from "react";

import { IProject } from "@syntensor/common/types";

import Header from "./header";
import ProjectRoutes from "./routes";

import styles from "./project.module.css";

export const PROJECT_UPDATE_INTERVAL = 10000;

export const ProjectDataContext = createContext<IProject | null>(null);

export const isUserProjectAdmin = () => {
  const projectData = useContext(ProjectDataContext);
  if (projectData) {
    return (
      projectData.roleScope === "admin" ||
      projectData.roleScope === "super-admin"
    );
  }
  return false;
};

export default function Project() {
  return (
    <div className={styles.project}>
      <div className={styles.header}>
        <Header />
      </div>
      <div className={styles.content}>
        <ProjectRoutes />
      </div>
    </div>
  );
}
