import { createElement, ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import cn from "classnames";

import styles from "./tooltip.module.css";

export interface ITooltipProps {
  children: ReactNode;
  tooltip?: string | ReactNode;
  elementType?: string;
  elStyle?: { [key: string]: unknown };
  placement?: string;
}

export default function Tooltip({
  children,
  tooltip = "",
  elementType = "div",
  elStyle = {},
}: ITooltipProps) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [reference, setReference] = useState(null);
  const [popper, setPopper] = useState<HTMLDivElement | null>(null);
  // const [arrowNode, setArrowNode] = useState(null);

  const { styles: popperStyles } = usePopper(reference, popper, {
    modifiers: [
      // {
      //   name: "arrow",
      //   options: { element: arrowNode },
      // },
    ],
  });

  const handlePopupClick = () => {
    setIsPopupOpen(true);
  };

  const handleOpenClick = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    if (isPopupOpen && typeof window !== "undefined") {
      setTimeout(() => {
        window.addEventListener("click", handleOpenClick);
      });
    } else {
      window.removeEventListener("click", handleOpenClick);
    }

    return () => {
      window.removeEventListener("click", handleOpenClick);
    };
  }, [isPopupOpen]);

  //  @TODO - hacky way of setting max width if needed
  const isMultiline =
    tooltip && typeof tooltip === "string" && tooltip.length > 30;
  const tooltipClassName = cn(styles.popup, {
    [styles.popupMultiline]: isMultiline,
  });

  const allChildren = (
    <>
      {children}
      {isPopupOpen &&
        tooltip &&
        createPortal(
          <div ref={setPopper} style={{ ...popperStyles.popper, zIndex: 1 }}>
            <div className={tooltipClassName}>{tooltip}</div>
            {/* 
              NO ARROW FOR NOW UNTIL FIGURE OUT THE POSITIONING OF THE ARROW BETTER
              <div
              ref={setArrowNode}
              className={styles.arrow}
              style={{ ...popperStyles.arrow }}
            /> */}
          </div>,
          document.body
        )}
    </>
  );

  const wrapperEl = createElement(
    elementType,
    {
      ref: setReference,
      className: styles.tooltip,
      onClick: handlePopupClick,
      style: elStyle,
    },
    allChildren
  );

  return wrapperEl;
}
