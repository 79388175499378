import { ChangeEvent } from "react";
import cn from "classnames";

import { DownIcon, EIconSize } from "../icons";

import styles from "./select.module.css";

export interface ISelectOption {
  id: string;
  name: string;
  disabled?: boolean;
}
export interface ISelectProps {
  options: ISelectOption[];
  value?: string | number;
  name?: string;
  onChange?: (_: ChangeEvent<HTMLSelectElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  isDisabled?: boolean;
  isMultiple?: boolean;
  size?: "large" | "small";
  role?: "basic" | "primary" | "subtle";
  label?: string;
}

export default function Select({
  options,
  value,
  name,
  label = "",
  onChange,
  onFocus = () => null,
  onBlur = () => null,
  isDisabled = false,
  isMultiple = false,
  size = "large",
  role = "basic",
}: ISelectProps) {
  const classNames = cn(styles.wrapper, styles[size], styles[role], {
    [styles.disabled]: isDisabled,
  });

  return (
    <div className={classNames}>
      {label && <label className={styles.label}>{label}</label>}
      <select
        name={name}
        className={styles.select}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={isDisabled}
        multiple={isMultiple}
      >
        {options &&
          options.map(({ id, name, disabled = false }, index: number) => {
            return (
              <option disabled={disabled} key={index} value={id}>
                {name}
              </option>
            );
          })}
      </select>
      <DownIcon size={EIconSize.TINY} />
    </div>
  );
}
