import { NodeDefinition, EdgeDefinition, ElementsDefinition } from "cytoscape";

import { IStudyComponent } from "@syntensor/common/types";

export function processGraphData(
  graph: ElementsDefinition,
  proteinPerturbationData: IStudyComponent[]
) {
  const nodeLookup: Record<string, NodeDefinition> = {};

  graph.nodes.forEach((node: NodeDefinition) => {
    //  add syntensor data if available
    if (proteinPerturbationData) {
      const syntensorId = node.data.syntensorId;
      const syntensorData = proteinPerturbationData.find((protein) => {
        return protein.syntensorId === syntensorId;
      });
      if (syntensorData) {
        node.data.syntensor = syntensorData;
      }
    }

    if (node.data.id) {
      nodeLookup[node.data.id] = node;
    }
  });

  //  for each reaction node, see if there's a complex
  //  and if there is, remove all subnodes
  graph.nodes.forEach((node: NodeDefinition) => {
    const reactionClasses = ["association", "dissociation", "process"];
    const isReaction = reactionClasses.includes(node.data.class);

    if (isReaction) {
      //  get all reaction participants
      const reactionEdges = graph.edges.filter((edge: EdgeDefinition) => {
        return (
          edge.data.source === node.data.id || edge.data.target === node.data.id
        );
      });
      const reactionNodes: NodeDefinition[] = reactionEdges.reduce(
        (acc: NodeDefinition[], edge: EdgeDefinition) => {
          // return edge.data.source
          if (nodeLookup[edge.data.source]) {
            acc.push(nodeLookup[edge.data.source]);
          }
          if (nodeLookup[edge.data.target]) {
            acc.push(nodeLookup[edge.data.target]);
          }

          return acc;
        },
        []
      );

      // see if we have complex
      const complexNodes = reactionNodes.filter((node: NodeDefinition) => {
        const hasSyntensorData =
          node.data.syntensor && node.data.syntensor.simulations;
        return node.data.class === "complex" && hasSyntensorData;
      });

      if (complexNodes.length > 0) {
        reactionNodes.forEach((node: NodeDefinition) => {
          if (node.data.class === "EntityWithAccessionedSequence") {
            node.data.class = "hidden";
          }
        });
      }
    }
  });

  //  filter those elements which are subnodes but they do not have
  //  syntensor data otherwise they just add to the noise, we might
  //  want to allow to show them somehow later (setting in the UI)
  graph.nodes = graph.nodes.filter((node: NodeDefinition) => {
    const shouldBeIncluded = !node.data.isSubnode || node.data.syntensor;
    return shouldBeIncluded;
  });

  //  filter edges for nodes which have been filtered out
  //  or do not exist for some reason (e.g. corrupted initial data)
  graph.edges = graph.edges.filter((edge: EdgeDefinition) => {
    const sourceNode = graph.nodes.find((node: NodeDefinition) => {
      return node.data.id === edge.data.source;
    });
    const targetNode = graph.nodes.find((node: NodeDefinition) => {
      return node.data.id === edge.data.target;
    });
    const shouldBeIncluded = sourceNode && targetNode;
    return shouldBeIncluded;
  });

  return graph;
}
