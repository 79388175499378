import { FormEvent, ReactNode, RefObject, useState } from "react";

import SearchInput, { MIN_CHARS } from "./search_input";
import SuggestionsList from "./suggestions_list";
import Button from "../button";
import getCopy from "../../data/copy";
import { KEY_CODES } from "../../utils/key_utils";
import { DEBOUNCE_DELAY } from "./search_input";

import styles from "./search_form.module.css";

export type PlaceholderFn = (_?: string) => void;

export interface ISearchFormProps<SuggestionType = unknown> {
  placeholder?: string;
  suggestions?: SuggestionType[] | null;
  defaultInputValue?: string;
  isLoading?: boolean;
  beforeChildren?: ReactNode;
  afterChildren?: ReactNode;
  debounceDelay?: number;
  onChange?: (_: string) => void;
  onSearch?: (_: string) => void;
  onSubmit?: (_: string) => void;
  onClose?: () => void;
  onCancel?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onInputFocus?: () => void;
  onInputBlur?: () => void;
  onItemSelected?: (suggestion: SuggestionType) => void;
  hasSubmitBtn?: boolean;
  hasCancelBtn?: boolean;
  inputRef?: RefObject<HTMLInputElement> | null;
  size?: string;
  minChars?: number;
  name?: string;
  autoComplete?: string;
  disabled?: boolean;
}

export default function SearchForm<SuggestionType>({
  placeholder = "",
  suggestions = null,
  defaultInputValue = "",
  isLoading = false,
  beforeChildren = null,
  afterChildren = null,
  debounceDelay = DEBOUNCE_DELAY,
  onChange,
  onSearch,
  onSubmit,
  onClose,
  onCancel,
  onFocus,
  onBlur,
  onInputFocus,
  onInputBlur,
  onItemSelected,
  hasSubmitBtn = false,
  hasCancelBtn = false,
  inputRef = null,
  size = "large",
  minChars = MIN_CHARS,
  name = "search",
  autoComplete = "on",
  disabled = false,
}: ISearchFormProps<SuggestionType>) {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    if (onSubmit && typeof onSubmit === "function") {
      onSubmit(evt.currentTarget.search.value);
    }
  };

  const handleKeyDown = (evt: { keyCode: number }) => {
    switch (KEY_CODES[evt.keyCode]) {
      case "up":
        if (suggestions) {
          const upIndex = Math.max(selectedIndex - 1, 0);
          setSelectedIndex(upIndex);
        }
        break;
      case "down":
        if (suggestions) {
          const downIndex = Math.min(selectedIndex + 1, suggestions.length - 1);
          setSelectedIndex(downIndex);
        }

        break;
      case "enter":
        if (suggestions) {
          if (selectedIndex > -1 && selectedIndex < suggestions.length) {
            const suggestion = suggestions[selectedIndex];
            if (onItemSelected) {
              onItemSelected(suggestion);
            }
          }
        }
        break;
      case "escape":
        if (onClose) {
          onClose();
        }
        break;
      default:
        break;
    }
  };

  //  does input need offset
  const hasBeforeChildren = !!beforeChildren;
  const hasBtns = hasSubmitBtn || hasCancelBtn;

  return (
    <form
      className={styles.searchForm}
      onSubmit={handleSubmit}
      onKeyDown={handleKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {beforeChildren}
      <div className={styles.input}>
        <SearchInput
          inputRef={inputRef}
          isLoading={isLoading}
          placeholder={placeholder}
          hasLeftOffset={hasBeforeChildren}
          defaultValue={defaultInputValue}
          onChange={onChange}
          onSearch={onSearch}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          size={size}
          minChars={minChars}
          name={name}
          autoComplete={autoComplete}
          disabled={disabled}
          debounceDelay={debounceDelay}
        />
        {hasBtns && (
          <div className={styles.btns}>
            {hasSubmitBtn && (
              <Button type="submit" size={size} role="primary">
                {getCopy("studies_search_submit")}
              </Button>
            )}
            {hasCancelBtn && (
              <Button
                className={styles.cancelBtn}
                size={size}
                role="secondary"
                onClick={onCancel}
                type="button"
              >
                {getCopy("studies_search_cancel")}
              </Button>
            )}
          </div>
        )}
      </div>
      {afterChildren}
      <SuggestionsList<SuggestionType>
        selectedIndex={selectedIndex}
        onItemSelected={onItemSelected}
        suggestions={suggestions}
      />
    </form>
  );
}
