import { useRouteMatch } from "react-router-dom";

import Button from "@syntensor/common/components/button";
import Table from "@syntensor/common/components/table";
import { IStudyCompound, ICellLine } from "@syntensor/common/types";
import getCopy from "@syntensor/common/data/copy";

import {
  IStudyMatchParams,
  IStudyPathwayDetailComponentMatchParams,
} from "../../../types";
import { STUDY_URL_PREFIX } from "../../../routes";
import { cellRendererFn } from "../../../component_detail/header";
import { getCompoundColName } from "../../../components/columns";

import styles from "./component_detail.module.css";

export function getVerticalTableCols() {
  return [
    {
      id: "compound",
      name: "Compound",
    },
    {
      id: "perturbation",
      name: "Differential expression (log2FC)",
      cellRenderer: cellRendererFn,
    },
  ];
}

export function getVerticalTableData(
  compounds: IStudyCompound[],
  perturbations: Record<string, number>,
  cellLine: string
) {
  if (!compounds || !perturbations || !cellLine) {
    return [];
  }

  return compounds.map((compound: IStudyCompound) => {
    const simulationKey = `${cellLine}:${compound.compoundId}`;
    const perturbation = perturbations[simulationKey];

    const name = getCompoundColName(compound);
    return {
      compound: name,
      perturbation,
    };
  });
}

export interface IStudiesPathwayDetailComponentPerturbationsProps {
  compounds: IStudyCompound[];
  simulations: { perturbation: Record<string, number> };
  cellLines: ICellLine[];
}

export default function StudiesPathwayDetailComponentPerturbations({
  compounds,
  simulations,
  cellLines,
}: IStudiesPathwayDetailComponentPerturbationsProps) {
  const { params } = useRouteMatch<IStudyPathwayDetailComponentMatchParams>();
  const { componentId } = params;

  const studyMatch = useRouteMatch<IStudyMatchParams>(STUDY_URL_PREFIX);
  const studyBaseUrl = studyMatch?.url || "";

  const cellLine = cellLines && cellLines.length ? cellLines[0] : "";

  const tableCols = getVerticalTableCols();
  let tableData: { compound: string; perturbation: number }[] = [];
  if (simulations && cellLine) {
    tableData = getVerticalTableData(
      compounds,
      simulations.perturbation,
      cellLine.id
    );
  }

  return (
    <div className={styles.perturbations}>
      <Table
        config={{
          cols: tableCols,
        }}
        data={tableData}
      />
      <div className={styles.btns}>
        <Button
          data-testid="pathway-explorer_component-details"
          role="light"
          to={`${studyBaseUrl}/components/${componentId}`}
        >
          {getCopy("studies_pathway-detail_component-detail")}
        </Button>
      </div>
    </div>
  );
}
