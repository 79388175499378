import { ScaleLinear, scaleLinear } from "d3-scale";

import classes from "./box_plot.module.css";

//  used for ADMET'S and similar
export const DEFAULT_SCALE = scaleLinear<string, number>()
  .range(["0%", "100%"])
  .domain([0, 100]);

export interface IBoxPlotProps {
  data: {
    minimum: number;
    maximum: number;
    quartile1: number;
    quartile3: number;
    mean: number;
  } | null;
  color: string;
  scale: ScaleLinear<number | string, number | string>;
}

export default function BoxPlot({
  data,
  color = "#000",
  scale = DEFAULT_SCALE,
}: IBoxPlotProps) {
  if (!data) {
    return null;
  }

  const minimum = scale(data.minimum);
  const maximum = scale(data.maximum);
  const quartile1 = scale(data.quartile1);
  const quartile3 = scale(data.quartile3);
  const mean = scale(data.mean);

  const whiskerWidth =
    parseFloat(maximum.toString()) - parseFloat(minimum.toString());
  const boxWidth =
    parseFloat(quartile3.toString()) - parseFloat(quartile1.toString());

  const hasWhiskers = whiskerWidth > 0;
  const whiskerStyle = hasWhiskers
    ? {
        width: whiskerWidth,
        left: minimum,
        "--color": color,
      }
    : { display: "none" };
  const whiskerLineStyle = {
    backgroundColor: color,
  };

  const hasBox = boxWidth > 0;
  const boxStyle = hasBox
    ? {
        width: boxWidth,
        left: quartile1,
        backgroundColor: color,
      }
    : {};

  const hasMean = mean !== undefined && mean !== null;
  const hasCircle = !hasBox && hasMean;
  const circleStyle = hasCircle
    ? {
        left: mean,
        backgroundColor: color,
      }
    : {};

  return (
    <div className={classes.boxPlot}>
      <span className={classes.whiskers} style={whiskerStyle}>
        <span style={whiskerLineStyle} />
      </span>
      {hasBox && <span className={classes.box} style={boxStyle} />}
      {hasCircle && <span className={classes.circle} style={circleStyle} />}
    </div>
  );
}
