import cn from "classnames";

import { isNotEmpty } from "../../utils/obj_utils";

import styles from "./table.module.css";

export type TCellValue = string | number | null | undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TCellRenderer = (_: any, __: any, ___: any, index: number) => any;

export interface ITableCol {
  id?: string;
  name?: string;
  className?: string;
  colSpan?: number;
  width?: number | string;
  cellRenderer?: TCellRenderer;
  headerRenderer?: (
    col: ITableCol,
    data: any,
    i?: number
  ) => React.ReactElement;
  // (
  //   d: Record<string, TCellValue>,
  //   datum: TCellValue,
  //   col: ITableCol | string,
  //   i: number
  // ) => ReactElement;
}

export interface ITableProps {
  config: {
    cols?: ITableCol[];
  };
  data: Record<string, any>[];
}

export function defaultCellRenderer(d: TCellValue) {
  const isNull = typeof d === "undefined" || d === null || d === "";
  return !isNull ? d : "-";
}

export function linkCellRenderer(d: string) {
  return (
    <a className={styles.link} href={d} target="_blank" rel="noreferrer">
      {d}
    </a>
  );
}

export function descriptionCellRenderer(d: TCellValue) {
  return <span className={styles.description}>{d}</span>;
}

export default function Table({ config = {}, data = [] }: ITableProps) {
  const { cols } = config;

  return (
    <table className={styles.table} data-testid="table">
      <thead>
        <tr>
          {cols &&
            cols.map((col, i) => {
              const colId = col && typeof col !== "string" ? col.id : col;
              const colName = col && typeof col !== "string" ? col.name : col;
              const colSpan =
                col && typeof col !== "string" && col.colSpan ? col.colSpan : 1;

              //  we want to allow to render more complex header cells (e.g.)
              const rendererHeaderCell =
                col && typeof col.headerRenderer === "function"
                  ? col.headerRenderer(col, i)
                  : colName;

              const style: Record<string, string | number> = {};
              if (typeof col !== "string" && col.width) {
                style.width = col.width;
              }

              return (
                <th
                  key={colId}
                  colSpan={colSpan}
                  style={style}
                  className={styles.th}
                >
                  {rendererHeaderCell}
                </th>
              );
            })}
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((datum, i: number) => {
            return (
              <tr key={i}>
                {cols &&
                  cols.map((col, i2: number) => {
                    const colId = col && typeof col !== "string" ? col.id : col;
                    const cellValue =
                      colId && datum && isNotEmpty(datum[colId])
                        ? datum[colId]
                        : "";
                    const className =
                      (col && typeof col !== "string" && col.className) || "";

                    const colClassNames = cn(styles.td, {
                      [className]: !!className,
                    });
                    const cellRenderer =
                      (typeof col !== "string" && col.cellRenderer) ||
                      defaultCellRenderer;

                    //  could be potentially rendering more cells
                    if (cellValue && Array.isArray(cellValue)) {
                      return cellValue.map((val, i3) => {
                        return (
                          <td key={i3} className={colClassNames}>
                            {cellRenderer(val, datum, col, i3)}
                          </td>
                        );
                      });
                    }

                    //  render single cell
                    return (
                      <td key={i2} className={colClassNames}>
                        {cellRenderer(cellValue, datum, col, i)}
                      </td>
                    );
                  })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}
